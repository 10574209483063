import React, { Component } from 'react';
import { FaFileContract } from 'react-icons/fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProspecto } from '~/Store/Prospecto/actions';
import Outsider from '../../Outsider';
import { Formik } from 'formik';
import { vincularSenhaLancamento } from '../../../Services/Service';
// import { default as auth } from '../../../Utils/AuthStorage';
import { EMPREENDIMENTO_LANCAMENTO } from '~/constant';

class VinculadorSenhaLancamento extends Component {
  state = {
    isEditandoSenha: false,
    isVinculando: false,
    isSucesso: null,
    isError: null,
  };

  editarSenha = isEditandoSenha => {
    this.setState({ isEditandoSenha });
  };

  errorEnum = {
    ERRO_SENHA_EM_USO: 'Senha já está em uso',
    ERRO_NENHUM_LANCAMENTO_ATIVO:
      'Nenhum empreendimento em lançamento no momento',
    ERRO_DESCONHECIDO: 'Erro desconhecido',
  };

  mensagens = {
    SENHA_VINCULADA: 'Senha vinculada com sucesso',
    SENHA_ATUALIZADA: 'Senha alterada com sucesso',
    VERIFICADO_LANCAMENTO: 'O cliente optou por contrato digital.',
    SENHA_JA_VINCULADA: 'Senha alterada com sucesso',
    ERRO_SENHA_EM_USO: 'Senha em uso!',
    ERRO_NENHUM_LANCAMENTO_ATIVO: 'Nenhum lançamento ativo!',
    ERRO_DESCONHECIDO: 'Erro desconhecido! Comunique a TI!',
  };

  timeout = null;

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  setErrorVinculando = isError => {
    this.setState({
      isError,
    });
  };

  onClickVincularSenha = ({ codigo_prospecto, senha, codigo_familia }) => {
    this.setState({
      isVinculando: true,
      isSucesso: null,
      isError: null,
    });

    let request = {
      codigo_prospecto,
      senha: parseInt(senha),
      codigo_familia,
    };

    return vincularSenhaLancamento(request)
      .then(response => {
        var mensagem = this.mensagens[response.message];

        if (!mensagem.includes('erro')) this.props.setProspecto('senha', senha);
        // this.props.setProspecto('acompanhantes', acompanhantes);

        this.setState({
          isVinculando: false,
          isSucesso: mensagem,
          isEditandoSenha: false,
        });

        this.timeout = setTimeout(() => {
          this.setState({
            isSucesso: null,
          });
        }, 5000);
      })
      .catch(error => {
        console.error(error);
        this.setState({
          isVinculando: false,
          isError: this.errorEnum[error.message],
        });
      });
  };

  render() {
    const { prospecto } = this.props;
    const { codigo_familia, senha, codigo } = prospecto;
    const { isEditandoSenha, isVinculando, isSucesso, isError } = this.state;
    const possuiSenha = Number.isInteger(senha);

    if (codigo_familia === EMPREENDIMENTO_LANCAMENTO) {
      return (
        <Outsider
          onClickOut={() => {
            if (!isVinculando) {
              this.editarSenha(false);
            }
          }}
        >
          <div className="VinculadorSenhaLancamento d-flex justify-content-end d-md-block">
            {isEditandoSenha ? (
              <Formik
                initialValues={{ senha, codigo_familia }}
                onSubmit={values =>
                  this.onClickVincularSenha({
                    ...values,
                    codigo_prospecto: codigo,
                  })
                }
                validate={values => {
                  let errors = {};

                  if (isError) this.setErrorVinculando(false);

                  if (!values.senha) {
                    errors.senha = 'Informe uma senha';
                  } else if (isNaN(parseInt(values.senha))) {
                    errors.senha = 'Senha inválida';
                  }

                  return errors;
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-row align-items-center">
                      <div className="col-auto">
                        <label htmlFor="senha">
                          <small>Senha</small>
                        </label>
                        <input
                          required
                          type="tel"
                          className={`${errors.senha &&
                            'is-invalid'}  form-control form-control-sm`}
                          disabled={isVinculando}
                          onChange={handleChange}
                          autoFocus
                          value={values.senha}
                          id="senha"
                          name="senha"
                          placeholder="Ex.: 77"
                        />
                      </div>
                      {/* <div className="col-auto">
                        <label htmlFor="inlineFormInputGroup">
                          <small>Acompanhantes</small>
                        </label>
                        <input
                          type="tel"
                          value={values.Acompanhantes}
                          className="form-control form-control-sm"
                          disabled={isVinculando}
                          onChange={handleChange}
                          name="Acompanhantes"
                          id="inlineFormInput"
                          placeholder="Ex.: 1"
                        />
                      </div> */}
                      <div className="col-auto">
                        <button
                          type="submit"
                          disabled={isVinculando}
                          className="btn btn-outline-secondary btn-sm mt-4"
                        >
                          {isVinculando ? 'Vinculando...' : 'Vincular'}
                        </button>
                      </div>
                    </div>
                    {errors.senha && (
                      <small className="text-danger">{errors.senha}</small>
                    )}
                    {isError && (
                      <small className="text-danger">{isError}</small>
                    )}
                  </form>
                )}
              </Formik>
            ) : (
              <div className="d-flex align-items-end d-block VinculadorSenhaLancamento__wrapper-editar">
                {isSucesso && (
                  <small
                    className={`text-${
                      isSucesso.includes('sucesso') ? 'success' : 'warning'
                    } mr-2 d-block`}
                  >
                    {isSucesso}
                  </small>
                )}
                <button
                  className={`VinculadorSenhaLancamento__btn-editar btn btn-outline-${
                    possuiSenha ? 'success' : 'danger'
                  }`}
                  type="button"
                  // onClick={() => this.editarSenha(true)}
                  disabled={possuiSenha}
                  onClick={() =>
                    this.onClickVincularSenha({
                      codigo_familia,
                      senha: 0,
                      codigo_prospecto: codigo,
                    })
                  }
                >
                  <span>
                    <FaFileContract />{' '}
                    <small>
                      {possuiSenha ? 'Doc. Digital' : 'Doc. físico.'}
                    </small>
                  </span>
                </button>
              </div>
            )}
          </div>
        </Outsider>
      );
    }

    return null;
  }
}

const mapStateToProps = state => ({
  prospecto: state.prospecto.detail,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProspecto,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VinculadorSenhaLancamento);
