import AuthStorage from '../../Utils/AuthStorage';
import history from '../../Config/history';

//Actions Types
export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGOUT = 'DO_LOGOUT';

export const doLogin = auth => ({
  type: 'DO_LOGIN',
  payload: auth,
});

export const doLogout = (redirect = true) => dispatch => {
  AuthStorage.removeToken();
  dispatch({ type: DO_LOGOUT });
  if (redirect) {
    history.push('/login?logout=true');
  }
};
