import React from 'react';

import AuthStorage from '~/Utils/AuthStorage';

import empreendimento from '~/Images/empreendimentos.svg';
import prospecto from '~/Images/prospecto.svg';
import sismac from '~/Images/sismac.svg';
import speedometer from '~/Images/produtividade.svg';
import handingMoney from '~/Images/estorno.svg';
import declined from '~/Images/declined.svg';
import transfer from '~/Images/transfer.svg';
import sistema from '~/Images/sistema.svg';
import vendas from '~/Images/vendas.svg';
import proposalAccept from '~/Images/proposal-accept.svg';
import proposalReject from '~/Images/proposal-reject.svg';
import proposalCancel from '~/Images/proposal-cancel.svg';
import discount from '~/Images/discount.svg';
import megaphone from '~/Images/megaphone.svg';
import {
  FaUsers,
  FaBuilding,
  FaSitemap,
  FaExchangeAlt,
  FaDollarSign,
  FaBarcode,
  FaCog,
  FaEnvelope,
  FaDesktop,
  FaCogs,
  FaBolt,
  FaArchive,
  FaThList,
  FaChartBar,
  FaGlobe,
  FaFilePdf,
  FaInfoCircle,
  FaUser,
  FaRocket,
} from 'react-icons/fa';

import { FcBullish } from 'react-icons/fc';

export const URLV1 = 'https://www.revobrasil.com.br/Extranet';
export const URLV2 = 'https://extranet.revobrasil.com.br';

const links = [
  {
    icon: <FaRocket />,
    label: 'Menu de Lançamento de Vendas',
    dropdown: [
      {
        header: 'Proposta',
      },
      {
        href: '/prospectos',
        icon: <img src={prospecto} alt="Prospectos" />,
        label: 'Prospectos',
      },
      {
        divider: true,
      },
      {
        header: 'Atendimento',
      },
      {
        href: `${URLV1}/Lancamento/Colaborador/Atendimento.aspx`,
        icon: <FaDesktop />,
        label: 'Iniciar / Encerrar Atendimento',
      },
      {
        divider: true,
      },
      {
        header: 'Mapa/Espelho',
      },
      {
        href: `${URLV1}/PDV/MapaEmpreendimento.aspx`,
        icon: (
          <img
            className="invert-color"
            src={empreendimento}
            alt="Mapa de empreendimentos"
          />
        ),
        label: 'Mapa do empreendimento',
      },
      {
        divider: true,
      },
      {
        header: 'Adm. Vendas',
      },
      {
        href: `${URLV1}/Comercial/AdministracaoVendas.aspx`,
        icon: <FaChartBar />,
        label: 'Administração vendas',
      },
    ],
    visible: () => {
      return AuthStorage && AuthStorage.isAtendenteLancamento();
    },
  },

  {
    icon: <FaUsers />,
    label: 'Prospectos',
    dropdown: [
      {
        href: '/prospectos',
        icon: <img src={prospecto} alt="Prospectos" />,
        label: (
          <>
            Prospectos <span className="badge badge-primary">novo</span>
          </>
        ),
      },
      {
        href: '/atribuir/prospecto',
        icon: <img src={transfer} alt="Atribuir prospecto" />,
        label: 'Atribuir Prospecto',
        visible: () => {
          return (
            AuthStorage &&
            AuthStorage.temCaracteristicas([201, 199], [495]) &&
            !AuthStorage.isAtendenteLancamento()
          );
        },
      },
    ],
    visible: () => {
      return (
        AuthStorage &&
        AuthStorage.temCaracteristicas([], [501]) &&
        !AuthStorage.isAtendenteLancamento()
      );
    },
  },
  {
    icon: <img src={empreendimento} alt="Empreendimentos" />,
    label: 'Empreendimentos',
    dropdown: [
      {
        href: `${URLV1}/PDV/MapaEmpreendimento.aspx`,
        icon: (
          <img
            className="invert-color"
            src={empreendimento}
            alt="Mapa de empreendimentos"
          />
        ),
        label: 'Mapa de empreendimentos',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([200, 201, 277], [224]);
        },
      },
      {
        href: `${URLV1}/WebImob/Comercial/MapaEmpreend.aspx`,
        icon: (
          <img
            className="invert-color"
            src={empreendimento}
            alt="Mapa de empreendimentos"
          />
        ),
        label: 'Mapa de GES',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([201, 277], [200, 495]);
        },
      },
      {
        href: `${URLV1}/Empreendimento/ImoveisDisponiveis.aspx`,
        icon: <FaBuilding />,
        label: 'Estoque disponível',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([200, 201, 277]);
        },
      },
      {
        href: `${URLV1}/Empreendimento/CaixaLancamento.aspx`,
        icon: <FaArchive />,
        label: 'Caixa lançamento',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([], [179, 495]);
        },
      },
      {
        href: `${URLV1}/Empreendimento/HierarquiaComercial.aspx`,
        icon: <FaSitemap />,
        label: 'Hierarquia comercial',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([199], [495]);
        },
      },
      {
        href: `${URLV1}/WebImob/Comercial/ListarTabelaPrecos.aspx`,
        icon: <FaThList />,
        label: 'Tabelas de preços',
      },
    ],
    visible: () => {
      return (
        AuthStorage &&
        AuthStorage.temCaracteristicas([], [501]) &&
        !AuthStorage.isAtendenteLancamento()
      );
    },
  },
  {
    icon: <img src={vendas} alt="Vendas" />,
    label: 'Vendas',
    dropdown: [
      {
        href: `${URLV1}/Comercial/AdministracaoVendas.aspx`,
        icon: <FaChartBar />,
        label: 'Administração vendas',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([200, 201, 277], [224]);
        },
      },
      {
        href: `${URLV1}/Descontos/Consultar.aspx`,
        icon: <img src={discount} alt="Atribuir prospecto" />,
        label: 'Solicitação de descontos',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([200, 201, 373, 376], [224]);
        },
      },
      {
        href: `${URLV1}/WebImob/Comercial/TransferenciaPortador.aspx`,
        icon: <FaExchangeAlt />,
        label: 'Transferência do portador',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([], [179]);
        },
      },
      {
        href: `${URLV1}/Comercial/ConsultarSaldos.aspx`,
        icon: <FaDollarSign />,
        label: 'Consulta de saldo',
      },
      {
        href: '/proposta/estorno/solicitacoes',
        icon: <img src={declined} alt="Estorno de proposta" />,
        label: 'Estorno de proposta',
        visible: () => {
          return !AuthStorage ? false : AuthStorage.temCaracteristicas([277]);
        },
      },
      {
        href: '/inadimplencia',
        icon: <img src={handingMoney} alt="Inadimplência" />,
        label: 'Inadimplência',
      },
      {
        divider: true,
      },
      {
        header: 'Proposta',
      },
      {
        href: `${URLV1}/WebImob/Comercial/Propostas.aspx`,
        icon: <img src={proposalAccept} alt="Propostas gravadas" />,
        label: 'Propostas gravadas',
        visible: () => {
          return !AuthStorage ? false : AuthStorage.temCaracteristicas([216]);
        },
      },
      {
        href: `${URLV1}/Propostas/AutorizaPropostas.aspx`,
        icon: <img src={proposalReject} alt="Autorizar proposta" />,
        label: 'Autorizar proposta',
        visible: () => {
          return !AuthStorage ? false : AuthStorage.temCaracteristicas([196]);
        },
      },
      {
        href: '/vendas/autorizacao',
        icon: <img src={proposalReject} alt="Autorizar Venda v2" />,
        label: 'Autorizar Venda v2',
        visible: () => {
          return !AuthStorage ? false : AuthStorage.temCaracteristicas([196]);
        },
      },
      {
        href: `${URLV1}/Propostas/PrevendaSemConjuge.aspx`,
        icon: (
          <img src={proposalCancel} alt="Venda sem assinatura do cônjuge" />
        ),
        label: 'Pré-Venda sem cônjuge',
        visible: () => {
          return !AuthStorage ? false : AuthStorage.temCaracteristicas([242]);
        },
      },
      {
        divider: true,
      },
      {
        header: 'PDV',
      },
      {
        href: `${URLV1}/PDV/ControlePDV.aspx`,
        icon: <FaSitemap />,
        label: 'Controle do PDV',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([200, 201, 279, 295, 347]);
        },
      },
      {
        href: `${URLV1}/PDV/Fechamento.aspx`,
        icon: <FaBarcode />,
        label: 'Fechamento',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([278, 296, 348]);
        },
      },
      {
        href: `${URLV1}/PDV/Consultar.aspx`,
        icon: <FaCog />,
        label: 'Parâmetros',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicaGerParametros();
        },
      },
      {
        href: `${URLV1}/PDV/VincularUsuarios.aspx`,
        icon: <FaUsers />,
        label: 'Vincular usuário',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicaGerParametros();
        },
      },
    ],
    visible: () => {
      return (
        AuthStorage &&
        AuthStorage.temCaracteristicas([], [495, 501]) &&
        !AuthStorage.isAtendenteLancamento()
      );
    },
  },
  {
    icon: <FaChartBar />,
    label: 'Relatórios',
    dropdown: [
      {
        href: `${URLV1}/Relatorios.aspx`,
        icon: <FaChartBar />,
        label: 'Ver todos',
      },
      {
        href: '/comercial/relatorio/produtividade',
        icon: <img src={speedometer} alt="Produtividade" />,
        label: <>Produtividade</>,
        visible: () => {
          return !AuthStorage ? false : AuthStorage.temCaracteristicas([518]);
        },
      },
      {
        href: '/comercial/relatorio/comissao',
        icon: <FcBullish />,
        label: <>Comissões</>,
        visible: () => {
          return !AuthStorage ? false : AuthStorage.temCaracteristicas([223]);
        },
      },
    ],
    visible: () => {
      return (
        AuthStorage &&
        AuthStorage.temCaracteristicas([], [495, 501]) &&
        !AuthStorage.isAtendenteLancamento()
      );
    },
  },
  {
    icon: <FaGlobe />,
    label: 'Sismac',
    dropdown: [
      {
        href: `${URLV1}/Sismac/Empreendimentos.aspx`,
        icon: <img src={sismac} alt="Ficha dos empreendimentos" />,
        label: 'Ficha dos empreendimentos',
      },
      {
        href: `${URLV1}/Sismac/Assinatura.aspx`,
        icon: <FaEnvelope />,
        label: 'Assinatura imobiliária',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([200, 201], [495]);
        },
      },
      {
        href: `${URLV1}/Sismac/PecasFacebook.aspx`,
        icon: <img src={megaphone} alt="Campanha sazonal" />,
        label: 'Campanha sazonal',
      },
    ],
    visible: () => {
      return AuthStorage && !AuthStorage.isAtendenteLancamento();
    },
  },
  {
    icon: <FaCogs />,
    label: 'Configurações',
    dropdown: [
      {
        header: 'Gerencial',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([], [495]);
        },
      },
      {
        href: `${URLV1}/Gerencial/EscalaCorretores.aspx`,
        icon: <FaCog />,
        label: 'Escala corretores',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([], [495]);
        },
      },
      {
        href: `${URLV1}/Gerencial/Campanha.aspx`,
        icon: <FaBolt />,
        label: 'Campanha',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicaGerParametros([495]);
        },
      },
      {
        href: `${URLV1}/Gerencial/ManutencaoBoletos.aspx`,
        icon: <FaFilePdf />,
        label: 'Manutenção de boletos',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicaGerParametros([495]);
        },
      },
      {
        divider: true,
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([], [495]);
        },
      },
      {
        header: 'Sistema',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicas([], [495]);
        },
      },
      // {
      //   href: `${URLV1}/Novidades.aspx`,
      //   icon: <FaBullhorn />,
      //   label: 'Novidades',
      // },
      {
        href: `${URLV1}/Lancamento/Treinamento.aspx`,
        icon: <FaInfoCircle />,
        label: 'Treinamento',
        // visible: () => {
        //   return !AuthStorage ? false : AuthStorage.temCaracteristicas([]);
        // },
      },
      {
        href: `${URLV1}/Usuarios/UsuariosConsultar.aspx`,
        icon: <FaUser />,
        label: 'Usuários',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicaGerParametros();
        },
      },
      {
        href: '/configuracoes/prospecto/fluxo',
        icon: <img src={sistema} alt="Fluxo Prospecto" />,
        label: 'Fluxo do prospecto',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicaGerParametros([495]);
        },
      },
      {
        href: '/configuracoes/campanha',
        icon: <FaRocket />,
        label: 'Ranking de Campanha',
        visible: () => {
          return !AuthStorage
            ? false
            : AuthStorage.temCaracteristicaGerParametros();
        },
      },
    ],
    visible: () => {
      return (
        AuthStorage &&
        AuthStorage.temCaracteristicas([], [501]) &&
        !AuthStorage.isAtendenteLancamento()
      );
    },
  },
];

const getLinks = () =>
  links
    .map(link => {
      if (!link.dropdown) return link;

      return {
        ...link,
        dropdown: link.dropdown.filter(
          drop => !drop.visible || (drop.visible && drop.visible())
        ),
      };
    })
    .filter(link => !link.visible || (link.visible && link.visible()));

export default getLinks;
