import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { BsInbox } from 'react-icons/bs';
import { MdError } from 'react-icons/md';
import { AiFillFilter, AiOutlineFilter } from 'react-icons/ai';
import { Button } from 'react-bootstrap';

import * as api from '~/Services/Service';
import AuthStorage from '~/Utils/AuthStorage';
import { getToday } from '~/Utils/Helpers';

import Aviso from '~/Components/Aviso';
import Header from '~/Components/Header/Header';
import Loading from '~/Components/Loading';
import FiltrosInadimplencia from '~/Components/Inadimplencia/FiltrosInadimplencia';
import TabelaInadimplencia from '~/Components/Inadimplencia/TabelaInadimplencia';
import EstatisticasInadimplencia from '~/Components/Inadimplencia/EstatisticasInadimplencia';
import ModalDetalhesClienteInadimplencias from '~/Components/Inadimplencia/ModalDetalhesClienteInadimplencias';

//import mockInadimplencias from './mock';

const CobrancaInadimplencia = ({
  empreendimentos,
  imobiliarias,
  corretores,
  isLoadingOptions,
}) => {
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [registros, setRegistros] = useState([]);

  const [erroBuscaInadimpencias, setErroBuscaInadimpencias] = useState(false);
  const [carregandoInadimplencias, setCarregandoInadimplencias] = useState(
    false
  );
  const [registroSelecionado, setRegistroSelecionado] = useState(null);

  useEffect(() => {
    const filtros = {
      data_inicial: getToday().add(-90, 'days'),
      data_final: getToday(),
    };
    buscarListaInadimplencias(AuthStorage.isCorretor() ? {} : filtros);
  }, []);

  const toArrayObjetos = columsRowsData => {
    const array = [];
    for (const row of columsRowsData.rows) {
      const obj = {};
      for (let index = 0; index < row.length; index++) {
        const value = row[index];
        const propertyName = columsRowsData.columns[index];
        obj[propertyName] = value;
      }
      array.push(obj);
    }
    return array;
  };

  const buscarListaInadimplencias = async filtros => {
    setErroBuscaInadimpencias(false);
    setCarregandoInadimplencias(true);
    setRegistros([]);
    try {
      const response = await api.buscarListaInadimplencias(filtros);
      //const response = mockInadimplencias;

      const registros = toArrayObjetos(response);
      setRegistros(registros);
    } catch (err) {
      setErroBuscaInadimpencias(true);
      toast.error(err.message);
    } finally {
      setCarregandoInadimplencias(false);
    }
  };

  const mostrarDetalhesRegistro = registro => {
    setRegistroSelecionado(registro);
  };

  const onCobrancaEncaminhada = numeroPreVenda => {
    setRegistros(
      registros.map(reg => {
        if (reg.numero_pre_venda === numeroPreVenda)
          reg.status_encaminhamento = 1;
        return reg;
      })
    );
    setRegistroSelecionado(null);
  };

  const onContatoRealizado = (numeroPreVenda, data) => {
    setRegistros(
      registros.map(reg => {
        if (reg.numero_pre_venda === numeroPreVenda)
          reg.ultimo_contato_corretor = data;
        return reg;
      })
    );
  };

  return (
    <>
      <Header title="Inadimplência">
        <Button
          variant={mostrarFiltros ? 'secondary' : 'outline-secondary'}
          className="rounded-circle"
          onClick={() => setMostrarFiltros(!mostrarFiltros)}
        >
          {mostrarFiltros ? <AiFillFilter /> : <AiOutlineFilter />}
        </Button>
      </Header>
      {mostrarFiltros && (
        <FiltrosInadimplencia onSubmit={buscarListaInadimplencias} />
      )}
      {carregandoInadimplencias && <Loading label="Carregando..." />}
      {!carregandoInadimplencias &&
        !erroBuscaInadimpencias &&
        registros.length === 0 && (
          <Aviso
            icon={<BsInbox fontSize={72} />}
            text="Dados não encontrados. Tente outros filtros."
          />
        )}
      {erroBuscaInadimpencias && (
        <Aviso
          icon={<MdError fontSize={72} />}
          text="Ocorreu um erro ao carregar os dados."
        />
      )}
      {registros.length > 0 && (
        <>
          <EstatisticasInadimplencia
            registros={registros}
            imobiliarias={imobiliarias}
            corretores={corretores}
            empreendimentos={empreendimentos}
            isLoadingOptions={isLoadingOptions}
          />
          {isLoadingOptions && <Loading label="Carregando dados..." />}
          {!isLoadingOptions && (
            <TabelaInadimplencia
              registros={registros}
              mostrarDetalhesRegistro={mostrarDetalhesRegistro}
              empreendimentos={empreendimentos}
            />
          )}
        </>
      )}

      <ModalDetalhesClienteInadimplencias
        onCobrancaEncaminhada={onCobrancaEncaminhada}
        onContatoRealizado={onContatoRealizado}
        registro={registroSelecionado}
        onHide={() => setRegistroSelecionado(null)}
        empreendimentos={empreendimentos}
      />
    </>
  );
};

const mapStateToProps = state => ({
  isLoadingOptions: state.prospecto.isLoadingOptions,
  empreendimentos: state.empreendimentos.list,
  imobiliarias: state.corretor.listaGerentes,
  corretores: state.corretor.listaCorretores,
});

export default connect(mapStateToProps)(CobrancaInadimplencia);
