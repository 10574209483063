import {
  COMISSAO_CARREGADA,
  CARREGANDO_COMISSAO,
} from '~/Store/Comissao/reducer';
import * as api from '../../Services/Service';
import { default as auth } from '~/Utils/AuthStorage';

export const carregarComissoes = params => async dispatch => {
  dispatch({ type: CARREGANDO_COMISSAO });
  let { codigo_gerente, codigo_corretor } = params;

  let codigo_usuario_comissao = !auth.isADM()
    ? auth.getUser().usuario
    : codigo_gerente || codigo_corretor;
  let codigo_usuario_corretor = codigo_corretor;

  let comissoes = await api.consultarComissao({
    codigo_usuario_comissao,
    codigo_usuario_corretor,
    ...params,
  });
  dispatch({
    type: COMISSAO_CARREGADA,
    payload: {
      comissoes,
    },
  });
};
