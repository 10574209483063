import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal, Form } from 'react-bootstrap';

//baseado em: https://medium.com/quadminds/react-confirm-dialog-as-service-8929c88a6702

let resolve;
class Confirm extends Component {
  static create(props = {}) {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<Confirm createConfirmProps={props} />, containerElement);
  }

  constructor() {
    super();

    this.state = {
      isOpen: false,
      showConfirmProps: {},
      promptMessage: undefined,
      isPrompt: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    this.setState({ isOpen: false });
    const dialogResult = {
      proceed: false,
      message: this.state.promptMessage,
    };
    resolve(dialogResult);
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    const dialogResult = {
      proceed: true,
      message: this.state.promptMessage,
    };
    resolve(dialogResult);
  }

  show(props = {}) {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({ isOpen: true, isPrompt: false, showConfirmProps });
    return new Promise(res => {
      resolve = res;
    });
  }

  prompt(props = {}) {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({ isOpen: true, isPrompt: true, showConfirmProps });
    return new Promise(res => {
      resolve = res;
    });
  }

  render() {
    const { isOpen, showConfirmProps, isPrompt, promptMessage } = this.state;
    const {
      message,
      title,
      confirmLabel,
      confirmVariant,
      cancelLabel,
      cancelVariant,
    } = showConfirmProps;
    return (
      <Modal show={isOpen} onHide={this.handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Tem certeza?'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{message || 'Confirmação'}</p>
          {isPrompt && (
            <Form.Control
              value={promptMessage}
              onChange={e => this.setState({ promptMessage: e.target.value })}
            ></Form.Control>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={cancelVariant || 'secondary'}
            onClick={this.handleCancel}
          >
            {cancelLabel || isPrompt ? 'Cancelar' : 'Não'}
          </Button>
          <Button
            variant={confirmVariant || 'danger'}
            disabled={isPrompt && !promptMessage}
            onClick={this.handleConfirm}
          >
            {confirmLabel || isPrompt ? 'Prosseguir' : 'Sim'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Confirm;
