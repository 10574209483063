import styled from 'styled-components';
import { warningColor, infoColor } from '~/Styles';

export const DashboardContainer = styled.div`
  padding-top: 53px;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  height: auto;
  min-height: calc(100vh - 60px);
`;

export const MainContent = styled.div.attrs({
  className: `ml-sm-auto`,
})`
  flex: 1;
  transition: all 0.3s ease;
  @media (min-width: 992px) {
    width: ${props => (props.isFull ? '100%' : '80%')};
  }
`;

let variants = {
  warning: warningColor,
  info: infoColor,
};
export const EnvironmentTag = styled.div`
  height: 15px;
  font-size: 10px;
  top: 0;
  border-radius: 0 0 7px 7px;
  text-transform: uppercase;
  font-weight: 500;
  position: fixed;
  width: 100%;
  z-index: 5000;
  text-align: center;
  left: calc(50% - 100px);
  max-width: 200px;

  @media (min-width: 768px) {
    left: calc(50% - 200px);
    max-width: 400px;
  }

  background: ${props =>
    variants[props.variant] ? variants[props.variant] : '#ccc'};
`;
