import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import {
  toggleModalOcorrencia,
  salvarOcorrencia,
} from '~/Store/Prospecto/actions';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import DateMask from '../../../DateMask';
import MaskedInput from 'react-text-mask';
import * as constants from '../../../../constant';
import _size from 'lodash/size';
import { FaSpinner } from 'react-icons/fa';

const ModalOcorrencia = ({
  modalOcorrenciaAberto,
  toggleModalOcorrencia,
  salvarOcorrencia,
  salvandoOcorrencia,
  erroSalvarOcorrencia,
  ocorrenciaParaEditar,
}) => {
  let initialValues = {
    titulo: '',
    texto: '',
    data_agendamento: '',
  };

  if (ocorrenciaParaEditar) {
    initialValues = {
      ...initialValues,
      ...ocorrenciaParaEditar,
    };
  }

  const schemaValidation = Yup.object().shape({
    texto: Yup.string().required('Informe o texto da ocorrência'),
    titulo: Yup.string().required('Informe a descrição da ocorrência'),
  });

  if (!modalOcorrenciaAberto) {
    return null;
  }

  return (
    <Formik
      onSubmit={salvarOcorrencia}
      initialValues={initialValues}
      validationSchema={schemaValidation}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
        submitCount,
      }) => (
        <Modal
          show={modalOcorrenciaAberto}
          onHide={() => toggleModalOcorrencia(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Adicionar ocorrência</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="values.titulo">
              <Form.Label>
                Titulo <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                name="titulo"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.titulo}
                maxLength={100}
                isInvalid={errors.titulo && touched.titulo}
                disabled={salvandoOcorrencia}
                placeholder="Título da ocorrência"
              />
              <Form.Control.Feedback type="invalid">
                {errors.titulo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="values.texto">
              <Form.Label>
                Descrição <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="texto"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.texto}
                isInvalid={errors.texto && touched.texto}
                disabled={salvandoOcorrencia}
                placeholder="Descrição da ocorrência"
              />
              <Form.Control.Feedback type="invalid">
                {errors.texto}
              </Form.Control.Feedback>
            </Form.Group>
            <DateMask
              allowFuture
              allowPast={false}
              initialValue={values.data_agendamento}
              onValidate={value => setFieldValue('data_agendamento', value)}
            >
              {({ dateError, dateValue, setDate }) => (
                <Form.Group controlId="values.data_agendamento">
                  <Form.Label>Data de Agendamento</Form.Label>
                  <MaskedInput
                    name="data_agendamento"
                    type="tel"
                    className={`form-control ${((errors.data_agendamento &&
                      touched.data_agendamento) ||
                      dateError) &&
                      'is-invalid'}`}
                    placeholder="Ex.: 10/06/2019"
                    mask={constants.MASK_DATE}
                    value={dateValue}
                    onChange={e => setDate(e.target.value)}
                    onBlur={handleBlur}
                    disabled={salvandoOcorrencia}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.data_agendamento}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {dateError}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </DateMask>
          </Modal.Body>
          <Modal.Footer>
            {submitCount > 0 && _size(errors) > 0 && (
              <div className="text-danger">
                Preenchas os campos corretamente antes de salvar
              </div>
            )}
            {erroSalvarOcorrencia && (
              <div className="text-danger">Erro ao salvar ocorrência</div>
            )}
            <Button
              variant="outline-primary"
              disabled={salvandoOcorrencia}
              onClick={() => {
                handleReset();
                toggleModalOcorrencia(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={salvandoOcorrencia}
            >
              {salvandoOcorrencia ? (
                <span>
                  <FaSpinner /> Salvando...
                </span>
              ) : (
                'Salvar'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  modalOcorrenciaAberto: state.prospecto.modalOcorrenciaAberto,
  salvandoOcorrencia: state.prospecto.salvandoOcorrencia,
  erroSalvarOcorrencia: state.prospecto.erroSalvarOcorrencia,
  ocorrenciaParaEditar: state.prospecto.ocorrenciaParaEditar,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleModalOcorrencia,
      salvarOcorrencia,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalOcorrencia);
