import styled from 'styled-components';

export const Container = styled.div`
  .list-group li {
    line-height: 1;
  }

  .pendencia-financiamento {
    background: #f8f4e2;
  }

  .pendencia-sinal {
    background: #fff1f1;
  }

  .concluido {
    background: #cdfdd8;
  }
`;
