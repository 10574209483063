import styled from 'styled-components';
import defaultLogo from '~/Images/logo.svg';

export const primaryColor = '#007bff';
export const infoColor = '#17a2b8';
export const warningColor = '#ffc107';
export const successColor = '#28a745'

export const Bullet = styled.strong.attrs({
  className: 'bullet',
})`
  margin-right: 10px;
  background-color: ${props => props.cor || '#ccc'};
`;

export const AvatarImage = styled.img.attrs(props => ({
  ...props,
  src: props.src ? props.src : defaultLogo,
}))``;
