import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-select';
import { FaChevronRight } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { toast } from 'react-toastify';

import * as api from '~/Services/Service';
import EscalaSatisfacao from '~/Components/EscalaSatisfacao/EscalaSatisfacao';
import * as constants from '~/constant';
import { format } from '~/Utils/Helpers';
import Header from '~/Components/Header/Header';

export default function PesquisaPosVenda(props) {
  const [dadosDaVenda, setDadosDaVenda] = useState({});
  const [questionario, setQuestionario] = useState([]);

  const buscarDadosCliente = async () => {
    const { codigo_boleto } = props.match.params;
    let response = await api.getPesquisaPosVenda(codigo_boleto);
    setDadosDaVenda(response);
  };

  const buscarQuestionario = async () => {
    let response = await api.getQuestionarioPosVenda();
    setQuestionario(response);
  };

  const perguntaSeConheciaSetparAntesDaCompra = 76;
  const mostrarPergunta = codigo_pergunta => {
    if (codigo_pergunta === perguntaSeConheciaSetparAntesDaCompra)
      return dadosDaVenda.primeiro_contrato;
    else return true;
  };

  useEffect(() => {
    buscarDadosCliente();
    buscarQuestionario();
  }, []);

  const salvarPesquisa = async (values, actions) => {
    try {
      let respostas = [];
      values.forEach(function(element, index) {
        if (element) {
          if (typeof element == 'string')
            respostas.push({
              codigo_pergunta: index,
              codigo_resposta: 0,
              dissertativa: element,
            });
          else
            respostas.push({
              codigo_pergunta: index,
              codigo_resposta: element,
              dissertativa: null,
            });
        }
      });

      if (respostas.length < QTD_PERGUNTAS_OBRIGATORIAS) {
        toast.warning(
          'Por favor, verifique se preencheu todos os campos antes de enviar.'
        );
        actions.setSubmitting(false);
        return;
      }

      const response = await api.salvarPesquisaPosVenda(
        respostas,
        dadosDaVenda.codigo_boleto
      );
      if (response.success) toast.success('Pesquisa salva com sucesso');
      else toast.warning('A pesquisa já foi salva posteriormente');
      actions.setSubmitting(false);
      buscarDadosCliente();
    } catch (error) {
      toast.error('Erro ao salvar a pesquisa. Tente novamente');
      actions.setSubmitting(false);
    }
  };

  const isRespondido =
    dadosDaVenda.respostas && dadosDaVenda.respostas.length > 0;

  const QTD_PERGUNTAS_OBRIGATORIAS =
    questionario && questionario.filter(item => item.obrigatoria).length;

  const obterValorPadrao = (pergunta, tipo_pergunta) => {
    const ultimaRespostaCliente = dadosDaVenda.respostas
      ? dadosDaVenda.respostas.filter(value => {
          return value.codigo_pergunta === pergunta;
        })
      : [];

    if (ultimaRespostaCliente.length === 0) return undefined;

    const {
      codigo_resposta,
      dissertativa,
      descricao,
    } = ultimaRespostaCliente[0];

    switch (tipo_pergunta) {
      case constants.TIPOS_PESQUISA.ESCOLHA:
        return { value: codigo_resposta, label: descricao };
      case constants.TIPOS_PESQUISA.PESO:
        return codigo_resposta;
      case constants.TIPOS_PESQUISA.DISSERTATIVA:
        return dissertativa;
      default:
        return;
    }
  };

  return (
    <>
      <Header title="Pesquisa Pós-venda" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 mx-auto">
            <div className="card border-0">
              <div className="card-body">
                {dadosDaVenda && (
                  <>
                    <div className="tri-empreendimento-imob-corretor">
                      <div className="logo-emprendimento-pesquisa">
                        <img
                          alt={dadosDaVenda.empreendimento}
                          src={dadosDaVenda.url_logo_empreendimento}
                        />
                      </div>
                      <FaChevronRight />
                      <div className="logo-imobiliaria-pesquisa">
                        <img
                          alt={dadosDaVenda.imobiliaria}
                          src={dadosDaVenda.url_logo_imobiliaria}
                        />
                      </div>
                      <FaChevronRight />
                      <div className="foto-corretor-pesquisa">
                        <img
                          alt={dadosDaVenda.nome_corretor}
                          src={dadosDaVenda.url_foto_corretor}
                        />
                      </div>
                    </div>
                    <div className="dados-pesquisa-posvenda">
                      <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center mt-3">
                        <li>
                          <small className="d-block">Empreendimento</small>
                          <strong className="d-block">
                            {dadosDaVenda.empreendimento}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Imobiliaria</small>
                          <strong className="d-block">
                            {dadosDaVenda.nome_imobiliaria}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Corretor</small>
                          <strong className="d-block">
                            {dadosDaVenda.nome_corretor}
                          </strong>
                        </li>
                      </ul>
                      <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center my-3">
                        <li>
                          <small className="d-block">Quadra</small>
                          <strong className="d-block">
                            {dadosDaVenda.quadra}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Lote</small>
                          <strong className="d-block">
                            {dadosDaVenda.lote}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Pré-venda</small>
                          <strong className="d-block">
                            {dadosDaVenda.pre_venda}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Data da Venda</small>
                          <strong className="d-block">
                            {format.date(dadosDaVenda.data_venda)}
                          </strong>
                        </li>
                      </ul>
                      <hr />
                      <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center">
                        <li>
                          <small className="d-block">Nome do cliente</small>
                          <strong className="d-block">
                            <a
                              href={
                                '//extranet.revobrasil.com.br/prospectos/lista/' +
                                dadosDaVenda.codigo_prospecto
                              }
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {dadosDaVenda.name_cliente}{' '}
                              <FiExternalLink className="align-icon-link" />
                            </a>
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">E-mail</small>
                          <strong className="d-block">
                            {dadosDaVenda.email}
                          </strong>
                        </li>
                      </ul>
                      <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center my-3">
                        <li>
                          <small className="d-block">Celular</small>
                          <strong className="d-block">
                            {dadosDaVenda.celular
                              ? format.celular(dadosDaVenda.celular)
                              : '-'}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Tel. Residencial</small>
                          <strong className="d-block">
                            {dadosDaVenda.tel_residencial
                              ? format.celular(dadosDaVenda.tel_residencial)
                              : '-'}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Tel. Comercial</small>
                          <strong className="d-block">
                            {dadosDaVenda.tel_comercial
                              ? format.celular(dadosDaVenda.tel_comercial)
                              : '-'}
                          </strong>
                        </li>
                      </ul>
                      <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center mt-3">
                        <li>
                          <small className="d-block">Endereço</small>
                          <strong className="d-block">
                            {dadosDaVenda.endereco} - {dadosDaVenda.bairro},{' '}
                            {dadosDaVenda.cidade} - {dadosDaVenda.estado},{' '}
                            {dadosDaVenda.cep
                              ? format.cep(dadosDaVenda.cep)
                              : '-'}
                          </strong>
                        </li>
                      </ul>
                      {isRespondido && (
                        <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center mt-3">
                          <li>
                            <small className="d-block">Enviado por</small>
                            <strong className="d-block text-muted">
                              {dadosDaVenda.nome_usuario_envio_formulario}
                            </strong>
                          </li>
                          <li>
                            <small className="d-block">Enviado em</small>
                            <strong className="d-block text-muted">
                              {format.datetime(
                                dadosDaVenda.data_envio_formulario
                              )}
                            </strong>
                          </li>
                        </ul>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mx-auto">
            <div className="card mt-4 border-0">
              <div className="card-body">
                {dadosDaVenda && questionario && (
                  <div>
                    <Formik onSubmit={salvarPesquisa} initialValues={[]}>
                      {({
                        values,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                        isLoadingOptions,
                      }) => (
                        <Form>
                          <>
                            {questionario.map((pergunta, index) => (
                              <div className="mb-3" key={pergunta.codigo}>
                                {pergunta.codigo === 75 && (
                                  <div>
                                    <hr />
                                    <h5 className="text-center">
                                      Questionário
                                    </h5>
                                    <hr />
                                  </div>
                                )}
                                {pergunta.tipo ===
                                  constants.TIPOS_PESQUISA.ESCOLHA &&
                                  isRespondido &&
                                  mostrarPergunta(pergunta.codigo) && (
                                    <>
                                      <label htmlFor={pergunta.codigo}>
                                        {pergunta.descricao_pergunta}
                                      </label>
                                      <Select
                                        isDisabled={true}
                                        value={obterValorPadrao(
                                          pergunta.codigo,
                                          pergunta.tipo
                                        )}
                                      />
                                    </>
                                  )}
                                {pergunta.tipo ===
                                  constants.TIPOS_PESQUISA.ESCOLHA &&
                                  !isRespondido &&
                                  mostrarPergunta(pergunta.codigo) && (
                                    <>
                                      <label htmlFor={pergunta.codigo}>
                                        {pergunta.descricao_pergunta}
                                      </label>
                                      <Select
                                        noOptionsMessage={() =>
                                          'Nenhuma resposta encontrada'
                                        }
                                        isDisabled={
                                          isLoadingOptions || isSubmitting
                                        }
                                        placeholder="Escolha uma resposta..."
                                        options={pergunta.respostas.map(
                                          ({ codigo, descricao_resposta }) => ({
                                            value: codigo,
                                            label: descricao_resposta,
                                          })
                                        )}
                                        onChange={resposta =>
                                          setFieldValue(
                                            pergunta.codigo,
                                            resposta.value
                                          )
                                        }
                                        defaultValue={obterValorPadrao(
                                          pergunta.codigo,
                                          pergunta.tipo
                                        )}
                                      />
                                    </>
                                  )}
                                {pergunta.tipo ===
                                  constants.TIPOS_PESQUISA.PESO && (
                                  <EscalaSatisfacao
                                    name={`pergunta.${index}`}
                                    disabled={isSubmitting || isRespondido}
                                    value={values[pergunta.codigo]}
                                    label={pergunta.descricao_pergunta}
                                    onChange={resposta =>
                                      setFieldValue(
                                        pergunta.codigo,
                                        parseInt(resposta)
                                      )
                                    }
                                    defaultValue={obterValorPadrao(
                                      pergunta.codigo,
                                      pergunta.tipo
                                    )}
                                  />
                                )}
                                {pergunta.tipo ===
                                  constants.TIPOS_PESQUISA.DISSERTATIVA && (
                                  <>
                                    <label htmlFor={pergunta.codigo}>
                                      {pergunta.descricao_pergunta}
                                    </label>
                                    <textarea
                                      className="form-control"
                                      id={pergunta.codigo}
                                      rows="3"
                                      onChange={resposta =>
                                        setFieldValue(
                                          pergunta.codigo,
                                          resposta.target.value
                                        )
                                      }
                                      disabled={isRespondido}
                                      defaultValue={obterValorPadrao(
                                        pergunta.codigo,
                                        pergunta.tipo
                                      )}
                                    />
                                  </>
                                )}
                              </div>
                            ))}
                            <hr />
                            <div className="mt-3">
                              <button
                                className="btn btn-primary btn-lg m-auto d-flex align-items-center"
                                type="button"
                                onClick={handleSubmit}
                                disabled={isSubmitting || isRespondido}
                              >
                                <ClipLoader
                                  css={css`
                                    margin-right: 10px;
                                  `}
                                  sizeUnit={'px'}
                                  size={20}
                                  color={'#fff'}
                                  loading={isSubmitting}
                                />
                                {isSubmitting
                                  ? 'Enviando...'
                                  : 'Enviar pesquisa'}
                              </button>
                            </div>
                          </>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
