import React from 'react';
import * as constants from '../../../../constant';
import MaskedInput from 'react-text-mask';
import { Col, Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import BuscaEnderecoPorCEP from '../BuscaEnderecoPorCEP';

const CamposEndereco = ({
  getAtualizarCidades,
  options,
  handleBlur,
  handleChange,
  values,
  setFieldValue,
  setValues,
  errors,
  touched,
}) => {
  const filtroCidades = string => {
    return options.cidades
      .filter(cidades =>
        cidades.descricao.toLowerCase().includes(string.toLowerCase())
      )
      .map(item => ({
        value: item.codigo,
        label: item.descricao,
      }));
  };

  let timeout = null;
  const carregarCidades = (inputValue, callback) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(filtroCidades(inputValue));
    }, 500);
  };

  const preparaEndereco = endereco => {
    setValues({
      ...values,
      ...endereco,
      endereco: endereco.logradouro,
      estado: endereco.uf,
    });
    getAtualizarCidades(endereco.uf);
  };

  return (
    <BuscaEnderecoPorCEP
      onChangeCEP={handleChange}
      quandoCarregarEndereco={endereco => preparaEndereco(endereco)}
    >
      {({ buscandoEndereco, erroBuscaEndereco, onChangeCEP }) => {
        return (
          <div className="row">
            <Col xs="12" md="6">
              <Form.Group controlId="prospecto.cep">
                <Form.Label>CEP</Form.Label>
                <MaskedInput
                  type="tel"
                  className={`form-control ${errors.cep &&
                    touched.cep &&
                    'is-invalid'}`}
                  name="cep"
                  onChange={onChangeCEP}
                  onBlur={handleBlur}
                  value={values.cep}
                  mask={constants.MASK_CEP}
                  placeholder="Ex.: 15015-820"
                  disabled={buscandoEndereco}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.cep}
                </Form.Control.Feedback>
                {erroBuscaEndereco && (
                  <small className="form-text text-danger">
                    {erroBuscaEndereco}
                  </small>
                )}
              </Form.Group>
              <Form.Group controlId="prospecto.estado">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  as="select"
                  className="form-control"
                  name="estado"
                  onChange={event => {
                    getAtualizarCidades(event.target.value);
                    handleChange(event);
                    setFieldValue('codigo_cidade', '');
                  }}
                  onBlur={handleBlur}
                  value={values.estado}
                  disabled={buscandoEndereco}
                  isInvalid={errors.estado && touched.estado}
                >
                  <option value="">Selecione um estado</option>
                  {constants.ESTADOS.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.estado}
                </Form.Control.Feedback>
              </Form.Group>
              {values.estado && options.cidades && options.cidades.length > 0 && (
                <Form.Group controlId="prospecto.codigo_cidade">
                  <Form.Label>Cidade</Form.Label>
                  <AsyncSelect
                    isDisabled={
                      options.cidades.length === 0 || buscandoEndereco
                    }
                    isLoading={options.cidades.length === 0}
                    loadingMessage={() => 'Carregando...'}
                    noOptionsMessage={() => 'Digite uma cidade. Ex.: Rio Preto'}
                    placeholder="Pesquisar cidades..."
                    loadOptions={carregarCidades}
                    onChange={option => {
                      setFieldValue('codigo_cidade', option.value);
                    }}
                    value={
                      values.codigo_cidade &&
                      options.cidades.length > 0 &&
                      options.cidadesById[values.codigo_cidade]
                        ? {
                            value: parseInt(
                              options.cidadesById[values.codigo_cidade].codigo
                            ),
                            label:
                              options.cidadesById[values.codigo_cidade]
                                .descricao,
                          }
                        : {
                            value: '',
                            label:
                              options.cidades.length === 0
                                ? 'Carregando...'
                                : 'Selecione uma cidade',
                          }
                    }
                  />
                  {errors.codigo_cidade && (
                    <small className="form-text text-danger">
                      {errors.codigo_cidade}
                    </small>
                  )}
                </Form.Group>
              )}
              <Form.Group controlId="prospecto.bairro">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="bairro"
                  maxLength="20"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bairro.substring(0, 20)}
                  placeholder="Ex.: Redentora"
                  disabled={buscandoEndereco}
                  isInvalid={errors.bairro && touched.bairro}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.bairro}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group controlId="prospecto.endereco">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  name="endereco"
                  maxLength="40"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endereco}
                  placeholder="Ex.: Rua Penita"
                  disabled={buscandoEndereco}
                  isInvalid={errors.endereco && touched.endereco}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.endereco}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="prospecto.numero">
                <Form.Label>Número</Form.Label>
                <Form.Control
                  type="text"
                  name="numero"
                  maxLength="10"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.numero}
                  placeholder="Ex.: 3355"
                  disabled={buscandoEndereco}
                  isInvalid={errors.numero && touched.numero}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numero}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="prospecto.complemento">
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  type="text"
                  name="complemento"
                  maxLength="30"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.complemento}
                  placeholder="Ex.: Setpar"
                  disabled={buscandoEndereco}
                />
              </Form.Group>
            </Col>
          </div>
        );
      }}
    </BuscaEnderecoPorCEP>
  );
};

export default CamposEndereco;
