import React, { useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Spinner,
  Button,
  Container,
  Badge,
} from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik } from 'formik';

import { MASK_DATE } from '~/constant';
import { contraSenhaHelper } from '~/Utils/Helpers';
import { format } from '~/Utils/Helpers.js';
import ErrorAlert from '~/Components/ErrorAlert';
import ConfirmButton from '~/Components/ConfirmButton';
import { buscarUsuariosAutorizadoresVenda } from '~/Store/Options/actions';
import EfetivacaoEvaluator from './EfetivacaoEvaluator';
import LoginSenhaEvaluator from './LoginSenhaEvaluator';

import dialog from '~/Utils/dialog';
import Divider from '~/Components/Divider';
import Hint from '~/Components/Hint/Hint';
import AuthStorage from '~/Utils/AuthStorage';

const Checkout = ({
  erroGravacaoVenda,
  listaMotivosAutorizacao,
  buscarUsuariosAutorizadoresVenda,
  buscandoUsuariosAutorizadoresVenda,
  optionsUsuarios,
  optionsPDV,
  totalTitulosSinal,
  codigoContaCliente,
  isGravandoVenda,
  gravarVenda,
  autorizando,
  autorizarVenda,
  isExcluindoVenda,
  erroExclusaoVenda,
  excluirVenda,
  dataVenda,
  codigoPontoVenda,
  codigoProposta,
}) => {
  useEffect(() => {
    if (optionsUsuarios.length === 0 && !buscandoUsuariosAutorizadoresVenda)
      buscarUsuariosAutorizadoresVenda();
  }, [listaMotivosAutorizacao]);

  const baseContraSenha = contraSenhaHelper.calcularBaseContraSenha(
    totalTitulosSinal,
    codigoContaCliente
  );

  const dicaContraSenha = contraSenhaHelper.calcularDicaContraSenha(
    baseContraSenha
  );

  const resultadoContraSenha = contraSenhaHelper.calcularContraSenha(
    totalTitulosSinal,
    baseContraSenha
  );

  const isBusy = isGravandoVenda || isExcluindoVenda;
  const erro = erroGravacaoVenda || erroExclusaoVenda;

  const requerJustificativa = !!listaMotivosAutorizacao.find(
    m => m.justificativaObrigatoria
  );

  const definirNomeBotao = podeEfetivar => {
    if (isGravandoVenda) {
      if (autorizando) return 'Autorizando venda...';
      else return 'Gravando sua venda...';
    } else {
      if (autorizando) return 'Autorizar venda';
      if (podeEfetivar) return 'Realizar venda';
      if (modoLancamento()) return 'Autorizar com senha';
      else return 'Gravar venda';
    }
  };

  const modoLancamento = podeEfetivar => {
    return !autorizando && !podeEfetivar && AuthStorage.isAtendenteLancamento();
  };

  return (
    <Formik
      initialValues={{
        dataVendaMask: format.date(dataVenda),
        codigoUsuarioAutorizacao: '',
        senha: '',
        contraSenha: '',
        codigoPontoVenda: codigoPontoVenda,
        justificativa: '',
        podeEfetivar: listaMotivosAutorizacao.length === 0,
        credenciaisValidas: false,
      }}
      validate={values => {
        let errors = {};
        if (
          values.contraSenha &&
          values.contraSenha !== resultadoContraSenha.toString()
        )
          errors.contraSenha = 'Contra-senha inválida';
        if (
          (!values.podeEfetivar || requerJustificativa || values.contraSenha) &&
          !values.justificativa
        )
          errors.justificativa = 'Informe uma justificativa';
        return errors;
      }}
      validateOnChange
      validateOnBlur
      onSubmit={async values => {
        const result = await dialog.confirm({
          title: 'Confirmação',
          message: values.podeEfetivar
            ? autorizando
              ? 'Deseja autorizar esta venda?'
              : 'Deseja realizar esta venda?'
            : 'Esta venda será gravada para posterior autorização, deseja continuar?',
        });
        if (!result.proceed) return;

        if (autorizando) autorizarVenda(codigoProposta, values.justificativa);
        else gravarVenda(values);
      }}
    >
      {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
        <>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Data da Venda</Form.Label>
                <Form.Control
                  as={MaskedInput}
                  mask={MASK_DATE}
                  value={values.dataVendaMask}
                  type="tel"
                  placeholder="Ex.: 10/10/2020"
                  required
                  disabled={true}
                />
                {/* <GeradorData
                  original={values.dataVendaMask}
                  onUpdate={date => setFieldValue('dataVenda', date)}
                /> */}
                {errors.dataVendaMask && (
                  <small className="text-danger">{errors.dataVendaMask}</small>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>PDV</Form.Label>
                <Select
                  isSearchable
                  isClearable
                  placeholder="Selecione um ponto de venda"
                  value={optionsPDV.find(
                    option => option.value === values.codigoPontoVenda
                  )}
                  closeMenuOnSelect
                  options={optionsPDV}
                  className="react-select"
                  onChange={e => setFieldValue('codigoPontoVenda', e.value)}
                  isDisabled={autorizando || isBusy}
                />
              </Form.Group>
            </Col>
          </Row>

          {listaMotivosAutorizacao.length > 0 && (
            <div className="mb-4">
              <h5 className="text-center">Autorização</h5>
              {!autorizando && (
                <Row className="row-divided">
                  <Col md={6}>
                    <h6>Autorizar com usuário e senha</h6>
                    <Form.Group>
                      <Form.Label>Usuário</Form.Label>
                      <Select
                        isSearchable
                        isClearable
                        isLoading={buscandoUsuariosAutorizadoresVenda}
                        isDisabled={
                          buscandoUsuariosAutorizadoresVenda || isBusy
                        }
                        placeholder="Selecione um usuário"
                        value={optionsUsuarios.find(
                          option =>
                            option.value === values.codigoUsuarioAutorizacao
                        )}
                        closeMenuOnSelect
                        options={optionsUsuarios}
                        className="react-select"
                        onChange={e => {
                          setFieldValue(
                            'codigoUsuarioAutorizacao',
                            e ? e.value : ''
                          );
                          setFieldValue('senha', '');
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        type="text" //pra não deixar o navegador salvar a senha
                        className="password" //pra esconder a senha digitada
                        onCopy={e => e.preventDefault()} //pra não deixar o usuário copiar a senha
                        disabled={!values.codigoUsuarioAutorizacao || isBusy}
                        value={values.senha}
                        name="senha"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </Form.Group>

                    <LoginSenhaEvaluator
                      codigoUsuario={values.codigoUsuarioAutorizacao}
                      senha={values.senha}
                      onValidated={isValid =>
                        setFieldValue('credenciaisValidas', isValid)
                      }
                    />
                  </Col>
                  <Divider>ou</Divider>
                  <Col md={6}>
                    <h6>Autorizar com contra-senha</h6>
                    <div className="d-flex justify-content-between align-items-baseline mb-2">
                      <Badge variant="info">Base: {dicaContraSenha}</Badge>
                      <Hint
                        title="Base da Contra-Senha"
                        content="Este número serve como pase para o cálculo da contra-senha. Peça ao seu gestor ou a um colaborador da Secretaria de Vendas para calcular a contra-senha para autorizar esta venda."
                      />
                    </div>
                    {/* <div className="d-flex flex-column align-items-center">
                      <QRCode
                        value={hashContraSenhaQRCode.toString()}
                        size={192}
                      />
                      <small className="mb-2 text-center">
                        Seu gestor ou um colaborador administrativo podem
                        escanear este QR Code para gerar a contra-senha
                      </small>
                    </div> */}
                    <Form.Group>
                      <Form.Label>Contra-senha</Form.Label>
                      <Form.Control
                        value={values.contraSenha}
                        name="contraSenha"
                        onChange={handleChange}
                        isValid={!!values.contraSenha && !errors.contraSenha}
                        isInvalid={!!values.contraSenha && errors.contraSenha}
                        disabled={isBusy}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      Observações
                      <span className="ml-2 text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      value={values.justificativa}
                      name="justificativa"
                      placeholder="Observações para aprovação"
                      onChange={handleChange}
                      disabled={isBusy}
                      isValid={!!values.justificativa && !errors.justificativa}
                      isInvalid={!!values.justificativa && errors.justificativa}
                    />
                    {errors.justificativa && (
                      <small className="text-danger">
                        {errors.justificativa}
                      </small>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <EfetivacaoEvaluator
                contraSenha={values.contraSenha}
                resultadoContraSenha={resultadoContraSenha}
                codigoUsuarioAutorizacao={values.codigoUsuarioAutorizacao}
                senha={values.senha}
                credenciaisValidas={values.credenciaisValidas}
                autorizando={autorizando}
                onUpdate={podeEfetivar =>
                  setFieldValue('podeEfetivar', podeEfetivar)
                }
              />
            </div>
          )}
          {listaMotivosAutorizacao.length === 0 && (
            <Container className="text-center mt-4">
              <h3>Tudo Certo!</h3>

              <p>
                Sua venda não precisa de autorização. Revise os valores
                informados e já pode realizar sua venda.
              </p>
            </Container>
          )}
          {erro && <ErrorAlert danger>{erro}</ErrorAlert>}
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <Button
                size="lg"
                block
                className="text-uppercase text-center py-3 px-4 shadow-lg d-flex justify-content-center align-items-center"
                variant={
                  values.podeEfetivar || autorizando ? 'success' : 'warning'
                }
                onClick={handleSubmit}
                disabled={
                  isGravandoVenda ||
                  Object.keys(errors).length > 0 ||
                  modoLancamento(values.podeEfetivar)
                }
              >
                {definirNomeBotao(values.podeEfetivar)}
                {isGravandoVenda && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="ml-2"
                  />
                )}
              </Button>
            </Col>
            {autorizando && (
              <Col sm={{ span: 4, offset: 4 }} className="text-center mt-4">
                <ConfirmButton
                  className="text-uppercase text-center d-flex justify-content-center align-items-center ml-auto mr-auto"
                  variant="outline-danger"
                  onConfirm={excluirVenda}
                  disabled={isExcluindoVenda}
                  confirmTitle="Confirmação"
                  confirmText="Deseja excluir esta proposta?"
                >
                  Excluir Proposta
                  {isExcluindoVenda && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="ml-2"
                    />
                  )}
                </ConfirmButton>
              </Col>
            )}
          </Row>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  optionsUsuarios: state.options.usuariosAutorizadoresVenda.map(e => ({
    value: e.codigo,
    label: e.nome,
  })),
  buscandoUsuariosAutorizadoresVenda:
    state.options.buscandoUsuariosAutorizadoresVenda,

  optionsPDV: state.options.pdv.map(e => ({
    value: e.codigo,
    label: e.descricao,
  })),
});

const mapDispatchToProps = {
  buscarUsuariosAutorizadoresVenda,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
