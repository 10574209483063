import { BOLETO, CARTAO, DEPOSITO, DINHEIRO, CHEQUE } from '~/constant';

export default function formaPgtoValidate(values) {
  let errors = {};
  const mensagem = 'Obrigatório';
  switch (values.tipo_parcela) {
    case BOLETO:
      errors[BOLETO] = {};
      if (!values[BOLETO].qtdParcelas) errors[BOLETO].qtdParcelas = mensagem;
      if (!values[BOLETO].valor) errors[BOLETO].valor = mensagem;
      if (!values[BOLETO].vencimento_mask)
        errors[BOLETO].vencimento_mask = mensagem;
      if (Object.keys(errors[BOLETO]).length === 0) errors = {};
      break;
    case CARTAO:
      errors[CARTAO] = {};
      if (!values[CARTAO].cartao) errors[CARTAO].cartao = mensagem;
      if (!values[CARTAO].qtdParcelas) errors[CARTAO].qtdParcelas = mensagem;
      if (!values[CARTAO].valor) errors[CARTAO].valor = mensagem;
      if (!values[CARTAO].vencimento_mask)
        errors[CARTAO].vencimento_mask = mensagem;
      if (!values[CARTAO].numero_documento)
        errors[CARTAO].numero_documento = mensagem;
      if (Object.keys(errors[CARTAO]).length === 0) errors = {};
      break;
    case DINHEIRO:
      errors[DINHEIRO] = {};
      if (!values[DINHEIRO].valor) errors[DINHEIRO].valor = mensagem;
      if (!values[DINHEIRO].vencimento_mask)
        errors[DINHEIRO].vencimento_mask = mensagem;
      if (Object.keys(errors[DINHEIRO]).length === 0) errors = {};
      break;
    case DEPOSITO:
      errors[DEPOSITO] = {};
      if (!values[DEPOSITO].valor) errors[DEPOSITO].valor = mensagem;
      if (!values[DEPOSITO].vencimento_mask)
        errors[DEPOSITO].vencimento_mask = mensagem;
      if (Object.keys(errors[DEPOSITO]).length === 0) errors = {};
      break;
    case CHEQUE:
      errors[CHEQUE] = {};
      if (!values[CHEQUE].valor) errors[CHEQUE].valor = mensagem;
      if (!values[CHEQUE].vencimento_mask)
        errors[CHEQUE].vencimento_mask = mensagem;
      if (!values[CHEQUE].banco) errors[CHEQUE].banco = mensagem;
      if (!values[CHEQUE].agencia) errors[CHEQUE].agencia = mensagem;
      if (!values[CHEQUE].digito2) errors[CHEQUE].digito2 = mensagem;
      if (!values[CHEQUE].camara_compensacao)
        errors[CHEQUE].camara_compensacao = mensagem;
      if (!values[CHEQUE].numero) errors[CHEQUE].numero = mensagem;
      if (!values[CHEQUE].tipificacao) errors[CHEQUE].tipificacao = mensagem;
      if (!values[CHEQUE].digito1) errors[CHEQUE].digito1 = mensagem;
      if (!values[CHEQUE].conta) errors[CHEQUE].conta = mensagem;
      if (!values[CHEQUE].digito3) errors[CHEQUE].digito3 = mensagem;
      if (!values[CHEQUE].cgccpf) errors[CHEQUE].cgccpf = mensagem;
      if (!values[CHEQUE].emitente) errors[CHEQUE].emitente = mensagem;
      if (Object.keys(errors[CHEQUE]).length === 0) errors = {};
      break;
    default:
      break;
  }
  return errors;
}
