import React from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import * as constants from '~/constant';
import { colourStyles } from '~/Styles/reactSelect';

import DateMask from '~/Components/DateMask';
import Hint from '~/Components/Hint/Hint';

const ModalCampanha = ({
  campanha,
  onHide,
  onSubmit,
  empreendimentos,
  imobiliarias,
  isLoadingOptions,
}) => {
  const optionsEmpreendimento = (empreendimentos || []).map(e => ({
    value: e.codigo,
    label: e.descricao,
  }));

  const optionsImobiliarias = (imobiliarias || [])
    .filter(i => i.codigo !== 1077)
    .map(i => ({
      value: i.codigo_imobiliaria,
      label: i.nome_imobiliaria,
      color: i.cor_imobiliaria,
    }));

  const optionsCriterios = [
    { value: 1, label: 'VGV' },
    { value: 2, label: 'Quantidade' },
  ];

  const validarFormCampanha = values => {
    const errors = {};
    if (!values.descricao) errors.descricao = 'Informe o nome da campanha';
    if (!values.codigo_familia)
      errors.codigo_familia = 'Empreendimento requerido';
    if (!values.imobiliarias || values.imobiliarias.length === 0)
      errors.imobiliarias = 'Selecione ao menos uma imobiliária';
    if (!values.data_fim) errors.data_fim = 'Requerido';
    if (!values.data_inicio) errors.data_inicio = 'Requerido';
    if (values.data_fim <= values.data_inicio) {
      errors.data_inicio = 'Período inválido';
      errors.data_fim = 'Período inválido';
    }
    if (!values.criterio) errors.criterio = 'Requerido';
    return errors;
  };

  return (
    <Modal show={!!campanha} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {campanha && campanha.codigo ? 'Editar Campanha' : 'Criar Campanha'}
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          ...campanha,
        }}
        onSubmit={onSubmit}
        validate={validarFormCampanha}
        validateOnBlur
        validateOnChange
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => (
          <>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  placeholder="Nome da campanha"
                  name="descricao"
                  value={values.descricao}
                  className={errors.descricao ? 'is-invalid' : ''}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.descricao}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Empreendimento</Form.Label>
                <Select
                  isSearchable
                  isLoading={isLoadingOptions}
                  isDisabled={isLoadingOptions || isSubmitting}
                  label="Empreendimento"
                  placeholder="Selecione um empreendimento"
                  value={optionsEmpreendimento.find(
                    option => option.value === values.codigo_familia
                  )}
                  closeMenuOnSelect
                  options={optionsEmpreendimento}
                  name="codigo_familia"
                  className={`react-select ${
                    errors.codigo_familia && touched.codigo_familia
                      ? 'is-invalid'
                      : ''
                  }`}
                  onChange={e => setFieldValue('codigo_familia', e.value)}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.codigo_familia}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col sm={12} md={6}>
                  <DateMask
                    initialValue={values.data_inicio}
                    allowEmpty
                    allowFuture
                    allowPast
                    onValidate={value => setFieldValue('data_inicio', value)}
                  >
                    {({ dateError, dateValue, setDate }) => (
                      <>
                        <Form.Label>
                          Data Inicial
                          <Hint
                            title="Data Inicial da Campanha"
                            content="Data inicial da campanha, a partir da qual o ranking desta será gerado."
                            placement="top"
                          />
                        </Form.Label>
                        <Form.Group controlId="data_inicio">
                          <MaskedInput
                            name="data_inicio"
                            guide={false}
                            className={`form-control ${((errors.data_inicio &&
                              touched.data_inicio) ||
                              dateError) &&
                              'is-invalid'}`}
                            placeholder="Ex.: 01/01/2019"
                            mask={constants.MASK_DATE}
                            value={dateValue}
                            onChange={e => setDate(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.data_inicio}
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {dateError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}
                  </DateMask>
                </Col>
                <Col sm={12} md={6}>
                  <DateMask
                    initialValue={values.data_fim}
                    allowEmpty
                    allowFuture
                    allowPast
                    onValidate={value => setFieldValue('data_fim', value)}
                  >
                    {({ dateError, dateValue, setDate }) => (
                      <>
                        <Form.Label>
                          Data Final
                          <Hint
                            title="Data Final da Campanha"
                            content="Data final da campanha, e também a data final de geração do ranking da mesma. A partir desta data, é possível encerrar a campanha, gerando o resultado final"
                            placement="top"
                          />
                        </Form.Label>
                        <Form.Group controlId="data_fim">
                          <MaskedInput
                            name="data_fim"
                            guide={false}
                            className={`form-control ${((errors.data_fim &&
                              touched.data_fim) ||
                              dateError) &&
                              'is-invalid'}`}
                            placeholder="Ex.: 01/01/2019"
                            mask={constants.MASK_DATE}
                            value={dateValue}
                            onChange={e => setDate(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.data_fim}
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            {dateError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}
                  </DateMask>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>Imobiliárias</Form.Label>
                <Select
                  isSearchable
                  isMulti
                  isLoading={isLoadingOptions}
                  isDisabled={isLoadingOptions || isSubmitting}
                  label="Imobiliárias"
                  placeholder="Selecione as imobiliárias participantes"
                  options={optionsImobiliarias}
                  defaultValue={optionsImobiliarias.filter(
                    option =>
                      !!values.imobiliarias.find(
                        imob => imob.codigo === option.value
                      )
                  )}
                  name="imobiliarias"
                  className={`react-select ${
                    errors.imobiliarias && touched.imobiliarias
                      ? 'is-invalid'
                      : ''
                  }`}
                  onChange={selecionadas =>
                    setFieldValue(
                      'imobiliarias',
                      !selecionadas
                        ? []
                        : selecionadas.map(option => ({
                            codigo: option.value,
                          }))
                    )
                  }
                  styles={colourStyles}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.imobiliarias}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col sm={12} md={4}>
                  <Form.Group>
                    <Form.Label>
                      Critério
                      <Hint
                        title="Critério do Ranking"
                        content="Critério para geração e ordenação dos corretores no ranking"
                        placement="top"
                      />
                    </Form.Label>
                    <Select
                      label="Critério"
                      placeholder="Selecione..."
                      closeMenuOnSelect
                      options={optionsCriterios}
                      value={optionsCriterios.find(
                        option => option.value === values.criterio
                      )}
                      name="criterio"
                      className={`react-select ${
                        errors.criterio && touched.criterio ? 'is-invalid' : ''
                      }`}
                      onChange={e => setFieldValue('criterio', e.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.criterio}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm={12} md={4}>
                  <Form.Group>
                    <Form.Label>
                      Classificados
                      <Hint
                        title="Número de Corretores Classificados"
                        content="Número máximo de corretores que serão classificados. Os corretores classificados ficarão destacados no ranking. Se vazio, todos os corretores do ranking serão considerados classificados"
                        placement="top"
                      />
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="numero_corretores"
                      onChange={handleChange}
                      value={values.numero_corretores || ''}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} md={4}>
                  <Form.Group>
                    <Form.Label>
                      Dias
                      <Hint
                        title="Dias para Destaque"
                        content="Número de dias antes do final da campanha para contagem regressiva no ranking."
                        placement="top"
                      />
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="dias_contador"
                      onChange={handleChange}
                      value={values.dias_contador}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-primary" onClick={onHide}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isLoadingOptions || isSubmitting}
              >
                Salvar
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalCampanha;
