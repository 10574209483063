import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col } from 'react-bootstrap';
import { FaRegCreditCard, FaReceipt, FaMoneyCheckAlt } from 'react-icons/fa';
import numeral from 'numeral';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { toast } from 'react-toastify';

import * as helpers from '~/Utils/Helpers';
import * as api from '../Services/Service';
import CardDocumento from '../Components/Prospectos/ProspectoDetalhes/CamposDocumentos/CardDocumento';
import {
  DESCRICOES_STATUS_COMPROVANTE,
  TIPO_UPLOAD_PDF,
  TIPO_TITULO,
  TIPO_HISTORICO_PROPOSTA_RAPIDA_CORES,
} from '../constant';
import buildPDFFromImage from '../Utils/ImageToPdf';
import { MOTIVOS_RECUSA_DOCUMENTOS } from '../Components/Comercial/Comprovantes/Motivos';
import { format } from '../Utils/Helpers';
import PDFThumb from '~/Utils/PDFThumb';
import Header from '~/Components/Header/Header';
import { DATETIME_DEFAULT_FORMAT } from '~/constant';

//TODO: um lugar melhor para colocar isso?
numeral.register('locale', 'br', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: 'R$',
  },
});

// switch between locales
numeral.locale('br');

class AnexarComprovante extends Component {
  state = {
    documentoModal: null,
    documentos: [],
    carregandoDocumentos: false,
    carregandoTitulos: false,
    carregandoDadosBancarios: false,
    carregandoHistorico: false,
    exibePainelCancelamento: false,
    motivosSelecionados: [],
    status_documentacao: null,
    caracteristica: null,
    dados_bancarios: [],
    observacoes: [],
    titulos: [],
    justificativa: '',
    historico: [],
  };

  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        prevenda: PropTypes.string.isRequired,
        ge: PropTypes.string.isRequired,
      }),
    }),
  };

  buildDocumento = async documento => ({
    sequencia: documento.sequencia,
    url: documento.url,
    Thumb:
      documento.titulo && !documento.url.includes('hml')
        ? await PDFThumb.create(documento.url)
        : null,
    descricao: (
      <>
        {documento.titulo}
        <small className="d-block mt-2">
          Digitalizado por:{' '}
          <span className="font-weight-bold">{documento.usuario}</span>
        </small>
      </>
    ),
  });

  async componentDidMount() {
    this.setState({
      carregandoDocumentos: true,
      carregandoTitulos: true,
      carregandoDadosBancarios: true,
      carregandoHistorico: true,
    });
    const { prevenda, ge } = this.props.match.params;
    const {
      arquivos_pre_venda,
      caracteristica,
    } = await api.consultarComprovantes(prevenda, ge);
    let { dados_bancarios } = await api.obterDadosBancariosPreVenda(prevenda);
    const { titulos } = await api.buscarTitulosSinalPreVenda(prevenda);
    const historico = await api.buscarHistoricoDocumentacao(prevenda);
    const { arquivos, status_documentacao } = arquivos_pre_venda;

    dados_bancarios = dados_bancarios || [];

    this.setState({
      status_documentacao,
      caracteristica,
      titulos,
      dados_bancarios,
      historico,
      carregandoTitulos: false,
      carregandoDadosBancarios: false,
      carregandoHistorico: false,
    });

    Promise.all(
      arquivos.map(async item => await this.buildDocumento(item))
    ).then(documentos => {
      this.setState({
        documentos,
        carregandoDocumentos: false,
      });
    });
  }

  setDocumentoModal = documento => {
    this.setState({
      documentoModal: documento,
    });
  };

  inputRef = React.createRef();

  uploadComprovante = async acceptedFiles => {
    let request = new FormData();
    const { prevenda, ge } = this.props.match.params;

    let pdf = null;
    if (acceptedFiles.type === TIPO_UPLOAD_PDF) pdf = acceptedFiles;
    else pdf = await buildPDFFromImage(acceptedFiles);

    request.append('Arquivo', pdf);

    const {
      status_documentacao,
      arquivo,
      historico,
    } = await api.uploadComprovante(request, ge, prevenda);
    const doc = await this.buildDocumento(arquivo);
    this.setState({
      documentos: [...this.state.documentos, doc],
      status_documentacao,
      historico,
    });

    //Limpar input do upload
    this.inputRef.current.value = '';
  };

  onRemove = async documento => {
    const { status_documentacao, historico } = await api.removerComprovante(
      documento.sequencia
    );
    if (status_documentacao) {
      //verificar se foi sucesso e executar:
      const documentos = this.state.documentos.filter(item => {
        return item.sequencia !== documento.sequencia;
      });
      this.setState({ documentos, status_documentacao, historico });
    } else {
      //tratar erro
    }
  };

  atualizarStatusComprovante = async status => {
    const { prevenda } = this.props.match.params;
    const { observacoes, motivosSelecionados, justificativa } = this.state;
    if (!justificativa) {
      toast.warning('Informe uma justificativa para a aprovação!');
      return;
    }

    let novo_status = status;

    let motivos = motivosSelecionados.filter(item => !item.includes('Sub'));

    const request = {
      novo_status,
      pre_venda: prevenda,
      motivos,
      observacoes,
      justificativa,
    };
    try {
      let response = await api.atualizarStatusComprovante(request);
      const { status_documentacao, historico } = response;
      toast.success('Status da documentação alterado com sucesso');
      this.setState({
        status_documentacao,
        historico,
        exibePainelCancelamento: false,
      });
    } catch (err) {
      toast.error(err.message || 'Erro ao alterar status da documentação ');
    }
  };

  exibirPainelCancelamento = exibePainelCancelamento => {
    this.setState({
      exibePainelCancelamento,
      motivosSelecionados: [],
    });
  };

  alterarMotivo = event => {
    let motivosSelecionados = this.state.motivosSelecionados;
    const id = event.target.value;

    if (event.target.checked) {
      motivosSelecionados.push(id);
    } else {
      motivosSelecionados = motivosSelecionados.filter(item => {
        return item !== id;
      });
    }

    this.setState({ motivosSelecionados });
  };

  selecionouUmSubgrupo = Motivos => {
    //filtra motivos selecionados com subgrupos
    const motivosComSubGrupos = MOTIVOS_RECUSA_DOCUMENTOS.filter(
      item =>
        item.subGrupo && this.state.motivosSelecionados.indexOf(item.value) > -1
    );

    //se a lista for vazia retorna true (permitido enviar o formulário)
    if (motivosComSubGrupos.length === 0) return true;

    //retorna um boolean se todos os motivos com subgrupo tem ao menos um item selecionado
    return motivosComSubGrupos.reduce((prev, curr) => {
      return (
        prev &&
        curr.subGrupo.reduce((prev, curr) => {
          return (
            prev || this.state.motivosSelecionados.indexOf(curr.value) > -1
          );
        }, false)
      );
    }, true);
  };

  setObservacoes = (motivo, Observacao) => {
    let ObservacoesAtual = this.state.observacoes;
    let key = `${motivo.label}:`;

    var observacoes = ObservacoesAtual.filter(item => !item.includes(key));
    observacoes.push(`${key} ${Observacao}`);
    this.setState({
      observacoes,
    });
  };

  render() {
    const {
      documentoModal,
      documentos,
      exibePainelCancelamento,
      motivosSelecionados,
      status_documentacao,
      caracteristica,
      titulos,
      dados_bancarios,
      historico,
      carregandoDocumentos,
      carregandoTitulos,
      carregandoDadosBancarios,
      carregandoHistorico,
      justificativa,
    } = this.state;
    const habilitarUploadDelete =
      (status_documentacao === 1 ||
        status_documentacao === 2 ||
        status_documentacao === 8 ||
        status_documentacao === 9) &&
      caracteristica !== 'WebFinanceiro';
    const StatusAtual = DESCRICOES_STATUS_COMPROVANTE.find(
      item => item.value === parseInt(status_documentacao)
    );
    const exibeBotoesAprovacao =
      (documentos.length > 0 &&
        (status_documentacao === 2 || status_documentacao === 3) &&
        caracteristica === 'WebAprovaComprov') ||
      (((status_documentacao === 2 && caracteristica === 'WebSecVendas') ||
        (status_documentacao === 3 && caracteristica === 'WebFinanceiro')) &&
        caracteristica !== 'Corretor');

    const desabilitarBotaoRecusar =
      motivosSelecionados.length === 0 ||
      !this.selecionouUmSubgrupo(MOTIVOS_RECUSA_DOCUMENTOS) ||
      justificativa.length === 0;

    const desabilitarBotaoAprovar = justificativa.length === 0;

    return (
      <>
        <Header title="Comprovantes">
          {habilitarUploadDelete && (
            <label htmlFor="UploadArquivo" className="mb-0">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  this.inputRef.current.click();
                }}
              >
                Adicionar arquivo
              </button>
              <input
                type="file"
                className="d-none"
                name="UploadArquivo"
                id="UploadArquivo"
                ref={this.inputRef}
                onChange={event => {
                  this.uploadComprovante(event.target.files[0]);
                }}
              />
            </label>
          )}
        </Header>
        <div className="mx-3">
          <Row className="mt-3">
            <Col md className="mb-3">
              <p className="mb-1">
                <strong>Dados Bancários</strong>
              </p>
              {carregandoDadosBancarios && (
                <p className="text-muted">Carregando dados bancários...</p>
              )}
              {!carregandoDadosBancarios &&
                dados_bancarios.map((b, index, a) => {
                  return (
                    <>
                      <Row key={index}>
                        <Col md={2} sm={2} xs={3}>
                          <span>Banco:</span>
                          <br />
                          <span>Agência:</span>
                          <br />
                          <span>Conta:</span>
                        </Col>
                        <Col>
                          <span>
                            {b.codigo_banco} - {b.nome_banco}
                          </span>
                          <br />
                          <span>{b.agencia}</span>
                          <br />
                          <span>{b.conta}</span>
                        </Col>
                      </Row>
                      {index < a.length - 1 && <hr />}
                    </>
                  );
                })}
            </Col>
            <Col md className="mb-3">
              <p className="mb-1">
                <strong>Títulos de Sinal</strong>
              </p>
              {carregandoTitulos && (
                <p className="text-muted">Carregando títulos de sinal...</p>
              )}
              {!carregandoTitulos &&
                titulos.map(titulo => {
                  const accent = titulo.baixado ? 'success' : 'dark';
                  const accentMuted = titulo.baixado ? 'success' : 'muted';
                  return (
                    <div
                      key={titulo.codigo}
                      className={`card p-2 w-100 text-${accent} border-${accent}`}
                    >
                      <Row>
                        <Col>
                          <p className="m-0">
                            Valor:&nbsp;R${' '}
                            {numeral(titulo.valor).format('(R$ 0,00.00)')}
                          </p>
                        </Col>
                        <Col className="text-right">
                          {titulo.promisoria &&
                            titulo.promisoria.NossoNumero !== 'DEP' && (
                              <small className={`text-${accentMuted} mr-2`}>
                                {titulo.promisoria &&
                                  titulo.promisoria.NossoNumero}
                              </small>
                            )}
                          {titulo.tipo === TIPO_TITULO.CARTAO && (
                            <span>
                              <FaRegCreditCard />
                              <small className="ml-1">Cartão</small>
                            </span>
                          )}
                          {titulo.tipo === TIPO_TITULO.DEPOSITO && (
                            <span>
                              <FaReceipt />
                              <small className="ml-1">Depósito</small>
                            </span>
                          )}
                          {titulo.tipo === TIPO_TITULO.CHEQUE && (
                            <span>
                              <FaMoneyCheckAlt />
                              <small className="ml-1">Cheque</small>
                            </span>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <small className={`text-${accentMuted}`}>
                            Vencimento:&nbsp;
                            {format.date(titulo.data_vencimento)}
                          </small>
                        </Col>
                      </Row>
                      {titulo.tipo === TIPO_TITULO.CHEQUE && (
                        <>
                          <small className="mt-1">Dados do cheque:</small>
                          <Row>
                            <Col xs={4}>
                              <small>
                                Banco
                                <span className="d-block">
                                  {titulo.cheque.banco}
                                </span>
                              </small>
                            </Col>
                            <Col xs={4}>
                              <small>
                                Agência
                                <span className="d-block">
                                  {titulo.cheque.agencia}
                                </span>
                              </small>
                            </Col>
                            <Col xs={4}>
                              <small>
                                Conta
                                <span className="d-block">
                                  {titulo.cheque.conta}
                                </span>
                              </small>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={4}>
                              <small>
                                Numero
                                <span className="d-block">
                                  {titulo.cheque.numero}
                                </span>
                              </small>
                            </Col>
                            <Col xs={4}>
                              <small>
                                Emitente
                                <span className="d-block">
                                  {titulo.cheque.emitente}
                                </span>
                              </small>
                            </Col>
                            <Col xs={4}>
                              <small>
                                CPF
                                <span className="d-block">
                                  {titulo.cheque.cgccpf}
                                </span>
                              </small>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  );
                })}
            </Col>
          </Row>

          <Row className="CamposDocumentos">
            <Col>
              <div className="my-3 d-flex justify-content-between align-items-center">
                <p className="text-secondary mb-0">
                  Clique para visualizar o documento.
                </p>
                {StatusAtual && (
                  <h5
                    className="mb-0 px-3 text-light rounded-pill"
                    style={{ background: StatusAtual.cor }}
                  >
                    {StatusAtual.label}
                  </h5>
                )}
              </div>

              <hr />

              {carregandoDocumentos && (
                <p className="text-muted">Carregando documentos...</p>
              )}
              {!carregandoDocumentos && documentos.length === 0 && (
                <p className="text-muted">Nenhum documento inserido ainda.</p>
              )}
              {!carregandoDocumentos && (
                <ul className="list-unstyled">
                  {documentos.map((documento, index) => {
                    return (
                      <CardDocumento
                        key={index}
                        documento={documento}
                        onRemove={this.onRemove}
                        enableTrash={habilitarUploadDelete}
                        onClickDocumento={this.setDocumentoModal}
                        onSelectImage={() => {}}
                        onThumbCreated={() => {}}
                      />
                    );
                  })}
                </ul>
              )}
            </Col>
          </Row>

          {exibeBotoesAprovacao && (
            <div>
              {!exibePainelCancelamento && (
                //TODO: "twin buttons component"?
                <>
                  <Row>
                    <Col>
                      <div>
                        <div className="row form-group mt-5">
                          <div className="col-12">
                            <h4 className="text-center">
                              Informe uma justificativa para a aprovação
                            </h4>
                            <textarea
                              autoFocus
                              className="form-control mx-auto w-60"
                              placeholder="Justificativa de aprovação (obrigatória)"
                              onChange={e =>
                                this.setState({
                                  justificativa: e.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="text-center">
                    <Col md={{ span: 2, offset: 4 }} xs={6}>
                      <button
                        type="button"
                        className="btn btn-success"
                        disabled={desabilitarBotaoAprovar}
                        onClick={() =>
                          this.atualizarStatusComprovante(
                            caracteristica === 'WebSecVendas' ||
                              caracteristica === 'WebAprovaComprov'
                              ? status_documentacao == '3' &&
                                caracteristica === 'WebAprovaComprov'
                                ? '4'
                                : '3'
                              : '4'
                          )
                        }
                      >
                        Aprovar
                      </button>
                    </Col>
                    <Col md={2} xs={6}>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.exibirPainelCancelamento(true)}
                      >
                        Recusar
                      </button>
                    </Col>
                  </Row>
                </>
              )}
              {exibePainelCancelamento && (
                <>
                  <Row>
                    <Col>
                      <div>
                        <div className="row form-group mt-5">
                          <div className="col-12">
                            <h4 className="text-center">
                              Informe um ou mais motivos e uma justificativa
                              para reprovar as digitalizações
                            </h4>
                            <table
                              id="motivos"
                              className="table table-condensed table-center"
                            >
                              <tbody>
                                {MOTIVOS_RECUSA_DOCUMENTOS.map(item => (
                                  <tr key={item.value}>
                                    <td>
                                      <div className="d-flex">
                                        <label htmlFor={item.value}>
                                          <input
                                            id={item.value}
                                            type="checkbox"
                                            name="motivo"
                                            value={item.value}
                                            className="teclaenter"
                                            onChange={this.alterarMotivo}
                                          />
                                          {item.label}
                                        </label>
                                      </div>
                                      {motivosSelecionados.indexOf(item.value) >
                                        -1 &&
                                        item.subGrupo && (
                                          <div className="d-flex pl-5">
                                            <table className="w-100">
                                              <tbody>
                                                {item.subGrupo.map(subitem => (
                                                  <tr key={subitem.value}>
                                                    <td>
                                                      <div className="ItemSubGrupo d-flex align-items-center">
                                                        <label
                                                          htmlFor={
                                                            subitem.value
                                                          }
                                                        >
                                                          <input
                                                            id={subitem.value}
                                                            type="checkbox"
                                                            name="motivo"
                                                            value={
                                                              subitem.value
                                                            }
                                                            onChange={
                                                              this.alterarMotivo
                                                            }
                                                          />
                                                          {subitem.label}
                                                        </label>
                                                        {motivosSelecionados.indexOf(
                                                          subitem.value
                                                        ) > -1 &&
                                                          subitem.outros && (
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder={
                                                                subitem.outros
                                                              }
                                                              onBlur={event =>
                                                                this.setObservacoes(
                                                                  subitem,
                                                                  event.target
                                                                    .value
                                                                )
                                                              }
                                                            />
                                                          )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                ))}
                                              </tbody>
                                            </table>
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <textarea
                              autoFocus
                              className="form-control mx-auto w-60"
                              placeholder="Justificativa (obrigatória)"
                              onChange={e =>
                                this.setState({ justificativa: e.target.value })
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col md={{ span: 2, offset: 4 }} xs={6}>
                      <button
                        className="btn btn-warning"
                        onClick={() => this.exibirPainelCancelamento(false)}
                      >
                        Cancelar
                      </button>
                    </Col>
                    <Col md={2} xs={6}>
                      <button
                        className="btn btn-danger"
                        disabled={desabilitarBotaoRecusar}
                        onClick={() =>
                          this.atualizarStatusComprovante(
                            caracteristica === 'WebSecVendas' ||
                              caracteristica === 'WebAprovaComprov'
                              ? status_documentacao == '9' &&
                                caracteristica === 'WebAprovaComprov' ? '8' : '9'
                              : '8'
                          )
                        }
                      >
                        Recusar
                      </button>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}

          <hr />

          {carregandoHistorico && (
            <p className="text-muted text-center">Carregando histórico...</p>
          )}
          {!carregandoHistorico && (
            <>
              <p className="mb-0">
                <strong>Histórico</strong>
              </p>
              {historico.length === 0 && (
                <p className="text-muted">
                  Não há registro de atividade de comprovantes para esta venda.
                </p>
              )}
              <Timeline lineColor={'#ddd'} animate={true} className="ml-auto">
                {historico.map((h, index) => {
                  const cor = TIPO_HISTORICO_PROPOSTA_RAPIDA_CORES.find(
                    t => t.value === h.tipo
                  ).color;

                  return (
                    <TimelineItem
                      key={index}
                      dateText={helpers.format.date(
                        h.data,
                        DATETIME_DEFAULT_FORMAT
                      )}
                      dateInnerStyle={{
                        background: cor,
                      }}
                      style={{
                        color: cor,
                      }}
                    >
                      <>
                        <h5>{h.titulo}</h5>
                        <p className="text-muted">{h.usuario.nome}</p>
                        {h.motivos.length > 0 && (
                          <ul>
                            {h.motivos.map((m, index) => (
                              <li key={index}>
                                {m.motivo}
                                {m.justificativa && (
                                  <>
                                    <span>:</span>
                                    <span className="text-muted">
                                      {m.justificativa}
                                    </span>
                                  </>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                        {h.justificativa && (
                          <p>Justificativa: {h.justificativa}</p>
                        )}
                        {h.titulo_documento && (
                          <small>Título documento: {h.titulo_documento}</small>
                        )}
                      </>
                    </TimelineItem>
                  );
                })}
              </Timeline>
            </>
          )}

          {documentoModal && (
            <Modal
              show={documentoModal !== null}
              onHide={() => this.setDocumentoModal(null)}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <small>{documentoModal.descricao}</small>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <a
                  href={documentoModal.URL}
                  title="Clique na imagem para visualizar o PDF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={documentoModal.Thumb}
                    alt={documentoModal.descricao}
                    className="img-fluid"
                  />
                </a>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default AnexarComprovante;
