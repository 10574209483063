import React from 'react';
import { Form } from 'react-bootstrap';

const FormRecuperarUser = ({handleChangeEmail, handleChangeTelefone, isLoading}) => {
  return (
    <>
      <Form.Group controlId="login.email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          autoFocus
          type='email'
          placeholder="Seu email"
          name="email"
          onChange={handleChangeEmail}
          disabled={isLoading}
        />
      </Form.Group>
      <Form.Group controlId="login.telefone">
            <Form.Label>Telefone</Form.Label>
            <Form.Control
              type="text"
              name="telefone"
              placeholder="Seu telefone"
              disabled={isLoading}
              onChange={handleChangeTelefone}
            />
      </Form.Group>
    </>
  );
};

export default FormRecuperarUser;
