import React, { useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import { FaMoneyBill } from 'react-icons/fa';
import { MdMoneyOff } from 'react-icons/md';

import { formatarMonetario } from '~/Utils/Helpers';
import AuthStorage from '~/Utils/AuthStorage';
import defaultLogo from '~/Images/logo.svg';

import {
  obterTotalTitulosSinal,
  obterTotalComissao,
  valoresComissaoSupervisor,
  distinctTitulos,
  agruparPorCpfCliente,
  agruparPorGerente,
  agruparPorVenda,
  TIPO_COMISSAO_COR,
  TIPO_COMISSAO_IMO,
  TIPO_COMISSAO_MKT,
  TIPO_COMISSAO_SUP,
} from '../helpers';

import ModalDetalhesEstatisticasInadimplencia, {
  renderEstatisticas,
} from '../ModalDetalhesEstatisticasInadimplencia';

const EstatisticasInadimplencia = ({
  registros,
  empreendimentos,
  imobiliarias,
  corretores,
  isLoadingOptions,
}) => {
  const [
    registrosDetalhesEstatisticas,
    setRegistroslDetalhesEstatisticas,
  ] = useState([]);
  const isAdm = AuthStorage.isADM();
  const isGer = AuthStorage.isGerente();
  const isCor = AuthStorage.isCorretor();

  const calcularTotaisComissao = registros => {
    const totalComissaoCorretor = obterTotalComissao(
      registros,
      TIPO_COMISSAO_COR
    );
    let totalComissaoImobiliaria = obterTotalComissao(
      registros,
      TIPO_COMISSAO_IMO
    );
    if (isCor) totalComissaoImobiliaria = 0;
    let totalComissaoImobMkt = obterTotalComissao(registros, TIPO_COMISSAO_MKT);
    if (isCor) totalComissaoImobMkt = 0;
    let totalComissaoSupervisor = obterTotalComissao(
      registros,
      TIPO_COMISSAO_SUP
    );
    if (!isAdm) totalComissaoSupervisor = 0;
    const totalComissao =
      totalComissaoCorretor +
      totalComissaoImobiliaria +
      totalComissaoImobMkt +
      totalComissaoSupervisor;

    return {
      totalComissaoCorretor,
      totalComissaoImobiliaria,
      totalComissaoImobMkt,
      totalComissaoSupervisor,
      totalComissao,
    };
  };

  const renderCardsDadosGeral = registros => {
    const totaisCom = calcularTotaisComissao(registros);

    const colspan = isAdm ? 3 : isGer ? 4 : 6;

    return (
      <>
        <Col xs={12} lg={4}>
          <Card>
            <Card.Body>
              <div className="produtividade-icon">
                <FaMoneyBill color="#EE9A00" />
              </div>
              <ul className="produtividade produtividade-resumo">
                <li className="Destaque">
                  <small>Total de recebíveis pendentes</small>
                  <strong className="d-block">
                    {formatarMonetario(obterTotalTitulosSinal(registros)) ||
                      '-'}
                  </strong>
                </li>
                <Row>
                  <Col as="li" sm={6} className="m-0 my-2">
                    <small className="text-nowrap">Parcelas não pagas</small>
                    <strong className="d-block">
                      {distinctTitulos(registros).length}
                    </strong>
                  </Col>
                  <Col as="li" sm={6} className="m-0 my-2">
                    <small className="text-nowrap">Vendas com pendências</small>
                    <strong className="d-block">
                      {agruparPorVenda(registros).length}
                    </strong>
                  </Col>
                </Row>
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} lg={8}>
          <Card>
            <Card.Body>
              <div className="produtividade-icon">
                <MdMoneyOff color="#dc3545" />
              </div>
              <ul className="produtividade produtividade-resumo">
                <li className="Destaque">
                  <small>Total de comissão pendente</small>
                  <strong className="d-block">
                    {formatarMonetario(totaisCom.totalComissao) || '-'}
                  </strong>
                </li>
                <Row>
                  {isAdm && (
                    <Col as="li" sm={6} lg={colspan} className="m-0 my-2">
                      <small className="text-nowrap">Comissão Comercial</small>
                      <strong className="d-block">
                        {valoresComissaoSupervisor(registros)}
                      </strong>
                    </Col>
                  )}
                  {(isAdm || isGer) && (
                    <>
                      <Col as="li" sm={6} lg={colspan} className="m-0 my-2">
                        <small className="text-nowrap">
                          Comissão Imobiliária
                        </small>
                        <strong className="d-block">
                          {formatarMonetario(
                            totaisCom.totalComissaoImobiliaria
                          ) || '-'}
                        </strong>
                      </Col>
                      <Col as="li" sm={6} lg={colspan} className="m-0 my-2">
                        <small className="text-nowrap">
                          Comissão Imob. Mkt.
                        </small>
                        <strong className="d-block ">
                          {formatarMonetario(totaisCom.totalComissaoImobMkt) ||
                            '-'}
                        </strong>
                      </Col>
                      <Col as="li" sm={6} lg={colspan} className="m-0 my-2">
                        <small className="text-nowrap">
                          Comissão Corretores
                        </small>
                        <strong className="d-block">
                          {formatarMonetario(totaisCom.totalComissaoCorretor) ||
                            '-'}
                        </strong>
                      </Col>
                    </>
                  )}
                  {isCor && (
                    <Col as="li" className="m-0 my-2">
                      <small>Clientes inadimplentes</small>
                      <strong className="d-block">
                        {agruparPorCpfCliente(registros).length}
                      </strong>
                    </Col>
                  )}
                </Row>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </>
    );
  };

  return (
    <div className="CardProdutividadeResumo m-4">
      <div className="d-flex justify-content-between w-100">
        <h5>Estatísticas Gerais</h5>
        <Button
          variant="link"
          disabled={isLoadingOptions}
          onClick={() => setRegistroslDetalhesEstatisticas(registros)}
        >
          Saiba mais
        </Button>
      </div>
      <Row>
        {renderCardsDadosGeral(registros)}

        {isAdm && <hr />}
        {isAdm &&
          agruparPorGerente(registros).map(
            ({ gerente, registros: registrosImobiliaria }) => {
              const imobiliaria = imobiliarias.find(
                imo => imo.codigo === gerente.codigo_usuario_gerente
              );
              return (
                <Col xs={12} key={gerente.codigo_usuario_gerente}>
                  <Card className="border-0 shadow-sm">
                    <Card.Body>
                      <div>
                        {/* <strong>{gerente.apelido_conta_corretor}</strong> */}
                        <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                          <div className="text-center img-resumo-imobiliaria d-flex flex-column">
                            {imobiliaria ? (
                              <img
                                alt={imobiliaria.nome_imobiliaria}
                                style={{ width: 120 }}
                                src={
                                  imobiliaria ? imobiliaria.logo : defaultLogo
                                }
                              />
                            ) : (
                              <h5>
                                {gerente.nome_imobiliaria ||
                                  gerente.codigo_usuario_gerente}
                              </h5>
                            )}
                          </div>

                          {renderEstatisticas(registrosImobiliaria, true, true)}
                          <Button
                            variant="link"
                            disabled={isLoadingOptions}
                            onClick={() =>
                              setRegistroslDetalhesEstatisticas(
                                registrosImobiliaria
                              )
                            }
                            className="ml-auto"
                          >
                            Detalhes
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            }
          )}
      </Row>

      <ModalDetalhesEstatisticasInadimplencia
        registros={registrosDetalhesEstatisticas}
        empreendimentos={empreendimentos}
        corretores={corretores}
        show={registrosDetalhesEstatisticas.length > 0}
        onHide={() => setRegistroslDetalhesEstatisticas([])}
      />
    </div>
  );
};

export default EstatisticasInadimplencia;
