import styled from 'styled-components';

export const TableRowTitulo = styled.tr`
  height: 40px;
  & > td {
    vertical-align: middle !important;
  }
`;

export const TableHeaderVenda = styled.tr`
  & > td {
    vertical-align: middle !important;
  }
`;

export const AcoesVenda = styled.div`
  display: flex;
  justify-content: around;
  & > button + button {
    margin-left: 8px;
  }
`;
