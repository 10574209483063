import React, { useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { format } from '~/Utils/Helpers';
import Header from '~/Components/Header/Header';
import defaultLogo from '../../Images/logo.svg';
import { Card, Col, Alert } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import * as api from '~/Services/Service';

const CardSituacaoDetalhada = props => {
  const [dados, setDados] = useState({});
  const [dadosDetalhe, setDadosDetalhe] = useState({});
  const [dadosAgregado, setDadosAgregado] = useState(null);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [isErrorDetail, setisErrorDetail] = useState(false);

  const buscarDadosCliente = async () => {
    try {
      const { cpf } = props.match.params;
      const response = await api.getPesquisaStatusSituacaoCliente(cpf);
      setDados(response.dados_cliente);

      if (response.dados_situacao_detalhada)
        setDadosDetalhe(response.dados_situacao_detalhada);

      if (response.dados_agregado) setDadosAgregado(response.dados_agregado);

      setIsLoadingOptions(false);
    } catch (error) {
      console.error('Erro ao buscar dados do cliente', error);
      setIsLoadingOptions(false);
      setisErrorDetail(true);
    }
  };

  const toggleVisibility = elementId => {
    const element = document.querySelector(elementId);
    if (element) {
      element.classList.toggle('d-none');
    }
  };

  const onClickCardResumo = codigo => {
    const elementId = `#tabelaResumo_${codigo}`;
    toggleVisibility(elementId);
  };

  const onClickCardAgregado = () => {
    const elementId = `#tabelaResumo_${1}`;
    toggleVisibility(elementId);
  };

  useEffect(() => {
    buscarDadosCliente();
  }, []);

  return (
    <>
      <Header title="Detalhe Situação Cliente" />
      {isLoadingOptions && (
        <>
          <div className="mx-auto d-flex justify-content-center w-100 mt-4">
            Carregando dados...
          </div>
        </>
      )}
      {!isLoadingOptions && !isErrorDetail && (
        <>
          <div className="CardDetalhesVenda mb-4">
            <div className="linha-resumo-imobiliaria">
              <div className="row">
                <div className="col-12 col-md-6 mx-auto">
                  <div className="card border-0">
                    <div className="card-body">
                      {dados && (
                        <>
                          <div className="dados-pesquisa-posvenda">
                            <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center">
                              <li>
                                <small className="d-block">
                                  Nome do cliente
                                </small>
                                <strong className="d-block">
                                  <a
                                    href={
                                      '//extranet.revobrasil.com.br/prospectos/lista/' +
                                      dados.codigo_prospecto
                                    }
                                    target="_blank"
                                    rel="noreferrer noopener"
                                  >
                                    {dados.nome_cliente}{' '}
                                    <FiExternalLink className="align-icon-link" />
                                  </a>
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">E-mail</small>
                                <strong className="d-block">
                                  {dados.email}
                                </strong>
                              </li>
                            </ul>
                            <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center my-3">
                              <li>
                                <small className="d-block">Celular</small>
                                <strong className="d-block">
                                  {dados.celular
                                    ? format.celular(dados.celular)
                                    : '-'}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">
                                  Tel. Residencial
                                </small>
                                <strong className="d-block">
                                  {dados.tel_residencial
                                    ? format.celular(dados.tel_residencial)
                                    : '-'}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">
                                  Tel. Comercial
                                </small>
                                <strong className="d-block">
                                  {dados.tel_comercial
                                    ? format.celular(dados.tel_comercial)
                                    : '-'}
                                </strong>
                              </li>
                            </ul>
                            <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center mt-3">
                              <li>
                                <small className="d-block">Endereço</small>
                                <strong className="d-block">
                                  {dados.endereco} - {dados.bairro},{' '}
                                  {dados.cidade} - {dados.estado},{' '}
                                  {dados.cep ? format.cep(dados.cep) : '-'}
                                </strong>
                              </li>
                            </ul>
                            <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center my-3">
                              <li>
                                <small className="d-block">
                                  Status Situação
                                </small>
                                <strong className="d-block">
                                  {dados.tipo}
                                </strong>
                              </li>
                            </ul>
                            <ul className="list-unstyled d-flex flex-wrap produtividade text-md-left df-center my-3">
                              <li>
                                <small className="d-block">
                                  Contratos Totais
                                </small>
                                <strong className="d-block">
                                  {dados.contratos}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">
                                  Contratos Financiados
                                </small>
                                <strong className="d-block">
                                  {dados.contratos_financiados}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">
                                  Contratos Rescindidos
                                </small>
                                <strong className="d-block">
                                  {dados.contratos_rescindidos}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">
                                  Contratos Transferidos
                                </small>
                                <strong className="d-block">
                                  {dados.contratos_transferidos}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">
                                  Contratos Quitados
                                </small>
                                <strong className="d-block">
                                  {dados.contratos_quitados}
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {dadosAgregado && (
                <>
                  <div className="col-12 col-md-10 mx-auto mt-4">
                    <div className="ImobiliariaItem">
                      <Card className="border-0 shadow-sm">
                        <div
                          onClick={() => onClickCardAgregado()}
                          className="cardHover"
                        >
                          <div className="flag-ranking"></div>
                          <div className="nomeEmpreendimentoCorretor">
                            <span>CPF(s) Vinculado(s)</span>
                          </div>
                          <div className="d-flex flex-column flex-md-row produtividade-imobiliaria produtividade__detalhes-venda flex-wrap">
                            <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                              <div className="text-center img-resumo-imobiliaria d-flex flex-column"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="resumo-vendas d-none"
                          id={`tabelaResumo_${1}`}
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th>CPF</th>
                                <th>Tipo</th>
                                <th>Contrato</th>
                                <th>Situação</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.values(dadosAgregado).map(
                                (dadosItem, index) => (
                                  <tr key={dadosItem.cpf}>
                                    <td>{dadosItem.nome}</td>
                                    <td>
                                      <a
                                        href={
                                          '//extranet.revobrasil.com.br/cliente/pesquisa/situacao/' +
                                          dadosItem.cpf
                                        }
                                        target="_blank"
                                        rel="noreferrer noopener"
                                      >
                                        {dadosItem.cpf}{' '}
                                        <FiExternalLink className="align-icon-link" />
                                      </a>
                                    </td>
                                    <td>{dadosItem.tipo}</td>
                                    <td>{dadosItem.contratos}</td>
                                    <td
                                      style={{
                                        color:
                                          dadosItem.situacao === 'Inadimplente'
                                            ? 'red'
                                            : dadosItem.situacao ===
                                              'Adimplente'
                                            ? 'green'
                                            : dadosItem.situacao ===
                                              'Cliente Novo'
                                            ? 'blue'
                                            : 'black',
                                      }}
                                    >
                                      {dadosItem.situacao}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Card>
                    </div>
                  </div>
                </>
              )}

              {Object.values(dadosDetalhe).map((item, index) => (
                <div
                  className="ImobiliariaItem mt-2"
                  key={item.dados.codigo_familia}
                >
                  <Col>
                    <Card className="border-0 shadow-sm">
                      <div
                        onClick={() =>
                          onClickCardResumo(item.codigo_familia)
                        }
                        className="cardHover"
                      >
                        <div className="flag-ranking"></div>
                        <div className="nomeEmpreendimentoCorretor">
                          <span>{item.nome_familia}</span>
                        </div>
                        <div className="d-flex flex-column flex-md-row produtividade-imobiliaria produtividade__detalhes-venda flex-wrap">
                          <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                            <div className="text-center img-resumo-imobiliaria d-flex flex-column">
                              <img
                                src={defaultLogo}
                                title={item.nome_familia}
                                alt={item.nome_familia}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="resumo-vendas d-none"
                        id={`tabelaResumo_${item.codigo_familia}`}
                      >
                        <Table>
                          <Thead>
                            <Tr>
                              <Th>Quadra</Th>
                              <Th>Lote</Th>
                              <Th>Código conta</Th>
                              <Th>Valor total parcelas vencidas</Th>
                              <Th>Quantidade parcelas</Th>
                              <Th>Data da última parcela paga</Th>
                              <Th>Data da parcela mais atrasada</Th>
                              <Th>Status contrato</Th>
                              <Th>Adimplencia</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {item.dados.map((dadosItem, dataIndex) => (
                              <Tr key={dataIndex}>
                                <Td>{dadosItem.mapa1}</Td>
                                <Td>{dadosItem.mapa2}</Td>
                                <Td>{dadosItem.codigo_conta}</Td>
                                <Td>
                                  {`R$ ${
                                    dadosItem.vencidas_valor
                                      ? dadosItem.vencidas_valor.toLocaleString(
                                          'pt-BR'
                                        )
                                      : '0,00'
                                  }`}
                                </Td>
                                <Td>{dadosItem.vencidas_parcelas}</Td>
                                <Td>
                                  {dadosItem.ultima_parcela_paga
                                    ? format.date(dadosItem.ultima_parcela_paga)
                                    : '-'}
                                </Td>
                                <Td>
                                  {dadosItem.mais_atrasada
                                    ? format.date(dadosItem.mais_atrasada)
                                    : '-'}
                                </Td>
                                <Td>{dadosItem.status_contrato}</Td>
                                <Td
                                  style={{
                                    color:
                                      dadosItem.adimplencia === 'Inadimplente'
                                        ? 'red'
                                        : dadosItem.adimplencia === 'Adimplente'
                                        ? 'green'
                                        : dadosItem.adimplencia ===
                                          'Cliente Novo'
                                        ? 'blue'
                                        : 'black',
                                  }}
                                >
                                  {dadosItem.adimplencia}
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </div>
                    </Card>
                  </Col>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {!isLoadingOptions && isErrorDetail && (
        <>
          <div className="mx-auto d-flex justify-content-center w-100 mt-4">
            <Alert variant="warning ">
              Erro ao carregar os detalhes da situação do cliente
            </Alert>
          </div>
        </>
      )}
    </>
  );
};
export default CardSituacaoDetalhada;
