import Pusher from 'pusher-js';
import * as constant from '../constant';

let pusher;

export function connect() {
  if (process.env.NODE_ENV !== 'production') {
    Pusher.logToConsole = true;
  }

  const appId = constant.pusherConfig.id;
  const appCluster = constant.pusherConfig.cluster;

  pusher = new Pusher(appId, {
    cluster: appCluster,
    encrypted: true,
  });
}

export function addListerner(channelId, eventId, callback) {
  let channel = pusher.subscribe(channelId);
  channel.bind(eventId, callback);
}
