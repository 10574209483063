import React from 'react';
import { Card, Button } from 'react-bootstrap';

import { FaEdit, FaTrash } from 'react-icons/fa';
import picture from '~/Images/picture.svg';
import { format, createDate, getToday } from '~/Utils/Helpers';
import ConfirmButton from '~/Components/ConfirmButton';
import { CardCampanhaWrapper } from './styles';

const CardCampanha = ({
  campanha,
  excluirCampanha,
  encerrarCampanha,
  editarCampanha,
  mostrarResultados,
  isBusy,
}) => {
  const renderRibbon = campanha => {
    const inicio = createDate(campanha.data_inicio);
    const fim = createDate(campanha.data_fim);
    const hoje = getToday();
    if (campanha.data_finalizacao) {
      return (
        <div className="ribbon ribbon-danger ribbon-top-right">
          <span>Encerrada</span>
        </div>
      );
    }

    if (inicio <= hoje && fim >= hoje) {
      return (
        <div className="ribbon ribbon-success ribbon-top-right">
          <span>Vigente</span>
        </div>
      );
    }

    if (inicio >= hoje) {
      return (
        <div className="ribbon ribbon-primary ribbon-top-right">
          <span>Agendada</span>
        </div>
      );
    }

    if (fim < hoje) {
      return (
        <div className="ribbon ribbon-warning ribbon-top-right">
          <span>Finalizada</span>
        </div>
      );
    }
  };
  return (
    <CardCampanhaWrapper key={campanha.codigo} className="m-2">
      {renderRibbon(campanha)}

      <Card.Header className="d-flex bg-white">
        <img
          src={campanha.familia.logo || picture}
          alt={campanha.familia.nome}
          style={{
            height: 120,
            overflow: 'hidden',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </Card.Header>
      <Card.Body
        className={`d-flex flex-column justify-content-between ${
          campanha.data_finalizacao ? 'bg-light' : ''
        }`}
      >
        <div>
          <p className={campanha.data_finalizacao ? 'text-muted' : ''}>
            <strong>{campanha.descricao}</strong>
          </p>
          <p>
            {campanha.codigo_familia} - {campanha.familia.nome}
          </p>
          <small>
            De {format.date(campanha.data_inicio)} até{' '}
            {format.date(campanha.data_fim)}
          </small>
        </div>
        <div className="ml-auto">
          {campanha.data_finalizacao &&
          campanha.codigo !== 1 &&
          campanha.codigo !== 3 && ( //campanhas "legadas" não tem resultado
              <Button
                variant="link"
                disabled={isBusy}
                onClick={() => mostrarResultados(campanha.codigo)}
              >
                Ver resultados
              </Button>
            )}
          {!campanha.data_finalizacao && (
            <>
              {createDate(campanha.data_fim) < getToday() && (
                <ConfirmButton
                  confirmTitle="Encerrar campanha?"
                  confirmText="Deseja encerrar esta campanha?"
                  onConfirm={() => encerrarCampanha(campanha.codigo)}
                  variant="danger"
                  disabled={isBusy}
                >
                  Encerrar
                </ConfirmButton>
              )}
              <Button
                variant="link"
                onClick={() => editarCampanha(campanha)}
                disabled={isBusy}
              >
                <FaEdit />
              </Button>
              <ConfirmButton
                confirmTitle="Excluir campanha?"
                confirmText="Deseja excluir esta campanha?"
                onConfirm={() => excluirCampanha(campanha.codigo)}
                className="text-danger"
                disabled={isBusy}
              >
                <FaTrash />
              </ConfirmButton>
            </>
          )}
        </div>
      </Card.Body>
    </CardCampanhaWrapper>
  );
};

export default CardCampanha;
