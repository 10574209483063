import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const ContainerBusca = styled.div`
  position: relative;
  z-index: 20;
  background: #fff;
  padding: 2rem 0;

  .drop-corretor {
  }
`;

export const DropdownCorretor = styled(Dropdown)`
  z-index: 1010;
  @media (min-width: 992px) {
    z-index: 1040;
  }

  .dropdown-menu {
    &::before {
      position: absolute;
      z-index: 10;
      bottom: 0;
      width: 100%;
      height: 50px;
      content: '';
      display: block;
      background: linear-gradient(180deg, transparent, #fff 70%);
    }
  }
`;
