import React, { Component } from 'react';
import qs from 'qs';
import Header from '~/Components/Header/Header';

import CardImportacaoLead from '~/Components/ImportarLead/CardImportacaoLead';

class ImportarLead extends Component {
  render() {
    const { atendimentoIdGuid } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    return (
      <>
        <Header title="Importar Prospecto do CRM" />
        <div className="my-3">
          <CardImportacaoLead AtendimentoID={atendimentoIdGuid} />
        </div>
      </>
    );
  }
}

export default ImportarLead;
