import React, { Fragment } from 'react';
import { Card, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CardNavigationTab = ({
  activeTab,
  onChangeTab,
  tabs,
  variant = 'pills',
  withCard = true,
  footer,
  header,
}) => {
  const NavLinkList = (
    <Nav variant={variant}>
      {tabs.map(({ haveError, url, isDisabled, label }, index) => (
        <Nav.Item key={index}>
          <Nav.Link
            className={`btn-sm ${haveError && 'is-error'} ${isDisabled &&
              'disabled'} ${activeTab === url && 'NavLink__active '}`}
            onClick={() => onChangeTab(url)}
          >
            {label}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );

  const NavTabs = tabs.map((tab, index) => {
    if (activeTab === tab.url) {
      return <Fragment key={index}>{tab.component}</Fragment>;
    }

    return null;
  });

  if (withCard) {
    return (
      <Card>
        <Card.Header>
          <div className="d-lg-flex flex-row align-items-center justify-content-between">
            {NavLinkList}
            {header}
          </div>
        </Card.Header>
        <Card.Body>{NavTabs}</Card.Body>
        {footer && <Card.Footer>{footer}</Card.Footer>}
      </Card>
    );
  } else {
    return (
      <Fragment>
        {NavLinkList}
        {NavTabs}
      </Fragment>
    );
  }
};

CardNavigationTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      isDisabled: PropTypes.bool,
      isActive: PropTypes.bool,
      label: PropTypes.any.isRequired,
      component: PropTypes.any.isRequired,
      haveError: PropTypes.bool,
    })
  ),
};

export default CardNavigationTab;
