import * as api from '~/Services/Service';

export const getRanking = (codigoCampanha = null, dataIncial = null, dataFinal = null, tipoRanking = null) => async dispatch => {
  dispatch({
    type: 'CARREGANDO_RANKING',
  });
  const data = await api.consultarRankingCorretores(codigoCampanha, dataIncial, dataFinal, tipoRanking);
  dispatch({
    type: 'RANKING_CARREGADO',
    payload: data,
  });
};

export const selecionaImobiliaria = (imobiliaria = null) => ({
  type: 'SELECIONA_IMOBILIARIA',
  payload: {
    imobiliaria,
  },
});

export const selecionarCampanha = (codigoCampanha = null) => ({
  type: 'SELECIONAR_CAMPANHA',
  payload: codigoCampanha,
});
