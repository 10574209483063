// Prospectos
export const ALL_PROSPECTS_FETCH = 'ALL_PROSPECTS_FETCH';
export const FETCHING_PROSPECTS = 'FETCHING_PROSPECTS';
export const FETCH_ERROR_PROSPECTS = 'FETCH_ERROR_PROSPECTS';
export const ACTIVATE_SEARCH = 'ACTIVATE_SEARCH';
export const DEACTIVATE_SEARCH = 'DEACTIVATE_SEARCH';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const FETCHING_PROSPECT_DETAILS = 'FETCHING_PROSPECT_DETAILS';
export const FETCH_PROSPECT_DETAILS_SUCCESS = 'FETCH_PROSPECT_DETAILS_SUCCESS';
export const FETCH_PROSPECT_DETAILS_ERROR = 'FETCH_PROSPECT_DETAILS_ERROR';
export const UPDATE_PROSPECT = 'UPDATE_PROSPECT';
export const CLEAR_FILTER_PROSPECT = 'CLEAR_FILTER_PROSPECT';
export const INICIAR_NOVO_PROSPECTO = 'INICIAR_NOVO_PROSPECTO';
export const CREATING_PROSPECT = 'CREATING_PROSPECT';
export const CREATE_PROSPECT_SUCCESS = 'CREATE_PROSPECT_SUCCESS';
export const CREATE_PROSPECT_ERROR = 'CREATE_PROSPECT_ERROR';
export const CREATE_PROSPECT_SUCCESS_CLOSE = 'CREATE_PROSPECT_SUCCESS_CLOSE';
export const UPDATING_PROSPECT = 'UPDATING_PROSPECT';
export const UPDATE_PROSPECT_SUCCESS = 'UPDATE_PROSPECT_SUCCESS';
export const UPDATE_PROSPECT_ERROR = 'UPDATE_PROSPECT_ERROR';
export const UPDATE_PROSPECT_SUCCESS_CLOSE = 'UPDATE_PROSPECT_SUCCESS_CLOSE';
export const DISMISS_AVISO_DESCARTE_SUA_HOUSE =
  'DISMISS_AVISO_DESCARTE_SUA_HOUSE';

// Documento
export const UPLOADING_DOCUMENTO = 'UPLOADING_DOCUMENTO';
export const UPLOAD_DOCUMENTO_SUCCESS = 'UPLOAD_DOCUMENTO_SUCCESS';
export const UPLOAD_DOCUMENTO_ERROR = 'UPLOAD_DOCUMENTO_ERROR';
export const ATUALIZA_DOCUMENTO = 'ATUALIZA_DOCUMENTO';

// Socio
export const INICIAR_ADICIONAR_SOCIO = 'INICIAR_ADICIONAR_SOCIO';
export const CANCELAR_ADICIONAR_SOCIO = 'CANCELAR_ADICIONAR_SOCIO';
export const SELECIONA_SOCIO = 'SELECIONA_SOCIO';
export const SALVANDO_SOCIO = 'SALVANDO_SOCIO';
export const SALVAR_SOCIO_SUCESSO = 'SALVAR_SOCIO_SUCESSO';
export const SALVAR_SOCIO_ERRO = 'SALVAR_SOCIO_ERRO';
export const SALVAR_SOCIO_SUCESSO_FECHAR = 'SALVAR_SOCIO_SUCESSO_FECHAR';
export const ATUALIZAR_SOCIO_SUCESSO = 'ATUALIZAR_SOCIO_SUCESSO';
export const ATUALIZAR_SOCIO_ERRO = 'ATUALIZAR_SOCIO_ERRO';
export const ATUALIZAR_SOCIO_SUCESSO_FECHAR = 'ATUALIZAR_SOCIO_SUCESSO_FECHAR';

// Endereço
export const BUSCANDO_CEP = 'BUSCANDO_CEP';
export const BUSCA_CEP_SUCESSO = 'BUSCA_CEP_SUCESSO';
export const BUSCA_CEP_ERRO = 'BUSCA_CEP_ERRO';

// Empreendimento
export const VINCULANDO_EMPREENDIMENTO = 'VINCULANDO_EMPREENDIMENTO';
export const EMPRENDIMENTO_VINCULADO = 'EMPRENDIMENTO_VINCULADO';
export const EMPREENDIMENTO_VINCULADO_ERROR = 'EMPREENDIMENTO_VINCULADO_ERROR';
export const SELECIONA_EMPREENDIMENTO = 'SELECIONA_EMPREENDIMENTO';
export const ATUALIZANDO_STATUS_PROSPECTO = 'ATUALIZANDO_STATUS_PROSPECTO';
export const ATUALIZAR_STATUS_PROSPECTO_SUCESSO =
  'ATUALIZAR_STATUS_PROSPECTO_SUCESSO';
export const ATUALIZAR_STATUS_PROSPECTO_SUCESSO_FECHAR =
  'ATUALIZAR_STATUS_PROSPECTO_SUCESSO_FECHAR';
export const ATUALIZAR_STATUS_PROSPECTO_ERRO =
  'ATUALIZAR_STATUS_PROSPECTO_ERRO';
export const REMOVENDO_PROSPECTO_EMPREENDIMENTO = 'REMOVENDO_EMPREENDIMENTO';
export const REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO =
  'REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO';
export const REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO_FECHAR =
  'REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO_FECHAR';
export const REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO =
  'REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO';

// CPF
export const CONSULTANDO_CPF = 'CONSULTANDO_CPF';
export const CONSULTA_CPF_SUCESSO = 'CONSULTA_CPF_SUCESSO';
export const CONSULTA_CPF_ERRO = 'CONSULTA_CPF_ERRO';
export const LIMPA_PROSPECTO_ENCAMINHAR = 'LIMPA_PROSPECTO_ENCAMINHAR';

// Email
export const CONSULTANDO_EMAIL = 'CONSULTANDO_EMAIL';
export const CONSULTA_EMAIL_SUCESSO = 'CONSULTA_EMAIL_SUCESSO';
export const CONSULTA_EMAIL_ERRO = 'CONSULTA_EMAIL_ERRO';

// Gerentes
export const FETCHING_MANAGERS = 'FETCHING_MANAGERS';
export const FETCH_MANAGERS_SUCCESS = 'FETCH_MANAGERS_SUCCESS';
export const FETCH_ERROR_MANAGERS = 'FETCH_ERROR_MANAGERS';

// Corretores
export const FETCHING_AGENTS = 'FETCHING_AGENTS';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_ERROR_AGENTS = 'FETCH_ERROR_AGENTS';

// Options
export const FETCHING_OPTIONS = 'FETCHING_OPTIONS';
export const FETCH_OPTIONS_SUCCESS = 'FETCH_OPTIONS_SUCCESS';
export const FETCH_ERROR_OPTIONS = 'FETCH_ERROR_OPTIONS';

//Pesquisa
export const CARREGANDO_PESQUISA = 'CARREGANDO_PESQUISA';
export const CARREGAR_PESQUISA_SUCESSO = 'CARREGAR_PESQUISA_SUCESSO';
export const CARREGAR_PESQUISA_ERRO = 'CARREGAR_PESQUISA_ERRO';
export const SALVANDO_PESQUISA = 'SALVANDO_PESQUISA';
export const SALVAR_PESQUISA_SUCESSO = 'SALVAR_PESQUISA_SUCESSO';
export const SALVAR_PESQUISA_ERRO = 'SALVAR_PESQUISA_ERRO';
export const SALVAR_PESQUISA_SUCESSO_FECHAR = 'SALVAR_PESQUISA_SUCESSO_FECHAR';

// Profissões
export const FETCHING_OCCUPATIONS = 'FETCHING_OCCUPATIONS';
export const FETCH_OCCUPATIONS_SUCCESS = 'FETCH_OCCUPATIONS_SUCCESS';
export const FETCH_ERROR_OCCUPATIONS = 'FETCH_ERROR_OCCUPATIONS';

// Cidades
export const FETCHING_CITIES = 'FETCHING_CITIES';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_ERROR_CITIES = 'FETCH_ERROR_CITIES';

// Nacionalidades
export const FETCHING_NATIONALITIES = 'FETCHING_NATIONALITIES';
export const FETCH_NATIONALITIES_SUCCESS = 'FETCH_NATIONALITIES_SUCCESS';
export const FETCH_ERROR_NATIONALITIES = 'FETCH_ERROR_NATIONALITIES';

// PDV
export const FETCHING_PDV = 'FETCHING_PDV';
export const FETCH_PDV_SUCCESS = 'FETCH_PDV_SUCCESS';
export const FETCH_ERROR_PDV = 'FETCH_ERROR_PDV';

// Motivos de Descarte
export const FETCHING_MOTIVOS_DESCARTE = 'FETCHING_MOTIVOS_DESCARTE';
export const FETCH_MOTIVOS_DESCARTE_SUCCESS = 'FETCH_MOTIVOS_DESCARTE_SUCCESS';
export const FETCH_ERROR_MOTIVOS_DESCARTE = 'FETCH_ERROR_MOTIVOS_DESCARTE';

// Update Obj
export const UPDATE_CLIENTE = 'UPDATE_CLIENTE';

// Ocorrencias
export const TOGGLE_MODAL_OCORRENCIA = 'TOGGLE_MODAL_OCORRENCIA';
export const SALVANDO_OCORRENCIA = 'SALVANDO_OCORRENCIA';
export const SALVAR_OCORRENCIA_SUCESSO = 'SALVAR_OCORRENCIA_SUCESSO';
export const SALVAR_OCORRENCIA_SUCESSO_FECHAR =
  'SALVAR_OCORRENCIA_SUCESSO_FECHAR';
export const SALVAR_OCORRENCIA_ERRO = 'SALVAR_OCORRENCIA_ERRO';
export const EDITAR_OCORRENCIA = 'EDITAR_OCORRENCIA';
export const EDITAR_OCORRENCIA_SUCESSO = 'EDITAR_OCORRENCIA_SUCESSO';
export const EDITAR_OCORRENCIA_SUCESSO_FECHAR =
  'EDITAR_OCORRENCIA_SUCESSO_FECHAR';

//Options para Venda
export const FETCH_USUARIOS_AUTORIZADORES_VENDA_SUCCESS =
  'FETCH_USUARIOS_AUTORIZADORES_VENDA_SUCCESS';
export const FETCHING_USUARIOS_AUTORIZADORES_VENDA =
  'FETCHING_USUARIOS_AUTORIZADORES_VENDA';
