import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import { default as Selector } from 'react-select';
import AsyncSelect from 'react-select/async';
import MaskedInput from 'react-text-mask';
import { FaSearch } from 'react-icons/fa';
import { Formik } from 'formik';

import { getToday } from '~/Utils/Helpers';
import AuthStorage from '~/Utils/AuthStorage';
import * as constants from '~/constant';

import { getAtualizarCidades } from '~/Store/Prospecto/actions';
import SelectGerenteCorretor from '~/Components/GerentesCorretores/SelectGerenteCorretor';
import Hint from '../../Hint/Hint';
import DateMask from '../../DateMask';

class FiltrosInadimplencia extends React.Component {
  filtroCidades = string => {
    return this.props.cidades
      .filter(cidades =>
        cidades.descricao.toLowerCase().includes(string.toLowerCase())
      )
      .map(item => ({
        value: item.codigo,
        label: item.descricao,
      }));
  };

  timeout = null;
  carregarCidades = (inputValue, callback) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      callback(this.filtroCidades(inputValue));
    }, 500);
  };

  buildValueEmpreendimento = codigo => {
    return codigo
      ? {
          value: parseInt(codigo),
          label: this.props.empreendimentos.byId[codigo].descricao,
        }
      : { value: '', label: 'Todos empreendimentos' };
  };
  render() {
    const {
      empreendimentos,
      isLoadingOptions,
      cidades,
      cidadesById,
      getAtualizarCidades,
      onSubmit,
    } = this.props;

    const optionsEmpreendimentos = [
      { value: '', label: 'Todos empreendimentos' },
      ...empreendimentos.sort.map(item => ({
        value: item.codigo,
        label: item.descricao,
      })),
    ];

    const isCor = AuthStorage.isCorretor();

    return (
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          data_inicial: isCor ? '' : getToday().add(-90, 'days'),
          data_final: isCor ? '' : getToday(),
          codigo_gerente: '',
          codigo_corretor: '',
          codigo_cidade: '',
          codigo_familia: '',
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          setFieldValue,
          errors,
          touched,
        }) => (
          <div className="CardProdutividadeFiltro">
            <Card>
              <Card.Body>
                <form onSubmit={handleSubmit}>
                  <Row className="align-items-end">
                    <Col sm="12" lg="8">
                      <SelectGerenteCorretor
                        gerente={values.codigo_gerente}
                        corretor={values.codigo_corretor}
                        onChangeCorretor={corretor =>
                          setFieldValue('codigo_corretor', corretor)
                        }
                        onChangeGerente={gerente =>
                          setFieldValue('codigo_gerente', gerente)
                        }
                        ColSizeMd={6}
                        showLabels
                        mostrarParaCorretor
                      />
                    </Col>

                    <Col sm="12" lg="4">
                      <Form.Group controlId="search.codigo_familia">
                        <Form.Label>Empreendimento</Form.Label>
                        <Selector
                          noOptionsMessage={() =>
                            'Nenhum empreendimentos encontrado'
                          }
                          placeholder="Pesquisar empreendimentos..."
                          options={optionsEmpreendimentos}
                          onChange={({ value }) =>
                            setFieldValue('codigo_familia', value)
                          }
                          isLoading={isLoadingOptions}
                          value={this.buildValueEmpreendimento(
                            values.codigo_familia
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="4" lg="2">
                      <Form.Group controlId="search.estado">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control
                          as="select"
                          className="form-control"
                          name="estado"
                          onChange={event => {
                            getAtualizarCidades(event.target.value);
                            handleChange(event);
                            setFieldValue('codigo_cidade', '');
                          }}
                          value={values.estado}
                          isInvalid={errors.estado && touched.estado}
                        >
                          <option value="">Estado</option>
                          {constants.ESTADOS.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="8" lg="4">
                      <Form.Group controlId="search.codigo_cidade">
                        <Form.Label>Cidade</Form.Label>
                        <AsyncSelect
                          isDisabled={cidades && cidades.length === 0}
                          isLoading={cidades.length === 0}
                          loadingMessage={() => 'Carregando...'}
                          noOptionsMessage={() =>
                            'Digite uma cidade. Ex.: Rio Preto'
                          }
                          placeholder="Pesquisar cidades..."
                          loadOptions={this.carregarCidades}
                          onChange={option => {
                            setFieldValue('codigo_cidade', option.value);
                          }}
                          value={
                            values.codigo_cidade &&
                            cidades.length > 0 &&
                            cidadesById[values.codigo_cidade]
                              ? {
                                  value: parseInt(
                                    cidadesById[values.codigo_cidade].codigo
                                  ),
                                  label:
                                    cidadesById[values.codigo_cidade].descricao,
                                }
                              : {
                                  value: '',
                                  label:
                                    cidades.length === 0
                                      ? 'Selecione um Estado'
                                      : 'Selecione uma cidade',
                                }
                          }
                        />
                        {errors.codigo_cidade && (
                          <small className="form-text text-danger">
                            {errors.codigo_cidade}
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col xs="12" sm="6" lg="2">
                      <DateMask
                        initialValue={values.data_inicial}
                        allowEmpty
                        onValidate={value =>
                          setFieldValue('data_inicial', value)
                        }
                      >
                        {({ dateError, dateValue, setDate }) => (
                          <>
                            <Form.Label>
                              Data Inicial
                              <Hint
                                title="Data Inicial"
                                content="Data inicial da realização da pré-venda"
                              />
                            </Form.Label>
                            <Form.Group controlId="produtividade.data_inicial">
                              <MaskedInput
                                name="data_inicial"
                                type="tel"
                                required={false}
                                guide={false}
                                className={`form-control ${((errors.data_inicial &&
                                  touched.data_inicial) ||
                                  dateError) &&
                                  'is-invalid'}`}
                                placeholder="Ex.: 01/01/2019"
                                mask={constants.MASK_DATE}
                                value={dateValue}
                                onChange={e => setDate(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.data_inicial}
                              </Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                {dateError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </>
                        )}
                      </DateMask>
                    </Col>
                    <Col xs="12" sm="6" lg="2">
                      <DateMask
                        initialValue={values.data_final}
                        allowEmpty
                        onValidate={value => setFieldValue('data_final', value)}
                      >
                        {({ dateError, dateValue, setDate }) => (
                          <>
                            <Form.Label>
                              Data Final
                              <Hint
                                title="Data Final"
                                content="Data final da realização da pré-venda"
                              />
                            </Form.Label>
                            <Form.Group controlId="produtividade.data_final">
                              <MaskedInput
                                name="data_final"
                                type="tel"
                                required={false}
                                guide={false}
                                className={`form-control ${((errors.data_final &&
                                  touched.data_final) ||
                                  dateError) &&
                                  'is-invalid'}`}
                                placeholder="Ex.: 01/01/2019"
                                mask={constants.MASK_DATE}
                                value={dateValue}
                                onChange={e => setDate(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.data_final}
                              </Form.Control.Feedback>
                              <Form.Control.Feedback type="invalid">
                                {dateError}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </>
                        )}
                      </DateMask>
                    </Col>

                    <Col xs="12" lg="2" className="text-center btn-sm">
                      <Form.Group>
                        <Button
                          variant="outline-primary"
                          type="submit"
                          className="ml-auto"
                        >
                          <FaSearch />
                          <span>&nbsp;Buscar</span>
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </div>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  empreendimentos: state.empreendimentos,
  imobiliarias: state.corretor.listaGerentes,
  isLoadingOptions: state.prospecto.isLoadingOptions,
  cidades: state.options.cidades,
  cidadesById: state.options.cidadesById,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getAtualizarCidades }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltrosInadimplencia);
