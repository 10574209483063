import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const SendToClipboardButton = ({ className, children, valueToSend }) => {
  const initialMessage = 'Copiar';
  const [mensagem, setMensagem] = useState(initialMessage);

  const sendToClipboard = () => {
    navigator.clipboard.writeText(valueToSend);
    setMensagem('Copiado');
    setTimeout(() => setMensagem(initialMessage), 1000);
  };

  return (
    <OverlayTrigger
      trigger={['focus', 'hover']}
      overlay={<Tooltip>{mensagem}</Tooltip>}
    >
      <Button
        variant="link"
        className={className}
        onClick={sendToClipboard}
        style={{ textDecoration: 'underline dashed' }}
      >
        {children}
      </Button>
    </OverlayTrigger>
  );
};

export default SendToClipboardButton;
