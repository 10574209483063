import React from 'react';
import {
  Col,
  Button,
  ButtonGroup,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';

import { MASK_DATE } from '~/constant';
import MoneyField from '~/Components/Form/MoneyField';
import { calcularValorParcelaPlano } from '~/Pages/Vendas/helpers';
import moment from 'moment';
import GeradorData from '~/Components/GeradorData';

const FormPlanoFinanciamento = ({
  initialValues,
  onCancel,
  onConfirm,
  saldoAFinanciar,
  parcelasAtuais,
  tabela,
  vencimentoPlanejado,
  vencimentoObrigatorioEscadinha,
}) => {
  const temParcelas = !!initialValues.parcelas;

  const diasVencimentosValidos = [5, 10, 15, 20, 25];
  const validar = values => {
    let errors = {};
    if (!values.vencimento_mask) {
      errors.vencimento_mask = 'Informe o vencimento';
      return errors;
    }

    const vencimento = moment(values.vencimento_mask, 'DD/MM/YYYY');
    if (vencimento.isValid()) {
      if (!diasVencimentosValidos.includes(vencimento.date()))
        errors.vencimento_mask = 'Dia inválido. Use 5, 10, 15, 20 ou 25';
    }
    if (!vencimento) errors.vencimento_mask = 'Data inválida';

    return errors;
  };

  const setParcelaEValor = (parcelas, setFieldValue) => {
    setFieldValue('parcelas', parcelas);
    const valor = calcularValorParcelaPlano(saldoAFinanciar, parcelas, tabela);
    setFieldValue('valor', valor);
  };

  const vencimentoInicial =
    vencimentoObrigatorioEscadinha || vencimentoPlanejado;

  return (
    <Formik
      initialValues={{
        ...initialValues,
        vencimento_mask: vencimentoInicial,
      }}
      onSubmit={onConfirm}
      validate={validar}
      enableReinitialize
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form.Row>
          <Col md={2}>
            <Form.Group controlId="formaPagamento">
              <small>Parcelas *</small>
              <Form.Control
                name="parcelas"
                type="number"
                className="form-control"
                disabled={temParcelas}
                value={values.parcelas}
                min="1"
                onChange={e => setParcelaEValor(e.target.value, setFieldValue)}
                autoFocus={!temParcelas}
              />
              <ErrorMessage
                name="parcelas"
                className="text-danger"
                component="small"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formaPagamento">
              <small>Valor</small>
              <Field
                name="valor"
                className="form-control"
                component={MoneyField}
                placeholder="Ex.: 1.000,00"
                disabled
              />
              <ErrorMessage
                name="valor"
                className="text-danger"
                component="small"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formaPagamento">
              <small>Vencimento *</small>
              <Field
                name="vencimento_mask"
                className="form-control"
                as={MaskedInput}
                mask={MASK_DATE}
                placeholder="Ex.: 10/10/2023"
                autoFocus={temParcelas}
                value={vencimentoInicial}
                disabled={vencimentoObrigatorioEscadinha}
              />
              <GeradorData
                original={values.vencimento_mask}
                onUpdate={date => setFieldValue('vencimento', date)}
              />
              <ErrorMessage
                name="vencimento_mask"
                className="text-danger"
                component="small"
              />
            </Form.Group>
          </Col>
          <Col md="auto">
            <ButtonGroup
              className="form-group float-right"
              style={{ marginTop: 25 }}
            >
              {parcelasAtuais.length === 0 && (
                <Button
                  variant="primary"
                  disabled={!values.parcelas || !values.vencimento_mask}
                  onClick={handleSubmit}
                >
                  Inserir
                </Button>
              )}
              {parcelasAtuais.length > 0 && (
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  overlay={
                    <Popover>
                      <Popover.Title>Confirmar Ação</Popover.Title>
                      <Popover.Content>
                        <p>Deseja substituir as parcelas de financiamento?</p>
                        <ButtonGroup className="w-100">
                          <Button
                            variant="outline-primary"
                            onClick={() => document.body.click()}
                          >
                            Não
                          </Button>
                          <Button variant="primary" onClick={handleSubmit}>
                            Sim
                          </Button>
                        </ButtonGroup>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button
                    variant="primary"
                    disabled={!values.parcelas || !values.vencimento_mask}
                  >
                    Inserir
                  </Button>
                </OverlayTrigger>
              )}
              <Button variant="outline-primary" onClick={onCancel}>
                Cancelar
              </Button>
            </ButtonGroup>
          </Col>
        </Form.Row>
      )}
    </Formik>
  );
};

export default FormPlanoFinanciamento;
