import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { Formik } from 'formik';
import {
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import { default as Selector } from 'react-select';
import MaskedInput from 'react-text-mask';

import * as constants from '~/constant';
import { mask, format, createDate } from '~/Utils/Helpers';
import { default as auth } from '~/Utils/AuthStorage';
import SelectGerenteCorretor from '~/Components/GerentesCorretores/SelectGerenteCorretor';

const searchType = {
  nome: {
    label: 'Nome',
    mask: false,
    input: 'text',
  },
  cpf_cnpj: {
    label: 'CPF/CNPJ',
    mask: mask.cpf_cnpj,
    input: 'tel',
  },
  rg_ie: {
    label: 'RG/IE',
    mask: false,
    input: 'text',
  },
  telefone: {
    label: 'Telefone',
    mask: mask.celular,
    input: 'tel',
  },
  lead: {
    label: 'Lead',
    mask: false,
    input: 'tel',
  },
  prospecto: {
    label: 'Prospecto',
    mask: false,
    input: 'tel',
  },
};

export default function ProspectoBuscaFormulario({
  onSubmit,
  filter,
  empreendimentos,
  isLoading,
  isLoadingOptions,
  filterIsDirty,
  exibirFiltroEtapa = true,
  exibirFiltroGerenteCorretor = true,
  onReset,
  exclude = [],
}) {
  const [showFullSearch, setShowFullSearch] = useState(exclude.length > 0);

  const defaultValues = {
    tipo_filtro: 'nome',
    filtro: '',
    codigo_gerente: '',
    codigo_corretor: '',
    codigo_empreendimento: '',
    codigo_prospecto_status: '',
    data_inicial: '',
    data_final: '',
    tipo_pasta: '',
    origem: '',
  };

  if (auth.isGerente()) {
    defaultValues.codigo_gerente = auth.getUser().usuario;
    defaultValues.codigo_corretor = '';
  }

  if (auth.isCorretor()) {
    defaultValues.codigo_corretor = auth.getUser().usuario;
    defaultValues.codigo_gerente = '';
  }

  const initialValues = {
    ...defaultValues,
    ...filter,
  };

  const searchTypeList = Object.keys(searchType)
    .filter(key => auth.isADM() || (!auth.isADM() && key !== 'prospecto'))
    .map(key => ({ value: key, label: searchType[key].label }));

  const handleSubmit = values => {
    let request = { ...values };

    if (request.data_inicial) {
      request.data_inicial = createDate(
        values.data_inicial,
        'DD/MM/YYYY'
      ).format(constants.DATE_FORMAT_SERVER);
    }

    if (request.data_final) {
      request.data_final = createDate(values.data_final, 'DD/MM/YYYY').format(
        constants.DATE_FORMAT_SERVER
      );
    }

    if (
      searchType[request.tipo_filtro] &&
      searchType[request.tipo_filtro].input === 'tel'
    ) {
      request.filtro = format.clear(request.filtro);
    }

    onSubmit(request);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        data_inicial: initialValues.data_inicial
          ? format.date(initialValues.data_inicial)
          : '',
        data_final: initialValues.data_final
          ? format.date(initialValues.data_final)
          : '',
      }}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit}>
          {values.tipo_filtro && (
            <Form.Group controlId="search.termo">
              <InputGroup>
                <MaskedInput
                  className="form-control"
                  autoFocus
                  type={searchType[values.tipo_filtro].input}
                  name="filtro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mask={searchType[values.tipo_filtro].mask}
                  value={values.filtro}
                  placeholder="Pesquisar por..."
                />

                <DropdownButton
                  as={InputGroup.Append}
                  alignRight
                  variant="outline-secondary"
                  title={searchType[values.tipo_filtro].label}
                  id="input-group-dropdown-tipo-busca"
                >
                  {searchTypeList.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        if (item.value !== values.tipo_filtro) {
                          // salva o valor anterior no tipo novo
                          setFieldValue(item.value, values[values.tipo_filtro]);
                          // limpa o campo anterior
                          setFieldValue(values.tipo_filtro, '');
                          // seleciona o tipo novo
                          setFieldValue('tipo_filtro', item.value);
                        }
                      }}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
            </Form.Group>
          )}

          {isLoadingOptions && 'Carregando filtros...'}

          {!isLoadingOptions && (
            <>
              <Form.Group controlId="search.codigo_empreendimento">
                <Form.Label srOnly>Empreendimentos</Form.Label>
                <Selector
                  noOptionsMessage={() => 'Nenhum empreendimentos encontrado'}
                  placeholder="Pesquisar empreendimentos..."
                  options={[
                    { value: '', label: 'Todos empreendimentos' },
                    ...empreendimentos.sort.map(item => ({
                      value: item.codigo,
                      label: item.descricao,
                    })),
                  ]}
                  onChange={option => {
                    setFieldValue('codigo_empreendimento', option.value);
                    if (option.value === '') {
                      setFieldValue('tipo_pasta ', '');
                    }
                  }}
                  value={
                    values.codigo_empreendimento
                      ? {
                          value: parseInt(
                            empreendimentos.byId[values.codigo_empreendimento]
                              .codigo
                          ),
                          label:
                            empreendimentos.byId[values.codigo_empreendimento]
                              .descricao,
                        }
                      : { value: '', label: 'Todos empreendimentos' }
                  }
                />
              </Form.Group>
              {values.codigo_empreendimento && exibirFiltroEtapa && (
                <Form.Group controlId="prospect.tipo_pasta ">
                  <Form.Label srOnly>Situação cadastro</Form.Label>
                  <Form.Control
                    as="select"
                    name="tipo_pasta"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tipo_pasta}
                  >
                    <option value="">Todos os tipos de cadastro</option>
                    {constants.TIPO_CADASTRO.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              {showFullSearch && (
                <>
                  <Form.Label srOnly>Data de cadastro entre</Form.Label>
                  <Row>
                    <Col xs="6">
                      <Form.Group controlId="search.data_inicial">
                        <Form.Label srOnly>Data inicial</Form.Label>
                        <MaskedInput
                          name="data_inicial"
                          type="tel"
                          guide={false}
                          className="form-control"
                          placeholder="Ex.: Cadastro de 29/12/1990"
                          mask={constants.MASK_DATE}
                          value={values.data_inicial}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="6">
                      <Form.Group controlId="search.data_final">
                        <Form.Label srOnly>Data final</Form.Label>
                        <MaskedInput
                          name="data_final"
                          type="tel"
                          guide={false}
                          className="form-control"
                          placeholder="Ex.: Cadastro Até 29/12/1990"
                          mask={constants.MASK_DATE}
                          value={values.data_final}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {exibirFiltroEtapa && (
                    <Row>
                      <Col xs="12" md="6">
                        <Form.Group controlId="search.codigo_prospecto_status">
                          <Form.Label srOnly>Status</Form.Label>
                          <Form.Control
                            as="select"
                            name="codigo_prospecto_status"
                            onChange={handleChange}
                            value={values.codigo_prospecto_status}
                          >
                            <option value="">
                              Todos os status de atendimento
                            </option>
                            {constants.PROSPECTO_STATUS.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs="12" md="6">
                        <Form.Group controlId="search.origem">
                          <Form.Label srOnly>Origem Cadastro</Form.Label>
                          <Form.Control
                            as="select"
                            name="origem"
                            onChange={handleChange}
                            value={values.origem}
                          >
                            <option value="">Todos tipos de cadastro</option>
                            {constants.PROSPECTO_ORIGEM_CADASTRO.map(
                              (item, index) => (
                                <option key={index} value={item.value}>
                                  {item.label}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {exibirFiltroGerenteCorretor && (
                    <SelectGerenteCorretor
                      gerente={values.codigo_gerente}
                      corretor={values.codigo_corretor}
                      onChangeCorretor={codigoCorretor =>
                        setFieldValue('codigo_corretor', codigoCorretor)
                      }
                      onChangeGerente={codigoGerente =>
                        setFieldValue('codigo_gerente', codigoGerente)
                      }
                      ColSizeMd={6}
                    />
                  )}
                </>
              )}
              <div className="clearfix">
                {exclude.length === 0 && (
                  <div className="float-left">
                    <Button
                      type="button"
                      variant={showFullSearch ? 'primary' : 'outline-primary'}
                      onClick={() => setShowFullSearch(!showFullSearch)}
                      disabled={isLoading}
                    >
                      <FaFilter /> Filtros
                    </Button>
                  </div>
                )}
                <div className="float-right text-right">
                  {(dirty || filterIsDirty) && (
                    <Button
                      type="reset"
                      variant="link"
                      onClick={async () => {
                        await resetForm(defaultValues);
                        onReset();
                      }}
                      disabled={isLoading}
                    >
                      Limpar
                    </Button>
                  )}
                  <Button type="submit" disabled={isLoading} className="mr-1">
                    <FaSearch />
                    &nbsp;
                    {isLoading ? <>Buscando...</> : <>Buscar</>}
                  </Button>
                </div>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

ProspectoBuscaFormulario.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  filter: PropTypes.object,
  empreendimentos: PropTypes.object.isRequired,
  isLoading: PropTypes.any,
  isLoadingOptions: PropTypes.any,
  filterIsDirty: PropTypes.any,
  onReset: PropTypes.func,
  exclude: PropTypes.array,
};
