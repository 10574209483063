import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import qs from 'qs';

import Header from '~/Components/Header/Header';
import WizardSteps from '~/Components/WizardSteps';
import FormularioSinal from '~/Components/Vendas/Sinal';
import FormularioFinanciamento from '~/Components/Vendas/Financiamento';
import Checkout from '~/Components/Vendas/Checkout';
import VendaGravada from '~/Components/Vendas/VendaGravada';
import VendaExcluida from '~/Components/Vendas/VendaExcluida';
import ResumoVenda from '~/Components/Vendas/Resumo';
import {
  carregarPropostaInicial,
  carregarPropostaAuxiliar,
  setProspectoVenda,
  gravarVenda,
  autorizarVenda,
  excluirVenda,
} from '~/Store/Vendas/actions';
import {
  salvarSinal,
  adicionaFormaPgtoEntrada,
  removeFormaPgtoEntrada,
  atualizaFormaPgtoEntrada,
  removeTodasParcelasEntrada,
} from '~/Store/Vendas/Sinal/actions';
import {
  adicionarParcelaFinanciamento,
  removerParcelaFinanciamento,
  atualizarParcelaFinanciamento,
  removerTodasParcelasFinanciamento,
} from '~/Store/Vendas/Financiamento/actions';
import {
  setDataVenda,
  setCodigoPontoVenda,
} from '~/Store/Vendas/Checkout/actions';
import { dividirComissao } from '~/Store/Vendas/DivisaoComissao/actions';
import {
  selecionaTabelaPreco,
  selecionaAtivo,
  selecionaConstrutor,
} from '~/Store/Vendas/actions';
import ProspectoDetalhes from '~/Components/Prospectos/ProspectoDetalhes/ProspectoDetalhes';
import Loading from '~/Components/Loading';
import ErrorAlert from '~/Components/ErrorAlert';
import {
  listarMotivosParaAutorizacao,
  obterInformacoesAtivosSelecionados,
  calcularTotalVenda,
  calcularTotalEntrada,
  calcularTotalTitulosSinal,
  r2,
} from './helpers';
import { CHEQUE } from '~/constant';

const ETAPAS_VENDA = [
  'Prospecto',
  'Lotes e Entrada',
  'Financiamento',
  'Confirmação',
];

class Vendas extends Component {
  state = {
    etapaAtual: ETAPAS_VENDA[0],
    etapasCompletas: [],
    erro: '',
    autorizando: false,
    codigoProposta: null,
  };

  componentDidMount() {
    const {
      carregarPropostaInicial,
      carregarPropostaAuxiliar,
      setProspectoVenda,
      match,
    } = this.props;

    const { prospecto, empreendimento } = match.params;
    const queryString = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (prospecto && empreendimento) {
      carregarPropostaInicial(empreendimento, prospecto);
      setProspectoVenda();
    } else if (queryString.codigoProposta) {
      carregarPropostaAuxiliar(queryString.codigoProposta);
      setProspectoVenda();
      this.setState({
        ...this.state,
        autorizando: true,
        codigoProposta: queryString.codigoProposta,
      });
    } else {
      this.setState({ ...this.state, erro: 'Parâmetros inválidos.' });
    }
  }

  alterarEtapa = (etapa = null) => {
    let index;
    if (etapa) index = ETAPAS_VENDA.indexOf(etapa);
    else {
      index = ETAPAS_VENDA.indexOf(this.state.etapaAtual);
      index++;
    }

    let etapaAtual = ETAPAS_VENDA[index];

    this.setState({
      etapaAtual,
      etapasCompletas: [...this.state.etapasCompletas, this.state.etapaAtual],
    });
  };

  permiteAvancarEtapa = (
    etapa = null,
    ativosSelecionados,
    totalFaltanteEntrada,
    listaFormasDePagamento,
    financiamentos
  ) => {
    let permitir = false;
    let mensagem = '';
    switch (etapa) {
      case ETAPAS_VENDA[0]:
        const { prospecto } = this.props;
        permitir = prospecto && parseInt(prospecto.codigo_conta) > 0;
        if (!permitir)
          mensagem =
            prospecto && prospecto.codigo
              ? 'Para avançar, crie a pasta do prospecto.'
              : '';
        break;
      case ETAPAS_VENDA[1]:
        permitir = ativosSelecionados.length > 0 && totalFaltanteEntrada === 0;
        if (!permitir)
          mensagem =
            'Para avançar, selecione os lotes e informe as parcelas de sinal.';
        if (
          ativosSelecionados.length > 1 &&
          !listaFormasDePagamento.find(p => p.tipo_parcela === CHEQUE)
        ) {
          permitir = false;
          if (mensagem === '')
            mensagem =
              'Para avançar, informe um cheque como forma de pagamento de sinal';
          else
            mensagem += ' Informe um cheque como forma de pagamento de sinal';
        }
        break;
      case ETAPAS_VENDA[2]:
        permitir = ativosSelecionados.every(
          codigo =>
            financiamentos[codigo] &&
            financiamentos[codigo].parcelasFinanciamento.filter(
              x => x.periodicidade_balao === null && !x.tipo_escadinha
            ).length > 0
        );
        if (!permitir)
          mensagem =
            'Para avançar, informe as condições de financiamento do(s) lote(s).';
        break;
      case ETAPAS_VENDA[3]:
        break;
      default:
        break;
    }
    return { permitir, mensagem };
  };

  render() {
    const {
      etapaAtual,
      etapasCompletas,
      autorizando,
      codigoProposta,
    } = this.state;

    const {
      totalVenda,
      totalEntrada,
      selecionaTabelaPreco,
      selecionaConstrutor,
      vendas,
      selecionaAtivo,
      tabelasPorCodigo,
      ativosPorCodigo,
      salvarSinal,
      listaFormasDePagamento,
      totalFaltanteEntrada,
      totalTitulosSinal,
      adicionaFormaPgtoEntrada,
      removeFormaPgtoEntrada,
      atualizaFormaPgtoEntrada,
      removeTodasParcelasEntrada,
      adicionarParcelaFinanciamento,
      removerTodasParcelasFinanciamento,
      prospecto,
      gravarVenda,
      autorizarVenda,
      excluirVenda,
      corretores,
      dividirComissao,
      divisaoComissao,
      setDataVenda,
      setCodigoPontoVenda,
      cartoes,
    } = this.props;

    const {
      codigoProspecto,
      codigoFamilia,
      carregandoPropostaInicial,
      erroCarregamentoPropostaInicial,
      tabelaSelecionada,
      ativosSelecionados,
      empreendimento,
      isGravandoVenda,
      vendaGravada,
      erroGravacaoVenda,
      financiamentos,
      isExcluindoVenda,
      erroExclusaoVenda,
      vendaExcluida,
      checkout,
    } = vendas;

    const listaMotivosAutorizacao = listarMotivosParaAutorizacao(
      vendas,
      tabelasPorCodigo
    );

    const erro = erroCarregamentoPropostaInicial || this.state.erro;

    return (
      <>
        <Header title="Vendas">
          {!vendaGravada && !vendaExcluida && (
            <WizardSteps
              steps={ETAPAS_VENDA}
              current={etapaAtual}
              complete={etapasCompletas}
              onClick={this.alterarEtapa}
            />
          )}
        </Header>

        <div className="container py-3">
          {carregandoPropostaInicial && (
            <Loading label="Carregando dados para venda..." />
          )}

          {erro && (
            <ErrorAlert>
              <p>{erro}</p>
            </ErrorAlert>
          )}

          {empreendimento && (
            <>
              {!!vendaGravada && <VendaGravada vendaGravada={vendaGravada} />}
              {vendaExcluida && (
                <VendaExcluida codigoProposta={codigoProposta} />
              )}
              {!vendaGravada && !vendaExcluida && (
                <div className="row">
                  <div className="col-md-4 order-md-2 mb-4">
                    <ResumoVenda
                      tabelaSelecionada={tabelasPorCodigo[tabelaSelecionada]}
                      empreendimento={empreendimento}
                      totalVenda={totalVenda}
                      totalTitulosSinal={totalTitulosSinal}
                      ativosSelecionados={ativosSelecionados}
                      ativosPorCodigo={ativosPorCodigo}
                      ativosDaTabelaSelecionadaPorCodigo={
                        vendas.ativosDaTabelaSelecionadaPorCodigo
                      }
                      permissaoAvancar={this.permiteAvancarEtapa(
                        etapaAtual,
                        ativosSelecionados,
                        totalFaltanteEntrada,
                        listaFormasDePagamento,
                        financiamentos
                      )}
                      quandoAvancar={() => this.alterarEtapa()}
                      prospecto={prospecto}
                      etapaAtual={etapaAtual}
                      etapas={ETAPAS_VENDA}
                      listaMotivosAutorizacao={listaMotivosAutorizacao}
                    />
                  </div>

                  <div className="col-md-8 order-md-1">
                    {etapaAtual === ETAPAS_VENDA[0] && (
                      <ProspectoDetalhes
                        hiddenSearch
                        hiddenSelectEmpreendimento
                        prospectoID={codigoProspecto}
                        empreendimento={codigoFamilia}
                        readonly={autorizando}
                        liberarConsulta={true}
                      />
                    )}

                    {etapaAtual === ETAPAS_VENDA[1] && (
                      <FormularioSinal
                        empreendimento={empreendimento}
                        selecionaTabela={selecionaTabelaPreco}
                        selecionaConstrutor={selecionaConstrutor}
                        tabelaSelecionada={tabelasPorCodigo[tabelaSelecionada]}
                        selecionaAtivo={selecionaAtivo}
                        ativosSelecionados={ativosSelecionados}
                        tabelasPorCodigo={tabelasPorCodigo}
                        ativosDaTabelaSelecionadaPorCodigo={
                          vendas.ativosDaTabelaSelecionadaPorCodigo
                        }
                        totalEntrada={totalEntrada}
                        totalFaltanteEntrada={totalFaltanteEntrada}
                        totalTitulosSinal={totalTitulosSinal}
                        salvarSinal={salvarSinal}
                        salvarDivisaoComissao={dividirComissao}
                        listaFormasDePagamento={listaFormasDePagamento}
                        adicionaFormaPgtoEntrada={adicionaFormaPgtoEntrada}
                        removeFormaPgtoEntrada={removeFormaPgtoEntrada}
                        atualizaFormaPgtoEntrada={atualizaFormaPgtoEntrada}
                        removeTodasParcelasEntrada={removeTodasParcelasEntrada}
                        readonly={autorizando}
                        corretores={corretores}
                        divisaoComissao={divisaoComissao}
                        cartoes={cartoes}
                        dataVenda={checkout.dataVenda}
                        setDataVenda={setDataVenda}
                      />
                    )}

                    {etapaAtual === ETAPAS_VENDA[2] && (
                      <FormularioFinanciamento
                        ativosPorCodigo={ativosPorCodigo}
                        empreendimento={empreendimento}
                        tabelaSelecionada={tabelasPorCodigo[tabelaSelecionada]}
                        ativosSelecionados={ativosSelecionados}
                        tabelasPorCodigo={tabelasPorCodigo}
                        ativosDaTabelaSelecionadaPorCodigo={
                          vendas.ativosDaTabelaSelecionadaPorCodigo
                        }
                        listaFormasDePagamento={listaFormasDePagamento}
                        adicionarParcelaFinanciamento={
                          adicionarParcelaFinanciamento
                        }
                        financiamentos={financiamentos}
                        readonly={autorizando}
                        totalTitulosSinal={totalTitulosSinal}
                        removerTodasParcelasFinanciamento={
                          removerTodasParcelasFinanciamento
                        }
                        taxaParcelaIntermediaria={
                          tabelasPorCodigo[tabelaSelecionada]
                            .taxa_parcela_intermediaria
                        }
                        taxaTabela={
                          tabelasPorCodigo[tabelaSelecionada].juros_mensal
                        }
                        dataVenda={checkout.dataVenda}
                      />
                    )}

                    {etapaAtual === ETAPAS_VENDA[3] && (
                      <Checkout
                        ativosDaTabelaSelecionadaPorCodigo={
                          vendas.ativosDaTabelaSelecionadaPorCodigo
                        }
                        erroGravacaoVenda={erroGravacaoVenda}
                        readonly={autorizando}
                        listaMotivosAutorizacao={listaMotivosAutorizacao}
                        totalTitulosSinal={totalTitulosSinal}
                        codigoContaCliente={prospecto.codigo_conta}
                        gravarVenda={gravarVenda}
                        isGravandoVenda={isGravandoVenda}
                        autorizando={autorizando}
                        autorizarVenda={autorizarVenda}
                        isExcluindoVenda={isExcluindoVenda}
                        erroExclusaoVenda={erroExclusaoVenda}
                        excluirVenda={() => excluirVenda(codigoProposta)}
                        dataVenda={checkout.dataVenda}
                        codigoPontoVenda={checkout.codigoPontoVenda}
                        setCodigoPontoVenda={setCodigoPontoVenda}
                        codigoProposta={codigoProposta}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  let ativos = [];
  let totalVenda = 0;
  let totalEntrada = 0;
  let totalFaltanteEntrada = totalEntrada;
  let totalTitulosSinal = 0;

  let { empreendimento } = state.vendas;

  if (empreendimento && empreendimento.ativos) {
    ativos = obterInformacoesAtivosSelecionados(state.vendas);

    totalVenda = calcularTotalVenda(ativos);
    totalEntrada = calcularTotalEntrada(ativos);
    totalTitulosSinal = calcularTotalTitulosSinal(
      state.vendas.sinal.listaFormasDePagamento
    );

    totalFaltanteEntrada = r2(totalEntrada - totalTitulosSinal);
    if (totalFaltanteEntrada < 0) totalFaltanteEntrada = 0;
  }

  return {
    totalVenda,
    totalEntrada,
    prospecto: state.vendas.prospecto,
    sinal: state.vendas.sinal,
    tabelasPorCodigo: state.vendas.tabelasPorCodigo,
    ativosPorCodigo: state.vendas.ativosPorCodigo,
    financiamentos: state.vendas.financiamentos,
    vendas: state.vendas,
    listaFormasDePagamento: state.vendas.sinal.listaFormasDePagamento,
    totalFaltanteEntrada,
    totalTitulosSinal,
    corretores: state.corretor.listaCorretores,
    divisaoComissao: state.vendas.divisaoComissao,
    cartoes: state.vendas.cartoes,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      adicionaFormaPgtoEntrada,
      removeFormaPgtoEntrada,
      atualizaFormaPgtoEntrada,
      removeTodasParcelasEntrada,
      selecionaTabelaPreco,
      selecionaConstrutor,
      setProspectoVenda,
      carregarPropostaInicial,
      carregarPropostaAuxiliar,
      selecionaAtivo,
      salvarSinal,
      adicionarParcelaFinanciamento,
      removerParcelaFinanciamento,
      removerTodasParcelasFinanciamento,
      atualizarParcelaFinanciamento,
      gravarVenda,
      autorizarVenda,
      dividirComissao,
      excluirVenda,
      setDataVenda,
      setCodigoPontoVenda,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Vendas));
