import { combineReducers } from 'redux';
import { FETCH_OPTIONS_SUCCESS } from '../../actionTypes';
import _sortBy from 'lodash/sortBy';

const empreendimentos = (state = [], action) => {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return action.payload.empreendimentos;
    default:
      return state;
  }
};

const empreendimentoById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return action.payload.empreendimentos.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.codigo]: curr,
        };
      }, {});
    default:
      return state;
  }
};

const ordenarEmpreendimentos = (state = [], action) => {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return _sortBy(action.payload.empreendimentos, 'descricao');
    default:
      return state;
  }
};

export default combineReducers({
  sort: ordenarEmpreendimentos,
  list: empreendimentos,
  byId: empreendimentoById,
});
