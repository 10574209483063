import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '~/Components/Header/Header';
import CardComissaoFiltro from '~/Components/Comissao/CardComissaoFiltro';
import Loading from '~/Components/Loading';
import CardComissaoResumo from '~/Components/Comissao/CardComissaoResumo';
import CardComissoesMensais from '~/Components/Comissao/CardComissoesMensais';

class Comissao extends Component {
  render() {
    const { carregandoComissoes } = this.props;

    return (
      <>
        <Header title="Relatório de comissões"></Header>
        <div className="ProdutividadePage">
          <CardComissaoFiltro />
          {carregandoComissoes && (
            <Loading label="Carregando relatório de comissão..." />
          )}
          {!carregandoComissoes && (
            <>
              <CardComissaoResumo />
              <CardComissoesMensais />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  carregandoComissoes: state.comissoes.carregandoComissoes,
});

export default connect(mapStateToProps)(Comissao);
