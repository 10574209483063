import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Container, Row, Col, Dropdown, Alert, Modal } from 'react-bootstrap';
import { FaRegEdit } from 'react-icons/fa';
import { FiChevronRight } from 'react-icons/fi';

import _keyBy from 'lodash/keyBy';
import _find from 'lodash/find';

import * as api from '~/Services/Service';
import Loading from '~/Components/Loading';
import {
  applyFilter,
  selecionarEmpreendimento,
} from '~/Store/Prospecto/actions';
import PageOrderBy from '~/Components/PageOrderBy';

import AuthStorage from '~/Utils/AuthStorage';
import {
  STATUS_ETAPAS_PROSPECTOS,
  DATE_FORMAT_SERVER,
  PROSPECTO_STATUS_ENUM,
} from '~/constant';

import {
  EtapasProspectos,
  ContainerListProspecto,
  DropdownEtapas,
  ListaProspecto,
  ContainerGerenteProspectoDetalhe,
  ContainerEtapasProspectos,
  ScrollListaProspecto,
  ScrollProspectoAtivo,
  ProspectoAtivo,
  BtnEditProspect,
  HeaderListaProspecto,
} from './styles';

import ProspectoItem from '~/Components/Prospectos/ProspectoItem';
import SelectEmpreendimentoProspecto from '~/Components/Prospectos/ProspectoDetalhes/SelectEmpreendimentoProspecto';
import ResumeProspect from '~/Components/ResumeProspect';
import ProspectoDetalhes from '~/Components/Prospectos/ProspectoDetalhes/ProspectoDetalhes';
import ProspectosNavbar from '~/Components/Prospectos/ProspectosNavbar';
import ModalAdicionarProspecto from '~/Components/Prospectos/ModalAdicionarProspecto';
import { getToday } from '~/Utils/Helpers';

class DashboardProspecto extends Component {
  state = {
    isSearchActive: false,
    carregandoProspectos: false,
    carregandoEtapas: false,
    expandirCorretores: false,
    filtros: {
      pagina_atual: 1,
    },
    etapasProspecto: null,
    prospectos: null,
    prospectoAtivo: null,
    contador: 0,
    corretores: [],
    corretoresSelecionaveis: [],
    prospectoCarregado: null,
    codigoProspectoStatus: null,
    quantidadeProspectos: null,
    etapaSelecionada: {},
    editandoProspecto: false,
  };

  componentDidMount() {
    this.obterDados(this.state.filtros);

    if (this.props.corretores.length > 0) {
      this.atualizaCorretores();
    }
  }

  limpaProspectoAtivo = () => {
    this.setState({ prospectoAtivo: null });
  };

  toggleModal = editandoProspecto => {
    this.setState({ editandoProspecto });
  };

  atualizaCorretores = () => {
    let corretoresSelecionaveis = null;

    if (!AuthStorage.isGerente()) {
      corretoresSelecionaveis = this.props.corretores;
    } else {
      corretoresSelecionaveis = this.props.imobiliariasById[
        AuthStorage.getUser().usuario
      ].corretores;
    }

    this.setState({
      correttores: this.props.corretores,
      corretoresSelecionaveis,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.corretores.length !== this.props.corretores.length) {
      this.atualizaCorretores();
    }
    if (
      !prevProps.prospecto.prospectoCriado &&
      this.props.prospecto.prospectoCriado
    ) {
      const { detail } = this.props.prospecto;

      let prospectoAtualizado = {
        ...detail,
        ...detail.prospecto_empreendimento[0],
        data_cadastro: getToday().format(DATE_FORMAT_SERVER),
        codigo_etapa_prospecto: PROSPECTO_STATUS_ENUM.nao_iniciado,
        empreendimento: this.props.empreendimentos.byId[detail.codigo_familia]
          .descricao,
      };

      const index = this.state.prospectos.findIndex(
        item => Number(item.codigo) === Number(prospectoAtualizado.codigo)
      );

      let listaProspectosAtualizados = this.state.prospectos;

      if (index > -1) {
        listaProspectosAtualizados[index] = {
          ...listaProspectosAtualizados[index],
          ...prospectoAtualizado,
          empreendimento: listaProspectosAtualizados[index].empreendimento,
        };
      } else {
        listaProspectosAtualizados.unshift(prospectoAtualizado);
      }

      this.setState({
        prospectoCarregado: prospectoAtualizado,
        prospectoAtivo: prospectoAtualizado,
        prospectos: listaProspectosAtualizados,
      });
    }
  }

  onClickExpandirCorretores = () =>
    this.setState({
      expandirCorretores: !this.state.expandirCorretores,
    });

  onClickSelecionaGerente = async (codigoGerente = null) => {
    let { filtros } = this.state;
    filtros.codigo_corretor = null;
    filtros.codigo_gerente = codigoGerente;
    filtros.pagina_atual = 1;

    let corretoresSelecionaveis = this.props.corretores;
    if (codigoGerente) {
      corretoresSelecionaveis = this.props.imobiliariasById[codigoGerente]
        .corretores;
    }

    this.setState({
      corretoresSelecionaveis,
      expandirCorretores: !!codigoGerente,
      contador: 0,
    });
    await this.obterDados(filtros);
  };

  onClickSelecionaCorretor = async codigoCorretor => {
    let { filtros } = this.state;
    filtros.codigo_corretor = codigoCorretor;
    filtros.pagina_atual = 1;
    this.setState({
      expandirCorretores: !codigoCorretor,
      contador: 0,
    });
    await this.obterDados(filtros);
  };

  onClickSelecionaEtapa = codigoEtapa => {
    let { filtros } = this.state;
    filtros.codigo_prospecto_status = codigoEtapa > 0 ? codigoEtapa : '';
    this.obterDados(filtros, false);
  };

  setSearchActive = isSearchActive => this.setState({ isSearchActive });

  onSubmitSearch = values => {
    this.props.applyFilter();
    this.obterDados(values);
  };

  getDetailsProspect = async codigo => {
    this.setState({
      carregandoProspectoAtivo: true,
    });
    let prospecto = await api.getProspecto(codigo);

    prospecto = {
      ...prospecto,
      ...prospecto.prospecto_empreendimento[0],
    };
    this.setState({
      prospectoCarregado: prospecto,
      prospectoAtivo: prospecto,
      carregandoProspectoAtivo: false,
    });
  };

  obterMaisDados = (novoFiltro = {}) => {
    let { filtros, contador } = this.state;
    filtros.pagina_atual++;

    this.setState({
      contador: ++contador,
    });

    this.obterDados({ ...filtros, ...novoFiltro }, false);
  };

  buildEtapasProspecto = etapas => {
    etapas = _keyBy(etapas, 'codigo');

    return STATUS_ETAPAS_PROSPECTOS.map(item => ({
      ...item,
      total: etapas[item.codigo] ? etapas[item.codigo].total : 0,
    }));
  };

  selecionarEmpreendimento = CodigoFamilia => {
    let prospectoAtivo = _find(
      this.state.prospectoCarregado.prospecto_empreendimento,
      {
        codigo_familia: Number(CodigoFamilia),
      }
    );

    this.setState({
      prospectoAtivo: {
        ...this.state.prospectoCarregado,
        ...prospectoAtivo,
      },
    });
  };

  async obterDados(filtros, buscaEtapas = true) {
    this.setState({
      carregandoProspectos: !buscaEtapas,
      carregandoEtapas: buscaEtapas,
      isSearchActive: false,
      prospectoAtivo: null,
    });

    let {
      prospectos,
      codigo_gerente: codigoGerente,
      codigo_corretor: codigoCorretor,
      codigo_prospecto_status: codigoProspectoStatus,
      quantidade_prospectos: quantidadeProspectos,
      pagina_atual,
      quantidade_por_pagina: quantidadePorPagina,
      ultima_pagina: ultimaPagina,
    } = await api.consultarProspectos(filtros);

    let { etapasProspecto } = this.state;
    if (buscaEtapas) {
      etapasProspecto = await api.consultarEtapasProspectos(filtros);
    }

    etapasProspecto = this.buildEtapasProspecto(etapasProspecto);
    const etapasProspectoByCodigo = _keyBy(etapasProspecto, 'codigo');

    if (!AuthStorage.isADM())
      etapasProspecto = etapasProspecto.filter(item => item.codigo !== 9);

    filtros.pagina_atual = pagina_atual;
    codigoProspectoStatus = codigoProspectoStatus ? codigoProspectoStatus : 0;

    const novosProspectos =
      this.state.prospectos && pagina_atual > 1
        ? [...this.state.prospectos, ...prospectos]
        : prospectos;

    this.setState({
      filtros,
      etapasProspecto,
      etapaSelecionada: etapasProspectoByCodigo[codigoProspectoStatus],
      prospectos: novosProspectos,
      carregandoProspectos: false,
      carregandoEtapas: false,
      codigoGerente,
      codigoCorretor,
      codigoProspectoStatus,
      quantidadeProspectos,
      pagina_atual,
      quantidadePorPagina,
      ultimaPagina,
      contador: buscaEtapas ? 0 : this.state.contador,
    });
  }
  onResetSearch = () => {};

  render() {
    const {
      isSearchActive,
      filtros,
      carregandoProspectos,
      carregandoEtapas,
      etapasProspecto,
      etapaSelecionada,
      prospectos,
      prospectoAtivo,
      corretoresSelecionaveis,
      codigoProspectoStatus,
      quantidadeProspectos,
      carregandoProspectoAtivo,
      contador,
      editandoProspecto,
    } = this.state;

    const {
      imobiliarias,
      empreendimentos,
      gerentesCorretor,
      filterIsDirty,
    } = this.props;

    const visualizarGerentes = AuthStorage.isADM();
    const visualizarCorretores = AuthStorage.isADM() || AuthStorage.isGerente();
    const podeAdicionarProspecto = AuthStorage.isADM();

    return (
      <>
        <div className="h-100 overflow-hidden">
          <ProspectosNavbar
            filterIsDirty={filterIsDirty}
            setSearchActive={this.setSearchActive}
            isSearchActive={isSearchActive}
            filtros={filtros}
            visualizarGerentes={visualizarGerentes}
            imobiliarias={imobiliarias}
            visualizarCorretores={visualizarCorretores}
            corretoresSelecionaveis={corretoresSelecionaveis}
            onSubmitSearch={this.onSubmitSearch}
            onResetSearch={this.onResetSearch}
            empreendimentos={empreendimentos}
            isLoading={carregandoEtapas}
            readonly={AuthStorage.temCaracteristica(495)}
            podeAdicionarProspecto={podeAdicionarProspecto}
          />

          {carregandoEtapas && !etapasProspecto && (
            <div className="mx-auto mt-4">
              <Loading label="Carregando informações..." />
            </div>
          )}
          <div className="h-100 overflow-hidden">
            {etapasProspecto && (
              <>
                <DropdownEtapas>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <span>
                        {etapaSelecionada.descricao}{' '}
                        <strong>({etapaSelecionada.Total})</strong>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {etapasProspecto.map(etapa => (
                        <Dropdown.Item
                          key={etapa.codigo}
                          type="button"
                          as="button"
                          onClick={() =>
                            this.onClickSelecionaEtapa(etapa.codigo)
                          }
                        >
                          {etapa.descricao} <span>({etapa.total})</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </DropdownEtapas>
                <ContainerEtapasProspectos>
                  <Container fluid>
                    <Row>
                      <Col>
                        <EtapasProspectos>
                          {etapasProspecto.map(etapa => (
                            <li key={etapa.codigo}>
                              <button
                                type="button"
                                className={
                                  codigoProspectoStatus === etapa.codigo
                                    ? 'text-primary'
                                    : ''
                                }
                                onClick={() =>
                                  this.onClickSelecionaEtapa(etapa.codigo)
                                }
                              >
                                <strong>{etapa.total}</strong>
                                <small>{etapa.descricao}</small>
                              </button>
                              <FiChevronRight />
                            </li>
                          ))}
                        </EtapasProspectos>
                      </Col>
                    </Row>
                  </Container>
                </ContainerEtapasProspectos>

                {imobiliarias.length > 0 && (
                  <ContainerGerenteProspectoDetalhe>
                    <ContainerListProspecto>
                      {!carregandoEtapas && (
                        <>
                          <HeaderListaProspecto>
                            {prospectos.length > 0 && !carregandoProspectos && (
                              <div className="d-flex justify-content-between align-items-center ">
                                <div>
                                  {`Exibindo ${prospectos.length} resultado${
                                    prospectos.length > 1 ? 's' : ''
                                  }.`}
                                </div>
                                <div>
                                  <PageOrderBy
                                    value={filtros.ordem}
                                    onChange={event =>
                                      this.obterDados({
                                        ordem: event.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {carregandoProspectos && (
                              <Loading label="Carregando mais prospectos..." />
                            )}
                          </HeaderListaProspecto>
                          <ScrollListaProspecto>
                            {prospectos.length === 0 && (
                              <Alert variant="secondary" className="mb-5 p-5">
                                Nenhum resultado foi encontrado!
                              </Alert>
                            )}
                            <ListaProspecto>
                              {prospectos.map((prospecto, index) => (
                                <ProspectoItem
                                  data={prospecto}
                                  key={index}
                                  isActive={
                                    prospectoAtivo &&
                                    Number(prospectoAtivo.codigo) ===
                                      Number(prospecto.codigo)
                                  }
                                  onClick={() =>
                                    this.getDetailsProspect(prospecto.codigo)
                                  }
                                />
                              ))}

                              {quantidadeProspectos > prospectos.length && (
                                <div className="d-flex justify-content-center flex-column">
                                  <button
                                    className="btn btn-outline-secondary d-block"
                                    onClick={() => this.obterMaisDados()}
                                  >
                                    Carregar mais prospectos
                                  </button>
                                  {contador > 2 && (
                                    <button
                                      className="btn btn-outline-secondary d-block mt-3"
                                      onClick={() => {
                                        this.obterMaisDados({
                                          QuantidadePorPagina: 50,
                                        });
                                      }}
                                    >
                                      Carregar mais 50 prospectos
                                    </button>
                                  )}
                                </div>
                              )}
                              {prospectos &&
                                prospectos.length === quantidadeProspectos &&
                                'Nenhum prospecto para carregar'}
                            </ListaProspecto>
                          </ScrollListaProspecto>
                        </>
                      )}
                    </ContainerListProspecto>

                    {etapasProspecto && carregandoEtapas && (
                      <div className="mx-auto mt-4">
                        <Loading label="Carregando informações..." />
                      </div>
                    )}

                    {!prospectoAtivo &&
                      !carregandoEtapas &&
                      prospectos.length > 0 &&
                      !carregandoProspectoAtivo && (
                        <div className="mx-auto my-auto d-none d-lg-flex">
                          <Alert variant="secondary" className="mb-5 p-5">
                            Selecione um prospecto para exibir os detalhes.
                          </Alert>
                        </div>
                      )}

                    {carregandoProspectoAtivo && (
                      <div className="mx-auto my-auto d-none d-lg-flex">
                        Carregando detalhes do prospecto.
                      </div>
                    )}

                    {prospectoAtivo && !carregandoProspectoAtivo && (
                      <ScrollProspectoAtivo>
                        <ProspectoAtivo>
                          <Row>
                            <Col>
                              <div>
                                <header>
                                  <small className="d-block">
                                    <BtnEditProspect
                                      onClick={() => this.toggleModal(true)}
                                    >
                                      #{prospectoAtivo.codigo} -&nbsp;
                                      <FaRegEdit /> Editar
                                    </BtnEditProspect>
                                  </small>
                                  <div>
                                    <strong>{prospectoAtivo.nome}</strong>
                                  </div>

                                  <button
                                    type="button"
                                    onClick={this.limpaProspectoAtivo}
                                    className="fecharProspectoAtivoMobile"
                                  >
                                    &times;
                                  </button>
                                </header>

                                <SelectEmpreendimentoProspecto
                                  empreendimentos={empreendimentos}
                                  prospecto={prospectoAtivo}
                                  atualizarStatusProspecto={() => {}}
                                  removerEmpreendimento={() => {}}
                                  selecionarEmpreendimento={
                                    this.selecionarEmpreendimento
                                  }
                                  setProspecto={() => {}}
                                  gerentesCorretores={gerentesCorretor}
                                  exibeBotoes={false}
                                  motivosDescarte={[]}
                                  onDismissAvisoDescarteSuaHouse={() => {}}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <ResumeProspect prospecto={prospectoAtivo} />
                            </Col>
                          </Row>
                        </ProspectoAtivo>
                      </ScrollProspectoAtivo>
                    )}
                  </ContainerGerenteProspectoDetalhe>
                )}
              </>
            )}
          </div>

          <Modal
            size="xl"
            show={editandoProspecto}
            onHide={() => this.toggleModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Editando prospecto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {editandoProspecto && (
                <ProspectoDetalhes
                  hiddenNavbar={true}
                  hiddenSearch={true}
                  showCancel={true}
                  prospectoID={
                    this.state.filtros.tipo_filtro == 'cpf_cnpj' &&
                    this.state.filtros.filtro != ''
                      ? prospectoAtivo.hash_codigo
                      : prospectoAtivo.codigo
                  }
                  onCancel={() => this.toggleModal(false)}
                />
              )}
            </Modal.Body>
          </Modal>

          <ModalAdicionarProspecto />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  imobiliarias: state.corretor.listaGerentes,
  imobiliariasById: state.corretor.gerentesById,
  empreendimentos: state.empreendimentos,
  corretores: state.corretor.listaCorretores,
  corretoresById: state.corretor.corretoresById,

  gerentesCorretor: state.corretor, //TODO Refatorar
  filter: state.prospecto.filter,
  filterIsDirty: false,

  prospecto: state.prospecto,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ selecionarEmpreendimento, applyFilter }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardProspecto)
);
