import React from 'react';

import { AvatarImage } from '~/Styles';

import { CorretorPodium } from './styles';
import numeral from 'numeral';

export default function Podium({ corretores, tipoRanking = null }) {
  const formataVlResumo = valor => numeral(valor).format('(R$ 0.00 a)');
  return (
    <ul className="podium">
      {corretores.map((corretor, index) => (
        <CorretorPodium
          key={index}
          rank={corretor.rank}
          classificado={corretor.classificado}
        >
          <div className="podium-avatar">
            <AvatarImage src={corretor.url_imagem} alt={corretor.nome} />
          </div>
          <strong>{corretor.nome.split(/(\s).+\s/).join('')}</strong>
          
          {tipoRanking === 'VGV' ? (
            <>
              <small
                className="d-block"
                title={`R$ ${
                  corretor.efetivadas
                    ? corretor.efetivadas.toLocaleString('pt-BR')
                    : '0,00'
                }`}
              >
                R$
                {formataVlResumo(corretor.efetivadas)}
              </small>
            </>
          ) : (
            <>
              <small className="d-block">
                {corretor.quantidade_efetivadas} venda
                {corretor.quantidade_efetivadas > 1 ? 's' : ''}
              </small>
            </>
          )}
        </CorretorPodium>
      ))}
    </ul>
  );
}
