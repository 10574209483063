import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

const PreviewArquivoDocumentoDigital = ({ pdfFile, url }) => {
  const [numeroPaginas, setNumeroPaginas] = useState(0);
  const [pagina, setPagina] = useState(1);

  function onDocumentLoadSuccess(document) {
    const { numPages } = document;
    setNumeroPaginas(numPages);
  }

  if (!pdfFile) return null;
  return (
    <>
      {url && (
        <p>
          Este é o documento solicitado para upload, e será enviado
          automaticamente no momento da aprovação:
        </p>
      )}
      <Document
        className="mt-2"
        file={pdfFile}
        loading="Carregando arquivo..."
        error={
          <span>
            Falha ao carregar arquivo.{' '}
            {url && (
              <a href={url} target="_blank" rel="noreferrer noopener">
                Clique aqui para visualizar o arquivo
              </a>
            )}
          </span>
        }
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pagina} />
      </Document>
      <div className="d-flex justify-content-center align-items-baseline">
        <button
          className="btn btn-primary btn-mini-rounded"
          disabled={pagina <= 1}
          onClick={() => setPagina(prev => prev - 1)}
        >
          &lt;
        </button>
        <p className="mx-1">
          Página {pagina || (numeroPaginas ? 1 : '--')} de{' '}
          {numeroPaginas || '--'}
        </p>
        <button
          className="btn btn-primary btn-mini-rounded"
          disabled={pagina >= numeroPaginas}
          onClick={() => setPagina(prev => prev + 1)}
        >
          &gt;
        </button>
      </div>
    </>
  );
};

export default PreviewArquivoDocumentoDigital;
