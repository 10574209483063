import React from 'react';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import _orderBy from 'lodash/orderBy';

import * as helpers from '~/Utils/Helpers';
import { DATETIME_DEFAULT_FORMAT } from '~/constant';

export default function VerticalTimeline({ etapas }) {
  const etapasOrdenada = _orderBy(etapas, 'data', 'desc');
  return (
    <Timeline lineColor={'#ddd'} animate={false}>
      {etapasOrdenada &&
        etapasOrdenada.map((etapa, index) => (
          <TimelineItem
            key={index}
            dateText={helpers.format.date(etapa.data, DATETIME_DEFAULT_FORMAT)}
            dateInnerStyle={{
              background: `${etapa.status.cor}`,
            }}
            style={{
              color: `${etapa.status.cor}`,
            }}
          >
            <h5 className="font-weight-bold">{etapa.status.descricao}</h5>
            <span className="d-block">
              <strong>Usuario: </strong> {etapa.usuario.nome}
            </span>

            <span className="d-block">
              <strong>Corretor: </strong> {etapa.corretor.nome}
              {' - '}
              <strong
                style={{
                  color: `${etapa.imobiliaria.cor}`,
                }}
              >
                {etapa.imobiliaria.nome}
              </strong>
            </span>

            <span className="d-block">
              <strong>Empreendimento:</strong> {etapa.empreendimento.descricao}
            </span>
            {etapa.evento_descarte && (
              <>
                <span className="d-block">
                  <strong>Motivo: </strong>
                  {etapa.evento_descarte.descricao_motivo}
                </span>
                <span className="d-block">
                  <strong>Justificativa: </strong>
                  {etapa.evento_descarte.justificativa}
                </span>
              </>
            )}
            {etapa.pre_venda && (
              <>
                <span className="d-block">
                  <strong>Pré-venda:</strong> {etapa.pre_venda.numero} {' - '}
                  <strong>Quadra:</strong> {etapa.pre_venda.imovel.quadra}
                  {' - '}
                  <strong>Lote:</strong> {etapa.pre_venda.imovel.lote}
                </span>
              </>
            )}
          </TimelineItem>
        ))}
    </Timeline>
  );
}
