import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { urlAdmVendas } from '~/constant';

const VendaGravada = ({ vendaGravada }) => {
  // const [segundos, setSegundos] = useState(10);

  // useEffect(() => {
  //   let countdown = setInterval(() => {
  //     if (segundos > 0) {
  //       setSegundos(segundos - 1);
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(countdown);
  //     if (segundos === 1) window.location = urlAdmVendas;
  //   };
  // }, [segundos]);

  const color = vendaGravada.efetivada ? '#28A745' : '#FFC107';

  const renderListaPreVendas = () =>
    vendaGravada.codigos_pre_vendas.map(codigo => (
      <div className="d-flex justify-content-between mb-2" key={codigo}>
        <h4>{codigo}</h4>
        <div>
          <a
            className="btn btn-outline-primary"
            target="blank"
            href={`https://www.revobrasil.com.br/Extranet/Comercial/PreVendaPropostaRapida.aspx?PreVenda=${codigo}`}
          >
            Ver Proposta
          </a>

          {/* <Link
            className="btn btn-outline-primary"
            to={`/documentodigital?codPreVenda=${codigo}`}
          >
            Solicitar Assinatura Digital
          </Link> */}
        </div>
      </div>
    ));

  return (
    <div className="d-flex flex-column align-items-center">
      <FaCheckCircle color="#28A745" fontSize={72} />
      <h4>
        Venda{' '}
        <span style={{ color: color }}>
          {vendaGravada.efetivada ? 'Realizada' : 'Gravada para Aprovação'}
        </span>
        !
      </h4>

      <div className="m-4 w-100 w-sm-50">
        <div className="text-center">
          <span className="text-info">Proposta</span>
          <h2>{vendaGravada.codigo_proposta}</h2>
        </div>

        {vendaGravada.efetivada && (
          <div>
            <span className="text-info">Pré-venda(s)</span>

            {renderListaPreVendas()}
          </div>
        )}
      </div>

      <div className="text-center mt-4 pt-4">
        <Button variant="Link" onClick={() => (window.location = urlAdmVendas)}>
          Ir para a Administração de Vendas
        </Button>
      </div>

      {/* <div className="text-muted text-center">
        {segundos > 0 && (
          <p>
            Você será redirecionado para a Administração de Vendas em {segundos}{' '}
            segundos...
          </p>
        )}
        {segundos === 0 && <p>Redirecionando...</p>}
      </div> */}
    </div>
  );
};

export default VendaGravada;
