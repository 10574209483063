import {
  BOLETO,
  CARTAO,
  CHEQUE,
  DINHEIRO,
  DEPOSITO,
  DIAS_TOLERANCIA_VENCIMENTO_CHEQUE_DEPOSITO,
} from '~/constant';

import {
  formatarMonetario,
  getToday,
  format,
  financeiro,
} from '~/Utils/Helpers';

const MOTIVO_PERIDIOCIDADE_SEM_CORRECAO = 'MOTIVO_PERIDIOCIDADE_SEM_CORRECAO';
const MOTIVO_BOLETO_SINAL = 'MOTIVO_BOLETO_SINAL';
const MOTIVO_ENTRADA_DCC_MENOR_OBRIGATORIA =
  'MOTIVO_ENTRADA_DCC_MENOR_OBRIGATORIA';
const MOTIVO_DIFERENCA_TITULOS_SINAL = 'MOTIVO_DIFERENCA_TITULOS_SINAL';
const MOTIVO_DESCONTO_INDEVIDO_VALOR_SINAL =
  'MOTIVO_DESCONTO_INDEVIDO_VALOR_SINAL';
const MOTIVO_DESCONTO_INDEVIDO_VALOR_VENDA =
  'MOTIVO_DESCONTO_INDEVIDO_VALOR_VENDA';
const MOTIVO_CARENCIA_MINIMA = 'MOTIVO_CARENCIA_MINIMA';

export const PeriodicidadesCorrecao = {
  Anual: 1,
  Mensal: 2,
  SemCorrecao: 3,
  SemCorrecaoSemJuros: 4,
};

export const TiposAplicacaoChequeBonus = {
  ValorSinal: 0,
  ValorVenda: 1,
};

export const TiposValorChequeBonus = {
  Fixo: 0,
  Porcentagem: 1,
};

export const TiposEntrada = {
  Fixa: 1,
  Porcentagem: 2,
};

export const ModalidadeTabela = {
  Sac: 1,
  Price: 2,
  Proprio: 3,
};

export const r2 = value => Number((value || 0).toFixed(2));

const isSemCorrecao = peridiocidade =>
  peridiocidade === PeriodicidadesCorrecao.SemCorrecao ||
  peridiocidade === PeriodicidadesCorrecao.SemCorrecaoSemJuros;

export const listarMotivosParaAutorizacao = (vendas, tabelasPorCodigo) => {
  const { tabelaSelecionada, empreendimento, sinal, dataVenda } = vendas;
  const { cheque_bonus } = empreendimento || {};

  const listaMotivos = [];

  const ativos = obterInformacoesAtivosSelecionados(vendas);
  const { listaFormasDePagamento } = sinal;
  incluirMotivoPeriodicidadeSePreciso(
    tabelaSelecionada,
    tabelasPorCodigo,
    listaMotivos
  );
  incluirMotivoBoletoNoSinalSePreciso(
    empreendimento,
    listaFormasDePagamento,
    listaMotivos
  );
  incluirMotivoEntradaMenorQueObrigatoriaSePreciso(
    empreendimento,
    listaFormasDePagamento,
    dataVenda,
    listaMotivos
  );
  incluirMotivoDiferencaEntradaSePreciso(
    ativos,
    listaFormasDePagamento,
    listaMotivos
  );
  incluirMotivoDescontoIndevidoSinalSePreciso(
    cheque_bonus,
    ativos,
    listaMotivos
  );
  incluirMotivoDescontoIndevidoValorVendaSePreciso(
    cheque_bonus,
    ativos,
    listaMotivos
  );
  incluirMotivoCarenciaMinimaSePreciso(
    empreendimento,
    listaFormasDePagamento,
    listaMotivos
  );
  //INCLUIR NOVO MOTIVO
  return listaMotivos;
};

const incluirMotivoPeriodicidadeSePreciso = (
  codigoTabela,
  tabelasPorCodigo,
  listaMotivos
) => {
  if (codigoTabela == null) return;
  const tabelaSelecionada = tabelasPorCodigo[codigoTabela];
  if (
    tabelaSelecionada &&
    tabelaSelecionada.periodicidade_correcao &&
    isSemCorrecao(tabelaSelecionada.periodicidade_correcao)
  ) {
    listaMotivos.push({
      id: MOTIVO_PERIDIOCIDADE_SEM_CORRECAO,
      descricao: `Tabela ${codigoTabela} com peridiocidade sem correção`,
      justificativaObrigatoria: true,
    });
  }
};

const incluirMotivoBoletoNoSinalSePreciso = (
  empreendimento,
  listaFormasDePagamento,
  listaMotivos
) => {
  if (!empreendimento) return;
  const parcelasBoleto = listaFormasDePagamento.filter(
    parcela => parcela.tipo_parcela === BOLETO
  );
  if (!empreendimento.permite_boletos && parcelasBoleto.length > 0) {
    listaMotivos.push({
      id: MOTIVO_BOLETO_SINAL,
      descricao: `Há ${parcelasBoleto.length} parcela(s) de boleto no sinal`,
    });
  }
};

const incluirMotivoEntradaMenorQueObrigatoriaSePreciso = (
  empreendimento,
  listaFormasDePagamento,
  dataVenda,
  listaMotivos
) => {
  if (!empreendimento) return;
  if (!empreendimento.sinal_minimo) return;
  if (listaFormasDePagamento.length === 0) return;
  const totalEntradaDCC = calcularTotalEntradaAVista(
    listaFormasDePagamento,
    dataVenda
  );
  if (totalEntradaDCC < empreendimento.sinal_minimo) {
    listaMotivos.push({
      id: MOTIVO_ENTRADA_DCC_MENOR_OBRIGATORIA,
      descricao: `Formas de pagamento à vista, com total de ${formatarMonetario(
        totalEntradaDCC,
        false
      )} não atingem o mínimo requerido de ${formatarMonetario(
        empreendimento.sinal_minimo
      )}`,
    });
  }
};

const incluirMotivoDiferencaEntradaSePreciso = (
  ativos,
  listaFormasDePagamento,
  listaMotivos
) => {
  const somaAtivos = calcularTotalVenda(ativos);
  if (somaAtivos === 0) return;
  const somaFormasPagamento = calcularTotalTitulosSinal(listaFormasDePagamento);
  if (somaFormasPagamento === 0) return;

  const limitePercentual = 0.9;
  const limitePagamento = somaAtivos * limitePercentual;

  if (somaFormasPagamento >= limitePagamento) {
    listaMotivos.push({
      id: MOTIVO_DIFERENCA_TITULOS_SINAL,
      descricao: `O total de títulos de sinal ultrapassa o limite de 90% do valor da venda`,
    });
  }
};

const incluirMotivoDescontoIndevidoSinalSePreciso = (
  chequeBonus,
  ativos,
  listaMotivos
) => {
  const descontosIndevidosAtivos = ativos
    .map(ativo => ({
      codigo: ativo.codigo,
      descontoIndevido: calcularDescontoIndevidoSinal(ativo, chequeBonus),
    }))
    .filter(d => d.descontoIndevido > 0);
  if (descontosIndevidosAtivos.length > 0) {
    const listaCodigosAtivos = descontosIndevidosAtivos
      .map(a => a.codigo.toString())
      .join(', ');
    listaMotivos.push({
      id: MOTIVO_DESCONTO_INDEVIDO_VALOR_SINAL,
      descricao: `Desconto identificado no valor da entrada no(s) ativo(s) ${listaCodigosAtivos}`,
    });
  }
};

const incluirMotivoDescontoIndevidoValorVendaSePreciso = (
  chequeBonus,
  ativos,
  listaMotivos
) => {
  const descontosIndevidosAtivos = ativos
    .map(ativo => ({
      codigo: ativo.codigo,
      descontoIndevido: calcularDescontoIndevidoVV(ativo, chequeBonus),
    }))
    .filter(d => d.descontoIndevido > 0);
  if (descontosIndevidosAtivos.length > 0) {
    const listaCodigosAtivos = descontosIndevidosAtivos
      .map(a => a.codigo.toString())
      .join(', ');
    listaMotivos.push({
      id: MOTIVO_DESCONTO_INDEVIDO_VALOR_VENDA,
      descricao: `Desconto identificado no valor de venda no(s) ativo(s) ${listaCodigosAtivos}`,
    });
  }
};

const incluirMotivoCarenciaMinimaSePreciso = (
  empreendimento,
  listaFormasDePagamento,
  listaMotivos
) => {
  const dataVencimentoMinima = calcularDataVencimentoMinimaBoleto(
    empreendimento
  );
  if (!dataVencimentoMinima) return;
  const boletosVencimentoMenorDataMinima = listaFormasDePagamento.filter(
    parcela =>
      parcela.tipo_parcela === BOLETO &&
      parcela.vencimento < dataVencimentoMinima
  );
  if (boletosVencimentoMenorDataMinima.length > 0) {
    listaMotivos.push({
      id: MOTIVO_CARENCIA_MINIMA,
      descricao: `Há parcela(s) de boleto no sinal com vencimento anterior a ${format.date(
        dataVencimentoMinima
      )}`,
    });
  }
};

export const calcularDataVencimentoMinimaBoleto = empreendimento => {
  if (!empreendimento) return;
  const {
    sugestao_dias_boleto_sinal,
    tipo_recebimento_comissao,
  } = empreendimento;
  //TODO: enum
  if (tipo_recebimento_comissao !== 7) return;
  if (!sugestao_dias_boleto_sinal) return;
  const dataMinima = getToday()
    .startOf('day')
    .add(sugestao_dias_boleto_sinal, 'days');
  // .add(1, 'month');
  return dataMinima;
};

export const calcularTotalEntradaAVista = listaFormasDePagamento => {
  return listaFormasDePagamento.reduce((total, parcela) => {
    const { tipo_parcela, vencimento } = parcela;
    if (tipo_parcela === DINHEIRO || tipo_parcela === CARTAO)
      return total + parcela.valor;
    else if (
      (tipo_parcela === CHEQUE || tipo_parcela === DEPOSITO) &&
      vencimento.startOf('day') <=
        getToday()
          .add(DIAS_TOLERANCIA_VENCIMENTO_CHEQUE_DEPOSITO, 'days')
          .startOf('day')
    )
      return total + parcela.valor;
    return total;
  }, 0);
};

// const isDCC = tipoParcela => {
//   return (
//     tipoParcela === DINHEIRO || tipoParcela === CHEQUE || tipoParcela === CARTAO
//   );
// };

export const obterInformacoesAtivosSelecionados = venda => {
  const { ativosDaTabelaSelecionadaPorCodigo, ativosSelecionados } = venda;
  return ativosSelecionados.map(codigo => {
    return ativosDaTabelaSelecionadaPorCodigo[codigo];
  });
};

export const calcularTotalVenda = ativos => {
  return ativos.reduce((prev, curr) => {
    return prev + curr.valor_venda;
  }, 0);
};

export const calcularTotalEntrada = ativos => {
  return ativos.reduce((prev, curr) => {
    return prev + curr.valor_sinal;
  }, 0);
};

export const calcularTotalTitulosSinal = listaFormasDePagamento => {
  return (
    listaFormasDePagamento.reduce(
      (prev, curr) => parseFloat(prev) + parseFloat(curr.valor),
      0
    ) || 0
  );
};

export const calcularValorChequeBonus = (chequeBonus, valorBase) => {
  if (!chequeBonus) return 0;
  if (chequeBonus.tipo_valor_cheque_bonus === TiposValorChequeBonus.Fixo)
    return chequeBonus.valor;
  //porcentagem
  return valorBase * chequeBonus.valor;
};

export const calcularDescontoIndevidoSinal = (ativo, chequeBonus) => {
  const descontoTotal = ativo.desconto ? ativo.desconto.valor_sinal : 0;
  let descontoIndevido = descontoTotal;
  if (ativo.desconto && ativo.desconto.aplicou_cheque_bonus_sinal) {
    const valorSinalEsperado = calcularValorSinalEsperado(
      ativo,
      ativo.valor_venda
    );

    const { tipo_aplicacao_cheque_bonus } = chequeBonus || {};
    const valorCB =
      tipo_aplicacao_cheque_bonus === TiposAplicacaoChequeBonus.ValorSinal
        ? calcularValorChequeBonus(chequeBonus, valorSinalEsperado)
        : 0;
    descontoIndevido -= valorCB;
  }
  return descontoIndevido;
};

export const calcularDescontoIndevidoVV = (ativo, chequeBonus) => {
  const descontoTotal = ativo.desconto ? ativo.desconto.valor_venda : 0;
  let descontoIndevido = descontoTotal;
  if (ativo.desconto && ativo.desconto.aplicou_cheque_bonus_valor_venda) {
    const { tipo_aplicacao_cheque_bonus } = chequeBonus || {};
    const valorCB =
      tipo_aplicacao_cheque_bonus === TiposAplicacaoChequeBonus.ValorVenda
        ? calcularValorChequeBonus(chequeBonus, ativo.valor_venda_original)
        : 0;
    descontoIndevido -= valorCB;
  }
  return descontoIndevido;
};

export const calcularValorSinalEsperado = (ativo, valorVenda = null) => {
  const { tipo_entrada, entrada_base } = ativo;
  const valorVendaAtivo = valorVenda === null ? ativo.valor_venda : valorVenda;
  return tipo_entrada === TiposEntrada.Fixa
    ? entrada_base
    : (valorVendaAtivo * entrada_base) / 100;
};

export const calcularComissaoEsperada = (
  ativo,
  valorVenda = null,
  valorSinal = null
) => {
  const { tipo_entrada } = ativo;
  const valorVendaAtivo = valorVenda === null ? ativo.valor_venda : valorVenda;
  const valorSinalAtivo = valorSinal === null ? ativo.valor_sinal : valorSinal;
  const valorSinalEsperado = calcularValorSinalEsperado(ativo, valorVendaAtivo);
  const comissaoEsperada = r2(
    tipo_entrada === TiposEntrada.Fixa ? valorSinalAtivo : valorSinalEsperado
  );
  return comissaoEsperada;
};

export const calcularValorVendaEfetivo = ativo => {
  // aka DescontoAutomaticoVV
  const comissaoEsperada = calcularComissaoEsperada(ativo);
  let diffComissao = comissaoEsperada - ativo.valor_sinal;
  if (diffComissao > 0 && ativo.desconto && ativo.desconto.valor_sinal) {
    diffComissao = 0;
  }

  return ativo.valor_venda - diffComissao;
};

export const calcularSaldoAFinanciar = (ativo, ativos, totalTitulosSinal) => {
  const vvEfetivoTotal = ativos.reduce(
    (total, a) => total + calcularValorVendaEfetivo(a),
    0
  );

  if (ativo.desconto && ativo.desconto.aplicou_cheque_bonus_sinal) {
    totalTitulosSinal += ativo.desconto.valor_sinal;
  }

  const vvEfetivo = calcularValorVendaEfetivo(ativo);
  const rateioTitulosSinal = totalTitulosSinal * (vvEfetivo / vvEfetivoTotal);
  return vvEfetivo - rateioTitulosSinal;
};

export const reCalcularPlanosFinanciamento = (
  planosOriginais,
  saldoAFinanciar,
  tabela
) => {
  return planosOriginais.map(plano => {
    const { parcelas } = plano;
    const valor = calcularValorParcelaPlano(saldoAFinanciar, parcelas, tabela);

    return {
      parcelas,
      valor,
    };
  });
};

export const calcularValorParcelaPlano = (
  saldoAFinanciar,
  totalParcelas,
  tabela
) => {
  const { modalidade, juros_mensal } = tabela;

  let valor = 0;
  if (modalidade === ModalidadeTabela.Sac) {
    console.error('NÃO IMPLEMENTADO');
  } else {
    valor = financeiro.price.calcularValorParcela(
      saldoAFinanciar,
      totalParcelas,
      juros_mensal / 100.0
    );
  }

  return valor;
};

//OBSOLETO - forma que a V1 calculava PI de construção e adimplencia (Setsul 2)
export const projetarParcelasComJuros = (
  valorInicial,
  taxaJuros,
  numeroParcelas = 1,
  somaFatores = 1,
  fatorJuros = 1
) => {
  const taxaJurosDecimal = taxaJuros / 100;

  for (let i = 1; i <= numeroParcelas; i++) {
    fatorJuros *= 1 + taxaJurosDecimal;
    somaFatores += fatorJuros;
  }

  somaFatores -= fatorJuros;
  const valorFinal = valorInicial * fatorJuros;

  return valorFinal / somaFatores;
};

/**
 * Calcula o valor de uma parcela com juros. Era utilizado na primeira versão das PIs de adimplência/construção
 *
 * @param {number} valorInicial - O valor inicial da parcela.
 * @param {number} taxaJuros - A taxa de juros em percentual.
 * @returns {number} - O valor da parcela com juros aplicados.
 */
export const projetarParcelaComJuros = (valorInicial, taxaJuros) => {
  const taxaJurosDecimal = taxaJuros / 100;
  const fatorJuros = 1 + taxaJurosDecimal;
  return valorInicial * fatorJuros;
};

// Definindo constantes para milissegundos e dias
const DIAS_NO_MES = 30;
const BASE_CALCULO_JUROS = 1;

/**
 * Calcula a diferença de dias entre duas datas.
 *
 * @param {Date} dataInicial - A data inicial.
 * @param {Date} dataFinal - A data final.
 * @returns {number} - A diferença de dias arredondada para cima.
 */
const calcularDiferencaDias = (dataInicial, dataFinal) => {
  const MILISSEGUNDOS_POR_DIA = 1000 * 60 * 60 * 24;
  var diferencaEmDias = Math.ceil(
    (dataFinal - dataInicial) / MILISSEGUNDOS_POR_DIA
  );
  console.log('Diferenca em dias', diferencaEmDias);
  return diferencaEmDias;
};

/**
 * Calcula o valor presente de um pagamento futuro.
 *
 * @param {number} pmt - O valor do pagamento futuro.
 * @param {number} taxa - A taxa de juros em percentual.
 * @param {Date} dataBase - A data base para o cálculo.
 * @param {Date} vencimento - A data de vencimento do pagamento.
 * @returns {number} - O valor presente arredondado para duas casas decimais.
 */
export const calcularValorPresente = (pmt, taxa, dataBase, vencimento) => {
  const diasDiferenca = calcularDiferencaDias(dataBase, vencimento);
  const taxaDecimal = taxa / 100;
  const fatorDesconto = Math.pow(
    BASE_CALCULO_JUROS + taxaDecimal,
    diasDiferenca / DIAS_NO_MES
  );
  const valorPresente = pmt / fatorDesconto;
  return Math.round(valorPresente * 100) / 100;
};

/**
 * Calcula o valor futuro de um valor presente projetado em uma data futura.
 *
 * @param {number} valorPresente - O valor presente.
 * @param {number} taxa - A taxa de juros em percentual.
 * @param {Date} dataBase - A data base para o cálculo.
 * @param {Date} vencimento - A data futura para o cálculo.
 * @returns {number} - O valor futuro.
 */
export const calcularValorFuturo = (
  valorPresente,
  taxa,
  dataBase,
  vencimento
) => {
  const diasDiferenca = calcularDiferencaDias(dataBase, vencimento);
  const taxaDecimal = taxa / 100;
  const fatorJuros = Math.pow(
    BASE_CALCULO_JUROS + taxaDecimal,
    diasDiferenca / DIAS_NO_MES
  );
  return valorPresente * fatorJuros;
};
