import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import numeral from 'numeral';
import { IoIosBusiness } from 'react-icons/io';
import { MdAttachMoney, MdMoneyOff } from 'react-icons/md';
import { FiPieChart, FiShoppingCart, FiFileText } from 'react-icons/fi';

class CardProdutividadeResumo extends Component {
  formatarValor = valor => numeral(valor).format('(R$ 0.00 a)');

  render() {
    const { resumo, carregandoProdutivdadeCorretor } = this.props;
    if (!resumo || carregandoProdutivdadeCorretor) return null;
    return (
      <Col>
        <Row className="CardProdutividadeResumo">
          <Col xs={6} md={4} xl={2}>
            <Card>
              <Card.Body>
                <div className="produtividade-icon responsive">
                  <FiPieChart color="#EE9A00" />
                </div>
                <ul className="produtividade produtividade-resumo">
                  <li className="Destaque">
                    <small>Cadastros</small>
                    <strong className="d-block">
                      {resumo.quantidade_atendimentos_leads}
                    </strong>
                  </li>
                  <li>
                    <small>Taxa de conversão</small>
                    <strong className="d-block">
                      {numeral(
                        resumo.conversao_periodo /
                          resumo.quantidade_atendimentos_leads
                      ).format('(0.00%)')}
                    </strong>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} xl={2}>
            <Card>
              <Card.Body>
                <div className="produtividade-icon responsive">
                  <IoIosBusiness color="#1D83E2" />
                </div>
                <ul className="produtividade produtividade-resumo">
                  <li className="Destaque">
                    <small>VGV</small>
                    <strong
                      className="d-block"
                      title={`R$ ${
                        resumo.vendas
                          ? resumo.vendas.toLocaleString('pt-BR')
                          : '0,00'
                      }`}
                    >
                      <span>R$</span> {this.formatarValor(resumo.vendas)}
                    </strong>
                  </li>
                  <ul className="ul-inline">
                    <li>
                      <small>Qtd. Vendas</small>
                      <strong className="d-block">
                        {resumo.quantidade_vendas}
                        (E:{resumo.quantidade_efetivadas}+P:
                        {resumo.quantidade_propostas_rapidas})
                      </strong>
                    </li>
                    <li>
                      <small>Comissão</small>
                      <strong
                        className="d-block"
                        title={`R$ ${
                          resumo.comissao_projetada
                            ? resumo.comissao_projetada.toLocaleString('pt-BR')
                            : '0,00'
                        }`}
                      >
                        <span>R$</span>{' '}
                        {this.formatarValor(resumo.comissao_projetada)}
                      </strong>
                    </li>
                  </ul>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} xl={2}>
            <Card>
              <Card.Body>
                <div className="produtividade-icon responsive">
                  <FiShoppingCart color="#876CF7" />
                </div>
                <ul className="produtividade produtividade-resumo">
                  <li className="Destaque">
                    <small>Proposta Rápida</small>
                    <strong
                      className="d-block"
                      title={`R$ ${
                        resumo.proposta_rapida_valor_total
                          ? resumo.proposta_rapida_valor_total.toLocaleString(
                              'pt-BR'
                            )
                          : '0,00'
                      }`}
                    >
                      <span>R$</span>{' '}
                      {this.formatarValor(resumo.proposta_rapida_valor_total)}
                    </strong>
                  </li>
                  <li>
                    <small>Qtd. Propostas</small>
                    <strong className="d-block">
                      {resumo.quantidade_propostas_rapidas}
                    </strong>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} xl={2}>
            <Card>
              <Card.Body>
                <div className="produtividade-icon responsive">
                  <FiFileText />
                </div>
                <ul className="produtividade produtividade-resumo">
                  <li className="Destaque">
                    <small>Efetivadas</small>
                    <strong
                      className="d-block"
                      title={`R$ ${
                        resumo.efetivadas
                          ? resumo.efetivadas.toLocaleString('pt-BR')
                          : '0,00'
                      }`}
                    >
                      <span>R$</span> {this.formatarValor(resumo.efetivadas)}
                    </strong>
                  </li>
                  <li>
                    <small>Qtd. Efetivadas</small>
                    <strong className="d-block">
                      {resumo.quantidade_efetivadas}
                    </strong>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} xl={2}>
            <Card>
              <Card.Body>
                <div className="produtividade-icon responsive">
                  <MdAttachMoney color="#50B848" />
                </div>
                <ul className="produtividade produtividade-resumo">
                  <li className="Destaque">
                    <small>Recebíveis</small>
                    <strong
                      className="d-block"
                      title={`R$ ${
                        resumo.recebiveis
                          ? resumo.recebiveis.toLocaleString('pt-BR')
                          : '0,00'
                      }`}
                    >
                      <span>R$</span> {this.formatarValor(resumo.recebiveis)}
                    </strong>
                  </li>
                  <ul className="ul-inline">
                    <li>
                      <small>Em aberto</small>
                      <strong
                        className="d-block"
                        title={`R$ ${
                          resumo.recebiveis_em_aberto
                            ? resumo.recebiveis_em_aberto.toLocaleString(
                                'pt-BR'
                              )
                            : '0,00'
                        }`}
                      >
                        <span>R$</span>{' '}
                        {this.formatarValor(resumo.recebiveis_em_aberto)}
                      </strong>
                    </li>
                    <li>
                      <small>Já baixados</small>
                      <strong
                        className="d-block"
                        title={`R$ ${
                          resumo.recebiveis_baixados
                            ? resumo.recebiveis_baixados.toLocaleString('pt-BR')
                            : '0,00'
                        }`}
                      >
                        <span>R$</span>{' '}
                        {this.formatarValor(resumo.recebiveis_baixados)}
                      </strong>
                    </li>
                  </ul>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={4} xl={2}>
            <Card>
              <Card.Body>
                <div className="produtividade-icon responsive">
                  <MdMoneyOff color="red" />
                </div>
                <ul className="produtividade produtividade-resumo">
                  <li className="Destaque">
                    <small>VGV Rescisões</small>
                    <strong
                      className="d-block"
                      title={`R$ ${
                        resumo.rescisoes
                          ? resumo.rescisoes.toLocaleString('pt-BR')
                          : '0,00'
                      }`}
                    >
                      <span>R$</span> {this.formatarValor(resumo.rescisoes)}
                    </strong>
                  </li>
                  <li>
                    <small>Qtd. Rescisões</small>
                    <strong className="d-block">
                      {resumo.quantidade_rescisoes}
                    </strong>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  resumo: state.produtividade.resumo,
  imobiliarias: state.produtividade.imobiliarias,
  corretoresCarregados: state.produtividade.corretoresCarregados,
  carregandoProdutivdadeCorretor:
    state.produtividade.carregandoProdutivdadeCorretor,
  imobiliariaSelecionada: state.produtividade.imobiliariaSelecionada,
});

export default withRouter(connect(mapStateToProps)(CardProdutividadeResumo));
