import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import qs from 'qs';
import { Card, Button, Spinner } from 'react-bootstrap';

import notFound from '~/Images/not-found.svg';

import dialog from '~/Utils/dialog';
import * as api from '~/Services/Service';
import {
  TiposGruposDocumentoDigital,
  TiposSignatarios,
  StatusDocumentoDigital,
  TiposDocDigital,
} from '~/constant';
import { toTitleCase } from '~/Utils/Helpers';

import Loading from '~/Components/Loading';
import Header from '~/Components/Header/Header';
import Aviso from '~/Components/Aviso';

import ResumoPreVendaDocumentoDigital from '~/Components/DocumentoDigital/ResumoPreVendaDocumentoDigital';
import AlertasDocumentoDigital from '~/Components/DocumentoDigital/AlertasDocumentoDigital';
import InfoSignatario from '~/Components/DocumentoDigital/InfoSignatario';
import OmissorGrupo from '~/Components/DocumentoDigital/OmissorGrupo';
import MensagemOmissaoGrupo from '~/Components/DocumentoDigital/MensagemOmissaoGrupo';
import DisplayDocumentosContratoVenda from '~/Components/DocumentoDigital/DisplayDocumentosContratoVenda';
import HistoricoDocumentoDigital from '~/Components/DocumentoDigital/HistoricoDocumentoDigital';
import PreviewArquivoDocumentoDigital from '~/Components/DocumentoDigital/PreviewArquivoDocumentoDigital';
import TestemunhaHandling from '~/Components/DocumentoDigital/TestemunhaHandling';
import EdicaoSignatario from '~/Components/DocumentoDigital/EdicaoSignatario';
import ModalTestemunha from '~/Components/DocumentoDigital/ModalTestemunha';

import {
  isReadOnly,
  isStatusPreAprovacao,
  verificarPermissaoExibicaoContratoVendaPJ,
  verificarBloqueioContratoSemConjuge,
  verificarPodeGerenciar,
  verificarPodeSolicitar,
} from '~/Components/DocumentoDigital/helpers';

import ModalCancelar from '../../Components/DocumentoDigital/ModalCancelar';

const DocumentoDigital = () => {
  const isLoadingOptions = useSelector(state => state.is_loading_options);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [documentoDigital, setDocumentoDigital] = useState(null);
  const [pdfFile, setPdfFile] = useState('');
  const [enviarCartaDesconto, setEnviarCartaDesconto] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isGoingBack, setIsGoingBack] = useState(false);
  const [isGoingForward, setIsGoingForward] = useState(false);

  const [incluirTestemunhas, setIncluirTestemunhas] = useState(true);
  const [testemunhaSelecionada, setTestemunhaSelecionada] = useState({});
  const [showModalNovaTestemunha, setShowModalNovaTestemunha] = useState(false);

  const [incluirProcuradores, setIncluirProcuradores] = useState(true);

  const [shouldApprove, setShouldApprove] = useState(false);

  const [showModalReprovacao, setShowModalReprovacao] = useState(false);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState({});
  const [motivos, setMotivos] = useState([]);

  const isLoading = isLoadingOptions || isLoadingData;

  const location = useLocation();
  const history = useHistory();

  const podeRealizarAcaoPreAprovacao =
    !isReadOnly(documentoDigital) && isStatusPreAprovacao(documentoDigital);

  const podeSolicitar = verificarPodeSolicitar(
    documentoDigital,
    incluirTestemunhas,
    incluirProcuradores
  );

  useEffect(() => {
    async function buscarDados() {
      const { codPreVenda, codDocDigital } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });

      if (codPreVenda) {
        await loadFromNumeroPreVenda(codPreVenda);
      } else if (codDocDigital) {
        await loadFromCodDocumentoDigital(codDocDigital);
      }
    }

    buscarDados();
  }, []);

  //exclusivo lançamento
  useEffect(() => {
    if (shouldApprove && documentoDigital !== null) {
      aprovar(documentoDigital);
      setShouldApprove(false);
    }
  }, [shouldApprove, documentoDigital]);

  function incluirSignatario(signatario) {
    setDocumentoDigital({
      ...documentoDigital,
      signatarios: [...documentoDigital.signatarios, signatario],
    });
  }

  function editarSignatario(index, signatario, onIndexMatch) {
    setDocumentoDigital({
      ...documentoDigital,
      signatarios: documentoDigital.signatarios.map((e, i) => {
        if (i === index) {
          if (onIndexMatch) onIndexMatch(e);
          else e = { ...e, ...signatario };
        }
        return e;
      }),
    });
  }

  function removerSignatario(index) {
    setDocumentoDigital({
      ...documentoDigital,
      signatarios: documentoDigital.signatarios.filter((e, i) => i !== index),
    });
  }

  async function loadFromNumeroPreVenda(codPreVenda) {
    try {
      setIsLoadingData(true);
      const documentoDigital = await api.buscarPreviaDocDigital(
        codPreVenda,
        TiposDocDigital.ContratoVenda
      );
      setDocumentoDigital(documentoDigital);
    } catch (err) {
      console.error(err);
      toast.error(
        err.message || 'Falha ao buscar o documento digital pela pré-venda'
      );
    } finally {
      setIsLoadingData(false);
    }
  }

  async function loadFromCodDocumentoDigital(codDocumentoDigital) {
    try {
      setIsLoadingData(true);
      const documentoDigital = await api.buscarDocumentoDigital(
        codDocumentoDigital
      );
      setDocumentoDigital(documentoDigital);
      if (documentoDigital.url_arquivo) {
        setPdfFile(documentoDigital.url_arquivo);
      }
      setEnviarCartaDesconto(
        documentoDigital.log &&
          documentoDigital.log.findIndex(val => val.evento === 14) > 0
      );
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao buscar o documento digital');
    } finally {
      setIsLoadingData(false);
    }
  }

  async function solicitarAssinaturaDigital() {
    setBusyForward(true);

    const request = construirRequest();

    try {
      const response = await api.solicitarAssinaturaDigital(request);
      toast.success('Solicitação efetuada com sucesso.');
      history.push(`/documentodigital?codDocDigital=${response.codigo}`);
      setDocumentoDigital(response);
      setShouldApprove(true);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao realizar a solicitação');
    } finally {
      setBusyForward(false);
    }
  }

  async function descartar() {
    const result = await dialog.prompt({
      title: 'Descarte',
      message: 'Informe uma justificativa para descartar o documento',
    });

    if (!result.proceed) {
      setBusyBackward(false);
      return;
    }

    setBusyBackward(true);

    try {
      const response = await api.destartarDocumentoDigital(
        documentoDigital.codigo,
        result.message
      );
      toast.success('Documento descartado com sucesso.');
      setDocumentoDigital(response);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao descartar o documento');
    } finally {
      setBusyBackward(false);
    }
  }

  async function reprovar(tipoContrato) {
    setOpcaoSelecionada('Reprovar');
    setShowModalReprovacao(true);
    fetchMotivos(tipoContrato);
  }

  const gravarReprovacao = async (values, { setSubmitting, resetForm }) => {
    setBusyBackward(true);
    try {
      const response = await api.reprovarDocumentoDigital(
        documentoDigital.codigo,
        values.observacao,
        values.motivoCancelar
      );

      toast.success('Documento reprovado com sucesso.');
      setDocumentoDigital(response);
    } catch (err) {
      toast.error(err.message || 'Falha ao reprovar o documento');
    } finally {
      setBusyBackward(false);
      setShowModalReprovacao(false);
      setOpcaoSelecionada({});
      resetForm();
    }
  };

  async function cancelar(tipoContrato) {
    setOpcaoSelecionada('Cancelar');
    setShowModalReprovacao(true);
    fetchMotivos(tipoContrato);
  }

  const gravarCancelamento = async (values, { setSubmitting, resetForm }) => {
    setBusyBackward(true);

    try {
      const response = await api.cancelarDocumentoDigital(
        documentoDigital.codigo,
        values.observacao,
        values.motivoCancelar
      );
      toast.success('Documento cancelado com sucesso.');
      setDocumentoDigital(response);
    } catch (err) {
      toast.error(err.message || 'Falha ao cancelar o documento');
    } finally {
      setBusyBackward(false);
      setShowModalReprovacao(false);
      setOpcaoSelecionada({});
      resetForm();
    }
  };

  async function fetchMotivos(tipoContrato) {
    setIsLoadingData(true);
    try {
      const response = await api.buscarMotivosCancelamento(tipoContrato);
      setMotivos(response);
    } catch (error) {
      console.error(error);
      toast.error(error.message || 'Falha ao buscar os motivos');
    } finally {
      setIsLoadingData(false);
    }
  }

  async function reSolicitar() {
    setBusyForward(true);

    const request = construirRequest();

    try {
      const response = await api.reSolicitarAssinaturaDigital(request);
      toast.success('Re-solicitação efetuada com sucesso.');
      setDocumentoDigital(response);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao realizar a re-solicitação');
    } finally {
      setBusyForward(false);
    }
  }

  async function aprovar() {
    const result = await dialog.confirm({
      title: 'Aprovação',
      message:
        'Deseja aprovar a solicitação de assinatura digital? Esta ação também realizará o upload do contrato na plataforma de assinatura digital.',
    });

    if (!result.proceed) {
      setBusyForward(false);
      return;
    }

    setBusyForward(true);

    const data = construirRequest();

    const request = new FormData();
    request.append('data', JSON.stringify(data));
    if (pdfFile) request.append('contrato', pdfFile);

    try {
      const response = await api.aprovarAssinaturaDigital(
        request,
        enviarCartaDesconto
      );
      toast.success('Documento aprovado com sucesso.');
      setDocumentoDigital(response);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao aprovar a documentação');
    } finally {
      setBusyForward(false);
    }
  }

  async function upload() {
    setBusyForward(true);

    const request = new FormData();
    if (pdfFile) request.append('contrato', pdfFile);

    try {
      const response = await api.uploadDocumentoDigital(
        documentoDigital.codigo,
        request,
        enviarCartaDesconto
      );
      toast.success('Upload do documento realizado com sucesso.');
      setDocumentoDigital(response);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao realizar upload');
    } finally {
      setBusyForward(false);
    }
  }

  async function deletarSignatario(codigoSignatario) {
    setIsBusy(true);
    try {
      const response = await api.removerSignatario(codigoSignatario);
      toast.success('Signatário removido com sucesso.');
      setDocumentoDigital(response);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Falha ao remover o signatário');
    } finally {
      setIsBusy(false);
    }
  }

  function construirRequest() {
    // if (documentoDigital) grupos = documentoDigital.grupos;

    // incluirGrupoSePreciso(grupos, TiposGruposDocumentoDigital.Compradores);
    // signatarios.push(...compradores.filter(el => el.signatario));
    // if (incluirTestemunhas) {
    //   incluirGrupoSePreciso(grupos, TiposGruposDocumentoDigital.Testemunhas);
    //   signatarios.push(...testemunhas);
    // }
    // if (incluirProcuradores) {
    //   incluirGrupoSePreciso(grupos, TiposGruposDocumentoDigital.Procuradores);
    //   signatarios.push(...procuradores);
    // }
    // incluirGrupoSePreciso(grupos, TiposGruposDocumentoDigital.Validadores);
    // signatarios.push(...validadores);

    // let request = {
    //   codigo: documentoDigital ? documentoDigital.codigo : 0,
    //   numero_pre_venda: preVenda.numero,
    //   seguir_ordem_global: true,
    //   tipo: 1, //contrato de venda
    //   grupos: grupos,
    //   signatarios: signatarios,
    // };
    const grupos = documentoDigital.grupos.filter(g => {
      if (g.tipo === TiposGruposDocumentoDigital.Testemunhas)
        return incluirTestemunhas;
      if (g.tipo === TiposGruposDocumentoDigital.Procuradores)
        return incluirProcuradores;
      return true;
    });

    const signatarios = documentoDigital.signatarios.filter(s => {
      if (s.tipo_signatario === TiposSignatarios.Testemunha)
        return incluirTestemunhas;
      if (s.tipo_signatario === TiposSignatarios.Procurador)
        return incluirProcuradores;
      return s.signatario;
    });

    return {
      ...documentoDigital,
      grupos,
      signatarios,
    };
  }

  function setBusyForward(isBusy) {
    setIsBusy(isBusy);
    setIsGoingForward(isBusy);
  }

  function setBusyBackward(isBusy) {
    setIsBusy(isBusy);
    setIsGoingBack(isBusy);
  }

  const renderRequisitos = () => {
    dialog.showMessage({
      title: 'Requisitos',
      body: (
        <ul>
          <li>Certifique-se de que há signatários em cada um dos grupos;</li>
          <li>Todos os signatários devem possuir CPF e e-mail;</li>
          <li>Os e-mails não podem se repetir;</li>
          <li>O grupo de testemunhas deve ter ao menos 2 integrantes.</li>
          {verificarBloqueioContratoSemConjuge(documentoDigital) && (
            <li className="text-danger">
              <strong>
                Cliente ou sócio casado porém sem cônjuge. Altere o cadastro do
                cliente ou comunique a Secretaria de Vendas
              </strong>
            </li>
          )}
          {!verificarPermissaoExibicaoContratoVendaPJ(documentoDigital) && (
            <li className="text-danger">
              <strong>
                A visualização do contrato da venda para pessoa jurídica está
                bloqueada. A solicitação de assinatura digital deverá ser feita
                pela Secretaria de Vendas
              </strong>
            </li>
          )}
        </ul>
      ),
    });
  };

  function beginEditarTestemunha(testemunha) {
    setTestemunhaSelecionada(testemunha);
    setShowModalNovaTestemunha(true);
  }
  function incluirOrEditarTestemunha(values) {
    const isEditing = testemunhaSelecionada.nome;
    if (isEditing) {
      const index = documentoDigital.signatarios.indexOf(testemunhaSelecionada);
      editarSignatario(index, values);
    } else {
      const grupoExistenteTestemunhas = documentoDigital
        ? documentoDigital.grupos.find(
            g => g.tipo === TiposGruposDocumentoDigital.Testemunhas
          )
        : null;
      values.signatario = true;
      values.tipo_signatario = TiposSignatarios.Testemunha;
      values.codigo_documento_digital_grupo = grupoExistenteTestemunhas
        ? grupoExistenteTestemunhas.codigo
        : TiposGruposDocumentoDigital.Testemunhas;
      values.ativo = true;
      console.debug('Incluindo testemunha', values);
      incluirSignatario(values);
    }
    setTestemunhaSelecionada({});
    setShowModalNovaTestemunha(false);
  }

  function renderBackwardAction() {
    let options = null;
    const { status } = documentoDigital;
    const { tipo } = documentoDigital;
    switch (status) {
      case StatusDocumentoDigital.NovaSolicitacao:
        return null;
      case StatusDocumentoDigital.Solicitado:
        if (!podeRealizarAcaoPreAprovacao) return null;
        options = {
          action: () => reprovar(tipo),
          label: 'Reprovar',
          onBusyLabel: 'Reprovando...',
        };
        break;
      case StatusDocumentoDigital.Reprovado:
        if (!podeRealizarAcaoPreAprovacao) return null;
        options = {
          action: descartar,
          label: 'Descartar',
          onBusyLabel: 'Descartando...',
        };
        break;
      case StatusDocumentoDigital.Aprovado:
        if (!verificarPodeGerenciar(documentoDigital)) return null;
        options = {
          action: descartar,
          label: 'Descartar',
          onBusyLabel: 'Descartando...',
        };
        break;
      case StatusDocumentoDigital.PendenteAssinaturasClientes:
      case StatusDocumentoDigital.PendenteAssinaturasSetpar:
        if (!verificarPodeGerenciar(documentoDigital)) return null;
        options = {
          action: () => cancelar(tipo),
          label: 'Cancelar',
          onBusyLabel: 'Cancelar...',
        };
        break;
      default:
        break;
    }

    if (!options) return null;

    return (
      <Button
        size="lg"
        variant="danger"
        className="d-flex align-items-center"
        disabled={isBusy}
        onClick={options.action}
      >
        {isGoingBack && (
          <Spinner animation="border" className="mr-2" size="sm" />
        )}
        {isGoingBack ? options.onBusyLabel : options.label}
      </Button>
    );
  }

  function renderForwardAction() {
    let options = null;
    const { status } = documentoDigital;
    switch (status) {
      case StatusDocumentoDigital.NovaSolicitacao:
        if (!podeRealizarAcaoPreAprovacao) return null;
        options = {
          action: solicitarAssinaturaDigital,
          label: 'Solicitar',
          onBusyLabel: 'Solicitando...',
          disabled: !podeSolicitar || isBusy,
        };
        break;
      case StatusDocumentoDigital.Solicitado:
        if (!podeRealizarAcaoPreAprovacao) return null;
        options = {
          action: aprovar,
          label: 'Aprovar',
          onBusyLabel: 'Aprovando...',
          disabled: !podeSolicitar || isBusy,
        };
        break;
      case StatusDocumentoDigital.Reprovado:
        if (!podeRealizarAcaoPreAprovacao) return null;
        options = {
          action: reSolicitar,
          label: 'Solicitar Novamente',
          onBusyLabel: 'Solicitando...',
          disabled: !podeSolicitar || isBusy,
        };
        break;
      case StatusDocumentoDigital.Aprovado:
        if (!verificarPodeGerenciar(documentoDigital)) return null;
        options = {
          action: upload,
          label: 'Upload',
          onBusyLabel: 'Uploading...',
          disabled: isBusy,
        };
        break;
      default:
        return null;
    }

    if (!options) return null;
    return (
      <Button
        size="lg"
        variant="success"
        className="d-flex align-items-center"
        disabled={options.disabled}
        onClick={options.action}
      >
        {isGoingForward && (
          <Spinner animation="border" className="mr-2" size="sm" />
        )}
        {isGoingForward ? options.onBusyLabel : options.label}
      </Button>
    );
  }

  const renderSignatarios = () => {
    const edicaoAvancada =
      documentoDigital.tipo === TiposDocDigital.ContratoVenda;
    return documentoDigital.grupos.map(grupo => {
      const signatariosDoGrupo = documentoDigital.signatarios.filter(
        s => s.codigo_documento_digital_grupo === grupo.codigo
      );

      let isGroupIncluded = true;
      if (grupo.tipo === TiposGruposDocumentoDigital.Testemunhas)
        isGroupIncluded = incluirTestemunhas;
      if (grupo.tipo === TiposGruposDocumentoDigital.Procuradores)
        isGroupIncluded = incluirProcuradores;

      console.debug(grupo);

      return (
        <section className="my-2" key={grupo.codigo}>
          <div className="d-sm-flex align-items-end justify-content-between mb-2">
            <div className="d-flex align-items-end ">
              <h5 className="mr-2">{toTitleCase(grupo.nome)}</h5>
              {edicaoAvancada &&
                grupo.tipo === TiposGruposDocumentoDigital.Testemunhas &&
                podeRealizarAcaoPreAprovacao && (
                  <TestemunhaHandling
                    incluirTestemunhas={isGroupIncluded}
                    onIncluirTestemunha={incluirSignatario}
                    documento={documentoDigital}
                    onNovaTestemunha={() => beginEditarTestemunha({})}
                  />
                )}
            </div>
            {edicaoAvancada && podeRealizarAcaoPreAprovacao && (
              <OmissorGrupo
                grupo={grupo}
                incluirGrupo={isGroupIncluded}
                isBusy={isBusy}
                onToggleIncluirGrupo={shouldInclude => {
                  if (grupo.tipo === TiposGruposDocumentoDigital.Testemunhas)
                    setIncluirTestemunhas(shouldInclude);
                  if (grupo.tipo === TiposGruposDocumentoDigital.Procuradores)
                    setIncluirProcuradores(shouldInclude);
                }}
              />
            )}
          </div>
          {!isGroupIncluded ? (
            <MensagemOmissaoGrupo omitir={!isGroupIncluded} />
          ) : (
            signatariosDoGrupo.map(signatario => {
              const index = documentoDigital.signatarios.indexOf(signatario);
              return (
                <Card
                  key={index}
                  className={`card mb-2 ml-4 bg-${
                    signatario.ativo && signatario.signatario
                      ? 'white'
                      : 'light'
                  } border-${
                    signatario.assinado &&
                    !signatario.codigo_signatario_substituto
                      ? 'success'
                      : 'white'
                  }`}
                >
                  <Card.Body>
                    <div className="d-flex flex-column flex-sm-row">
                      <InfoSignatario
                        status={documentoDigital.status}
                        signatario={signatario}
                        displayCpf={true}
                        signatarios={documentoDigital.signatarios}
                      />

                      <EdicaoSignatario
                        index={index}
                        isBusy={isBusy}
                        setIsBusy={setIsBusy}
                        signatario={signatario}
                        edicaoAvancada={edicaoAvancada}
                        podeRealizarAcaoPreAprovacao={
                          podeRealizarAcaoPreAprovacao
                        }
                        grupo={grupo}
                        podeGerenciar={verificarPodeGerenciar(documentoDigital)}
                        status={documentoDigital.status}
                        onEditarSignatario={editarSignatario}
                        beginEditarTestemunha={beginEditarTestemunha}
                        removerSignatario={removerSignatario}
                        deletarSignatario={deletarSignatario}
                        incluirTestemunhas={incluirTestemunhas}
                      />
                    </div>
                  </Card.Body>
                </Card>
              );
            })
          )}
        </section>
      );
    });
  };

  const renderMainContent = () => {
    if (!documentoDigital) return null;

    return (
      <main className="mx-4">
        <ResumoPreVendaDocumentoDigital documento={documentoDigital} />
        <hr />
        <AlertasDocumentoDigital documento={documentoDigital} />
        {renderSignatarios()}

        <hr />
        {documentoDigital.tipo === TiposDocDigital.ContratoVenda && (
          <DisplayDocumentosContratoVenda
            documento={documentoDigital}
            onArquivoSelecionado={file => setPdfFile(file)}
            enviarCartaDesconto={enviarCartaDesconto}
            setEnviarCartaDesconto={setEnviarCartaDesconto}
          />
        )}

        <PreviewArquivoDocumentoDigital
          pdfFile={pdfFile}
          url={documentoDigital.url_arquivo}
        />

        <section
          className="my-2 py-2 bg-light"
          style={{ bottom: 0, position: 'sticky' }}
        >
          <div className=" d-flex justify-content-between align-content-baseline ">
            {renderBackwardAction()}
            <div className="ml-auto d-flex">
              {!podeSolicitar && podeRealizarAcaoPreAprovacao && (
                <Button variant="link" onClick={renderRequisitos}>
                  Por que não consigo prosseguir?
                </Button>
              )}
              {renderForwardAction()}
            </div>
          </div>
        </section>

        <HistoricoDocumentoDigital documento={documentoDigital} />
      </main>
    );
  };

  return (
    <>
      <Header title="Solicitação de Assinatura Digital" />
      {isLoading && <Loading />}
      {!isLoading && documentoDigital && renderMainContent()}
      {!isLoading && !documentoDigital && (
        <Aviso
          icon={<img src={notFound} alt="not found" style={{ height: 80 }} />}
          text="Dados não encontrados"
        />
      )}
      <ModalTestemunha
        onSubmit={incluirOrEditarTestemunha}
        testemunhaSelecionada={testemunhaSelecionada}
        show={showModalNovaTestemunha}
        onClosing={() => {
          setTestemunhaSelecionada({});
          setShowModalNovaTestemunha(false);
        }}
      />
      <ModalCancelar
        show={showModalReprovacao}
        onClosing={() => {
          setShowModalReprovacao(false);
        }}
        onSubmit={
          opcaoSelecionada === 'Cancelar'
            ? gravarCancelamento
            : gravarReprovacao
        }
        opcao={opcaoSelecionada}
        isLoadingData={isLoadingOptions || isLoadingData}
        motivos={motivos}
      />
      ;
    </>
  );
};

export default DocumentoDigital;
