import React from 'react';

export default function PageQuantitySelector({ onChange, value }) {
  return (
    <select
      className="form-control"
      name="quantidadePorPagina"
      onChange={onChange}
      defaultValue={value}
    >
      <option value="10">10</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  );
}
