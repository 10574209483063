import React, { Component } from 'react';

import Header from '~/Components/Header/Header';
import Agenda from '~/Components/Agenda';

export class Agendamento extends Component {
  render() {
    return (
      <>
        <Header title="Agendamentos" />
        <div className="w-100">
          <Agenda />
        </div>
      </>
    );
  }
}

export default Agendamento;
