import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { adicionarEmpreendimento } from '~/Store/Prospecto/actions';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _size from 'lodash/size';
import { FaSpinner } from 'react-icons/fa';
import history from '../../../../Config/history';
import { default as Selector } from 'react-select';
import { default as auth } from '../../../../Utils/AuthStorage';
import SelectGerenteCorretor from '../../../GerentesCorretores/SelectGerenteCorretor';
import { EMPREENDIMENTO_LANCAMENTO } from '~/constant';

const ModalProspectoEmpreendimento = ({
  location,
  gerentesCorretores,
  empreendimentos,
  prospecto,
  salvandoEmpreendimento,
  erroAdicionarEmpreendimento,
  adicionarEmpreendimento,
}) => {
  let params = new URLSearchParams(location.search);

  let initialValues = {
    ...prospecto,
    codigo_familia: '',
    codigo_corretor: '',
    codigo_gerente: '',
  };

  if (auth.isCorretor() && gerentesCorretores.listaGerentes.length > 1) {
    initialValues.codigo_corretor = auth.getUser().usuario;
    initialValues.codigo_gerente =
      gerentesCorretores.corretoresById[auth.getUser().usuario].codigo_gerente;
  } else if (auth.isGerente()) {
    initialValues.codigo_gerente = auth.getUser().usuario;
  }

  const validationSchema = Yup.object().shape({
    codigo_familia: Yup.number().required('Selecione um empreendimento'),
    codigo_gerente: Yup.string().required('Selecione um gerente'),
    codigo_corretor: Yup.string().required('Selecione um corretor'),
  });

  const optionsEmpreendimento = empreendimentos.sort.map(item => ({
    value: item.codigo,
    label: item.descricao,
  }));

  // const optionsEmpreendimento = [
  //   {
  //     value: 11339,
  //     label: 'SETLIFE ESSENCE (UBERABA)',
  //   },
  // ];

  const buildValueEmpreendimento = empreendimento => {
    return empreendimento
      ? {
          value: parseInt(empreendimentos.byId[empreendimento].codigo),
          label: empreendimentos.byId[empreendimento].descricao,
        }
      : { value: '', label: 'Selecione um empreendimento' };
  };

  const vincularEmpreendimento = values => {
    adicionarEmpreendimento(values);
  };

  if (!params.get('vincular-empreendimento')) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={vincularEmpreendimento}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values, errors, submitCount, handleSubmit }) => {
        // const cadastroDuranteLancamento = values.codigo_familia === EMPREENDIMENTO_LANCAMENTO && auth.isADM();
        return (
          <Modal show={true} onHide={() => history.push({ search: '' })}>
            <Modal.Header closeButton>
              <Modal.Title>Vicular Empreendimento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="prospecto.codigo_familia">
                  <Form.Label srOnly>Selecione um empreendimento</Form.Label>
                  <Selector
                    noOptionsMessage={() => 'Nenhum empreendimento encontrado'}
                    placeholder="Pesquisar empreendimentos..."
                    options={optionsEmpreendimento}
                    onChange={option => {
                      if (
                        option.value === EMPREENDIMENTO_LANCAMENTO &&
                        auth.isADM()
                      ) {
                        setFieldValue('codigo_corretor', 1078);
                        setFieldValue('codigo_gerente', 1077);
                      }
                      setFieldValue('codigo_familia', option.value);
                    }}
                    value={buildValueEmpreendimento(values.codigo_familia)}
                    disabled={salvandoEmpreendimento}
                  />
                </Form.Group>

                {values.codigo_familia && (
                  <SelectGerenteCorretor
                    // disabledCorretor={cadastroDuranteLancamento}
                    // disabledGerente={cadastroDuranteLancamento}
                    gerente={values.codigo_gerente}
                    corretor={values.codigo_corretor}
                    onChangeCorretor={corretor =>
                      setFieldValue('codigo_corretor', corretor)
                    }
                    onChangeGerente={gerente =>
                      setFieldValue('codigo_gerente', gerente)
                    }
                  />
                )}
                {submitCount > 0 && _size(errors) > 0 && (
                  <div className="FormDadosPessoais__error text-danger">
                    Preenchas os campos corretamente antes de salvar
                  </div>
                )}
                {erroAdicionarEmpreendimento && (
                  <div className="FormDadosPessoais__error text-danger">
                    {erroAdicionarEmpreendimento}
                  </div>
                )}
                <div className="d-flex d-sm-block text-right justify-content-between">
                  <Button
                    variant="link"
                    className="text-uppercase px-4 d-block d-sm-inline-block"
                    onClick={() => history.push({ search: '' })}
                    disabled={salvandoEmpreendimento}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleSubmit}
                    className="text-uppercase px-4 d-block d-sm-inline-block"
                    disabled={salvandoEmpreendimento}
                  >
                    {salvandoEmpreendimento ? (
                      <span>
                        <FaSpinner /> Salvando...
                      </span>
                    ) : (
                      'Salvar'
                    )}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      adicionarEmpreendimento,
    },
    dispatch
  );

const mapStateToProps = state => ({
  salvandoEmpreendimento: state.prospecto.adicionandoEmpreendimento,
  erroAdicionarEmpreendimento: state.prospecto.erroAdicionarEmpreendimento,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalProspectoEmpreendimento)
);
