import React from 'react';
import { Alert } from 'react-bootstrap';
import {
  verificarBloqueioContratoSemConjuge,
  verificarPermissaoExibicaoContratoVendaPJ,
  isStatusPreAprovacao,
} from '../helpers';

const AlertasDocumentoDigital = ({ documento }) => {
  const bloqueioPorContratoSemConjuge = verificarBloqueioContratoSemConjuge(
    documento
  );
  const podeExibirContratoVendaPJ = verificarPermissaoExibicaoContratoVendaPJ(
    documento
  );
  return (
    <>
      {isStatusPreAprovacao(documento) && (
        <Alert variant="warning">
          Confira com atenção a lista de signatários da solicitação. Não será
          possível adicionar ou remover signatários após aprovação.
        </Alert>
      )}
      {bloqueioPorContratoSemConjuge && (
        <Alert variant="danger">
          Cliente ou sócio casado porém sem cônjuge. Altere o cadastro do
          cliente ou comunique a Secretaria de Vendas.
        </Alert>
      )}
      {!podeExibirContratoVendaPJ && (
        <Alert variant="danger">
          A visualização do contrato da venda para pessoa jurídica está
          bloqueada. A solicitação de assinatura digital deverá ser feita pela
          Secretaria de Vendas
        </Alert>
      )}
    </>
  );
};

export default AlertasDocumentoDigital;
