import React, { useMemo, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import {
  TiposGruposDocumentoDigital,
  TiposSignatarios,
  StatusDocumentoDigital,
} from '~/constant';
import AuthStorage from '~/Utils/AuthStorage';

const TestemunhaHandling = ({
  documento,
  incluirTestemunhas,
  onIncluirTestemunha,
  onNovaTestemunha,
}) => {
  const user = useMemo(() => AuthStorage.getUser(), []);

  useEffect(() => {
    if (
      documento.status === StatusDocumentoDigital.NovaSolicitacao &&
      !documento.signatarios.find(s => s.codigo_usuario === user.usuario)
    )
      onIncluirTestemunha(usuarioComoTestemunha());
  }, []);

  function usuarioComoTestemunha() {
    const grupoTestemunhas = documento
      ? documento.grupos.find(
          g => g.tipo === TiposGruposDocumentoDigital.Testemunhas
        )
      : documento.find(g => g.tipo === TiposGruposDocumentoDigital.Testemunhas);
    return {
      nome: user.nome,
      email: user.email,
      cpf: user.cpf,
      codigo_usuario: user.usuario,
      signatario: true,
      codigo_documento_digital_grupo: grupoTestemunhas.codigo,
      tipo_signatario: TiposSignatarios.Testemunha,
      ativo: true,
    };
  }

  const renderBotaoNovaTestemunha = () => {
    return (
      <Button
        className="ml-auto"
        onClick={() => onNovaTestemunha()}
        disabled={!incluirTestemunhas}
      >
        Nova
      </Button>
    );
  };

  const renderBotaoIncluirUsuarioComoTestemunha = () => {
    if (
      incluirTestemunhas &&
      !documento.signatarios.find(t => t.codigo_usuario === user.usuario)
    )
      return (
        <Button
          onClick={() => onIncluirTestemunha(usuarioComoTestemunha())}
          className="ml-1"
        >
          Incluir-me
        </Button>
      );
    return null;
  };

  return (
    <>
      {renderBotaoNovaTestemunha()}
      {renderBotaoIncluirUsuarioComoTestemunha()}
    </>
  );
};

export default TestemunhaHandling;
