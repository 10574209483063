import React, { useState } from 'react';
import { Button, Modal, Table, Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MdFileDownload, MdSend } from 'react-icons/md';
import { IoLogoWhatsapp, IoMdMail } from 'react-icons/io';

import {
  ContainerResumeProspect,
  LineResume,
} from '../../ResumeProspect/styles';
import { TIPO_TITULO } from '~/constant';
import {
  obterTotalTitulosSinal,
  obterTotalComissao,
  valoresComissaoSupervisor,
  agruparPorVenda,
  distinctTitulos,
  GRAVAR_OCORRENCIA,
  ENCAMINHAR_COBRANCA,
  TIPO_COMISSAO_IMO,
  TIPO_COMISSAO_MKT,
} from '../helpers';
import * as api from '~/Services/Service';
import AuthStorage from '~/Utils/AuthStorage';
import { formatarMonetario, format } from '~/Utils/Helpers';
import ModalOcorrenciasInadimplencias from '../ModalOcorrenciasInadimplencias';
import ModalGravarOcorrencia from '../ModalGravarOcorrencia';
import ModalEnviarBoletoPorEmail from '../ModalEnviarBoletoPorEmail';
import IconeTipoTitulo from '../../IconeTipoTitulo';
import { TableRowTitulo, TableHeaderVenda, AcoesVenda } from './styles';

const ModalDetalhesClienteInadimplencias = ({
  onHide,
  registro,
  onCobrancaEncaminhada,
  onContatoRealizado,
  empreendimentos,
}) => {
  const [mostrandoOcorrencias, setMostrandoOcorrencias] = useState(false);
  const [ocorrencias, setOcorrencias] = useState([]);
  const [busy, setBusy] = useState(null);
  const [tipoAcaoOcorrencia, setTipoAcaoOcorrencia] = useState(null);
  const [numeroPreVenda, setNumeroPreVenda] = useState(null);
  const [dadosEnvioBoleto, setDadosEnvioBoleto] = useState({});
  if (!registro) return null;

  const { cliente, registros } = registro;
  const {
    codigo_conta_cliente: conta,
    codigo_grupo_empresa_cliente: ge,
  } = cliente;

  const pendenciasPorVenda = agruparPorVenda(registros);
  const registrosTitulos = distinctTitulos(registros);

  const mostrarOcorrencias = async (codigoConta, ge, codigoBoleto) => {
    setBusy('ocorrencias');
    const somenteOcorrenciaDoUsuario = 's';
    try {
      const ocorrencias = await api.buscarOcorrenciasCliente(
        codigoConta,
        ge,
        codigoBoleto,
        somenteOcorrenciaDoUsuario
      );

      setOcorrencias(ocorrencias);
      setMostrandoOcorrencias(true);
    } catch (err) {
      toast.error(err.message || 'Erro ao buscar ocorrências');
      console.error(err);
    } finally {
      setBusy(null);
    }
  };

  const gravarOcorrencia = async descricao => {
    setBusy(tipoAcaoOcorrencia);
    const isEncaminhamento = tipoAcaoOcorrencia === ENCAMINHAR_COBRANCA;
    try {
      const ocorrencia = await api.gravarOcorrenciaCliente(
        {
          codigo_conta: conta,
          codigo_grupo_empresa: ge,
          texto: descricao,
          titulo: isEncaminhamento ? 'Encaminhamento à Cobrança' : 'Negociação',
          codigo_atendimento: 1002, // painel do corretor,
          codigo_sub_tipo_atendimento: isEncaminhamento ? 2 : 3,
        },
        numeroPreVenda,
        isEncaminhamento
      );
      onContatoRealizado(numeroPreVenda, ocorrencia.data);
      if (isEncaminhamento) onCobrancaEncaminhada(numeroPreVenda);

      const message = isEncaminhamento
        ? 'Cobrança encaminhada com sucesso'
        : 'Ocorrência gravada com sucesso';
      toast.success(message);
      setTipoAcaoOcorrencia(null);
      setNumeroPreVenda(null);
    } catch (err) {
      const message = isEncaminhamento
        ? 'Erro ao encaminhar cobrança'
        : 'Erro ao gravar ocorrência';
      toast.error(err.message || message);
      console.error(err);
    } finally {
      setBusy(null);
    }
  };

  const emitirSegundaVia = async codigoTitulo => {
    setBusy('segunda-via');
    try {
      const url = await api.obterUrlSegundaViaBoleto(codigoTitulo);
      window.open(url, '_blank');
    } catch (err) {
      toast.error(err.message || 'Erro ao obter 2ª via do boleto');
      console.error(err);
    } finally {
      setBusy(null);
    }
  };

  const enviarBoletoPorEmail = async email => {
    setBusy('envio-email');
    try {
      await api.enviarEmailSegundaViaBoleto(
        email,
        dadosEnvioBoleto.codigoTitulo
      );
      toast.success('2ª via do boleto enviada!');
      setDadosEnvioBoleto({});
    } catch (err) {
      toast.error(err.message || 'Erro ao enviar 2ª via do boleto');
      console.error(err);
    } finally {
      setBusy(null);
    }
  };

  const empreendimento = empreendimentos.find(
    e => e.codigo === registro.cliente.codigo_familia
  );
  return (
    <Modal show onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <div>
          <span className="d-block">
            <strong>
              {registro.cliente.codigo_grupo_empresa_cliente}/
              {registro.cliente.codigo_conta_cliente}&nbsp;-&nbsp;
              {registro.cliente.razao_social}
            </strong>
          </span>
          {empreendimento && (
            <small className="d-block">
              Empreendimento:&nbsp;{empreendimento.descricao}
            </small>
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        <ContainerResumeProspect>
          <strong className="header-section">Totalizadores</strong>
          <LineResume>
            <ul>
              <li>
                <small>Títulos</small>
                <span>{registrosTitulos.length}</span>
              </li>
              <li>
                <small>Recebíveis</small>
                <span>
                  {formatarMonetario(obterTotalTitulosSinal(registrosTitulos))}
                </span>
              </li>
              {AuthStorage.isADM() && (
                <li>
                  <small>Com. Sup.</small>
                  <span>{valoresComissaoSupervisor(registros)}</span>
                  <span></span>
                </li>
              )}
              {!AuthStorage.isCorretor() && (
                <>
                  <li>
                    <small>Com. Imob.</small>
                    <span>
                      {formatarMonetario(
                        obterTotalComissao(registros, TIPO_COMISSAO_IMO)
                      )}
                    </span>
                  </li>
                  <li>
                    <small>Com. Mkt.</small>
                    <span>
                      {formatarMonetario(
                        obterTotalComissao(registros, TIPO_COMISSAO_MKT)
                      )}
                    </span>
                  </li>
                </>
              )}
              <li>
                <small>Com. Corr.</small>
                <span>{formatarMonetario(obterTotalComissao(registros))}</span>
              </li>
            </ul>
          </LineResume>

          <strong className="header-section">Dados de Contato</strong>
          <LineResume>
            <ul>
              <li>
                <small>Email</small>
                <span>
                  {cliente.email}
                  {cliente.email && (
                    <Button
                      className="ml-2 p-0"
                      variant="link"
                      title="Enviar email"
                      onClick={() => window.open('mailto:' + cliente.email)}
                    >
                      <IoMdMail size={22} />
                    </Button>
                  )}
                </span>
              </li>
              <li>
                <small>Tel. Residencial</small>
                <span>{format.phone(cliente.tel_residencial)}</span>
              </li>
              <li>
                <small>Tel. Comercial</small>
                <span>{format.phone(cliente.tel_comercial)}</span>
              </li>
              <li>
                <small>Tel. Celular</small>
                <span>
                  {format.celular(cliente.tel_celular)}
                  {cliente.tel_celular && cliente.tel_celular > 10 && (
                    <a
                      className="ml-2"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Enviar mensagem no WhatsApp"
                      href={`https://api.whatsapp.com/send?phone=55${cliente.tel_celular}`}
                    >
                      <IoLogoWhatsapp color="25d366" size={22} />
                    </a>
                  )}
                </span>
              </li>
              <li>
                <small>Cidade</small>
                <span>{cliente.cidade}</span>
              </li>
            </ul>
          </LineResume>
        </ContainerResumeProspect>
        <Accordion defaultActiveKey="0">
          <Table responsive hover>
            <thead>
              <tr>
                <th>Pré-venda</th>
                <th>Data</th>
                <th>Quadra</th>
                <th>Lote</th>
                <th>Ult. Cont.</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {pendenciasPorVenda.map((porVenda, index) => {
                const { preVenda, titulos } = porVenda;
                const jaEncaminhada = preVenda.status_encaminhamento === 1;
                const travarEmissaoBoleto = titulos.length > 1;
                return (
                  <React.Fragment key={preVenda.numero_pre_venda}>
                    <TableHeaderVenda>
                      <td>{preVenda.numero_pre_venda}</td>
                      <td>{format.date(preVenda.data_pre_venda)}</td>
                      <td>{preVenda.quadra}</td>
                      <td>{preVenda.lote}</td>
                      <td>
                        {preVenda.ultimo_contato_corretor
                          ? format.datetime(preVenda.ultimo_contato_corretor)
                          : '-'}
                      </td>
                      <td>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={'' + index + 1}
                          className="p-0"
                        >
                          Detalhes
                        </Accordion.Toggle>
                      </td>
                    </TableHeaderVenda>
                    <tr className="no-hover">
                      <td colSpan={6} style={{ padding: 0 }}>
                        <Accordion.Collapse
                          eventKey={'' + index + 1}
                          className="p-2"
                          style={{ backgroundColor: '#ebf5ff' }}
                        >
                          <>
                            <Table
                              size="sm"
                              responsive
                              borderless
                              hover={false}
                            >
                              <thead>
                                <tr className="no-hover">
                                  <th></th>
                                  <th></th>
                                  <th>Valor</th>
                                  <th>Vencimento</th>
                                  <th>Cobrador</th>
                                  <th>2ª Via</th>
                                </tr>
                              </thead>
                              <tbody>
                                {titulos.map(titulo => (
                                  <TableRowTitulo
                                    key={titulo.codigo_titulo_sinal}
                                  >
                                    <td>
                                      <IconeTipoTitulo
                                        tipo={titulo.tipo_titulo_sinal}
                                      />
                                    </td>
                                    <td>
                                      {titulo.parcela === 0
                                        ? '-'
                                        : titulo.parcela}
                                      /{titulo.plano === 0 ? '-' : titulo.plano}
                                    </td>
                                    <td>
                                      {formatarMonetario(
                                        titulo.valor_titulo_sinal
                                      )}
                                    </td>
                                    <td>
                                      {format.date(
                                        titulo.data_vencimento_titulo_sinal
                                      )}
                                    </td>
                                    <td>
                                      {titulo.apelido_conta_cobrador ||
                                        '(nenhum)'}
                                    </td>
                                    <td>
                                      {titulo.tipo_titulo_sinal ===
                                        TIPO_TITULO.BOLETO && (
                                        <div className="d-flex">
                                          <Button
                                            variant="outline-primary"
                                            title="Fazer download"
                                            size="sm"
                                            className="w-100"
                                            disabled={
                                              !!busy ||
                                              travarEmissaoBoleto ||
                                              jaEncaminhada
                                            }
                                            onClick={() =>
                                              emitirSegundaVia(
                                                titulo.codigo_titulo_sinal
                                              )
                                            }
                                          >
                                            <MdFileDownload />
                                          </Button>

                                          <Button
                                            variant="outline-primary"
                                            title="Enviar por e-mail"
                                            size="sm"
                                            className="w-100"
                                            disabled={
                                              !!busy ||
                                              travarEmissaoBoleto ||
                                              jaEncaminhada
                                            }
                                            onClick={() =>
                                              setDadosEnvioBoleto({
                                                email: cliente.email,
                                                codigoTitulo:
                                                  titulo.codigo_titulo_sinal,
                                              })
                                            }
                                          >
                                            <MdSend />
                                          </Button>
                                        </div>
                                      )}
                                    </td>
                                  </TableRowTitulo>
                                ))}
                              </tbody>
                            </Table>
                            {!jaEncaminhada && travarEmissaoBoleto && (
                              <small className="d-block text-danger my-2">
                                Emissão de boletos deste contrato bloqueada.
                                Cliente deve ser direcionado para o Depto. de
                                Adm. de Recebíveis (Cobrança).
                              </small>
                            )}
                            {jaEncaminhada && (
                              <small className="d-block text-danger my-2">
                                Contrato já encaminhado para o Depto. de Adm. de
                                Recebíveis (Cobrança).
                              </small>
                            )}
                            <AcoesVenda>
                              <Button
                                variant="outline-primary"
                                className="w-100"
                                disabled={!!busy}
                                onClick={() =>
                                  mostrarOcorrencias(
                                    conta,
                                    ge,
                                    preVenda.codigo_boleto
                                  )
                                }
                              >
                                {busy === 'ocorrencias'
                                  ? 'Aguarde...'
                                  : 'Ver Ocorrências'}
                              </Button>
                              <Button
                                variant="primary"
                                className="w-100"
                                disabled={!!busy || jaEncaminhada}
                                onClick={() => {
                                  setTipoAcaoOcorrencia(GRAVAR_OCORRENCIA);
                                  setNumeroPreVenda(preVenda.numero_pre_venda);
                                }}
                              >
                                Gravar Ocorrência
                              </Button>
                              {preVenda.codigo_conta_cobrador && (
                                <Button
                                  variant="warning"
                                  className="w-100"
                                  disabled={!!busy || jaEncaminhada}
                                  onClick={() => {
                                    setTipoAcaoOcorrencia(ENCAMINHAR_COBRANCA);
                                    setNumeroPreVenda(
                                      preVenda.numero_pre_venda
                                    );
                                  }}
                                >
                                  Encaminhar p/ Cobrança
                                </Button>
                              )}
                            </AcoesVenda>
                          </>
                        </Accordion.Collapse>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </Accordion>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
      <ModalOcorrenciasInadimplencias
        ocorrencias={ocorrencias}
        show={mostrandoOcorrencias}
        onHide={() => {
          setOcorrencias([]);
          setMostrandoOcorrencias(false);
        }}
      />
      <ModalGravarOcorrencia
        onHide={() => {
          setTipoAcaoOcorrencia(null);
          setNumeroPreVenda(null);
        }}
        type={tipoAcaoOcorrencia}
        onSubmit={gravarOcorrencia}
        isBusy={!!busy}
      />
      <ModalEnviarBoletoPorEmail
        show={!!dadosEnvioBoleto.codigoTitulo}
        emailInicial={dadosEnvioBoleto.email}
        onHide={() => setDadosEnvioBoleto({})}
        onSubmit={enviarBoletoPorEmail}
        isBusy={!!busy}
      />
    </Modal>
  );
};

export default ModalDetalhesClienteInadimplencias;
