import { combineReducers } from 'redux';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';

import { FETCH_MANAGERS_SUCCESS, FETCH_OPTIONS_SUCCESS } from '~/actionTypes';

const listaGerentes = (state = [], action) => {
  switch (action.type) {
    case FETCH_MANAGERS_SUCCESS:
      return action.payload;
    case FETCH_OPTIONS_SUCCESS:
      return action.payload.gerentes;
    default:
      return state;
  }
};

const gerentesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MANAGERS_SUCCESS:
      return action.payload.reduce((prev, curr) => {
        prev[curr.codigo] = curr;
        return prev;
      }, {});
    case FETCH_OPTIONS_SUCCESS:
      return action.payload.gerentes.reduce((prev, curr) => {
        prev[curr.codigo] = curr;
        return prev;
      }, {});
    default:
      return state;
  }
};

const listaCorretores = (state = [], action) => {
  switch (action.type) {
    case FETCH_MANAGERS_SUCCESS:
      return _flatten(_map(action.payload, 'corretores'));
    case FETCH_OPTIONS_SUCCESS:
      return _flatten(_map(action.payload.gerentes, 'corretores'));
    default:
      return state;
  }
};

const corretoresById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MANAGERS_SUCCESS:
      return _flatten(_map(action.payload, 'corretores')).reduce(
        (prev, curr) => {
          prev[curr.codigo] = curr;
          return prev;
        },
        {}
      );
    case FETCH_OPTIONS_SUCCESS:
      return _flatten(_map(action.payload.gerentes, 'corretores')).reduce(
        (prev, curr) => {
          prev[curr.codigo] = curr;
          return prev;
        },
        {}
      );
    default:
      return state;
  }
};

export default combineReducers({
  listaGerentes,
  gerentesById,
  listaCorretores,
  corretoresById,
});
