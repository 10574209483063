import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import qs from 'qs';

import AuthStorage from '~/Utils/AuthStorage';
import AutoLogin from '~/Components/Login/AutoLogin';
import Dashboard from '~/Components/Dashboard/Dashboard';

export default function PrivateRoute({
  component: Component,
  level,
  authorizationEvaluator,
  hasDashboard,
  ...rest
}) {
  const toRender = props => {
    if (level) {
      let isAuthorizated = level.reduce((result, currLevel) => {
        return result || currLevel === AuthStorage.getLevelLogged();
      }, false);
      if (!isAuthorizated) {
        return <Redirect to="/prospectos" />;
      }
    }
    //Recupera query strings da url
    let {
      auth,
      env,
      nome,
      usuario,
      gerente,
      hn,
      atendente_lancamento,
      ...search
    } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    if (auth && hn) {
      const from = {
        pathname: props.location.pathname,
        search: qs.stringify(search),
      };
      return <AutoLogin from={from} />;
    }

    if (authorizationEvaluator) {
      const isAuthorized = authorizationEvaluator();
      if (!isAuthorized) return <Redirect to="/prospectos" />;
    }

    if (AuthStorage.isLogged()) {
      if (hasDashboard) {
        return (
          <Dashboard>
            <Component {...props} />
          </Dashboard>
        );
      } else {
        return <Component {...props} />;
      }
    }

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  };
  return <Route {...rest} render={toRender} />;
}
