import React from 'react';
import { FaSortAmountDown } from 'react-icons/fa';

export default function PageOrderBy({ onChange, value }) {
  return (
    <div className="input-group input-group-sm">
      <div className="input-group-prepend pr-0">
        <label className="input-group-text bg-white" htmlFor="ordem">
          <FaSortAmountDown />
          <span className="sr-only">Ordem</span>
        </label>
      </div>
      <select
        className="form-control border-left-0"
        name="ordem"
        id="ordem"
        onChange={onChange}
        defaultValue={value}
      >
        <option value="cadastro">Últimos Cadastros</option>
        <option value="ocorrencia">Últimas Ocorrências</option>
        <option value="nomeAZ">Nome A-Z</option>
        <option value="nomeZA">Nome Z-A</option>
      </select>
    </div>
  );
}
