import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import PropTypes from 'prop-types';

const Loading = ({ label = 'Carregando...' }) => (
  <div className="Loading text-center mt-3">
    <FaSpinner className="spinner" /> {label}
  </div>
);
Loading.propTypes = {
  label: PropTypes.string,
};

export default Loading;
