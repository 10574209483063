import React, { useEffect, useState } from 'react';
import { Button, Badge, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as api from '~/Services/Service';
import { DescricoesTiposDocDigital } from '~/constant';
import { downloadFileViaUrl } from '~/Utils/Helpers';
import { verificarPodeVerAssinaturas } from '../helpers';
import { FaInfoCircle } from 'react-icons/fa';

const ResumoPreVendaDocumentoDigital = ({ documento }) => {
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [dados, setDados] = useState({});

  const downloadContrato = async () => {
    setIsDownloadingFile(true);
    try {
      const response = await api.getDownloadUrl(documento.codigo);
      downloadFileViaUrl(response);
    } catch (err) {
      console.error(err);
      toast.error(
        err.message || 'Falha ao buscar o link de download do documento digital'
      );
    } finally {
      setIsDownloadingFile(false);
    }
  };

  const {
    pre_venda: preVenda,
    tipo,
    descricao_status,
    usuario_solicitacao,
  } = documento;

  const podeVerAssinaturas = verificarPodeVerAssinaturas(documento);

  const cpfCliente = preVenda.cliente.cpf;

  const buscarDadosCliente = async () => {
    let response = await api.getPesquisaStatusSituacaoCliente(cpfCliente);
    setDados(response.dados_cliente);
  };

  const getSituacaoClienteColor = situacaoCliente => {
    switch (situacaoCliente) {
      case 'Inadimplente':
        return 'red';
      case 'Adimplente':
        return 'green';
      case 'Cliente Novo':
        return 'blue';
      default:
        return 'black';
    }
  };

  const situacaoClienteColor = getSituacaoClienteColor(dados.tipo);

  useEffect(() => {
    buscarDadosCliente();
  }, []);

  return (
    <section className="my-2">
      <h5>Pré-venda {preVenda ? preVenda.numero : '-'}</h5>
      <hr />
      {tipo === 1 && (
        <>
          <h5 className="d-flex align-items-center">
            Situação cliente:{' '}
            {dados ? (
              <strong
                style={{ marginLeft: '4px', color: situacaoClienteColor }}
              >
                {dados.tipo}
              </strong>
            ) : (
              '-'
            )}
            {dados && dados.tipo !== 'Cliente Novo' && (
              <small>
                <a
                  className="ml-2"
                  href={`/cliente/pesquisa/situacao/${cpfCliente}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInfoCircle />
                  <span className="d-none d-lg-inline">Detalhes</span>
                </a>
              </small>
            )}
          </h5>
          <hr />
        </>
      )}

      <div className="d-sm-flex justify-content-between">
        <div>
          <span className="d-block">
            Empreendimento: {preVenda ? preVenda.empreendimento.descricao : '-'}
          </span>
          <span className="d-block">
            <span>Quadra: {preVenda ? preVenda.imovel.quadra : '-'}</span>
            <span className="ml-1">
              Lote: {preVenda ? preVenda.imovel.lote : '-'}
            </span>
          </span>
        </div>
        <div className="align-self-start d-flex flex-column align-items-end">
          <Badge variant="dark" className="mb-1">
            {DescricoesTiposDocDigital[tipo]}
          </Badge>
          <Badge variant="secondary">{descricao_status}</Badge>
          <small className="d-block">
            {usuario_solicitacao
              ? `Solicitado por: ${usuario_solicitacao.nome}`
              : 'Nova solicitação'}
          </small>
          {podeVerAssinaturas && (
            <Button
              variant="info"
              onClick={e => downloadContrato()}
              disabled={isDownloadingFile}
            >
              {isDownloadingFile && (
                <Spinner animation="border" className="mr-2" size="sm" />
              )}
              Ver Assinaturas
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default ResumoPreVendaDocumentoDigital;
