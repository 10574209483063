import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import reducer from './reducer'
import thunk from 'redux-thunk'

const configureStore = () => {
    const middlewares = [thunk]
    if (process.env.NODE_ENV !== 'production') {
        middlewares.push(createLogger())
    }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    return createStore(reducer,
        composeEnhancers(
            applyMiddleware(...middlewares)
        )
    )
}

export default configureStore