import React from 'react';
import {
  FaMoneyCheckAlt,
  FaReceipt,
  FaRegCreditCard,
  FaMoneyBill,
  FaMoneyCheck,
  FaBarcode,
} from 'react-icons/fa';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { TIPO_TITULO } from '~/constant';

export const getNomeTipoTitulo = tipo => {
  switch (tipo) {
    case TIPO_TITULO.CARTAO:
      return 'Cartão';
    case TIPO_TITULO.BOLETO:
      return 'Boleto';
    case TIPO_TITULO.CHEQUE:
      return 'Cheque';
    case TIPO_TITULO.DEPOSITO:
      return 'Depósito';
    case TIPO_TITULO.DINHEIRO:
      return 'Dinheiro';
    case TIPO_TITULO.DUPLICATA:
      return 'Duplicata';
    default:
      return 'Desconhecido';
  }
};

export const getIconeTipoTitulo = tipo => {
  switch (tipo) {
    case TIPO_TITULO.CARTAO:
      return <FaRegCreditCard />;
    case TIPO_TITULO.BOLETO:
      return <FaBarcode />;
    case TIPO_TITULO.CHEQUE:
      return <FaMoneyCheckAlt />;
    case TIPO_TITULO.DEPOSITO:
      return <FaReceipt />;
    case TIPO_TITULO.DINHEIRO:
      return <FaMoneyBill />;
    case TIPO_TITULO.DUPLICATA:
      return <FaMoneyCheck />;
    default:
      return <FaMoneyBill />;
  }
};

const IconeTipoTitulo = ({ tipo }) => (
  <OverlayTrigger
    placement="right"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip>{getNomeTipoTitulo(tipo) || ''}</Tooltip>}
  >
    {getIconeTipoTitulo(tipo) || ''}
  </OverlayTrigger>
);

export default IconeTipoTitulo;
