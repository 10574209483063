import $ from 'jquery';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * classe criada para gerar um thumb (imagem base64) a partir de um PDF
 */
const PDFThumb = {
  create: async pdfPath => {
    let uuid = new Date().getTime();
    let pdfDocument = await pdfjs.getDocument(pdfPath).promise;
    let idCanvas = `pdf-canvas-${uuid}`;
    $('body').append(
      `<canvas id="${idCanvas}" width="400" style="display:none"></canvas>`
    );

    try {
      const pdfPage = await pdfDocument.getPage(1);
      let viewport = pdfPage.getViewport({ scale: 1.0 });
      let canvas = document.getElementById(idCanvas);
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      let ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      await pdfPage.render({
        canvasContext: ctx,
        viewport: viewport,
      }).promise;

      $(`#${idCanvas}`).remove();
      return canvas.toDataURL();
    } catch (reason) {
      console.error(`Error PDFThumb: ${reason}`);
      return null;
    }
  },
};

export default PDFThumb;
