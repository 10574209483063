import React, { Component, Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { FaCloudUploadAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

import Loading from './Loading';
import {
  MAX_SIZE_DOCUMENTO,
  TIPOS_UPLOAD_DOCUMENTO_PROSPECTO,
  TIPO_UPLOAD_PDF,
} from '../constant';
import buildPDFFromImage from '../Utils/ImageToPdf';

export default class UploadDocumento extends Component {
  static propTypes = {
    documento: PropTypes.object.isRequired,
  };
  state = {
    isError: false,
  };

  toggleError = action => {
    if (action) {
      this.setState({
        isError: true,
      });

      setTimeout(() => {
        this.toggleError(false);
      }, 2000);

      return;
    }

    this.setState({
      isError: false,
    });
  };

  onDropAccepted = acceptedFiles => {
    let file = acceptedFiles[0];
    if (file.size > MAX_SIZE_DOCUMENTO && file.type === TIPO_UPLOAD_PDF) {
      this.toggleError(file);
      return;
    }
    if (file.type === TIPO_UPLOAD_PDF) {
      this.props.onSelectImage(file);
    } else {
      buildPDFFromImage(file).then(pdf => {
        this.props.onSelectImage(pdf);
      });
    }
  };

  render() {
    const { isError } = this.state;
    const { documento } = this.props;
    return (
      <Dropzone
        multiple={false}
        accept={TIPOS_UPLOAD_DOCUMENTO_PROSPECTO}
        onClick={e => {
          if (documento.isUploading) {
            e.preventDefault();
          }
        }}
        onDropRejected={this.toggleError}
        onDropAccepted={this.onDropAccepted}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          return (
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive && 'dropzone--is-active'}`}
            >
              <input {...getInputProps()} capture />
              <div className="dropzone__label">
                <div className="dropzone__label__wrapper">
                  <div className="dropzone__label__legend">
                    <strong>{documento.descricao}</strong>
                    {(isDragReject || isError) && (
                      <span className="text-danger">
                        Formato ou tamanho do arquivo incorreto
                      </span>
                    )}
                    {documento.error && (
                      <span className="text-danger">{documento.error}</span>
                    )}

                    {documento.isUploading ? (
                      <Loading label="Enviando documento..." />
                    ) : (
                      !isDragReject &&
                      !isError && (
                        <Fragment>
                          {isDragActive ? (
                            <span>Solte o arquivo aqui fazer o upload</span>
                          ) : (
                            <span>
                              Clique para selecionar um arquivo ou arraste para
                              fazer o upload
                            </span>
                          )}
                        </Fragment>
                      )
                    )}
                  </div>
                  <FaCloudUploadAlt className="dropzone__label__icon" />
                </div>
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}
