import React, { Component } from 'react';
import {
  Card,
  Col,
  Row,
  Button,
  Modal,
  Table,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FiAlertCircle } from 'react-icons/fi';

import { format } from '~/Utils/Helpers';
import { default as auth } from '~/Utils/AuthStorage';

class CardComissoesMensais extends Component {
  constructor() {
    super();
    this.state = {
      detalhesDoMes: {},
      mostrarModal: false,
    };
  }

  formatarReal = valor =>
    valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });
  render() {
    const { resumo, carregandoComissoes } = this.props;
    const { detalhesDoMes, mostrarModal } = this.state;
    const isCorretor = auth.isCorretor();
    if (!resumo || carregandoComissoes) return null;
    return (
      <>
        <Col>
          <Row className="CardProdutividadeResumo mt-0">
            <Col xs={12}>
              <h5 className="text-center text-primary">
                Projeção de comissões em aberto
              </h5>
              <hr />
            </Col>
            {resumo.resumo_mensal.map((item, index) => (
              <Col xs={12} md={6} xl={4} key={index}>
                <Card
                  className={`comissoes ${
                    item.parcelas_adimplentes ? 'top arrow-top-right ' : ''
                  } ${
                    item.parcelas_inadimplentes
                      ? 'bottom arrow-bottom-left '
                      : ''
                  } `}
                >
                  <Card.Body>
                    <ul className="produtividade produtividade-resumo">
                      <ul className="ul-inline">
                        <li className="col-md-4 pl-4 text-md-left">
                          <small>Mês</small>
                          <strong className="d-block">{item.mes}</strong>
                        </li>
                        <li className="col-md-2 px-0 text-md-left">
                          <small>Ano</small>
                          <strong className="d-block">{item.ano}</strong>
                        </li>
                        <li className="col-md-3 px-0 text-md-left">
                          <small>Total Comissão</small>
                          <strong className="d-block">
                            <span>R$ </span>
                            {this.formatarReal(item.valor_total_comissao)}
                          </strong>
                        </li>
                        <li className="my-auto px-0 col-md-3">
                          <Button
                            variant="btn btn-link"
                            onClick={() =>
                              this.setState({
                                detalhesDoMes: item,
                                mostrarModal: true,
                              })
                            }
                          >
                            Detalhar
                          </Button>
                        </li>
                      </ul>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>

        {detalhesDoMes && (
          <Modal
            size="lg"
            show={mostrarModal}
            onHide={() =>
              this.setState({
                detalhesDoMes: {},
                mostrarModal: false,
              })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Detalhes da projeção de comissões em aberto
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Col>
                <Row className="CardProdutividadeResumo mt-0">
                  <Col>
                    <Card>
                      <Card.Body>
                        <ul className="produtividade produtividade-resumo">
                          <ul className="ul-inline">
                            <li>
                              <small>Mês</small>
                              <strong className="d-block">
                                {detalhesDoMes.mes}
                              </strong>
                            </li>
                            <li>
                              <small>Ano</small>
                              <strong className="d-block">
                                {detalhesDoMes.ano}
                              </strong>
                            </li>
                            <li>
                              <small>
                                Valor total da comissão
                                <OverlayTrigger
                                  trigger={['focus', 'hover']}
                                  placement={'right'}
                                  overlay={
                                    <Popover id="popover-basic">
                                      <Popover.Title as="h3">
                                        AVISO LEGAL
                                      </Popover.Title>
                                      <Popover.Content className="text-danger">
                                        Valores atrelados ao efetivo pagamento
                                        do sinal pelo adquirente
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <FiAlertCircle
                                    color="#f00"
                                    size={18}
                                    className="ml-2"
                                  />
                                </OverlayTrigger>
                              </small>
                              <strong className="d-block">
                                <span>R$ </span>
                                {detalhesDoMes.valor_total_comissao &&
                                  this.formatarReal(
                                    detalhesDoMes.valor_total_comissao
                                  )}
                              </strong>
                            </li>
                          </ul>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>

              {detalhesDoMes.detalhes_comissao_mensal && (
                <Table responsive striped bordered hover size="xl">
                  <thead>
                    <tr className="text-center">
                      {!isCorretor && <th>Corretor</th>}
                      <th>Comissionado</th>
                      <th>#PV</th>
                      <th>Empreendimento</th>
                      <th>QD</th>
                      <th>LT</th>
                      <th>Dt. Venda</th>
                      <th>Comissão</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detalhesDoMes.detalhes_comissao_mensal.map(
                      (item, index) => (
                        <tr key={index}>
                          {!isCorretor && (
                            <td>{item.apelido_conta_corretor}</td>
                          )}
                          <td>{item.apelido_conta_usuario_comissao}</td>
                          <td>{item.codigo_pre_venda}</td>
                          <td>{item.empreendimento}</td>
                          <td className="text-center">{item.quadra}</td>
                          <td className="text-center">{item.lote}</td>
                          <td className="text-center">
                            {format.date(item.data_venda, 'DD/MM/YYYY')}
                          </td>
                          <td
                            className={`text-center text-nowrap
                          text-${item.inadimplente ? 'warning' : 'success'}`}
                          >
                            <small>R$ </small>
                            {this.formatarReal(item.valor_comissao)}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({
                    detalhesDoMes: {},
                    mostrarModal: false,
                  })
                }
              >
                Fechar
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  resumo: state.comissoes.dados,
  carregandoComissoes: state.comissoes.carregandoComissoes,
});

export default withRouter(connect(mapStateToProps)(CardComissoesMensais));
