import {
  StatusDocumentoDigital,
  TIPO_PESSOA_FISICA,
  TiposDocDigital,
  TiposGruposDocumentoDigital,
} from '~/constant';
import AuthStorage from '~/Utils/AuthStorage';

export const verificarPodeGerenciar = documentoDigital => {
  const user = AuthStorage.getUser();

  return (
    documentoDigital.codigos_usuarios_aprovadores.includes(user.usuario) ||
    AuthStorage.temCaracteristicaGerContratoDigital()
  );
};

export const verificarPodeVerAssinaturas = documentoDigital => {
  if (!documentoDigital) return false;
  return (
    isStatusPosUpload(documentoDigital) &&
    (documentoDigital.codigo_usuario_solicitacao ===
      AuthStorage.getUser().usuario ||
      verificarPodeGerenciar(documentoDigital))
  );
};

export const isStatusPosUpload = documentoDigital => {
  if (!documentoDigital) return false;
  const status = documentoDigital.status || 0;
  return (
    status === StatusDocumentoDigital.Uploaded ||
    status === StatusDocumentoDigital.PendenteAssinaturasClientes ||
    status === StatusDocumentoDigital.PendenteAssinaturasSetpar ||
    status === StatusDocumentoDigital.Finalizado
  );
};

export const isStatusPreAprovacao = documentoDigital => {
  if (!documentoDigital) return true;
  const status = documentoDigital.status || 0;
  return (
    status === StatusDocumentoDigital.NovaSolicitacao ||
    status === StatusDocumentoDigital.Solicitado ||
    status === StatusDocumentoDigital.Reprovado
  );
};

export const isStatusPreUpload = documentoDigital => {
  const { status } = documentoDigital;
  return (
    status === StatusDocumentoDigital.Solicitado ||
    status === StatusDocumentoDigital.Aprovado
  );
};

export const isReadOnly = documentoDigital => {
  if (!documentoDigital) return false;

  const status =
    documentoDigital.status || StatusDocumentoDigital.NovaSolicitacao;
  if (status === StatusDocumentoDigital.NovaSolicitacao) return false;
  if (status === StatusDocumentoDigital.Reprovado) {
    const user = AuthStorage.getUser();
    return documentoDigital.codigo_usuario_solicitacao !== user.usuario;
  }
  return !verificarPodeGerenciar(documentoDigital);
};

export const verificarBloqueioContratoSemConjuge = documentoDigital => {
  const { pre_venda, tipo } = documentoDigital;
  const temPermissaoContratoSemConjuge =
    pre_venda.contrato_sem_conjuge == null || pre_venda.contrato_sem_conjuge;
  return (
    tipo === TiposDocDigital.ContratoVenda && !temPermissaoContratoSemConjuge
  );
};
export const verificarPermissaoExibicaoContratoVendaPJ = documentoDigital => {
  const { pre_venda } = documentoDigital;
  return (
    pre_venda.cliente.tipo_pessoa === TIPO_PESSOA_FISICA.value ||
    (pre_venda.proposta_rapida && pre_venda.proposta_rapida.fase > 3) ||
    AuthStorage.isADM()
  );
};

export function emailsDistintos(documentoDigital) {
  const isValid = documentoDigital.signatarios.every(
    s =>
      documentoDigital.signatarios.filter(o => o.email === s.email).length === 1
  );
  return isValid;
}

export function dadosValidos(documentoDigital, tipoGrupo, minLength, needsCpf) {
  if (!documentoDigital) return false;
  const signatarios = signatariosPorGrupo(documentoDigital, tipoGrupo);
  return (
    signatarios.filter(el => el.signatario).length >= minLength &&
    signatarios
      .filter(el => el.signatario)
      .every(el => (!needsCpf || el.cpf) && el.email)
  );
}
function signatariosPorGrupo(documentoDigital, tipoGrupo) {
  if (!documentoDigital) return [];
  const grupo = documentoDigital.grupos.find(g => g.tipo === tipoGrupo);
  if (!grupo) return [];
  return documentoDigital.signatarios.filter(
    s => s.codigo_documento_digital_grupo === grupo.codigo
  );
}

export function verificarPodeSolicitar(
  documentoDigital,
  incluirTestemunhas,
  incluirProcuradores
) {
  if (!documentoDigital) return false;
  const compradoresValidos = dadosValidos(
    documentoDigital,
    TiposGruposDocumentoDigital.Compradores,
    1,
    true
  );
  console.debug('[Validando] Compradores:', compradoresValidos);
  const testemunhasValidas =
    !incluirTestemunhas ||
    dadosValidos(
      documentoDigital,
      TiposGruposDocumentoDigital.Testemunhas,
      2,
      true
    );
  console.debug('[Validando] Testemunhas:', testemunhasValidas);
  const procuradoresValidos =
    !incluirProcuradores ||
    dadosValidos(
      documentoDigital,
      TiposGruposDocumentoDigital.Procuradores,
      1,
      true
    );
  console.debug('[Validando] Procuradores:', procuradoresValidos);
  const validadoresValidos =
    documentoDigital.codigos_usuarios_aprovadores.includes(
      AuthStorage.getUser().usuario
    ) ||
    documentoDigital.tipo !== TiposDocDigital.ContratoVenda ||
    dadosValidos(
      documentoDigital,
      TiposGruposDocumentoDigital.Validadores,
      1,
      false
    );
  console.debug('[Validando] Validadores:', validadoresValidos);
  // const intervenientesValidos =
  //   documentoDigital.tipo === TiposDocDigital.ContratoVenda ||
  //   dadosValidos(
  //     documentoDigital,
  //     TiposGruposDocumentoDigital.Intervenientes,
  //     1,
  //     false
  //   );
  // console.debug('[Validando] Intervenientes:', intervenientesValidos);
  return (
    compradoresValidos &&
    testemunhasValidas &&
    procuradoresValidos &&
    validadoresValidos && // nossa...
    // intervenientesValidos &&
    emailsDistintos(documentoDigital) &&
    !verificarBloqueioContratoSemConjuge(documentoDigital) &&
    verificarPermissaoExibicaoContratoVendaPJ(documentoDigital)
  );
}
