import React from 'react';

const MensagemOmissaoGrupo = ({ omitir }) => {
  if (omitir)
    return (
      <p className="text-muted">
        Solicitante optou por não utilizar este grupo para este documento
      </p>
    );
  return null;
};

export default MensagemOmissaoGrupo;
