import React from 'react';
import { Document, Page } from 'react-pdf';

const TestPage = () => {
  const url = 'https://www.revobrasil.com.br/Extranet/PDF/Contrato141386.pdf';
  return (
    <div>
      <h5>Whatever the heck</h5>
      <Document
        className="mt-2"
        loading="Carregando arquivo..."
        error={
          <span>
            Falha ao carregar arquivo.{' '}
            <a href={url} target="_blank" rel="noreferrer noopener">
              Clique aqui para visualizar o arquivo
            </a>
          </span>
        }
        file={url}
      >
        <Page pageNumber={1} />
      </Document>
    </div>
  );
};

export default TestPage;
