import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import ModalNotificacao from './ModalNotificacao';

export default function App({ children }) {
  return (
    <div className="App">
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      <ModalNotificacao />
      {children}
    </div>
  );
}
