import React from 'react';
import { Link } from 'react-router-dom';
import { FaTrashAlt } from 'react-icons/fa';
import { urlAdmVendas } from '~/constant';

const VendaExcluida = ({ codigoProposta }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <FaTrashAlt color="#DC3545" fontSize={54} />
      <h4>Proposta #{codigoProposta} excluída</h4>

      <div className="col d-flex flex-column flex-sm-row justify-content-around m-4 pt-4">
        <Link to="/vendas/autorizacao">
          Voltar para lista de autorização de propostas
        </Link>
        <a href={urlAdmVendas}>Ir para a Administração de Vendas</a>
      </div>
    </div>
  );
};

export default VendaExcluida;
