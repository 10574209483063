import {
  PRODUTIVIDADE_CARREGADA,
  CARREGANDO_PRODUTIVIDADE,
  ATUALIZA_RESUMO,
  CARREGANDO_PRODUTIVIDADE_POR_CORRETOR,
  PRODUTIVIDADE_POR_CORRETOR_CARREGADA,
  CARREGANDO_PRODUTIVIDADE_POR_EMPREENDIMENTO,
  PRODUTIVIDADE_POR_EMPREENDIMENTO_CARREGADA,
  SELECIONA_IMOBILIARIA,
  SELECIONA_CORRETOR,
  SELECIONA_EMPREENDIMENTO_PRODUTIVIDADE,
} from '~/Store/Produtividade/reducer';
import * as api from '../../Services/Service';

export const carregaProdutividade = params => async dispatch => {
  dispatch({ type: CARREGANDO_PRODUTIVIDADE });
  let produtividade = await api.consultarProdutividade(params);
  dispatch({
    type: PRODUTIVIDADE_CARREGADA,
    payload: {
      produtividade,
      filtro: params,
    },
  });
};

export const carregaProdutividadePorCorretor = corretor => async (
  dispatch,
  getState
) => {
  const bodyParams = getState().produtividade.filtroSelecionado;
  const imobiliaria = getState().produtividade.imobiliariaSelecionada.codigo;

  let corretorCarregado = getState().produtividade.corretoresCarregados[
    corretor
  ];
  if (!corretorCarregado) {
    dispatch({ type: CARREGANDO_PRODUTIVIDADE_POR_CORRETOR });
    corretorCarregado = await api.consultarProdutividadePorCorretor(
      bodyParams,
      imobiliaria,
      corretor
    );
    dispatch({
      type: PRODUTIVIDADE_POR_CORRETOR_CARREGADA,
      payload: corretorCarregado,
    });
  }

  dispatch(selecionaCorretor(corretorCarregado));
};

export const carregaProdutividadePorEmpreendimento = empreendimento => async (
  dispatch,
  getState
) => {
  let bodyParams = getState().produtividade.filtroSelecionado;
  const CodigoImobiliaria = getState().produtividade.imobiliariaSelecionada
    .codigo;

  let empreendimentoCarregado = getState().produtividade
    .empreendimentosCarregados[empreendimento];

  if (!empreendimentoCarregado) {
    dispatch({ type: CARREGANDO_PRODUTIVIDADE_POR_EMPREENDIMENTO });
    empreendimentoCarregado = await api.consultarProdutividadePorEmprendimento(
      { ...bodyParams, CodigoImobiliaria },
      empreendimento
    );
    dispatch({
      type: PRODUTIVIDADE_POR_EMPREENDIMENTO_CARREGADA,
      payload: empreendimentoCarregado,
    });
  }

  dispatch(selecionaEmpreendimento(empreendimentoCarregado));
};

export const selecionaImobiliaria = imobiliaria => (dispatch, getState) => {
  dispatch({
    type: SELECIONA_IMOBILIARIA,
    payload: {
      imobiliaria,
    },
  });

  let resumo = imobiliaria || getState().produtividade.resumoCarregado;
  dispatch(atualizaResumo(resumo));
};

export const selecionaCorretor = corretor => (dispatch, getState) => {
  dispatch({
    type: SELECIONA_CORRETOR,
    payload: corretor,
  });

  let resumo = corretor;
  if (!corretor) {
    let imobiliaria = getState().produtividade.imobiliariaSelecionada;
    if (imobiliaria) {
      resumo = imobiliaria;
    } else {
      resumo = getState().produtividade.resumoCarregado;
    }
  }
  dispatch(atualizaResumo(resumo));
};

export const selecionaEmpreendimento = empreendimento => (
  dispatch,
  getState
) => {
  dispatch({
    type: SELECIONA_EMPREENDIMENTO_PRODUTIVIDADE,
    payload: empreendimento,
  });

  let resumo = empreendimento;
  if (!empreendimento) {
    let imobiliaria = getState().produtividade.imobiliariaSelecionada;
    if (imobiliaria) {
      resumo = imobiliaria;
    } else {
      resumo = getState().produtividade.resumoCarregado;
    }
  }
  dispatch(atualizaResumo(resumo));
};

export const atualizaResumo = resumo => ({
  type: ATUALIZA_RESUMO,
  payload: resumo,
});
