import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navbar from '../Navbar/Navbar';
import { buscarOpcoes } from '~/Store/Options/actions';
import { doLogout } from '../Login/AuthActions';
import AuthStorage from '~/Utils/AuthStorage';

import {
  EnvironmentTag,
  DashboardContainer,
  MainContent,
  Content,
} from './styles';

const environmentEnum = {
  dev: {
    label: 'desenvolvimento',
    style: 'info',
  },
  hml: {
    label: 'treinamento',
    style: 'warning',
  },
};

class Dashboard extends Component {
  state = {
    sidebarIsOpen: true,
  };

  componentDidMount() {
    this.props.buscarOpcoes();
  }

  render() {
    const { children, env, user, doLogout } = this.props;

    const environment = environmentEnum[env];

    return (
      <DashboardContainer>
        {environment && (
          <EnvironmentTag variant={environment.style}>
            {environment.label}
          </EnvironmentTag>
        )}

        {user && <Navbar onLogout={doLogout} user={user} />}
        <Content>
          <MainContent>{children}</MainContent>
        </Content>
      </DashboardContainer>
    );
  }
}

const mapStateToProps = state => ({
  env: state.auth.user ? AuthStorage.getEnv() : null,
  user: {
    ...state.auth.user,
    nome: state.auth.user && state.auth.user.nome ? state.auth.user.nome.split(' ')[0] : '',
  },
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ buscarOpcoes, doLogout }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
