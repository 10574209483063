import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import MoneyField from '~/Components/Form/MoneyField';

import GeradorData from '~/Components/GeradorData';
import { DINHEIRO, MASK_DATE } from '~/constant';

export default function Dinheiro() {
  const { values, setFieldValue } = useFormikContext();
  if (values.tipo_parcela !== DINHEIRO) return null;
  return (
    <Row>
      <Col md="6">
        <Form.Group>
          <Form.Label>Valor</Form.Label>
          <Field
            name={`${DINHEIRO}.valor`}
            className="form-control"
            type="tel"
            placeholder="Ex.: 1.000,00"
            component={MoneyField}
          />
          <ErrorMessage
            name={`${DINHEIRO}.valor`}
            className="text-danger"
            component="small"
          />
        </Form.Group>
      </Col>
      <Col md="6">
        <Form.Group>
          <Form.Label>Vencimento</Form.Label>
          <Field
            as={MaskedInput}
            mask={MASK_DATE}
            name={`${DINHEIRO}.vencimento_mask`}
            className="form-control"
            type="tel"
            placeholder="Ex.: 10/10/2020"
          />
          <ErrorMessage
            name={`${DINHEIRO}.vencimento_mask`}
            className="text-danger"
            component="small"
          />
          <GeradorData
            original={values[DINHEIRO].vencimento_mask}
            onUpdate={date => setFieldValue(`${DINHEIRO}.vencimento`, date)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
