import React, { Component } from 'react';
import { render } from 'react-dom';
import { Button, Modal } from 'react-bootstrap';

//veja o componente Confirm
//TODO^: criar um componente DIALOG único

class MessageModal extends Component {
  static create(props = {}) {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<MessageModal />, containerElement);
  }

  constructor() {
    super();

    this.state = {
      isOpen: false,
      dialogProps: {},
    };

    this.handleOk = this.handleOk.bind(this);
    this.show = this.show.bind(this);
  }

  handleOk() {
    this.setState({ isOpen: false });
  }

  show(props = {}) {
    this.setState({ isOpen: true, dialogProps: props });
  }

  render() {
    const { isOpen, dialogProps } = this.state;
    const { message, title, buttonLabel, buttonVariant, body } = dialogProps;
    return (
      <Modal show={isOpen} onHide={this.handleOk}>
        <Modal.Header closeButton>
          <Modal.Title>{title || 'Mensagem'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body ? body : <p>{message}</p>}</Modal.Body>
        <Modal.Footer>
          <Button variant={buttonVariant || 'primary'} onClick={this.handleOk}>
            {buttonLabel || 'OK'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MessageModal;
