import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

const primaryColor = '#007bff';

export const Avatar = styled.div`
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ccc;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;

export const DropdownEtapas = styled.div`
  margin: 5px 0;
  padding: 10px 15px;
  .dropdown {
    button.dropdown-toggle {
      width: 100%;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
      border-color: #ccc;
      color: #333;
      strong {
        font-weight: 500;
      }
    }
    button.dropdown-item {
      span {
        font-weight: 500;
      }
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const ContainerEtapasProspectos = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    background: #fff;
    position: relative;
    z-index: 15;
    border-top: 1px solid #eee;
    border-bottom: 2px solid #eee;
  }
`;

export const EtapasProspectos = styled.ul`
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: #fff;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
  }

  li {
    flex: auto;
    position: relative;
    button {
      outline-width: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 0;
      background: 0;
      padding: 10px;
      line-height: 1;
      text-align: left;
      strong {
        font-size: 19px;
      }
      small {
        font-size: 10px;
      }

      @media (min-width: 992px) {
        strong {
          font-size: 28px;
        }
        small {
          font-size: 13px;
        }
      }
    }
    svg {
      position: absolute;
      right: 0;
      font-size: 40px;
      opacity: 0.1;
      top: calc(50% - calc(20px));
    }
    &:last-child {
      svg {
        display: none;
      }
    }
  }
`;

export const ContainerSelectGerenteCorretor = styled.div`
  padding: 0 15px;
  position: relative;
  z-index: 40;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const ContainerGerenteProspectoDetalhe = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  /* z-index: 14; */
`;

export const VerticalGerenteCorretor = styled.div`
  display: none;
  flex-direction: row;

  @media (min-width: 768px) {
    display: flex;
  }
`;
export const BtnExpandirCorretores = styled.button.attrs({
  type: 'button',
})`
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;

  &:hover {
    color: ${primaryColor};
    svg {
      color: ${primaryColor};
    }
  }
`;

export const BtnVerTodos = styled.button.attrs({
  type: 'button',
})`
  svg {
    font-size: 30px;
  }
`;

export const VerticalCorretor = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px 5px;
  position: relative;
  z-index: 12;
  min-height: calc(100vh - 174px);
  height: auto;
  box-shadow: 2px 0 15px rgba(0, 0, 0, 0.1);
`;

export const VerticalGerente = styled(VerticalCorretor)`
  z-index: 13;
  ${BtnVerTodos} {
    text-align: center;
    flex-direction: column;
    font-size: 12px;
    span {
      margin: 0;
    }
  }
`;

export const ItemGerente = styled.li`
  display: flex;
  margin: 0 0 10px;
  button {
    border: 0;
    background: #fff;
    display: flex;
    align-items: center;
    width: 100%;

    &,
    &:focus {
      outline-width: 0;
    }

    ${Avatar} {
      border: 1px solid transparent;
      border-color: ${props => (props.isSelected ? primaryColor : '#ccc')};
      border-width: ${props => (props.isSelected ? 2 : 1)}px;
    }

    span {
      margin-left: 5px;
    }

    &:hover {
      ${Avatar} {
        border-color: ${primaryColor};
      }
    }
  }
`;

export const ContainerListProspecto = styled.div`
  position: relative;
`;

export const ListaProspecto = styled.div`
  max-width: 420px;
  width: 100%;
  position: relative;
`;

export const Scroll = styled(PerfectScrollbar)`
  // max-height: calc(100vh - 174px);
`;

export const ScrollListaProspecto = styled(Scroll)`
  padding: 5px 15px 0px;

  @media (max-width: 349px) {
    max-height: 90vh;
  }

  @media (min-width: 350px) and (max-width: 359px) {
    max-height: 90vh;
  }

  @media (max-width: 330px) {
    max-height: 100vh;
  }

  @media (min-width: 360px) and (max-width: 375px) {
    max-height: 85vh;
  }

  @media (min-width: 376px) and (max-width: 400px) {
    max-height: 79vh;
  }
  @media (min-width: 401px) and (max-width: 430px) {
    max-height: 70vh;
  }
  @media (min-width: 430px) and (max-width: 447px) {
    max-height: 65vh;
  }
`;

export const ScrollProspectoAtivo = styled(Scroll)`
  && {
    height: auto;
    flex: 1;
    width: 100%;
    z-index: 1030;
    position: fixed;
    top: 0;
    background: #fff;
    padding: 15px;
    min-height: 100%;
    padding-top: 100px;
    @media (min-width: 992px) {
      z-index: 1010;
    }

    header {
      line-height: 1;
      position: fixed;
      top: 0;
      z-index: 1011;
      background: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      width: 100%;
      left: 0;
      padding: 20px 30px 20px 15px;
    }

    .fecharProspectoAtivoMobile {
      background: transparent;
      border: 0;
      position: absolute;
      right: 0;
      line-height: 1;
      top: 0;
      font-size: 2rem;
    }

    @media (min-width: 992px) {
      position: relative;
      z-index: 15;
      top: auto;
      min-height: inherit;
      padding-top: 0;

      header {
        position: relative;
        top: auto;
        box-shadow: none;
        left: auto;
        padding: 20px 0;
      }

      .fecharProspectoAtivoMobile {
        display: none;
      }
    }
  }
`;

export const ProspectoAtivo = styled.div`
  width: 100%;
`;

export const BtnEditProspect = styled.button.attrs({
  type: 'button',
})`
  padding: 0;
  color: ${primaryColor};
  border: 0;
  background: transparent;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 5px;
  }
`;

export const HeaderListaProspecto = styled.div`
  padding: 10px 15px;
`;
