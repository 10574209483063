import { combineReducers } from 'redux';
import {
  ALL_PROSPECTS_FETCH,
  FETCHING_PROSPECTS,
  FETCH_ERROR_PROSPECTS,
  UPDATE_FILTER,
  CLEAR_FILTER_PROSPECT,
  FETCH_OPTIONS_SUCCESS,
  FETCHING_OPTIONS,
  FETCH_ERROR_OPTIONS,
  FETCHING_PROSPECT_DETAILS,
  FETCH_PROSPECT_DETAILS_ERROR,
  FETCH_PROSPECT_DETAILS_SUCCESS,
  UPDATE_PROSPECT,
  INICIAR_NOVO_PROSPECTO,
  ATUALIZA_DOCUMENTO,
  UPLOADING_DOCUMENTO,
  UPLOAD_DOCUMENTO_SUCCESS,
  UPLOAD_DOCUMENTO_ERROR,
  CREATE_PROSPECT_ERROR,
  CREATE_PROSPECT_SUCCESS,
  CREATING_PROSPECT,
  CREATE_PROSPECT_SUCCESS_CLOSE,
  UPDATING_PROSPECT,
  UPDATE_PROSPECT_SUCCESS,
  UPDATE_PROSPECT_ERROR,
  UPDATE_PROSPECT_SUCCESS_CLOSE,
  INICIAR_ADICIONAR_SOCIO,
  CONSULTANDO_CPF,
  CONSULTA_CPF_SUCESSO,
  CONSULTA_CPF_ERRO,
  CONSULTANDO_EMAIL,
  CONSULTA_EMAIL_SUCESSO,
  CONSULTA_EMAIL_ERRO,
  LIMPA_PROSPECTO_ENCAMINHAR,
  VINCULANDO_EMPREENDIMENTO,
  EMPRENDIMENTO_VINCULADO,
  EMPREENDIMENTO_VINCULADO_ERROR,
  BUSCANDO_CEP,
  BUSCA_CEP_SUCESSO,
  BUSCA_CEP_ERRO,
  SELECIONA_EMPREENDIMENTO,
  CANCELAR_ADICIONAR_SOCIO,
  SALVANDO_SOCIO,
  SALVAR_SOCIO_SUCESSO,
  SALVAR_SOCIO_ERRO,
  SALVAR_SOCIO_SUCESSO_FECHAR,
  SELECIONA_SOCIO,
  SALVANDO_PESQUISA,
  SALVAR_PESQUISA_SUCESSO,
  SALVAR_PESQUISA_ERRO,
  SALVAR_PESQUISA_SUCESSO_FECHAR,
  TOGGLE_MODAL_OCORRENCIA,
  SALVANDO_OCORRENCIA,
  SALVAR_OCORRENCIA_SUCESSO,
  SALVAR_OCORRENCIA_ERRO,
  SALVAR_OCORRENCIA_SUCESSO_FECHAR,
  EDITAR_OCORRENCIA,
  EDITAR_OCORRENCIA_SUCESSO,
  EDITAR_OCORRENCIA_SUCESSO_FECHAR,
  ATUALIZAR_SOCIO_SUCESSO,
  ATUALIZAR_SOCIO_ERRO,
  ATUALIZAR_SOCIO_SUCESSO_FECHAR,
  ATUALIZANDO_STATUS_PROSPECTO,
  ATUALIZAR_STATUS_PROSPECTO_SUCESSO,
  ATUALIZAR_STATUS_PROSPECTO_SUCESSO_FECHAR,
  ATUALIZAR_STATUS_PROSPECTO_ERRO,
  REMOVENDO_PROSPECTO_EMPREENDIMENTO,
  REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO,
  REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO_FECHAR,
  REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO,
  DISMISS_AVISO_DESCARTE_SUA_HOUSE,
} from '~/actionTypes';
import * as constants from '~/constant';
import _orderBy from 'lodash/orderBy';
import _find from 'lodash/find';
import { format, nullPropertiesToString } from '~/Utils/Helpers';

const list = (state = [], action) => {
  switch (action.type) {
    case ALL_PROSPECTS_FETCH:
      return action.payload.prospectos;
    case FETCHING_PROSPECTS:
      return [];
    default:
      return state;
  }
};

const pagination = (
  state = {
    quantidade_prospectos: 0,
    pagina_atual: 1,
    quantidade_por_pagina: constants.QUANTIDADEPORPAGINA,
    ultima_pagina: 0,
  },
  action
) => {
  switch (action.type) {
    case ALL_PROSPECTS_FETCH:
      return {
        ...state,
        quantidade_prospectos: action.payload.quantidade_prospectos,
        pagina_atual: action.payload.pagina_atual,
        quantidade_por_pagina: action.payload.quantidade_por_pagina,
        ultima_pagina: action.payload.ultima_pagina,
      };
    default:
      return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
    case FETCHING_PROSPECTS:
      return true;
    case ALL_PROSPECTS_FETCH:
    case FETCH_ERROR_PROSPECTS:
      return false;
    default:
      return state;
  }
};

const isError = (state = null, action) => {
  switch (action.type) {
    case FETCHING_PROSPECTS:
      return null;
    case FETCH_ERROR_PROSPECTS:
      return 'Erro ao carregar os prospectos';
    default:
      return state;
  }
};

const filter = (
  state = {
    filtro: '',
    codigo_gerente: '',
    status_atendimento: '',
    codigo_corretor: '',
    codigo_empreendimento: '',
    data_inicial: '',
    data_final: '',
    tipo_pasta: '',
    tipo_filtro: 'nome',
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_FILTER_PROSPECT:
      return {};
    default:
      return state;
  }
};

const isLoadingOptions = (state = false, action) => {
  switch (action.type) {
    case FETCHING_OPTIONS:
      return true;
    case FETCH_OPTIONS_SUCCESS:
    case FETCH_ERROR_OPTIONS:
      return false;
    default:
      return state;
  }
};

const mesmoDocumento = (docA, docB) => {
  return docA.ordem === docB.ordem && docA.codigo === docB.codigo;
};

const documentos = (state = [], action) => {
  switch (action.type) {
    case ATUALIZA_DOCUMENTO:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });
    case UPLOADING_DOCUMENTO:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          return {
            ...item,
            isUploading: true,
          };
        }

        return item;
      });
    case UPLOAD_DOCUMENTO_SUCCESS:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          delete item.Thumb;
          return {
            ...item,
            ...action.payload,
            isUploading: false,
          };
        }
        return item;
      });
    case UPLOAD_DOCUMENTO_ERROR:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          return {
            ...item,
            ...action.payload,
            isUploading: false,
          };
        }
        return item;
      });
    default:
      return state;
  }
};

const criaConjugePorEmpreendimento = (conjugeSocio = [], ge = null) => {
  return conjugeSocio.filter(item => {
    return ge && item.codigo_grupo_empresa === ge;
  });
};

const orderByDate = (list, key, order = 'desc') => {
  return _orderBy(
    list,
    item => {
      return item[key] ? format.timestamp(item[key]) : -1;
    },
    order
  );
};

const ordenarEmpreendimentoDataVinculo = empreendimentos => {
  return orderByDate(empreendimentos, 'data_vinculo');
};

const preparaPesquisa = respostas => {
  if (!Array.isArray(respostas)) {
    respostas = [];
  }
  return respostas.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.codigo_pergunta]: curr.codigo_resposta
        ? curr.codigo_resposta
        : curr.dissertativa,
    };
  }, {});
};

const ordernaPorData = (ocorrencias = []) => {
  return orderByDate(ocorrencias, 'Data');
};

export const permitidoAcionarDocumentos = prospecto => {
  let camposObrigatoriosDocumentos = [
    'codigo_familia',
    'codigo',
    'codigo_conta',
    'codigo_grupo_empresa',
    'tipo_pessoa',
  ];
  let permitido = camposObrigatoriosDocumentos.reduce((prev, curr) => {
    return prev && (!!prospecto[curr] || prospecto[curr] === 0);
  }, true);

  return permitido;
};

const detail = (
  state = {
    codigo_nacionalidade: 1,
    tipo_pessoa: 0,
    prospecto_empreendimento: [],
  },
  action
) => {
  let { prospecto_empreendimento, ...rest } = state;

  switch (action.type) {
    case INICIAR_NOVO_PROSPECTO:
    case ALL_PROSPECTS_FETCH:
    case FETCHING_PROSPECT_DETAILS:
      state = {
        codigo_nacionalidade: 1,
        tipo_pessoa: 0,
        prospecto_empreendimento: [],
      };
      break;
    case FETCH_PROSPECT_DETAILS_SUCCESS:
      let newState = action.payload;
      if (action.payload.prospecto_empreendimento.length > 0) {
        let empreendimentos = ordenarEmpreendimentoDataVinculo(
          action.payload.prospecto_empreendimento
        );
        newState = {
          ...newState,
          ...empreendimentos[0], //Seleciona o primeiro empreendimento
          prospecto_empreendimento: empreendimentos,
        };
      }
      // altera os valores null para string vazia para aplicar como valor inicial nos campos do form
      state = nullPropertiesToString(newState);
      break;
    case UPDATE_PROSPECT:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    case CREATE_PROSPECT_SUCCESS:
    case UPDATE_PROSPECT_SUCCESS:
      let {
        prospecto_empreendimento: prospectoEmpreendimentoAtualizado,
        ...prospectoAtualizado
      } = action.payload;
      state = {
        ...state,
        ...prospectoAtualizado,
        ...prospectoEmpreendimentoAtualizado,
      };
      state = nullPropertiesToString(state);
      break;
    case INICIAR_ADICIONAR_SOCIO:
      state.conjuge_socio.push({
        codigo_grupo_empresa: state.codigo_grupo_empresa,
        codigo_conta: state.codigo_conta,
        codigo_familia: state.codigo_familia,
        codigo_prospecto_cliente: state.codigo,
        codigo_corretor: state.codigo_corretor,
        codigo_gerente: state.codigo_gerente,
        codigo_nacionalidade: 1,
        tipo_pessoa: 0,
      });
      break;
    case CANCELAR_ADICIONAR_SOCIO:
      state.conjuge_socio = state.conjuge_socio.filter(item => item.codigo);
      break;
    case SALVAR_SOCIO_SUCESSO:
      state.conjuge_socio.push(action.payload);
      break;
    case ATUALIZAR_SOCIO_SUCESSO:
      state.conjuge_socio = state.conjuge_socio.map(item => {
        if (item.codigo !== action.payload.codigo) {
          return item;
        }
        return { ...item, ...action.payload };
      });
      break;
    case EMPRENDIMENTO_VINCULADO:
      let novoEmpreendimento = {
        ...rest,
        ...action.payload,
        documentos: [],
        codigo_conta: null,
      };

      prospecto_empreendimento = [
        novoEmpreendimento,
        ...prospecto_empreendimento,
      ];

      state = {
        ...rest,
        ...action.payload,
        documentos: [],
        codigo_conta: null,
        prospecto_empreendimento,
      };
      break;
    case REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO:
      prospecto_empreendimento = state.prospecto_empreendimento.map(item => {
        return item.codigo_familia === action.payload.vinculo.codigo_familia
          ? action.payload.vinculo
          : item;
      });

      state = {
        ...state,
        ...prospecto_empreendimento[0],
        requer_acao_sua_house: action.payload.requer_acao_sua_house,
        prospecto_empreendimento,
      };
      break;
    case ATUALIZAR_STATUS_PROSPECTO_SUCESSO:
      state.prospecto_empreendimento = state.prospecto_empreendimento.map(
        item => {
          if (item.codigo_familia === action.payload.codigo_familia) {
            return {
              ...item,
              codigo_status: action.payload.codigo_status,
            };
          }
          return item;
        }
      );
      if (state.codigo_familia === action.payload.codigo_familia) {
        state = {
          ...state,
          codigo_status: action.payload.codigo_status,
        };
      }
      break;
    case SELECIONA_EMPREENDIMENTO:
      let prospectoSelecionado = _find(prospecto_empreendimento, {
        codigo_familia: Number(action.payload),
      });

      state = {
        ...rest,
        ...prospectoSelecionado,
        prospecto_empreendimento,
      };
      break;
    case SALVAR_PESQUISA_SUCESSO:
      state.prospecto_empreendimento = state.prospecto_empreendimento.map(
        item => {
          if (item.codigo_familia === state.codigo_familia) {
            return {
              ...item,
              respostas_pesquisa: action.payload,
            };
          }

          return item;
        }
      );
      state = {
        ...state,
        respostas_pesquisa: action.payload,
      };
      break;
    case SALVAR_OCORRENCIA_SUCESSO:
      state.ocorrencias.unshift({
        ...action.payload.ocorrencia,
        novaOcorrencia: true,
      });
      break;
    case SALVAR_OCORRENCIA_SUCESSO_FECHAR:
      state.ocorrencias = state.ocorrencias.map(item => {
        return {
          ...item,
          novaOcorrencia: false,
        };
      });
      break;
    case EDITAR_OCORRENCIA_SUCESSO:
      state.ocorrencias = state.ocorrencias.map(item => {
        if (item.codigo === action.payload.codigo) {
          return {
            ...item,
            ...action.payload,
            ocorrenciaEditada: true,
          };
        }
        return item;
      });
      break;
    case EDITAR_OCORRENCIA_SUCESSO_FECHAR:
      state.ocorrencias = state.ocorrencias.map(item => {
        return {
          ...item,
          ocorrenciaEditada: false,
        };
      });
      break;
    case DISMISS_AVISO_DESCARTE_SUA_HOUSE:
      return {
        ...state,
        requer_acao_sua_house: false,
      };
    default:
      break;
  }

  return {
    ...state,
    documentos: documentos(state.documentos, action),
    ConjugePorEmpreendimento: criaConjugePorEmpreendimento(
      state.conjuge_socio,
      state.codigo_grupo_empresa
    ),
    ocorrencias: ordernaPorData(state.ocorrencias),
    Pesquisa: preparaPesquisa(state.respostas_pesquisa),
    permitidoAdicionarDocumento: permitidoAcionarDocumentos(state),
    permitidoAdicionarPesquisa: !!state.codigo,
  };
};

const isLoadingDetail = (state = false, action) => {
  switch (action.type) {
    case FETCHING_PROSPECT_DETAILS:
      return true;
    case FETCH_PROSPECT_DETAILS_ERROR:
    case FETCH_PROSPECT_DETAILS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isErrorDetail = (state = false, action) => {
  switch (action.type) {
    case FETCH_PROSPECT_DETAILS_ERROR:
      return true;
    case FETCHING_PROSPECT_DETAILS:
      return false;
    default:
      return state;
  }
};

const criandoProspecto = (state = false, action) => {
  switch (action.type) {
    case CREATE_PROSPECT_ERROR:
    case UPDATE_PROSPECT_ERROR:
    case UPDATE_PROSPECT_SUCCESS:
    case CREATE_PROSPECT_SUCCESS:
    case INICIAR_NOVO_PROSPECTO:
      return false;
    case CREATING_PROSPECT:
    case UPDATING_PROSPECT:
      return true;
    default:
      return state;
  }
};

const prospectoCriado = (state = false, action) => {
  switch (action.type) {
    case CREATE_PROSPECT_SUCCESS:
    case UPDATE_PROSPECT_SUCCESS:
      return true;
    case CREATE_PROSPECT_SUCCESS_CLOSE:
    case UPDATE_PROSPECT_SUCCESS_CLOSE:
      return false;
    default:
      return state;
  }
};

const erroCriandoProspecto = (state = false, action) => {
  switch (action.type) {
    case CREATE_PROSPECT_SUCCESS:
    case CREATING_PROSPECT:
    case UPDATE_PROSPECT_SUCCESS:
    case UPDATING_PROSPECT:
    case FETCHING_PROSPECT_DETAILS:
    case INICIAR_NOVO_PROSPECTO:
      return false;
    case CREATE_PROSPECT_ERROR:
      return action.payload.message
        ? action.payload.message
        : 'Erro ao criar prospecto';
    case UPDATE_PROSPECT_ERROR:
      return constants.ERROS[action.payload.message]
        ? constants.ERROS[action.payload.message]
        : action.payload.message
        ? action.payload.message
        : 'Erro ao atualizar prospecto';
    default:
      return state;
  }
};

const adicionandoSocio = (state = false, action) => {
  switch (action.type) {
    case INICIAR_ADICIONAR_SOCIO:
      return true;
    case CANCELAR_ADICIONAR_SOCIO:
    case SALVAR_SOCIO_SUCESSO:
    case ATUALIZAR_SOCIO_SUCESSO:
      return false;
    default:
      return state;
  }
};

const salvandoSocio = (state = false, action) => {
  switch (action.type) {
    case SALVANDO_SOCIO:
      return true;
    case SALVAR_SOCIO_SUCESSO:
    case SALVAR_SOCIO_ERRO:
    case ATUALIZAR_SOCIO_SUCESSO:
    case ATUALIZAR_SOCIO_ERRO:
      return false;
    default:
      return state;
  }
};

const socioSelecionado = (state = null, action) => {
  switch (action.type) {
    case SELECIONA_SOCIO:
      return action.payload === state ? null : action.payload;
    case INICIAR_ADICIONAR_SOCIO:
      return null;
    case SALVAR_SOCIO_SUCESSO:
    case ATUALIZAR_SOCIO_SUCESSO:
      return action.payload.codigo_prospecto_cliente;
    default:
      return state;
  }
};

const exibirMensagemSucessoSalvarSocio = (state = false, action) => {
  switch (action.type) {
    case SALVAR_SOCIO_SUCESSO:
    case ATUALIZAR_SOCIO_SUCESSO:
      return true;
    case SALVANDO_SOCIO:
    case SALVAR_SOCIO_SUCESSO_FECHAR:
    case SALVAR_SOCIO_ERRO:
    case ATUALIZAR_SOCIO_SUCESSO_FECHAR:
    case ATUALIZAR_SOCIO_ERRO:
      return false;
    default:
      return state;
  }
};

const erroSalvarSocio = (state = false, action) => {
  switch (action.type) {
    case SALVANDO_SOCIO:
    case SALVAR_SOCIO_SUCESSO:
    case ATUALIZAR_SOCIO_SUCESSO:
      return false;
    case SALVAR_SOCIO_ERRO:
    case ATUALIZAR_SOCIO_ERRO:
      return true;
    default:
      return state;
  }
};

const consultandoCPF = (state = false, action) => {
  switch (action.type) {
    case CONSULTANDO_CPF:
      return true;
    case CONSULTA_CPF_SUCESSO:
    case CONSULTA_CPF_ERRO:
      return false;
    default:
      return state;
  }
};

const consultandoEmail = (state = false, action) => {
  switch (action.type) {
    case CONSULTANDO_EMAIL:
      return true;
    case CONSULTA_EMAIL_SUCESSO:
    case CONSULTA_CPF_ERRO:
      return false;
    default:
      return state;
  }
};

const prospectoParaEncaminhar = (state = null, action) => {
  switch (action.type) {
    case CONSULTANDO_CPF:
    case CONSULTANDO_EMAIL:
    case LIMPA_PROSPECTO_ENCAMINHAR:
    case CONSULTA_CPF_ERRO:
    case CONSULTA_EMAIL_ERRO:
      return null;
    case CONSULTA_CPF_SUCESSO:
    case CONSULTA_EMAIL_SUCESSO:
      return action.payload;
    default:
      return state;
  }
};

const adicionandoEmpreendimento = (state = false, action) => {
  switch (action.type) {
    case VINCULANDO_EMPREENDIMENTO:
      return true;
    case EMPRENDIMENTO_VINCULADO:
    case EMPREENDIMENTO_VINCULADO_ERROR:
      return false;
    default:
      return state;
  }
};

const erroAdicionarEmpreendimento = (state = false, action) => {
  switch (action.type) {
    case VINCULANDO_EMPREENDIMENTO:
    case EMPRENDIMENTO_VINCULADO:
      return false;
    case EMPREENDIMENTO_VINCULADO_ERROR:
      return action.payload.message;
    default:
      return state;
  }
};

const buscandoEndereco = (state = false, action) => {
  switch (action.type) {
    case BUSCANDO_CEP:
      return true;
    case BUSCA_CEP_SUCESSO:
    case BUSCA_CEP_ERRO:
      return false;
    default:
      return state;
  }
};

const erroSalvarPesquisa = (state = false, action) => {
  switch (action.type) {
    case SALVANDO_PESQUISA:
    case SALVAR_PESQUISA_SUCESSO:
      return false;
    case SALVAR_PESQUISA_ERRO:
      return true;
    default:
      return state;
  }
};

const exibirMensagemSalvarPesquisaSucesso = (state = false, action) => {
  switch (action.type) {
    case SALVANDO_PESQUISA:
    case SALVAR_PESQUISA_SUCESSO_FECHAR:
      return false;
    case SALVAR_PESQUISA_SUCESSO:
      return true;
    default:
      return state;
  }
};

const salvandoPesquisa = (state = false, action) => {
  switch (action.type) {
    case SALVANDO_PESQUISA:
      return true;
    case SALVAR_PESQUISA_SUCESSO:
    case SALVAR_PESQUISA_ERRO:
      return false;
    default:
      return state;
  }
};

const modalOcorrenciaAberto = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_MODAL_OCORRENCIA:
      return action.payload;
    case SALVAR_OCORRENCIA_SUCESSO:
    case EDITAR_OCORRENCIA_SUCESSO:
      return false;
    default:
      return state;
  }
};

const salvandoOcorrencia = (state = false, action) => {
  switch (action.type) {
    case SALVANDO_OCORRENCIA:
      return true;
    case SALVAR_OCORRENCIA_SUCESSO:
    case EDITAR_OCORRENCIA_SUCESSO:
    case SALVAR_OCORRENCIA_ERRO:
      return false;
    default:
      return state;
  }
};

const erroSalvarOcorrencia = (state = false, action) => {
  switch (action.type) {
    case SALVAR_OCORRENCIA_ERRO:
      return true;
    case SALVANDO_OCORRENCIA:
    case SALVAR_OCORRENCIA_SUCESSO:
    case EDITAR_OCORRENCIA_SUCESSO:
      return false;
    default:
      return state;
  }
};

const sucessoSalvarOcorrencia = (state = false, action) => {
  switch (action.type) {
    case SALVAR_OCORRENCIA_SUCESSO:
    case EDITAR_OCORRENCIA_SUCESSO:
      return true;
    case SALVAR_OCORRENCIA_SUCESSO_FECHAR:
    case EDITAR_OCORRENCIA_SUCESSO_FECHAR:
      return false;
    default:
      return state;
  }
};

const ocorrenciaParaEditar = (state = null, action) => {
  switch (action.type) {
    case TOGGLE_MODAL_OCORRENCIA:
      let abrindoModal = action.payload;
      return abrindoModal ? state : null; // limpa a ocorrencia em editação caso feche o modal
    case EDITAR_OCORRENCIA:
      return action.payload;
    default:
      return state;
  }
};

const atualizandoProspectoEmpreendimento = (state = false, action) => {
  switch (action.type) {
    case REMOVENDO_PROSPECTO_EMPREENDIMENTO:
    case ATUALIZANDO_STATUS_PROSPECTO:
      return true;
    case REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO:
    case REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO:
    case ATUALIZAR_STATUS_PROSPECTO_SUCESSO:
    case ATUALIZAR_STATUS_PROSPECTO_ERRO:
      return false;
    default:
      return state;
  }
};

const erroAtualizarProspectoEmpreendimento = (state = null, action) => {
  switch (action.type) {
    case REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO:
    case ATUALIZAR_STATUS_PROSPECTO_ERRO:
      return action.payload;
    case REMOVENDO_PROSPECTO_EMPREENDIMENTO:
    case ATUALIZANDO_STATUS_PROSPECTO:
    case REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO:
    case ATUALIZAR_STATUS_PROSPECTO_SUCESSO:
      return null;
    default:
      return state;
  }
};

const sucessoAtualizarProspectoEmpreendimento = (state = false, action) => {
  switch (action.type) {
    case REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO:
    case ATUALIZAR_STATUS_PROSPECTO_ERRO:
    case REMOVENDO_PROSPECTO_EMPREENDIMENTO:
    case ATUALIZANDO_STATUS_PROSPECTO:
    case ATUALIZAR_STATUS_PROSPECTO_SUCESSO_FECHAR:
    case REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO_FECHAR:
      return false;
    case REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO:
    case ATUALIZAR_STATUS_PROSPECTO_SUCESSO:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  isLoading,
  isError,
  pagination,
  filter,
  isLoadingOptions,
  detail,
  isErrorDetail,
  isLoadingDetail,
  criandoProspecto,
  erroCriandoProspecto,
  prospectoCriado,
  adicionandoSocio,
  salvandoSocio,
  erroSalvarSocio,
  exibirMensagemSucessoSalvarSocio,
  socioSelecionado,
  consultandoCPF,
  consultandoEmail,
  prospectoParaEncaminhar,
  adicionandoEmpreendimento,
  erroAdicionarEmpreendimento,
  buscandoEndereco,
  erroSalvarPesquisa,
  salvandoPesquisa,
  exibirMensagemSalvarPesquisaSucesso,
  modalOcorrenciaAberto,
  salvandoOcorrencia,
  erroSalvarOcorrencia,
  sucessoSalvarOcorrencia,
  ocorrenciaParaEditar,
  atualizandoProspectoEmpreendimento,
  erroAtualizarProspectoEmpreendimento,
  sucessoAtualizarProspectoEmpreendimento,
});
