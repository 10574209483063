import React, { useState, useEffect } from 'react';
import { IoIosPerson } from 'react-icons/io';
import { withRouter } from 'react-router-dom';

import api from '~/Services/Service';
import Loading from '~/Components/Loading';
import { formatarComZero, format, capitalize } from '~/Utils/Helpers';
import { EMPREENDIMENTO_LANCAMENTO } from '~/constant';

//Resolução 2160px x 3840px

export default withRouter(function LancamentoSequenciaAtendimento({
  location,
}) {
  const [corretores, setCorretores] = useState([]);
  const [isCarregando, setIsCarregando] = useState(false);
  const [contador, setContador] = useState(0);
  const TEMPO_ATUALIZACAO = 30000;

  async function carregaCorretores() {
    try {
      setIsCarregando(true);
      let novosCorretores = await api.get(
        `/lancamento/empreendimento/${EMPREENDIMENTO_LANCAMENTO}/compensacao${location.search}`
      );

      novosCorretores = novosCorretores.map(item => ({
        ...item,
        nome_corretor: capitalize(item.nome_corretor)
          .split(/(\s).+\s/)
          .join(''),
        atendimentos_proprios_convertidos: formatarComZero(
          item.atendimentos_proprios_convertidos
        ),
        atendimentos_aleatorios: formatarComZero(item.atendimentos_aleatorios),
        ultimo_atendimento_aleatorio: format.datetime(
          item.ultimo_atendimento_aleatorio,
          'HH:mm'
        ),
        StyleImobiliaria: `.corretor-${item.codigo_corretor}::before{ background-color: ${item.cor_imobiliaria} }`,
      }));
      setCorretores(novosCorretores);
      setIsCarregando(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    carregaCorretores();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const novoContador = contador + 1000;

      if (contador > TEMPO_ATUALIZACAO) {
        setContador(0);
        carregaCorretores();
      } else {
        setContador(novoContador);
      }
    }, 1000);
  }, [contador]);

  return (
    <div className="LancamentoSequenciaAtendimento">
      <style
        dangerouslySetInnerHTML={{
          __html: `.LancamentoSequenciaAtendimento::before, .LancamentoSequenciaAtendimento::after{
            ${contador > TEMPO_ATUALIZACAO ? 'display: none;' : ''}
            height:${(contador / TEMPO_ATUALIZACAO) * 100}%}`,
        }}
      ></style>

      <div className="container-fluid">
        <header>
          <h2 className="Titulo">
            Sequência de atendimento dos clientes não cadastrados
          </h2>
          <img
            src="https://www.setpar.com.br/app/themes/setpar/dist/setpar-empreendimentos-w.svg"
            alt="Setpar"
          />
        </header>
      </div>

      {isCarregando && corretores.length === 0 && (
        <Loading label="Atualizando..." />
      )}

      <div className="container-fluid">
        <ul className="ListaCorretores">
          {corretores.map(corretor => (
            <li
              className={`${Number(corretor.ordem) === 2 &&
                'EmEspera'} corretor-${corretor.codigo_corretor}`}
              key={corretor.codigo_corretor}
            >
              <style
                dangerouslySetInnerHTML={{ __html: corretor.StyleImobiliaria }}
              ></style>
              <div className="Descricao">
                <div className="Avatar">
                  {corretor.foto_corretor ? (
                    <img
                      src={corretor.foto_corretor}
                      alt={corretor.nome_corretor}
                    />
                  ) : (
                    <IoIosPerson size={30} />
                  )}
                </div>
                <div className="Nome">{corretor.nome_corretor}</div>
              </div>
              <div className="Contadores">
                <ul>
                  <li>
                    <small>Conversões</small>
                    <strong>
                      {corretor.atendimentos_proprios_convertidos}
                    </strong>
                  </li>
                  <li>
                    <small>Atendimentos</small>
                    <strong>{corretor.atendimentos_aleatorios}</strong>
                  </li>
                  <li>
                    <small>Horário do último atend.</small>
                    <strong>{corretor.ultimo_atendimento_aleatorio}</strong>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
