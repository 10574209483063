import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import { FaSearch, FaPlus } from 'react-icons/fa';

import Paginator from '~/Components/Paginator';
import Loading from '~/Components/Loading';
import Prospectos from '~/Components/Prospectos/Prospectos';
import Prospecto from '~/Components/Prospectos/ProspectoItem';
import PageOrderBy from '~/Components/PageOrderBy';
import PageQuantitySelector from '~/Components/PageQuantitySelector';
import AuthStorage from '~/Utils/AuthStorage';

class ProspectoLista extends Component {
  render() {
    const {
      ProspectoLista,
      isLoadingProspect,
      isErrorProspect,
      pagination,
      history,
      filter,
      liberarCadastroProspecto,
    } = this.props;

    const readonly = AuthStorage.temCaracteristica(495);
    const isAdm = AuthStorage.isADM();

    return (
      <Prospectos>
        {({ doPaginate }) => (
          <Container className="mb-3 mt-4">
            <Row>
              {!isLoadingProspect &&
                !isErrorProspect &&
                ProspectoLista.length === 0 && (
                  <Col lg={{ span: 4, offset: 4 }}>
                    <div className="text-center mt-5 pt-5">
                      {Object.keys(filter).length > 0 && (
                        <Alert variant="secondary" className="mb-5 p-5">
                          Nenhum resultado foi encontrado!
                        </Alert>
                      )}
                      <p className="lead">
                        Clique no botão <FaSearch /> para realizar uma
                        <strong> busca</strong> de prospectos ou{' '}
                        {liberarCadastroProspecto && (
                          <>
                            <strong>
                              <FaPlus /> Adicionar,
                            </strong>{' '}
                            para adicionar um novo prospecto
                          </>
                        )}
                        {!liberarCadastroProspecto && (
                          <>
                            realize a importação de um Lead no
                            <strong> CRM</strong>
                          </>
                        )}
                      </p>
                      {!readonly && (liberarCadastroProspecto || isAdm) && (
                        <Link
                          to="/prospectos/lista?novo=true"
                          className="btn btn-lg btn-block btn-success text-uppercase shadow-lg py-3"
                        >
                          <small>
                            <FaPlus /> Adicionar
                          </small>
                        </Link>
                      )}
                    </div>
                  </Col>
                )}
              <Col xs="12">
                {isLoadingProspect && <Loading />}

                {!isLoadingProspect && isErrorProspect && (
                  <Alert variant="warning">
                    Erro ao carregar os prospectos
                  </Alert>
                )}

                {!isLoadingProspect && !isErrorProspect && (
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      {pagination.quantidade_prospectos >
                        pagination.quantidade_por_pagina &&
                        `Exibindo ${pagination.quantidade_por_pagina} de ${pagination.quantidade_prospectos} (Página ${pagination.pagina_atual})`}
                      {pagination.quantidade_prospectos > 0 &&
                        pagination.quantidade_prospectos <
                          pagination.quantidade_por_pagina &&
                        `Exibindo ${pagination.quantidade_prospectos} resultados.`}
                    </div>
                    {ProspectoLista.length > 0 && (
                      <div>
                        <PageOrderBy
                          value={filter.ordem}
                          onChange={event =>
                            doPaginate({
                              ordem: event.target.value,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                {!isLoadingProspect &&
                  ProspectoLista.map((item, index) => (
                    <Prospecto
                      onClick={prospecto =>
                        history.push(`/prospectos/${item.codigo}`)
                      }
                      data={item}
                      key={index}
                    />
                  ))}
                {!isLoadingProspect &&
                  !isErrorProspect &&
                  pagination.quantidade_prospectos >
                    pagination.quantidade_por_pagina && (
                    <div className="clearfix">
                      <div className="float-left">
                        <Paginator
                          numberOfPages={pagination.UltimaPagina}
                          initialPage={pagination.pagina_atual}
                          onPageChange={pagina_atual =>
                            doPaginate({ pagina_atual })
                          }
                        />
                      </div>
                      <div className="float-right">
                        <PageQuantitySelector
                          value={pagination.quantidade_por_pagina}
                          onChange={event =>
                            doPaginate({
                              quantidade_por_pagina: event.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
          </Container>
        )}
      </Prospectos>
    );
  }
}

const mapStateToProps = state => ({
  ProspectoLista: state.prospecto.list,
  isLoadingProspect: state.prospecto.isLoading,
  isErrorProspect: state.prospecto.isError,
  pagination: state.prospecto.pagination,
  filter: state.prospecto.filter,
  liberarCadastroProspecto: state.options.liberarCadastroProspecto,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProspectoLista)
);
