import React from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import Select from 'react-select';

import AuthStorage from '~/Utils/AuthStorage';

const ModalDivisaoComissao = ({
  onCancel,
  divisao,
  salvarDivisaoComissao,
  corretores,
  readonly,
}) => {
  if (!divisao) return null;

  const { porcentagem, codigo_usuario, codigo } = divisao || {};

  const codigoCorretor = AuthStorage.getUser().usuario;

  const optionsCorretores = corretores
    .filter(cor => cor.codigo !== codigoCorretor)
    .map(cor => ({
      value: cor.codigo,
      label: cor.nome,
    }));

  return (
    <Modal show={!!divisao} onHide={onCancel}>
      <Formik
        initialValues={{
          codigo: codigo,
          porcentagem: porcentagem || '',
          codigo_usuario: codigo_usuario || '',
        }}
        enableReinitialization
        onSubmit={values => {
          salvarDivisaoComissao(values);
          onCancel();
        }}
        validate={values => {
          let errors = {};
          // pra permitir o usuário limpar
          if (!values.codigo_usuario && !values.porcentagem) return {};

          if (!values.codigo_usuario) errors.codigo_usuario = 'Obrigatório';
          if (!values.porcentagem) errors.porcentagem = 'Obrigatório';
          return errors;
        }}
      >
        {({ values, setFieldValue, handleChange }) => {
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{`Divisão de comissão do lote #${divisao.codigo}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{`Quadra: ${divisao.quadra} / Lote: ${divisao.lote}`}</p>

                <Row>
                  <Col md="8">
                    <div className="form-group">
                      <label htmlFor="corretor">Corretor</label>
                      <Select
                        isClearable
                        noOptionsMessage={() => 'Nenhum corretor encontrado'}
                        placeholder="Selecione um corretor.."
                        options={optionsCorretores}
                        isDisabled={readonly}
                        onChange={option => {
                          setFieldValue(
                            'codigo_usuario',
                            option ? option.value : null
                          );
                        }}
                        value={
                          optionsCorretores.find(
                            option => option.value === values.codigo_usuario
                          ) || {
                            value: '',
                            label: 'Selecione um corretor...',
                          }
                        }
                      />
                      <ErrorMessage
                        className="text-danger"
                        component="small"
                        name="codigo_usuario"
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                      <label htmlFor="porcentagem">Porcentagem</label>
                      <Field
                        name="porcentagem"
                        type="number"
                        min={0}
                        max={99}
                        className="form-control"
                        disabled={readonly}
                      />
                      <ErrorMessage
                        className="text-danger"
                        component="small"
                        name="porcentagem"
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                {readonly ? (
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={onCancel}
                  >
                    Fechar
                  </Button>
                ) : (
                  <>
                    <Button variant="primary" className="mb-1" type="submit">
                      Salvar
                    </Button>
                    <Button
                      variant="outline-primary"
                      type="button"
                      onClick={onCancel}
                    >
                      Cancelar
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalDivisaoComissao;
