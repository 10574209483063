export const salvarSinal = values => ({
  type: '@vendas/SALVAR_SINAL',
  payload: values,
});

export const adicionaFormaPgtoEntrada = formaPgto => ({
  type: '@vendas/ADICIONA_FORMA_PAGAMENTO_ENTRADA',
  payload: formaPgto,
});

export const removeFormaPgtoEntrada = (item, index) => ({
  type: '@vendas/REMOVE_FORMA_PAGAMENTO_ENTRADA',
  payload: {
    item,
    index,
  },
});

export const atualizaFormaPgtoEntrada = (formaPgto, index) => ({
  type: '@vendas/ATUALIZA_FORMA_PAGAMENTO_ENTRADA',
  payload: {
    formaPgto,
    index,
  },
});

export const removeTodasParcelasEntrada = () => ({
  type: '@vendas/REMOVE_TOTAS_PARCELAS_ENTRADA',
});
