import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  consultaEmail,
  limpaProspectoEncaminhar,
} from '~/Store/Prospecto/actions';
import PropTypes from 'prop-types';
import ModalProspectoExistente from './ModalProspectoExistente';

class ConsultaEmail extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    ignore: PropTypes.bool,
  };

  handleChange = event => {
    const { ignore, onChangeEmail, consultaEmail } = this.props;
    if (ignore) {
      onChangeEmail(event);
      return;
    }
    consultaEmail(event.target.value);

    onChangeEmail(event);
  };

  //Se o Email existir, direciona para o prospecto existente com o CPF informado
  encaminhaProspecto = () => {
    const {
      limpaProspectoEncaminhar,
      history,
      prospectoParaEncaminhar,
    } = this.props;
    const { codigo } = prospectoParaEncaminhar;

    limpaProspectoEncaminhar();
    history.push(`/prospectos/${codigo}/dados-clientes/pessoais`);
  };

  render() {
    const {
      onChangeEmail,
      prospectoParaEncaminhar,
      consultandoEmail,
      limpaProspectoEncaminhar,
    } = this.props;
    return (
      <>
        <ModalProspectoExistente
          show={prospectoParaEncaminhar && prospectoParaEncaminhar.codigo}
          onCancel={() => {
            limpaProspectoEncaminhar();
            onChangeEmail('');
          }}
          prospectoParaEncaminhar={prospectoParaEncaminhar}
          onRedirect={this.encaminhaProspecto}
          isEmail
        />
        {this.props.children({
          onChangeEmail: this.handleChange,
          consultandoEmail: consultandoEmail,
        })}
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ consultaEmail, limpaProspectoEncaminhar }, dispatch);

const mapStateToProps = state => ({
  consultaEmail: state.prospecto.consultaEmail,
  prospectoParaEncaminhar: state.prospecto.prospectoParaEncaminhar,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConsultaEmail)
);
