import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

/**
 * Component that react if you click outside of it
 */
export default class Outsider extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.props.onClickOut();
    }
  };

  render() {
    const { children, onClickOut, ...rest } = this.props;
    return <span {...rest}>{children}</span>;
  }
}

Outsider.propTypes = {
  children: PropTypes.any.isRequired,
  onClickOut: PropTypes.func.isRequired,
};
