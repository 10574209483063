import React from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

import * as helpers from '~/Utils/Helpers';

export default function Ocorrencia({ ocorrencia, exibirDetalhes, onClick }) {
  return (
    <ul className="list-unstyled d-flex flex-column mb-1 pb-1 border-bottom">
      <li className="d-flex align-items-center" onClick={onClick}>
        <span className="text-primary mr-2 ml-md-3">
          {exibirDetalhes ? <FiMinus /> : <FiPlus />}
        </span>
        <span>
          <small className="d-block text-muted">
            {ocorrencia.data ? helpers.format.date(ocorrencia.data) : '-'}
          </small>
          {ocorrencia.titulo}
        </span>
        <span className="ml-4">
          <small className="d-block text-muted">Usuário</small>
          {ocorrencia.nome_usuario}
        </span>
      </li>
      {exibirDetalhes && (
        <li className="mt-2 ml-4">
          <small className="d-block text-muted">Descrição</small>
          {ocorrencia.texto}
        </li>
      )}
    </ul>
  );
}
