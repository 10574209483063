import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

const ModalEnviarBoletoPorEmail = ({
  show,
  onHide,
  onSubmit,
  emailInicial,
  isBusy,
}) => {
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail(emailInicial);
    setValidated(false);
  }, [emailInicial]);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const isValid = form.checkValidity();

    setValidated(true);
    if (isValid) onSubmit(email);
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdropClassName="z-index-1060"
      className="z-index-1060"
    >
      <Modal.Header closeButton={!isBusy}>
        Enviar boleto por e-mail
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              as="input"
              type="email"
              required
              name="email"
              defaultValue={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="exemplo@dominio.com.br"
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              Email não informado, ou formato incorreto.
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={onHide} disabled={isBusy}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={isBusy}>
            {isBusy ? 'Aguarde...' : 'Enviar'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalEnviarBoletoPorEmail;
