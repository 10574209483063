import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import AuthStorage from '../../Utils/AuthStorage';
import { doLogout, doLogin } from './AuthActions';
import * as Service from '~/Services/Service';

const mapStateToProps = state => ({
  userLogged: state.auth.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogin,
      doLogout,
    },
    dispatch
  );

class AutoLogin extends Component {
  state = {
    error: null,
  };

  async componentDidMount() {
    this.props.doLogout(false); //Limpa a sessão velha

    const params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    let sessao = {
      token_type: 'bearer',
      access_token: params.auth,
      usuario: parseInt(params.usuario),
      nome: params.nome,
      gerente: parseInt(params.gerente),
      env: params.env,
      hn: params.hn,
      atendente_lancamento: params.atendente_lancamento === 'true',
    };

    AuthStorage.setUser(sessao);

    const { caracteristicas, user_info } = await Service.getClaims();
    AuthStorage.setCaracteristicas(caracteristicas);
    AuthStorage.setUser({
      ...sessao,
      ...user_info,
    });

    this.props.doLogin(sessao);
  }

  render() {
    const { error } = this.state;
    const { userLogged, from } = this.props;

    if (userLogged) {
      if (from && from.pathname) {
        return <Redirect to={from} />;
      }

      return <Redirect to="/" />;
    }

    if (error) {
      return (
        <div className="col-12">
          <div className="text-center py-5">Erro</div>
        </div>
      );
    }

    return (
      <div className="col-12">
        <div className="text-center py-5">Carregando...</div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AutoLogin)
);
