import React from 'react';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import d4SignLogo from '~/Images/d4SignLogo.png';
import { format } from '~/Utils/Helpers';
import { CorEventoDocDigital, DATETIME_DEFAULT_FORMAT } from '~/constant';

const HistoricoDocumentoDigital = ({ documento }) => {
  return (
    <section className="my-2">
      <h5>Histórico</h5>
      {documento.log.length === 0 && (
        <p>
          Histórico ainda não disponível, esta é uma nova solicitação de
          assinatura digital
        </p>
      )}
      <Timeline lineColor={'#ddd'} animate className="ml-auto">
        {documento.log.map((el, index) => {
          const corEvento = CorEventoDocDigital.find(
            t => t.value === el.evento
          );
          const cor = corEvento ? corEvento.color : '#C4C4C4';

          return (
            <TimelineItem
              key={index}
              dateText={format.date(el.data, DATETIME_DEFAULT_FORMAT)}
              dateInnerStyle={{
                background: cor,
              }}
              style={{
                color: cor,
              }}
            >
              <>
                <h5>{el.descricao_evento}</h5>
                <p className="text-muted">{el.usuario.nome}</p>
                <p>{el.observacao}</p>
                {el.origem_remota && (
                  <img
                    alt="logo D4Sign"
                    style={{
                      width: 100,
                      position: 'absolute',
                      top: 0,
                      left: 200,
                    }}
                    src={d4SignLogo}
                  />
                )}
              </>
            </TimelineItem>
          );
        })}
      </Timeline>
    </section>
  );
};

export default HistoricoDocumentoDigital;
