import React, { Component } from 'react';
import { Formik } from 'formik';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

import * as api from '~/Services/Service';
import EscalaSatisfacao from '~/Components/EscalaSatisfacao/EscalaSatisfacao';

const TIPO_BUSCA_ATENDIMENTO = {
  1: 'Senha',
  2: 'Código do Prospecto',
  3: 'CPF',
};

export default class LancamentoAvaliacao extends Component {
  state = {
    erroSalvarPesquisa: null,
    tipoBusca: 1,
    clientes: {},
  };

  alteraTipo = tipoBusca => {
    this.setState({
      tipoBusca,
    });
  };

  buscaCliente = (value, callback) => {
    api.getProspectoAtendimento(this.state.tipoBusca, value).then(
      data => {
        if (data) {
          let clientes = this.state.clientes;
          clientes[data.codigo] = data;
          this.setState({
            clientes,
          });
        }

        let clientes = [data];
        clientes = clientes.map(cliente => ({
          value: cliente.codigo,
          label: cliente.prospecto.nome,
        }));

        callback(clientes);
      },
      error => {
        callback([]);
      }
    );
  };

  salvarPesquisa = async (
    { Cliente, avaliacao_atendente, avaliacao_corretor },
    actions
  ) => {
    const pesquisa = {
      codigo_atendimento: Cliente.value,
      avaliacao_atendente,
      avaliacao_corretor,
    };
    try {
      await api.salvarPesquisaAtendimento(pesquisa);

      toast.success('Pesquisa salva com sucesso');
      actions.setSubmitting(false);
      this.setState({ clientes: {} });
      actions.resetForm();
    } catch (error) {
      toast.error('Erro ao salvar a pesquisa. Tente novamente');
      actions.setSubmitting(false);
    }
  };

  render() {
    const { tipoBusca, clientes } = this.state;

    return (
      <div className="LancamentoAvaliacao container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card mt-4 border-0">
              <div className="card-body">
                <div className="d-flex flex-row mb-4 align-items-center justify-content-around">
                  <img
                    src="https://repositorio.setcorp.com.br/imagens/logo/setpar.svg"
                    className="mx-2"
                    height="30"
                    alt="Setpar"
                  />
                  <img
                    src="https://www.setpar.com.br/app/uploads/2019/01/logo-setvalley2.jpg"
                    className="mx-2"
                    height="60"
                    alt="SetValley 2"
                  />
                </div>
                <div className="text-center mb-2">
                  <h3>
                    Agradecemos sua visita e para atende-lo cada vez melhor,
                    pedimos que avalie nosso atendimento.
                  </h3>
                </div>
                <Formik
                  onSubmit={this.salvarPesquisa}
                  initialValues={{ Cliente: '' }}
                >
                  {({
                    values,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    isSubmitting,
                  }) => (
                    <div className="mx-auto mt-5">
                      <ul className="nav nav-pills justify-content-center mb-2">
                        {Object.keys(TIPO_BUSCA_ATENDIMENTO).map(key => (
                          <li key={key} className="nav-item">
                            <a
                              href="!#"
                              className={`nav-link ${Number(key) ===
                                Number(tipoBusca) && 'active'}`}
                              onClick={e => {
                                e.preventDefault();
                                this.alteraTipo(key);
                              }}
                            >
                              {TIPO_BUSCA_ATENDIMENTO[key]}
                            </a>
                          </li>
                        ))}
                      </ul>
                      <AsyncSelect
                        placeholder={`Informe ${TIPO_BUSCA_ATENDIMENTO[tipoBusca]} do cliente`}
                        noOptionsMessage={() => 'Nenhum cliente encontrado'}
                        loadOptions={this.buscaCliente}
                        onChange={option => setFieldValue('Cliente', option)}
                      />
                      {values.cliente && clientes[values.cliente.value] && (
                        <>
                          <div className="mt-5">
                            <EscalaSatisfacao
                              disabled={isSubmitting}
                              onChange={resposta =>
                                setFieldValue('avaliacao_atendente', resposta)
                              }
                              value={values.avaliacao_atendente}
                              error={errors && errors.avaliacao_atendente}
                              label={`Como avalia o atendimento do(a) <strong>${
                                clientes[values.cliente.value].atendente.nome
                              }</strong>?`}
                            />
                            <EscalaSatisfacao
                              disabled={isSubmitting}
                              onChange={resposta =>
                                setFieldValue('avaliacao_corretor', resposta)
                              }
                              value={values.avaliacao_corretor}
                              error={errors && errors.avaliacao_corretor}
                              label={`Como avalia o atendimento do(a) corretor(a) <strong>${
                                clientes[values.cliente.value].corretor.nome
                              }</strong>?`}
                            />
                          </div>
                          <button
                            className="btn btn-primary btn-lg m-auto d-flex align-items-center"
                            type="button"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                          >
                            <ClipLoader
                              css={css`
                                margin-right: 10px;
                              `}
                              sizeUnit={'px'}
                              size={20}
                              color={'#fff'}
                              loading={isSubmitting}
                            />
                            {isSubmitting ? 'Salvando...' : 'Salvar'}
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
