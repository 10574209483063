import React from 'react';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { createDate, format, getToday } from '~/Utils/Helpers';
import MaskedInput from 'react-text-mask';
import { DATE_FORMAT_SERVER, MASK_DATE } from '~/constant';
import dialog from '~/Utils/dialog';

const ModalDataVencimento = ({
  show,
  onCancel,
  setDataVenda,
  readonly,
  dataVenda,
  removeTodasParcelasEntrada,
  temParcelasSinal,
}) => {
  return (
    <Modal show={!!show} onHide={onCancel}>
      <Formik
        initialValues={{
          data_venda: format.date(dataVenda),
        }}
        onSubmit={async values => {
          if (temParcelasSinal) {
            const result = await dialog.confirm({
              title: 'Alterar data da venda?',
              message:
                'Ao alterar a data da venda, as parcelas de sinal informadas serão removidas. Deseja continuar?',
            });
            if (!result.proceed) return;
          }
          setDataVenda(
            createDate(values.data_venda, 'DD/MM/YYYY').format(
              DATE_FORMAT_SERVER
            )
          );
          if (temParcelasSinal) removeTodasParcelasEntrada();
          onCancel();
        }}
        validate={values => {
          let errors = {};
          // pra permitir o usuário limpar
          if (!values.data_venda) return {};
          if (!values.data_venda) errors.data_venda = 'Obrigatório';

          let dataVendaServer = createDate(values.data_venda, 'DD/MM/YYYY');
          if (dataVendaServer && !dataVendaServer.isValid())
            errors.data_venda = 'Informe uma data válida';
          if (dataVendaServer && dataVendaServer.isAfter(getToday()))
            errors.data_venda = 'Data da venda não pode ser futura';

          return errors;
        }}
      >
        {() => {
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Alterar data da venda</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md="6">
                    <div className="form-group">
                      <label htmlFor="data_venda">Data</label>
                      <Field
                        as={MaskedInput}
                        mask={MASK_DATE}
                        name="data_venda"
                        className="form-control"
                        disabled={readonly}
                        type="tel"
                        placeholder="Ex.: 10/10/2020"
                        required
                      />
                      <ErrorMessage
                        className="text-danger"
                        component="small"
                        name="data_venda"
                      />
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                {readonly ? (
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={onCancel}
                  >
                    Fechar
                  </Button>
                ) : (
                  <>
                    <Button variant="primary" className="mb-1" type="submit">
                      Salvar
                    </Button>
                    <Button
                      variant="outline-primary"
                      type="button"
                      onClick={onCancel}
                    >
                      Cancelar
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalDataVencimento;
