import React, { useMemo } from 'react';
import {
  Table,
  Button,
  Pagination,
  Form,
  Row,
  Col,
  Badge,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import _ from 'lodash';

import AuthStorage from '~/Utils/AuthStorage';
import {
  obterTotalTitulosSinal,
  agruparPorContaFamiliaCorretor,
} from '../helpers';
import { formatarMonetario, format } from '~/Utils/Helpers';
import { TabelaInadimplenciaContainer } from './styles';
import moment from 'moment';

const TabelaInadimplencia = ({
  registros,
  mostrarDetalhesRegistro,
  empreendimentos,
}) => {
  const registrosTabela = _(agruparPorContaFamiliaCorretor(registros))
    .orderBy(entry => entry.cliente.razao_social)
    .value();
  const isAdm = AuthStorage.isADM();
  const isCor = AuthStorage.isCorretor();

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'nome',
        Cell: ({ row, value }) => (
          <span className="d-block">
            <span>{value}</span>
            {row.original.encaminhavel && (
              <OverlayTrigger
                overlay={
                  <Tooltip>Cobrador associado a um ou mais contratos</Tooltip>
                }
              >
                <Badge
                  variant="success"
                  style={{ cursor: 'default' }}
                  className="ml-2"
                >
                  Cobrador
                </Badge>
              </OverlayTrigger>
            )}
            {row.original.encaminhada && (
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Um ou mais contratos foram encaminhados para o Departamento
                    de Adm. de Recebíveis (Cobrança)
                  </Tooltip>
                }
              >
                <Badge
                  variant="warning"
                  style={{ cursor: 'default' }}
                  className="ml-2"
                >
                  Encaminhada
                </Badge>
              </OverlayTrigger>
            )}
            {row.original.teveContato && (
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Ocorrência registrada em um ou mais contratos
                  </Tooltip>
                }
              >
                <GoPrimitiveDot color="#007bff" className="ml-2" />
              </OverlayTrigger>
            )}
          </span>
        ),
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
        Cell: props => format.cpf(props.value),
      },
      {
        Header: 'Empreendimento',
        accessor: 'nomeEmpreendimento',
      },
      {
        Header: 'Imobiliária',
        id: 'nomeImobiliaria',
        accessor: 'nomeImobiliaria',
      },
      {
        Header: 'Corretor',
        id: 'nomeCorretor',
        accessor: 'nomeCorretor',
      },
      {
        Header: 'Dias Atraso',
        id: 'diasAtraso',
        accessor: 'diasAtraso',
        sortType: 'basic',
      },
      {
        Header: 'Títulos Atrasados',
        accessor: 'totalTitulos',
        sortType: 'basic',
        disableFilters: true,
        Cell: props => formatarMonetario(props.value),
      },
      {
        Header: () => null, // No header
        id: 'action', // It needs an ID
        Cell: ({ row }) => (
          <Button
            variant="link"
            className="p-0"
            onClick={() =>
              mostrarDetalhesRegistro(
                registrosTabela.find(
                  re =>
                    re.cliente.codigo_conta_cliente ===
                      row.original.codigoConta &&
                    re.cliente.codigo_familia === row.original.codigoFamilia
                )
              )
            }
          >
            Detalhes
          </Button>
        ),
      },
    ],
    [registros]
  );

  const data = useMemo(
    () =>
      registrosTabela.map(registro => {
        const empreendimento = empreendimentos.find(
          emp => emp.codigo === registro.cliente.codigo_familia
        );

        const hoje = moment(new Date());
        const vencimento = moment(
          registro.cliente.data_vencimento_titulo_sinal
        );
        const duracao = moment.duration(hoje.diff(vencimento));

        return {
          nome: registro.cliente.razao_social.toUpperCase(),
          cpf: registro.cliente.cpf,
          codigoConta: registro.cliente.codigo_conta_cliente,
          codigoFamilia: registro.cliente.codigo_familia,
          totalTitulos: obterTotalTitulosSinal(registro.registros),
          nomeEmpreendimento: empreendimento
            ? empreendimento.descricao
            : '(não encontrado)',
          nomeImobiliaria: registro.cliente.nome_imobiliaria || 'Revo',
          nomeCorretor: registro.cliente.apelido_conta_corretor,
          encaminhavel:
            registro.registros.filter(reg => !!reg.codigo_conta_cobrador)
              .length > 0,
          encaminhada: !!registro.registros.find(
            reg => reg.status_encaminhamento === 1
          ),
          teveContato:
            registro.registros.filter(reg => !!reg.ultimo_contato_corretor)
              .length > 0,
          diasAtraso: Math.floor(duracao.asDays()),
        };
      }),
    [registros]
  );

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return (
      <Form.Control
        as="input"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder="Filtrar..."
      />
    );
  }

  const hiddenColumns = ['conta', 'familia'];
  if (!isAdm) hiddenColumns.push('nomeImobiliaria');
  if (isCor) hiddenColumns.push('nomeCorretor');
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn: useMemo(
        () => ({
          Filter: DefaultColumnFilter,
        }),
        []
      ),
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: useMemo(() => [{ id: 'nome', desc: false }], []),
        hiddenColumns: hiddenColumns,
      },
      disableSortRemove: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;

  return (
    <TabelaInadimplenciaContainer className="mx-4">
      <Row>
        <Col>
          <h5>Lista de clientes inadimplentes</h5>
          <span>
            Clique em detalhes para ver as informações do cliente, enviar 2º via
            de boleto, dentre outras funções
          </span>
        </Col>
        <Col sm={4}>
          <Form.Control
            as="select"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50, 100, 200].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>

      <Table {...getTableProps()} striped bordered hover responsive>
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div>
                    {// Render the header
                    column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <MdKeyboardArrowDown />
                        ) : (
                          <MdKeyboardArrowUp />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  <div onClick={e => e.stopPropagation()}>
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          page.map(row => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {// Loop over the rows cells
                row.cells.map(cell => {
                  // Apply the cell props
                  return (
                    <td {...cell.getCellProps()}>
                      {// Render the cell contents
                      cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Pagination>
        <Pagination.First
          onClick={() => gotoPage(0)}
          disabled={pageIndex === 0}
        />
        <Pagination.Prev
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        />
        {pageIndex > 0 && (
          <>
            <Pagination.Item onClick={() => gotoPage(0)}>1</Pagination.Item>
            {pageIndex - 2 > 1 && <Pagination.Ellipsis />}
          </>
        )}
        {pageIndex > 2 && (
          <Pagination.Item onClick={() => gotoPage(pageIndex - 2)}>
            {pageIndex - 1}
          </Pagination.Item>
        )}

        {pageIndex > 1 && (
          <Pagination.Item onClick={() => gotoPage(pageIndex - 1)}>
            {pageIndex}
          </Pagination.Item>
        )}

        <Pagination.Item active>{pageIndex + 1}</Pagination.Item>

        {pageIndex < pageOptions.length - 2 && (
          <Pagination.Item onClick={() => gotoPage(pageIndex + 1)}>
            {pageIndex + 2}
          </Pagination.Item>
        )}
        {pageIndex < pageOptions.length - 3 && (
          <Pagination.Item onClick={() => gotoPage(pageIndex + 2)}>
            {pageIndex + 3}
          </Pagination.Item>
        )}
        {pageIndex < pageOptions.length - 1 && (
          <>
            {pageIndex + 4 < pageOptions.length && <Pagination.Ellipsis />}
            <Pagination.Item onClick={() => gotoPage(pageOptions.length - 1)}>
              {pageOptions.length}
            </Pagination.Item>
          </>
        )}

        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        <Pagination.Last onClick={() => gotoPage(pageOptions.length - 1)} />
      </Pagination>
    </TabelaInadimplenciaContainer>
  );
};

export default TabelaInadimplencia;
