import { Component } from 'react';
import { buscaProfissoes } from '~/Store/Prospecto/actions';

let timeout = null;
export default class BuscarProfissao extends Component {
  carregarProfissoes = (inputValue, callback) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      buscaProfissoes(inputValue).then(response => {
        let profissoes = response.map(item => ({
          value: item.codigo,
          label: item.descricao,
        }));
        callback(profissoes);
      });
    }, 500);
  };

  render() {
    return this.props.children({
      carregarProfissoes: this.carregarProfissoes,
    });
  }
}
