var webStorage = window.localStorage || window.sessionStorage

const LocalStorage = {
    set(key, value) {
        if (typeof value === 'object' || value.constructor === Array) {
            value = JSON.stringify(value)
        }
        webStorage.setItem(key, value)
    },
    get(key) {
        try {
            return JSON.parse(webStorage.getItem(key))
        } catch (e) {
            return webStorage.getItem(key)
        }
    },
    remove(key) {
        return webStorage.removeItem(key)
    },
    hasSupport() {
        return true
    }
}

export default LocalStorage
