import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { format } from '~/Utils/Helpers';
import { MASK_CPF } from '~/constant';

const testemunhaValidationSchema = Yup.object().shape({
  nome: Yup.string().required('Informe o nome'),
  email: Yup.string()
    .required('Informe o e-mail')
    .email('Informe um email válido'),
  cpf: Yup.string().required('Informe o cpf'),
});

const ModalTestemunha = ({
  show,
  onClosing,
  onSubmit,
  testemunhaSelecionada,
}) => (
  <Formik
    enableReinitialize={true}
    validationSchema={testemunhaValidationSchema}
    onSubmit={onSubmit}
    initialValues={{
      nome: testemunhaSelecionada.nome,
      email: testemunhaSelecionada.email,
      cpf: testemunhaSelecionada.cpf,
    }}
  >
    {({
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      isSubmitting,
      resetForm,
    }) => (
      <Modal
        centered
        show={show}
        onHide={() => {
          onClosing();
          resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Testemunha</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome"
              name="nome"
              value={values.nome}
              onChange={handleChange}
              disabled={isSubmitting}
              isInvalid={touched.nome && errors.nome}
            />
            <Form.Control.Feedback type="invalid">
              {errors.nome}
            </Form.Control.Feedback>

            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="text"
              placeholder="E-mail"
              name="email"
              value={values.email}
              onChange={handleChange}
              disabled={isSubmitting}
              isInvalid={touched.email && errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>

            <Form.Label>CPF</Form.Label>
            <MaskedInput
              className={`form-control ${errors.cpf &&
                touched.cpf &&
                'is-invalid'}`}
              type="text"
              placeholder="000.000.000-00"
              name="cpf"
              value={format.cpf(values.cpf)}
              onChange={handleChange}
              isInvalid={touched.cpf && errors.cpf}
              disabled={isSubmitting}
              mask={MASK_CPF}
            />

            <Form.Control.Feedback type="invalid">
              {errors.cpf}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="mb-1"
            onClick={handleSubmit}
            // disabled={!values.cpf || !values.nome || !values.cpf}
          >
            {testemunhaSelecionada.nome ? 'Editar' : 'Incluir'}
          </Button>
          <Button
            variant="outline-primary"
            disabled={isSubmitting}
            onClick={() => {
              onClosing();
              resetForm();
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
);

export default ModalTestemunha;
