import styled from 'styled-components';

import medalhaOuro from '~/Images/medalha-ouro.svg';
import medalhaPrata from '~/Images/medalha-prata.svg';
import medalhaBronze from '~/Images/medalha-bronze.svg';
import star from '~/Images/star.svg';

export const CorretorPodium = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  line-height: 1.1;

  @media (min-width: 768px) {
    margin: 0 10px;
  }

  @media (min-width: 1366px) {
    margin: 0 20px;
  }

  .podium-avatar {
    margin-bottom: 45px;
    position: relative;

    img {
      box-shadow: 0 2px 10px
        ${props => (props.classificado ? '#fdd94b' : 'rgba(0, 0, 0, 0.2)')};

      border: 5px solid
        ${props => {
          switch (props.rank) {
            case 1:
              return '#fdd94b';
            case 2:
              return '#D4D4D4';
            case 3:
              return '#EC9922';
            default:
              return;
          }
        }};
      max-width: 80px;
      position: relative;
      z-index: 12;
      @media (min-width: 768px) {
        max-width: 90px;
      }
      @media (min-width: 1200px) {
        max-width: 110px;
      }
      @media (min-width: 1366px) {
        max-width: 130px;
        max-height: 130px;
      }
    }

    &::before {
      z-index: 11;
      content: '';
      display: block;
      position: absolute;
      bottom: -40px;
      width: 45px;
      height: 45px;
      left: calc(50% - calc(45px / 2));
      background: url(${props => {
        switch (props.rank) {
          case 1:
            return medalhaOuro;
          case 2:
            return medalhaPrata;
          case 3:
            return medalhaBronze;
          default:
            return;
        }
      }});
      background-repeat: no-repeat;
    }

    &::after {
      content: '';
      z-index: 12;
      display: block;
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      right: -5px;
      background: url(${props => (props.classificado ? star : null)});
      background-repeat: no-repeat;
    }
  }
`;
