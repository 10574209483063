import React from 'react';
import { AvisoContainer } from './styles';

const Aviso = ({ text, icon }) => {
  return (
    <AvisoContainer>
      {icon}
      <p className="text-center">{text}</p>
    </AvisoContainer>
  );
};

export default Aviso;
