import React from 'react';

export default function EscalaSatisfacao({
  label,
  value,
  error,
  onChange,
  disabled,
  defaultValue,
}) {
  return (
    <div className="form-group mt-2">
      <label className={error && 'text-danger'} htmlFor={label}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      <ul id={label} className="EscalaSatisfacao list-unstyled">
        {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map(
          (resposta, index) => (
            <li
              className={`EscalaSatisfacao__item d-inline`}
              id={resposta.codigo}
              key={index}
            >
              <button
                type="button"
                disabled={disabled}
                className={`EscalaSatisfacao__btn rounded-pill btn ${
                  index <= (value || defaultValue)
                    ? 'btn-primary'
                    : 'btn-outline-secondary'
                }`}
                title={resposta}
                onClick={() => onChange(resposta)}
              >
                {resposta}
              </button>
            </li>
          )
        )}
      </ul>
      <div className="text-danger mt-0">{error}</div>
    </div>
  );
}
