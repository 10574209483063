import React from 'react';
import { Button } from 'react-bootstrap';

import { TiposGruposDocumentoDigital } from '~/constant';

const OmissorGrupo = ({
  grupo,
  incluirGrupo,
  onToggleIncluirGrupo,
  isBusy,
}) => {
  if (
    grupo.tipo === TiposGruposDocumentoDigital.Testemunhas ||
    grupo.tipo === TiposGruposDocumentoDigital.Procuradores
  )
    return (
      <Button
        variant={incluirGrupo ? 'warning' : 'success'}
        className="ml-auto"
        onClick={() => onToggleIncluirGrupo(!incluirGrupo)}
        disabled={isBusy}
      >
        {incluirGrupo ? 'Não quero ' : 'Quero '}
        incluir este grupo
      </Button>
    );
  return null;
};

export default OmissorGrupo;
