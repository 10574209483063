import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  TIPO_PESSOA_FISICA,
  TIPO_PESSOA_JURIDICA,
  TAMANHO_CPF,
} from '../../../constant';
import { bindActionCreators } from 'redux';
import {
  consultaCPF,
  limpaProspectoEncaminhar,
} from '~/Store/Prospecto/actions';
import PropTypes from 'prop-types';
import ModalProspectoExistente from './ModalProspectoExistente';

class ConsultaCPF extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    ignore: PropTypes.bool,
  };

  handleChange = event => {
    if (this.props.ignore) {
      this.props.onChangeCPF(event);
      return;
    }
    let numerosCPF = event.target.value.replace(/\D/g, '');

    if (
      numerosCPF.length === TAMANHO_CPF &&
      Number(this.props.values.tipo_pessoa) === TIPO_PESSOA_FISICA.value
    ) {
      this.props.consultaCPF(numerosCPF);
    }

    if (
      numerosCPF.length > TAMANHO_CPF &&
      Number(this.props.values.tipo_pessoa) === TIPO_PESSOA_JURIDICA.value
    ) {
      this.props.consultaCPF(numerosCPF);
    }

    this.props.onChangeCPF(event);
  };

  //Se o CPF existir, direciona para o prospecto existente com o CPF informado
  encaminhaProspecto = () => {
    const { codigo } = this.props.prospectoParaEncaminhar;

    this.props.limpaProspectoEncaminhar();
    this.props.history.push(`/prospectos/${codigo}/dados-clientes/pessoais`);
  };

  render() {
    const { onChangeCPF, prospectoParaEncaminhar } = this.props;
    return (
      <>
        <ModalProspectoExistente
          show={prospectoParaEncaminhar && prospectoParaEncaminhar.codigo}
          onCancel={() => {
            this.props.limpaProspectoEncaminhar();
            onChangeCPF('');
          }}
          prospectoParaEncaminhar={prospectoParaEncaminhar}
          onRedirect={this.encaminhaProspecto}
        />
        {this.props.children({
          onChangeCPF: this.handleChange,
          consultandoCPF: this.props.consultandoCPF,
        })}
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ consultaCPF, limpaProspectoEncaminhar }, dispatch);

const mapStateToProps = state => ({
  consultandoCPF: state.prospecto.consultandoCPF,
  prospectoParaEncaminhar: state.prospecto.prospectoParaEncaminhar,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConsultaCPF)
);
