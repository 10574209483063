import { combineReducers } from 'redux';

export const PRODUTIVIDADE_CARREGADA = 'PRODUTIVIDADE_CARREGADA';
export const CARREGANDO_PRODUTIVIDADE = 'CARREGANDO_PRODUTIVIDADE';
export const ATUALIZA_RESUMO = 'ATUALIZA_RESUMO';
export const CARREGANDO_PRODUTIVIDADE_POR_CORRETOR =
  'CARREGANDO_PRODUTIVIDADE_POR_CORRETOR';
export const PRODUTIVIDADE_POR_CORRETOR_CARREGADA =
  'PRODUTIVIDADE_POR_CORRETOR_CARREGADA';
export const CARREGANDO_PRODUTIVIDADE_POR_EMPREENDIMENTO =
  'CARREGANDO_PRODUTIVIDADE_POR_EMPREENDIMENTO';
export const PRODUTIVIDADE_POR_EMPREENDIMENTO_CARREGADA =
  'PRODUTIVIDADE_POR_EMPREENDIMENTO_CARREGADA';
export const SELECIONA_IMOBILIARIA = 'SELECIONA_IMOBILIARIA';
export const SELECIONA_CORRETOR = 'SELECIONA_CORRETOR';
export const SELECIONA_EMPREENDIMENTO_PRODUTIVIDADE =
  'SELECIONA_EMPREENDIMENTO_PRODUTIVIDADE';

const imobiliarias = (state = [], action) => {
  switch (action.type) {
    case PRODUTIVIDADE_CARREGADA:
      return action.payload.produtividade.imobiliarias;
    default:
      return state;
  }
};

const resumoCarregado = (state = null, action) => {
  switch (action.type) {
    case PRODUTIVIDADE_CARREGADA:
      return action.payload.produtividade;
    default:
      return state;
  }
};

const resumo = (state = null, action) => {
  switch (action.type) {
    case PRODUTIVIDADE_CARREGADA:
      return action.payload.produtividade;
    case ATUALIZA_RESUMO:
      return action.payload;
    default:
      return state;
  }
};

const carregandoProdutividade = (state = false, action) => {
  switch (action.type) {
    case CARREGANDO_PRODUTIVIDADE:
      return true;
    case PRODUTIVIDADE_CARREGADA:
      return false;
    default:
      return state;
  }
};

const carregandoProdutivdadeCorretor = (state = false, action) => {
  switch (action.type) {
    case CARREGANDO_PRODUTIVIDADE_POR_CORRETOR:
      return true;
    case PRODUTIVIDADE_POR_CORRETOR_CARREGADA:
      return false;
    default:
      return state;
  }
};

const corretoresCarregados = (state = {}, action) => {
  switch (action.type) {
    case PRODUTIVIDADE_POR_CORRETOR_CARREGADA:
      //TODO adicionar imobiliaria
      state[action.payload.codigo] = action.payload;
      return state;
    case CARREGANDO_PRODUTIVIDADE:
      return {};
    default:
      return state;
  }
};

const carregandoProdutivdadeEmpreendimento = (state = false, action) => {
  switch (action.type) {
    case CARREGANDO_PRODUTIVIDADE_POR_EMPREENDIMENTO:
      return true;
    case PRODUTIVIDADE_POR_EMPREENDIMENTO_CARREGADA:
      return false;
    default:
      return state;
  }
};

const empreendimentosCarregados = (state = {}, action) => {
  switch (action.type) {
    case PRODUTIVIDADE_POR_EMPREENDIMENTO_CARREGADA:
      state[action.payload.codigo] = action.payload;
      return state;
    default:
      return state;
  }
};

const filtroSelecionado = (state = {}, action) => {
  switch (action.type) {
    case PRODUTIVIDADE_CARREGADA:
      return action.payload.filtro;
    default:
      return state;
  }
};

const imobiliariaSelecionada = (state = null, action) => {
  switch (action.type) {
    case SELECIONA_IMOBILIARIA:
      return action.payload.imobiliaria;
    case CARREGANDO_PRODUTIVIDADE:
      return null;
    default:
      return state;
  }
};

const corretorSelecionado = (state = null, action) => {
  switch (action.type) {
    case SELECIONA_CORRETOR:
      return action.payload;
    case CARREGANDO_PRODUTIVIDADE:
      return null;
    default:
      return state;
  }
};

const empreendimentoSelecionado = (state = null, action) => {
  switch (action.type) {
    case SELECIONA_EMPREENDIMENTO_PRODUTIVIDADE:
      return action.payload;
    case CARREGANDO_PRODUTIVIDADE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  resumo,
  resumoCarregado,
  imobiliarias,
  carregandoProdutividade,
  corretoresCarregados,
  filtroSelecionado,
  carregandoProdutivdadeCorretor,
  carregandoProdutivdadeEmpreendimento,
  empreendimentosCarregados,
  imobiliariaSelecionada,
  corretorSelecionado,
  empreendimentoSelecionado,
});
