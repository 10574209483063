import { combineReducers } from 'redux'
import AuthStorage from '../../Utils/AuthStorage'
import { DO_LOGIN, DO_LOGOUT } from './AuthActions'

const user = (state = AuthStorage.getUser(), action) => {
    switch (action.type) {
    case DO_LOGIN:
        return action.payload
    case DO_LOGOUT:
        return null
    default:
        return state
    }
}

export default combineReducers({
    user
})