import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { default as Selector } from 'react-select';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import MaskedInput from 'react-text-mask';
import { FaSearch } from 'react-icons/fa';
import { Formik } from 'formik';

import { carregaProdutividade } from '~/Store/Produtividade/actions';
import { getAtualizarCidades } from '~/Store/Prospecto/actions';
import { createDate, getToday } from '../../Utils/Helpers';
import * as constants from '../../constant';
import DateMask from '../DateMask';

class CardProdutividadeFiltro extends Component {
  state = {};

  filtroCidades = string => {
    return this.props.cidades
      .filter(cidades =>
        cidades.descricao.toLowerCase().includes(string.toLowerCase())
      )
      .map(item => ({
        value: item.codigo,
        label: item.descricao,
      }));
  };

  timeout = null;
  carregarCidades = (inputValue, callback) => {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      callback(this.filtroCidades(inputValue));
    }, 500);
  };

  preparaEndereco = endereco => {
    getAtualizarCidades(endereco.UF);
  };

  buscarProdutividade = ({
    data_inicial,
    data_final,
    codigo_empreendimento,
    tipo_periodo,
    visao,
    codigo_cidade,
    tipo_venda,
  }) => {
    let apenas_estoque = tipo_periodo === constants.TIPO_PERIODO_ESTOQUE,
      apenas_lancamento = tipo_periodo === constants.TIPO_PERIODO_LANCAMENTO,
      apenas_efetivadas = tipo_venda === 'E';

    let params = {
      codigo_empreendimento,
      data_inicial,
      data_final,
      apenas_estoque,
      apenas_lancamento,
      visao,
      codigo_cidade,
      apenas_efetivadas,
    };

    this.props.carregaProdutividade(params);
  };

  verificaPeriodo = (tipo_periodo, values, setFieldValue) => {
    setFieldValue('tipo_periodo', tipo_periodo);
    let data_inicial = '';
    let data_final = '';
    let { data_lancamento } = this.props.empreendimentos.byId[
      values.codigo_empreendimento
    ];
    if (data_lancamento) {
      let Hoje = getToday();
      if (tipo_periodo === constants.TIPO_PERIODO_ESTOQUE) {
        data_lancamento = createDate(data_lancamento);
        data_inicial = data_lancamento.add(90, 'days');
        if (Hoje.isBefore(data_inicial)) {
          data_inicial = Hoje;
        }
        data_inicial = data_inicial.format(constants.DATE_FORMAT_SERVER);
      }

      if (tipo_periodo === constants.TIPO_PERIODO_LANCAMENTO) {
        data_lancamento = createDate(data_lancamento);
        data_inicial = data_lancamento.format(constants.DATE_FORMAT_SERVER);
        data_final = data_lancamento.add(90, 'days');
        if (Hoje.isBefore(data_final)) {
          data_final = Hoje;
        }
        data_final = data_final.format(constants.DATE_FORMAT_SERVER);
      }
    }

    setFieldValue('data_inicial', data_inicial);
    setFieldValue('data_final', data_final);
  };

  verificaEmpreendimento = (codigo_empreendimento, values, setFieldValue) => {
    setFieldValue('codigo_empreendimento', codigo_empreendimento);
    let tipo_periodo = values.tipo_periodo;
    if (codigo_empreendimento === '') {
      tipo_periodo = constants.TIPO_PERIODO_ESPECIFICO;
      setFieldValue('tipo_periodo', tipo_periodo);
    }
    this.verificaPeriodo(
      tipo_periodo,
      { codigo_empreendimento, ...values },
      setFieldValue
    );
  };

  buildValueEmpreendimento = codigo => {
    return codigo
      ? {
          value: parseInt(codigo),
          label: this.props.empreendimentos.byId[codigo].descricao,
        }
      : { value: '', label: 'Todos empreendimentos' };
  };

  render() {
    const {
      empreendimentos,
      isLoadingOptions,
      cidades,
      cidadesById,
      getAtualizarCidades,
    } = this.props;

    const optionsEmpreendimentos = [
      { value: '', label: 'Todos empreendimentos' },
      ...empreendimentos.sort.map(item => ({
        value: item.codigo,
        label: item.descricao,
      })),
    ];

    return (
      <Formik
        onSubmit={this.buscarProdutividade}
        initialValues={{
          tipo_periodo: constants.TIPO_PERIODO_ESPECIFICO,
          visao: 0,
          data_final: getToday(),
          data_inicial: getToday().add(-7, 'days'),
        }}
      >
        {({
          handleSubmit,
          values,
          handleChange,
          setFieldValue,
          errors,
          touched,
        }) => (
          <div className="CardProdutividadeFiltro">
            <Col>
              <Card>
                <Card.Body>
                  <form onSubmit={handleSubmit}>
                    <Row className="align-items-end">
                      <Col xs="12" sm="8" lg="4">
                        <Form.Group controlId="search.codigo_empreendimento">
                          <Form.Label>Empreendimento</Form.Label>
                          <Selector
                            noOptionsMessage={() =>
                              'Nenhum empreendimentos encontrado'
                            }
                            placeholder="Pesquisar empreendimentos..."
                            options={optionsEmpreendimentos}
                            onChange={({ value, label }) =>
                              this.verificaEmpreendimento(
                                value,
                                values,
                                setFieldValue
                              )
                            }
                            isLoading={isLoadingOptions}
                            value={this.buildValueEmpreendimento(
                              values.codigo_empreendimento
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs="12" sm="3" lg="2">
                        <Form.Group controlId="search.estado">
                          <Form.Label>Estado</Form.Label>
                          <Form.Control
                            as="select"
                            className="form-control"
                            name="estado"
                            onChange={event => {
                              getAtualizarCidades(event.target.value);
                              handleChange(event);
                              setFieldValue('codigo_cidade', '');
                            }}
                            value={values.estado}
                            isInvalid={errors.estado && touched.estado}
                          >
                            <option value="">Estado</option>
                            {constants.ESTADOS.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs="12" lg="4">
                        <Form.Group controlId="search.codigo_cidade">
                          <Form.Label>Cidade</Form.Label>
                          <AsyncSelect
                            isDisabled={cidades && cidades.length === 0}
                            isLoading={values.estado && cidades.length === 0}
                            loadingMessage={() => 'Carregando...'}
                            noOptionsMessage={() =>
                              'Digite uma cidade. Ex.: Rio Preto'
                            }
                            placeholder="Pesquisar cidades..."
                            loadOptions={this.carregarCidades}
                            onChange={option => {
                              setFieldValue('codigo_cidade', option.value);
                            }}
                            value={
                              values.codigo_cidade &&
                              cidades.length > 0 &&
                              cidadesById[values.codigo_cidade]
                                ? {
                                    value: parseInt(
                                      cidadesById[values.codigo_cidade].codigo
                                    ),
                                    label:
                                      cidadesById[values.codigo_cidade]
                                        .descricao,
                                  }
                                : {
                                    value: '',
                                    label:
                                      cidades.length === 0
                                        ? 'Selecione um Estado'
                                        : 'Selecione uma cidade',
                                  }
                            }
                          />
                          {errors.codigo_cidade && (
                            <small className="form-text text-danger">
                              {errors.codigo_cidade}
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xs="12" sm="3" lg="2">
                        <Form.Group controlId="produtividade.tipo_venda">
                          <Form.Label>Tipo Venda</Form.Label>
                          <Form.Control
                            as="select"
                            name="tipo_venda"
                            onChange={handleChange}
                            value={values.tipo_venda}
                          >
                            <option value="">Todas</option>
                            <option value="E">Efetivadas</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="align-items-end">
                      <Col xs="12" sm="4" lg="3">
                        <Form.Group controlId="produtividade.tipo_periodo">
                          <Form.Label>Tipo Período</Form.Label>
                          <Form.Control
                            as="select"
                            name="tipo_periodo"
                            disabled={
                              !values.codigo_empreendimento ||
                              values.codigo_empreendimento === ''
                            }
                            onChange={e =>
                              this.verificaPeriodo(
                                e.target.value,
                                values,
                                setFieldValue
                              )
                            }
                            value={values.tipo_periodo}
                          >
                            {constants.TIPO_PERIODO.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col xs="12" sm="4" lg="2">
                        <DateMask
                          initialValue={values.data_inicial}
                          allowEmpty
                          onValidate={value =>
                            setFieldValue('data_inicial', value)
                          }
                        >
                          {({ dateError, dateValue, setDate }) => (
                            <>
                              <Form.Label>Data Inicial</Form.Label>
                              <Form.Group controlId="produtividade.data_inicial">
                                <MaskedInput
                                  name="data_inicial"
                                  type="tel"
                                  guide={false}
                                  className={`form-control ${((errors.data_inicial &&
                                    touched.data_inicial) ||
                                    dateError) &&
                                    'is-invalid'}`}
                                  placeholder="Ex.: 01/01/2019"
                                  mask={constants.MASK_DATE}
                                  value={dateValue}
                                  disabled={
                                    values.tipo_periodo !==
                                    constants.TIPO_PERIODO_ESPECIFICO
                                  }
                                  onChange={e => setDate(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.data_inicial}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                  {dateError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </>
                          )}
                        </DateMask>
                      </Col>
                      <Col xs="12" sm="4" lg="2">
                        <DateMask
                          initialValue={values.data_final}
                          allowEmpty
                          onValidate={value =>
                            setFieldValue('data_final', value)
                          }
                        >
                          {({ dateError, dateValue, setDate }) => (
                            <>
                              <Form.Label>Data Final</Form.Label>
                              <Form.Group controlId="produtividade.data_final">
                                <MaskedInput
                                  name="data_final"
                                  type="tel"
                                  guide={false}
                                  className={`form-control ${((errors.data_final &&
                                    touched.data_final) ||
                                    dateError) &&
                                    'is-invalid'}`}
                                  placeholder="Ex.: 01/01/2019"
                                  mask={constants.MASK_DATE}
                                  value={dateValue}
                                  disabled={
                                    values.tipo_periodo ===
                                    constants.TIPO_PERIODO_LANCAMENTO
                                  }
                                  onChange={e => setDate(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.data_final}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                  {dateError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </>
                          )}
                        </DateMask>
                      </Col>
                      <Col xs="12" sm="6" lg="3">
                        <Form.Group controlId="produtividade.visao">
                          <Form.Label>Visão Ranking</Form.Label>
                          <Form.Control
                            as="select"
                            name="visao"
                            onChange={handleChange}
                            value={values.visao}
                          >
                            {constants.TIPO_VISAO_RANKING.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xs="12" sm="6" lg="2" className="text-center btn-sm">
                        <Form.Group controlId="produtividade.tipo_visao_ranking">
                          <Button
                            variant="outline-primary"
                            type="submit"
                            className="ml-auto"
                            onClick={() =>
                              this.props.history.push(
                                '/comercial/relatorio/produtividade'
                              )
                            }
                          >
                            <FaSearch />
                            <span>&nbsp;Gerar Relatório</span>
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </div>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  empreendimentos: state.empreendimentos,
  isLoadingOptions: state.prospecto.isLoadingOptions,
  cidades: state.options.cidades,
  cidadesById: state.options.cidadesById,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ carregaProdutividade, getAtualizarCidades }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardProdutividadeFiltro));
