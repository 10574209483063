import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';

const ModalCancelar = ({
  show,
  onClosing,
  onSubmit,
  opcao,
  isLoadingData,
  motivos,
}) => (
  <Formik
    enableReinitialize={true}
    onSubmit={onSubmit}
    initialValues={{ observacao: '', motivoCancelar: '' }}
  >
    {({
      handleSubmit,
      values,
      touched,
      errors,
      isSubmitting,
      resetForm,
      handleChange,
    }) => (
      <Modal
        centered
        show={show}
        onHide={() => {
          onClosing();
          resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {opcao === 'Cancelar' ? 'Cancelar' : 'Reprovar'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>
              Motivo <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              name="motivoCancelar"
              value={values.motivoCancelar}
              onChange={handleChange}
              disabled={isLoadingData}
              isInvalid={errors.motivoCancelar && touched.motivoCancelar}
            >
              <option value="">Selecione um motivo</option>
              {motivos &&
                motivos
                  .filter(motivo => motivo.codigo !== 0)
                  .map((motivo, index) => (
                    <option key={index} value={motivo.codigo}>
                      {motivo.descricao}
                    </option>
                  ))}
            </Form.Control>
          </Form.Group>
          <Form.Label>Observação</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Observação"
            name="observacao"
            value={values.observacao}
            onChange={handleChange}
            disabled={isSubmitting}
            isInvalid={touched.observacao && errors.observacao}
            style={{ resize: 'vertical', maxHeight: '300px' }}
          />
          <Form.Control.Feedback type="invalid">
            {errors.observacao}
          </Form.Control.Feedback>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="mb-1"
            onClick={handleSubmit}
            disabled={isSubmitting || values.motivoCancelar === ''}
          >
            Salvar
          </Button>
          <Button
            variant="outline-primary"
            disabled={isSubmitting}
            onClick={() => {
              onClosing();
              resetForm();
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
);

export default ModalCancelar;
