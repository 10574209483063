import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Col, Form, Card, Button, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FaSearch } from 'react-icons/fa';
import { FiSave } from 'react-icons/fi';

import * as api from '~/Services/Service';
import AuthStorage from '~/Utils/AuthStorage';
import Header from '~/Components/Header/Header';
import Loading from '~/Components/Loading';
import { Selector } from '~/Components/Prospectos/ProspectoDetalhes/Pesquisa/Form';
import SelectGerenteCorretor from '~/Components/GerentesCorretores/SelectGerenteCorretor';

class AtribuirProspectos extends Component {
  state = {
    prospectos: null,
  };

  onClickNovaBusca = () => {
    this.setState({
      prospectos: null,
    });
  };

  atribuirProspectos = async (
    { codigo_corretor, codigo_gerente, justificativa, vinculos },
    { setSubmitting }
  ) => {
    vinculos = vinculos.map(item => {
      const [codigo_prospecto, codigo_familia] = item.split('|');

      return {
        codigo_prospecto: Number(codigo_prospecto),
        codigo_familia: Number(codigo_familia),
      };
    });

    let filtro = {
      codigo_corretor,
      codigo_gerente,
      justificativa,
      vinculos,
    };

    try {
      await api.atribuirProspectos(filtro);
      toast.success('Prospectos atribuidos com sucesso!', {
        position: 'top-right',
      });
      this.setState({ prospectos: null });
    } catch (error) {
      toast.warn('Erro ao atribuir prospectos, tente novamente mais tarde', {
        position: 'top-right',
      });
    }

    setSubmitting(false);
  };

  buscarProspectos = async (
    { codigo_empreendimento, codigos_prospectos },
    { setSubmitting }
  ) => {
    let filtro = {
      codigo_familia: codigo_empreendimento,
      codigos_prospectos: codigos_prospectos
        .replace(/(\d*)\D+/g, '$1,')
        .split(',')
        .map(Number),
    };
    try {
      let prospectos = await api.buscarListaAtribuirProspectos(filtro);
      this.setState({ prospectos });
      setSubmitting(false);
    } catch (err) {
      if (AuthStorage.isADM) {
        toast.warn('Prospecto(s) não encontrado(s).');
      } else
        toast.warn(
          'Prospecto(s) não encontrado(s) ou o(s) mesmo(s) não tem vínculo(s) que pertença(m) à você.'
        );
    }
    setSubmitting(false);
  };

  buildValueEmpreendimento = codigo => {
    return codigo
      ? {
          value: parseInt(codigo),
          label: this.props.empreendimentos.byId[codigo].descricao,
        }
      : { value: '', label: 'Todos empreendimentos' };
  };

  selecionaTodosProspectos = (vinculos, prospectos) => {
    if (vinculos && vinculos.length > 0) return [];

    return prospectos.map(
      prospecto => `${prospecto.codigo_prospecto}|${prospecto.codigo_familia}`
    );
  };

  render() {
    const { prospectos } = this.state;
    const { empreendimentos, isLoadingOptions } = this.props;

    const optionsEmpreendimentos = [
      { value: '', label: 'Todos empreendimentos' },
      ...empreendimentos.sort.map(item => ({
        value: item.codigo,
        label: item.descricao,
      })),
    ];

    return (
      <>
        <Header title="Atribuir Prospectos" />

        {!prospectos && (
          <Formik
            onSubmit={this.buscarProspectos}
            initialValues={{
              codigo_empreendimento: null,
              prospectos_buscar: null,
            }}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
              isSubmitting,
            }) => (
              <Col>
                <Card>
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      {!prospectos && (
                        <>
                          {!isLoadingOptions && (
                            <Form.Group controlId="search.codigo_empreendimento">
                              <Selector
                                noOptionsMessage={() =>
                                  'Nenhum empreendimentos encontrado'
                                }
                                placeholder="Pesquisar empreendimentos..."
                                options={optionsEmpreendimentos}
                                onChange={option => {
                                  setFieldValue(
                                    'codigo_empreendimento',
                                    option.value
                                  );
                                }}
                                name="codigo_empreendimento"
                                isLoading={isLoadingOptions || isSubmitting}
                                value={this.buildValueEmpreendimento(
                                  values.codigo_empreendimento
                                )}
                              />
                            </Form.Group>
                          )}
                          <Form.Group>
                            <Form.Control
                              as="textarea"
                              rows="3"
                              name="codigos_prospectos"
                              onChange={handleChange}
                              disabled={isSubmitting}
                              required
                              placeholder="Informe um ou mais prospectos..."
                            />
                          </Form.Group>

                          <Form.Group className="text-center">
                            <Button
                              variant="outline-primary"
                              type="submit"
                              className="ml-auto"
                              disabled={isSubmitting}
                            >
                              <FaSearch />
                              <span> Buscar</span>
                            </Button>
                          </Form.Group>
                        </>
                      )}
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Formik>
        )}

        {prospectos && (
          <Formik
            onSubmit={this.atribuirProspectos}
            initialValues={{
              vinculos: [],
            }}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              setFieldValue,
              isSubmitting,
            }) => (
              <>
                <Col>
                  <Card>
                    <Card.Body>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="text-center">
                          <Button
                            variant="outline-primary"
                            type="submit"
                            className="ml-auto"
                            onClick={this.onClickNovaBusca}
                            disabled={isSubmitting}
                          >
                            <FaSearch />
                            <span>&nbsp;Nova Busca</span>
                          </Button>
                        </Form.Group>

                        <p className="text-center">
                          Selecione os prospectos e qual corretor/gerente deseja
                          atribui-lo, informe um motivo e clique em Atribuir
                          Prospectos
                        </p>

                        <SelectGerenteCorretor
                          gerente={values.codigo_gerente}
                          corretor={values.codigo_corretor}
                          onChangeGerente={gerente =>
                            setFieldValue('codigo_gerente', gerente)
                          }
                          onChangeCorretor={corretor =>
                            setFieldValue('codigo_corretor', corretor)
                          }
                          ColSizeMd={6}
                        />

                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            placeholder="Informe um motivo..."
                            name="justificativa"
                            onChange={handleChange}
                            disabled={isSubmitting}
                            required
                          />
                        </Form.Group>

                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th className="text-center">
                                <label
                                  htmlFor="all"
                                  className="cursor-pointer mb-0"
                                >
                                  <input
                                    className="mr-2"
                                    id="all"
                                    type="checkbox"
                                    checked={
                                      values.vinculos &&
                                      values.vinculos.length ===
                                        prospectos.length
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        'vinculos',
                                        this.selecionaTodosProspectos(
                                          values.vinculos,
                                          prospectos
                                        )
                                      )
                                    }
                                  />
                                  Atribuir
                                </label>
                              </th>
                              <th>Prospecto</th>
                              <th>Empreendimento</th>
                              <th>Gerente</th>
                              <th>Corretor</th>
                              <th>Gerente</th>
                            </tr>
                          </thead>
                          <tbody>
                            {prospectos.map((prospecto, index) => (
                              <tr key={index}>
                                <td>
                                  <Form.Check
                                    checked={
                                      values.vinculos &&
                                      values.vinculos.includes(
                                        `${prospecto.codigo_prospecto}|${prospecto.codigo_familia}`
                                      )
                                    }
                                    onChange={() => {
                                      let id = `${prospecto.codigo_prospecto}|${prospecto.codigo_familia}`;
                                      if (
                                        values.vinculos &&
                                        values.vinculos.includes(id)
                                      ) {
                                        values.vinculos.splice(
                                          values.vinculos.indexOf(id),
                                          1
                                        );
                                      } else {
                                        values.vinculos.push(id);
                                      }
                                      setFieldValue(
                                        'Vinculos',
                                        values.vinculos
                                      );
                                    }}
                                  />
                                </td>
                                <td>{prospecto.prospecto.nome}</td>
                                <td>{prospecto.empreendimento.descricao}</td>
                                <td>{prospecto.gerente.nome}</td>
                                <td>{prospecto.corretor.nome}</td>
                                <td
                                  className="text-center font-weight-bold"
                                  style={{ color: prospecto.imobiliaria.cor }}
                                >
                                  {prospecto.imobiliaria.nome}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        {isSubmitting && (
                          <Loading label="Atribuindo prospectos..." />
                        )}
                        {!isSubmitting && (
                          <Form.Group className="text-center">
                            <Button
                              variant="outline-success"
                              type="submit"
                              className="ml-auto"
                              disabled={isSubmitting}
                            >
                              <FiSave />
                              <span>&nbsp;Atribuir Prospectos</span>
                            </Button>
                          </Form.Group>
                        )}
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  empreendimentos: state.empreendimentos,
  isLoadingOptions: state.prospecto.is_loading_options,
});

export default connect(mapStateToProps)(withRouter(AtribuirProspectos));
