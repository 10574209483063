import {
  TIPO_CADASTRO,
  ESTADO_CIVIL_CS,
  ESTADO_CIVIL_CLIENTE,
  SEXO,
} from '~/constant';
import * as helpers from '~/Utils/Helpers';
import _indexOf from 'lodash/indexOf';

const returnDeafult = value => (value ? value : '-');

const PROSPECTO_LABELS_FORM = {
  codigo: {
    label: 'Codigo Prospecto',
    value: returnDeafult,
  },
  tipo_pessoa: {
    label: 'Tipo do Cadastro',
    value: value =>
      TIPO_CADASTRO.find(item => item.value === parseInt(value)).label,
  },
  nome: {
    label: 'Nome',
    value: returnDeafult,
  },
  cpf: {
    label: 'CPF',
    value: value => (value ? helpers.format.cpf(value) : '-'),
  },
  email: {
    label: 'E-mail',
    value: returnDeafult,
  },
  tel_celular: {
    label: 'Celular',
    value: value => (value ? helpers.format.celular(value) : '-'),
  },
  tel_residencial: {
    label: 'Telefone Residencial',
    value: value => (value ? helpers.format.phone(value) : '-'),
  },
  tel_comercial: {
    label: 'Telefone Comercial',
    value: value => (value ? helpers.format.phone(value) : '-'),
  },
  descricao_profissao: {
    label: 'Profissao',
    value: returnDeafult,
  },
  descricao_nacionalidade: {
    label: 'Nacionalidade',
    value: returnDeafult,
  },
  tipo_conjuge_socio: {
    label: 'Tipo do Sócio/Cônjuge',
    value: value =>
      value ? ESTADO_CIVIL_CS.find(item => item.value === value).label : '-',
  },
  estado_civil: {
    label: 'Estado Civil',
    value: value =>
      value
        ? ESTADO_CIVIL_CLIENTE.find(item => item.value === value).label
        : '-',
  },
  sexo: {
    label: 'Gênero',
    value: value =>
      value ? SEXO.find(item => item.value === value).label : '-',
  },
  rg: {
    label: 'RG',
    value: returnDeafult,
  },
  data_emissao_rg: {
    label: 'Data Emissão RG',
    value: value => (value ? helpers.format.date(value) : '-'),
  },
  cnh: {
    label: 'CNH',
    value: returnDeafult,
  },
  orgao_emissor_cnh: {
    label: 'Orgão Emissor CNH',
    value: returnDeafult,
  },
  nascimento: {
    label: 'Data de Nascimento',
    value: value => (value ? helpers.format.date(value) : '-'),
  },
  // CodigoStatus: {
  //   label: 'Status Cadastro',
  //   value: value =>
  //     value
  //       ? PROSPECTO_STATUS.find(item => item.value === parseInt(value)).label
  //       : '-',
  // },
  orgao_emissor_rg: {
    label: 'Orgão Emissor RG',
    value: returnDeafult,
  },
  data_casamento: {
    label: 'Data do Casamento',
    value: value => (value ? helpers.format.date(value) : '-'),
  },
  registro_antenupcial: {
    label: 'Registro Antenupcial',
    value: returnDeafult,
  },
  tabeliao: {
    label: 'Tabelião',
    value: returnDeafult,
  },
};

const PROSPECTO_LABELS_FORM_ENDERECO = {
  cep: {
    label: 'CEP',
    value: value => (value ? helpers.format.cep(value) : '-'),
  },
  estado: {
    label: 'Estado',
    value: returnDeafult,
  },
  cidade: {
    label: 'Cidade',
    value: returnDeafult,
  },
  bairro: {
    label: 'Bairro',
    value: returnDeafult,
  },
  endereco: {
    label: 'Endereço',
    value: returnDeafult,
  },
  numero: {
    label: 'Número',
    value: returnDeafult,
  },
  complemento: {
    label: 'Complemento',
    value: returnDeafult,
  },
};

const ProspectoFormatacaoDados = ({ prospecto, isDadosPessoais, children }) => {
  //Ignora campos padrões
  let ignore = ['codigo_prospecto_cliente', 'codigo', 'sequencia', 'ordem'];
  let keys = Object.keys(prospecto).filter(item => {
    return _indexOf(ignore, item) === -1;
  });

  let values = keys
    .map(item => {
      if (
        (isDadosPessoais && !PROSPECTO_LABELS_FORM[item]) ||
        (!isDadosPessoais && !PROSPECTO_LABELS_FORM_ENDERECO[item])
      ) {
        return null;
      }

      let resultado = isDadosPessoais
        ? {
            label: PROSPECTO_LABELS_FORM[item].label,
            value: PROSPECTO_LABELS_FORM[item].value(
              prospecto[item],
              prospecto
            ),
          }
        : {
            label: PROSPECTO_LABELS_FORM_ENDERECO[item].label,
            value: PROSPECTO_LABELS_FORM_ENDERECO[item].value(
              prospecto[item],
              prospecto
            ),
          };

      return resultado;
    })
    .filter(item => !!item);

  return children({
    values,
  });
};

export default ProspectoFormatacaoDados;
