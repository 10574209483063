import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  Form as FormBootstrap,
  Button,
} from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';

import { FORMAS_PAGAMENTO, CHEQUE } from '~/constant';
import * as api from '~/Services/Service';

import Boleto from './Boleto';
import Cheque from './Cheque';
import Cartao from './Cartao';
import Deposito from './Deposito';
import Dinheiro from './Dinheiro';
import formaPgtoValidate from './formaPgtoValidate';

export default function ModalFormaPgto({
  show,
  onCancel,
  onSave,
  initialValues,
  cartoes,
}) {
  const [erroValidacaoCheque, setErroValidacaoCheque] = useState(null);
  const [validandoCheque, setValidandoCheque] = useState(false);

  useEffect(() => setErroValidacaoCheque(null), [show]);

  return (
    <Modal show={show} size="lg" onHide={onCancel}>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          if (values.tipo_parcela === CHEQUE) {
            setValidandoCheque(true);
            setErroValidacaoCheque(null);
            try {
              const response = await api.validarCheque(values[CHEQUE]);
              if (response.em_uso) setErroValidacaoCheque('Cheque em uso');
              else onSave(values);
            } catch (err) {
              setErroValidacaoCheque(err.message);
              console.error(err);
            } finally {
              setValidandoCheque(false);
            }
          } else onSave(values);
        }}
        validate={formaPgtoValidate}
        validateOnMount={true}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <Modal.Header closeButton>
              {initialValues.hasOwnProperty('index')
                ? 'Editando'
                : 'Adicionando'}{' '}
              forma de pagamento
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <FormBootstrap.Group controlId="formaPagamento">
                    <FormBootstrap.Label>Forma Pgto.</FormBootstrap.Label>
                    <Field
                      name="tipo_parcela"
                      className="form-control"
                      as="select"
                      disabled={initialValues.hasOwnProperty('index')}
                      onChange={e =>
                        setFieldValue(e.target.name, parseInt(e.target.value))
                      }
                    >
                      <option value="">Selecione uma forma de pagamento</option>
                      {FORMAS_PAGAMENTO.map(item => (
                        <option value={item.value} key={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </Field>
                  </FormBootstrap.Group>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <Boleto />
                  <Cheque erroValidacaoCheque={erroValidacaoCheque} />
                  <Cartao cartoes={cartoes} />
                  <Deposito />
                  <Dinheiro />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" type="button" onClick={onCancel}>
                Cancelar
              </Button>
              {values.tipo_parcela && (
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {validandoCheque
                    ? 'Validando...'
                    : initialValues.hasOwnProperty('index')
                    ? 'Atualizar'
                    : 'Inserir'}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
