import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import prize from '~/Images/prize.gif';
import * as api from '~/Services/Service';

import Header from '~/Components/Header/Header';
import Loading from '~/Components/Loading';
import CardCampanha from '~/Components/GerenciamentoRankingCampanha/CardCampanha';
import ModalCampanha from '~/Components/GerenciamentoRankingCampanha/ModalCampanha';
import { ContainerCorretores } from '~/Components/Ranking/styles';
import Podium from '~/Components/Ranking/Podium';
import ListaRanking from '~/Components/Ranking/ListaRanking';

const GerenciamentoRankingCampanha = ({
  empreendimentos,
  imobiliarias,
  isLoadingOptions,
}) => {
  const [campanhas, setCampanhas] = useState([]);
  const [campanhaSelecionada, setCampanhaSelecionada] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [isEncerrando, setIsEncerrando] = useState(false);

  const [isMostrandoResultados, setIsMostrandoResultados] = useState(false);
  const [resultados, setResultados] = useState([]);

  const novaCampanha = {
    data_inicio: null,
    data_fim: null,
    codigo_familia: null,
    imobiliarias: [],
    criterio: null,
  };

  useEffect(() => {
    (async () => {
      const campanhas = await api.buscarCampanhas();
      setCampanhas(campanhas);
    })();
  }, []);

  const criarOuEditarCampanha = async values => {
    setIsBusy(true);
    try {
      if (values.codigo) {
        const campanha = await api.editarCampanha(values);
        atualizarCampanha(campanha);
      } else {
        const campanha = await api.criarCampanha(values);
        setCampanhas(prev => [...prev, campanha]);
      }
      setCampanhaSelecionada(null);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsBusy(false);
    }
  };

  const excluirCampanha = async codigo => {
    setIsBusy(true);
    try {
      await api.removerCampanha(codigo);
      setCampanhas(prev =>
        prev.filter(existente => existente.codigo !== codigo)
      );
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsBusy(false);
    }
  };

  const encerrarCampanha = async codigo => {
    setIsBusy(true);
    setIsEncerrando(true);
    setCampanhaSelecionada(campanhas.find(c => c.codigo === codigo));
    try {
      const campanha = await api.finalizarCampanha(codigo);
      atualizarCampanha(campanha);
      toast.success('Campanha encerrada!');
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setIsBusy(false);
      setIsEncerrando(false);
      setCampanhaSelecionada(null);
    }
  };

  const atualizarCampanha = campanha => {
    setCampanhas(prev =>
      prev.map(existente =>
        existente.codigo === campanha.codigo ? campanha : existente
      )
    );
  };

  const mostrarResultados = async codigo => {
    setIsMostrandoResultados(true);
    setCampanhaSelecionada(campanhas.find(c => c.codigo === codigo));
    setResultados([]);

    try {
      const resultados = await api.buscarResultadosCampanha(codigo);
      setResultados(resultados);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setIsMostrandoResultados(false);
      setCampanhaSelecionada(null);
    }
  };

  const renderResultados = () => {
    if (resultados.length === 0) return <Loading label="Carregando..." />;
    const rankingOrdenado = resultados.map((rankEntry, index) => ({
      ...rankEntry,
      classificado: index < campanhaSelecionada.numero_corretores,
    }));
    const podium = rankingOrdenado.slice(0, 3);
    const restoRanking = rankingOrdenado.slice(3, rankingOrdenado.length);

    return (
      <ContainerCorretores>
        <div className="container-fluid">
          <header className="row text-center">
            <div className="col-12">
              <h4 className="mb-0 text-uppercase font-weight-bold">
                Resultados
              </h4>
              <Podium corretores={podium} />
            </div>
          </header>

          <ListaRanking ranking={restoRanking} />
        </div>
      </ContainerCorretores>
    );
  };

  return (
    <>
      <Header title="Gerenciamento de Campanhas para Ranking">
        <Button
          variant="primary"
          onClick={() => setCampanhaSelecionada(novaCampanha)}
          disabled={isBusy}
        >
          Criar
        </Button>
      </Header>
      {campanhas.length === 0 && <Loading label="Carregando..." />}
      {campanhas.length > 0 && (
        <div className="d-flex flex-wrap align-items-stretch justify-content-center">
          {campanhas.map(campanha => (
            <CardCampanha
              key={campanha.codigo}
              campanha={campanha}
              excluirCampanha={excluirCampanha}
              encerrarCampanha={encerrarCampanha}
              editarCampanha={setCampanhaSelecionada}
              mostrarResultados={mostrarResultados}
              isBusy={isBusy}
            />
          ))}
        </div>
      )}
      <ModalCampanha
        campanha={
          isEncerrando || isMostrandoResultados ? null : campanhaSelecionada
        }
        onHide={() => setCampanhaSelecionada(null)}
        onSubmit={criarOuEditarCampanha}
        empreendimentos={empreendimentos}
        imobiliarias={imobiliarias}
        isLoadingOptions={isLoadingOptions}
      />
      <Modal show={isEncerrando}>
        <Modal.Header>Encerrando Campanha</Modal.Header>
        <Modal.Body className="text-center">
          <img src={prize} alt="prize" />
          {isEncerrando && campanhaSelecionada && (
            <p>
              Aguarde! Estamos gerando o resultado da campanha
              <strong className="ml-1">{campanhaSelecionada.descricao}</strong>.
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={isMostrandoResultados}
        onHide={() => {
          setIsMostrandoResultados(false);
          setCampanhaSelecionada(null);
        }}
      >
        <Modal.Header closeButton>
          Resultados da campanha{' '}
          {isMostrandoResultados && campanhaSelecionada.descricao}
        </Modal.Header>
        <Modal.Body>{isMostrandoResultados && renderResultados()}</Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    empreendimentos: state.empreendimentos.list,
    imobiliarias: state.corretor.listaGerentes,
    isLoadingOptions: state.prospecto.isLoadingOptions,
  };
};

export default connect(mapStateToProps)(GerenciamentoRankingCampanha);
