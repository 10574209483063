import Confirm from '~/Components/Confirm';
import Message from '~/Components/Message';

class Dialog {
  confirmModal = Confirm.create({});
  messageModal = Message.create({});
  confirm(props) {
    return this.confirmModal.show(props);
  }
  prompt(props) {
    return this.confirmModal.prompt(props);
  }
  showMessage(props) {
    this.messageModal.show(props);
  }
}
export default new Dialog();
