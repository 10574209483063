import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';

import MoneyField from '~/Components/Form/MoneyField';

import { BOLETO, MASK_DATE } from '~/constant';
import GeradorData from '~/Components/GeradorData';

export default function Boleto({ empreendimento }) {
  const { values, setFieldValue, initialValues } = useFormikContext();

  if (values.tipo_parcela !== BOLETO) return null;

  return (
    <Row>
      {!initialValues.hasOwnProperty('index') && (
        <Col md="4">
          <Form.Group>
            <Form.Label>Qtd. Parcelas</Form.Label>
            <Field
              name={`${BOLETO}.qtdParcelas`}
              className="form-control"
              type="number"
              placeholder="Ex.: 10"
              min={1}
              max={120}
            />
            <ErrorMessage
              name={`${BOLETO}.qtdParcelas`}
              component="small"
              className="text-danger"
            />
          </Form.Group>
        </Col>
      )}
      <Col md="4">
        <Form.Group>
          <Form.Label>Valor da parcela</Form.Label>
          <Field
            name={`${BOLETO}.valor`}
            className="form-control"
            type="tel"
            placeholder="Ex.: 1.000,00"
            component={MoneyField}
          />
          <ErrorMessage
            name={`${BOLETO}.valor`}
            component="small"
            className="text-danger"
          />
        </Form.Group>
      </Col>

      <Col md="4">
        <Form.Group>
          <Form.Label>Vencimento</Form.Label>
          <Field
            as={MaskedInput}
            mask={MASK_DATE}
            name={`${BOLETO}.vencimento_mask`}
            className="form-control"
            type="tel"
            placeholder="Ex.: 10/10/2020"
          />
          <ErrorMessage
            name={`${BOLETO}.vencimento_mask`}
            className="text-danger"
            component="small"
          />
          <GeradorData
            original={values[BOLETO].vencimento_mask}
            onUpdate={date => setFieldValue(`${BOLETO}.vencimento`, date)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
