import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsInbox } from 'react-icons/bs';
import { Card, Container, Button, Modal } from 'react-bootstrap';
import _ from 'lodash';

import * as api from '~/Services/Service';
import { format } from '~/Utils/Helpers';

import Header from '~/Components/Header/Header';
import Loading from '~/Components/Loading';
import Aviso from '~/Components/Aviso';
import ConfirmButton from '~/Components/ConfirmButton';

const AutorizacaoVenda = ({ history }) => {
  const [buscandoPropostas, setBuscandoPropostas] = useState(false);
  const [busy, setBusy] = useState(false);
  const [propostasAuxiliares, setPropostasAuxiliares] = useState([]);
  const [propostaSendoVisualizada, setPropostaSendoVisualizada] = useState(
    null
  );

  useEffect(() => {
    buscarPropostasRapidasAuxiliares();
  }, []);

  const buscarPropostasRapidasAuxiliares = React.useCallback(async () => {
    setBuscandoPropostas(true);
    try {
      const propostas = await api.buscarPropostasGravadas();
      setPropostasAuxiliares(propostas);
    } catch (err) {
      console.error(err);
      toast.error(err.message || 'Erro ao buscar propostas gravadas');
    } finally {
      setBuscandoPropostas(false);
    }
  }, []);

  const excluirProposta = async codigoProposta => {
    setPropostasAuxiliares(
      propostasAuxiliares.map(proposta => {
        if (proposta.codigo === codigoProposta)
          proposta.excluindo = !proposta.excluindo;
        return proposta;
      })
    );
    setBusy(true);

    try {
      await api.excluirPropostaRapidaAuxiliar(codigoProposta);
      toast.success(`Proposta #"${codigoProposta} excluída com sucesso`);
      const pras = propostasAuxiliares.filter(
        proposta => proposta.codigo !== codigoProposta
      );
      setPropostasAuxiliares(pras);
    } catch (err) {
      console.error(err);
      toast.error(
        err.message || 'Erro ao excluir a proposta #' + codigoProposta
      );
      setPropostasAuxiliares(
        propostasAuxiliares.map(proposta => {
          if (proposta.codigo === codigoProposta)
            proposta.excluindo = !proposta.excluindo;
          return proposta;
        })
      );
    } finally {
      setBusy(false);
    }
  };

  const redirecionarParaVenda = proposta => {
    history.push('/vendas?codigoProposta=' + proposta.codigo);
  };

  const renderListaPropostasRapidasAuxiliares = () => {
    const propostasAgrupadas = _(propostasAuxiliares)
      .orderBy(p => p.sequencia)
      .groupBy(p => p.codigo)
      .map(values => ({
        proposta: values[0], // primeira proposta
        propostas: values,
      }))
      .value();
    return propostasAgrupadas.map(({ proposta, propostas }, index) => {
      return (
        <Card key={proposta.codigo} className="mb-2">
          <Card.Body>
            <div className="d-flex flex-column flex-sm-row justify-content-between">
              <h4>#{proposta.codigo}</h4>
              <small>
                {`Realizada por ${
                  proposta.proposta.usuario_digitacao.nome
                } em ${format.date(proposta.data)}`}
              </small>
            </div>
            <p>{proposta.familia.nome}</p>

            {propostas.map(p => (
              <p key={p.imovel.codigo}>
                <span className="text-muted mr-1">#{p.imovel.codigo}</span>
                Quadra: {p.imovel.quadra} / Lote: {p.imovel.lote}
              </p>
            ))}

            <div className="d-flex flex-column flex-sm-row justify-content-end w-100 button-area">
              <Button
                variant="outline-primary"
                onClick={() =>
                  setPropostaSendoVisualizada({ proposta, propostas })
                }
                disabled={busy}
              >
                Ver justificativa
              </Button>

              <ConfirmButton
                variant="outline-danger"
                confirmTitle="Confirmação"
                confirmText="Deseja excluir esta proposta?"
                onConfirm={() => excluirProposta(proposta.codigo)}
                disabled={busy}
              >
                {proposta.excluindo ? 'Aguarde...' : 'Excluir'}
              </ConfirmButton>
              <Button
                variant="primary"
                disabled={busy}
                onClick={() => redirecionarParaVenda(proposta)}
              >
                Revisar e Aprovar
              </Button>
            </div>
          </Card.Body>
        </Card>
      );
    });
  };

  const renderModalPropostaSendoVisualizada = () => {
    if (propostaSendoVisualizada == null) return null;

    return (
      <>
        <Modal.Header closeButton>
          Proposta #{propostaSendoVisualizada.proposta.codigo}
        </Modal.Header>
        <Modal.Body>
          <p
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {propostaSendoVisualizada.proposta.justificativa}
          </p>
        </Modal.Body>
      </>
    );
  };

  return (
    <>
      <Header title="Autorização de Propostas">
        <Button
          variant="primary"
          className="float-right"
          onClick={buscarPropostasRapidasAuxiliares}
        >
          Atualizar
        </Button>
      </Header>
      <Container className="mt-2">
        {buscandoPropostas && (
          <Loading label="Carregando propostas gravadas..." />
        )}
        {!buscandoPropostas && propostasAuxiliares.length === 0 && (
          <Aviso
            icon={<BsInbox fontSize={72} />}
            text="Nenhuma proposta encontrada"
          />
        )}
        {propostasAuxiliares.length > 0 &&
          renderListaPropostasRapidasAuxiliares()}
      </Container>
      <Modal
        show={propostaSendoVisualizada != null}
        onHide={() => setPropostaSendoVisualizada(null)}
      >
        {renderModalPropostaSendoVisualizada()}
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setPropostaSendoVisualizada(null)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default withRouter(AutorizacaoVenda);
