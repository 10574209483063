import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import {
  carregaProdutividadePorCorretor,
  carregaProdutividadePorEmpreendimento,
} from '~/Store/Produtividade/actions';

import AuthStorage from '~/Utils/AuthStorage';
import defaultLogo from '../../Images/logo.svg';

class CardCorretoresEmpreendimentos extends Component {
  formatarValor = valor => numeral(valor).format('(R$ 0.00 a)');

  render() {
    const {
      imobiliariaSelecionada,
      corretorSelecionado,
      carregandoProdutivdadeCorretor,
      carregandoProdutivdadeEmpreendimento,
      empreendimentoSelecionado,
    } = this.props;

    if (
      !imobiliariaSelecionada ||
      corretorSelecionado ||
      empreendimentoSelecionado ||
      carregandoProdutivdadeCorretor ||
      carregandoProdutivdadeEmpreendimento
    )
      return null;

    return (
      <div className="CardCorretoresEmpreendimentos mb-4">
        <Col>
          <Row className="linha-resumo-imobiliaria">
            <Col md={6}>
              <span className="text-center">Ranking de corretores</span>
              {imobiliariaSelecionada.ranking_corretores.map(itemCorretor => (
                <Row className="ImobiliariaItem" key={itemCorretor.codigo}>
                  <Col>
                    <Card className="border-0 shadow-sm">
                      <div className="flag-ranking">{itemCorretor.rank}°</div>
                      <div className="d-flex flex-column flex-md-row produtividade-imobiliaria produtividade-corretor flex-wrap">
                        <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                          <div className="text-center img-resumo-imobiliaria d-flex flex-column">
                            <button
                              onClick={() =>
                                this.props.carregaProdutividadePorCorretor(
                                  itemCorretor.codigo
                                )
                              }
                              className="btnTransparente"
                            >
                              <img
                                src={
                                  itemCorretor.url_imagem
                                    ? itemCorretor.url_imagem
                                    : defaultLogo
                                }
                                title={itemCorretor.nome}
                                alt={itemCorretor.nome}
                              />
                              <span>{itemCorretor.nome}</span>
                            </button>
                          </div>
                          <div>
                            <ul
                              className="list-unstyled d-flex flex-wrap produtividade
                          produtividade-card-resumo text-center text-md-left ml-2"
                            >
                              <li>
                                <small className="d-block">Cadastros</small>
                                <strong className="d-block">
                                  {itemCorretor.quantidade_atendimentos_leads}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">VGV</small>
                                <strong
                                  className="d-block"
                                  title={`R$ ${
                                    itemCorretor.vendas
                                      ? itemCorretor.vendas.toLocaleString(
                                          'pt-BR'
                                        )
                                      : '0,00'
                                  }`}
                                >
                                  <small>R$</small>{' '}
                                  {this.formatarValor(itemCorretor.vendas)}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">Recebíveis</small>
                                <strong
                                  className="d-block"
                                  title={`R$ ${
                                    itemCorretor.recebiveis
                                      ? itemCorretor.recebiveis.toLocaleString(
                                          'pt-BR'
                                        )
                                      : '0,00'
                                  }`}
                                >
                                  <small>R$</small>{' '}
                                  {this.formatarValor(itemCorretor.recebiveis)}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">Conversão</small>
                                <strong className="d-block">
                                  {numeral(
                                    itemCorretor.conversao_periodo /
                                      itemCorretor.quantidade_atendimentos_leads
                                  ).format('(0%)')}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">Qtd. Vendas</small>
                                <strong
                                  className="d-block"
                                  title="E = Vendas Efetivadas / P = Proposta Rápida"
                                >
                                  {itemCorretor.quantidade_vendas}
                                  (E:{itemCorretor.quantidade_efetivadas}+P:
                                  {itemCorretor.quantidade_propostas_rapidas})
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">Em aberto</small>
                                <strong
                                  className="d-block"
                                  title={`R$ ${
                                    itemCorretor.recebiveis_em_aberto
                                      ? itemCorretor.recebiveis_em_aberto.toLocaleString(
                                          'pt-BR'
                                        )
                                      : '0,00'
                                  }`}
                                >
                                  <small>R$</small>{' '}
                                  {this.formatarValor(
                                    itemCorretor.recebiveis_em_aberto
                                  )}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">Comissão</small>
                                <strong
                                  className="d-block"
                                  title={`R$ ${
                                    itemCorretor.comissao_projetada
                                      ? itemCorretor.comissao_projetada.toLocaleString(
                                          'pt-BR'
                                        )
                                      : '0,00'
                                  }`}
                                >
                                  <small>R$</small>{' '}
                                  {this.formatarValor(
                                    itemCorretor.comissao_projetada
                                  )}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">VGV Rescisões</small>
                                <strong
                                  className="d-block"
                                  title={`R$ ${
                                    itemCorretor.rescisoes
                                      ? itemCorretor.rescisoes.toLocaleString(
                                          'pt-BR'
                                        )
                                      : '0,00'
                                  }`}
                                >
                                  <small>R$</small>{' '}
                                  {this.formatarValor(itemCorretor.rescisoes)}
                                </strong>
                              </li>
                              <li>
                                <small className="d-block">Já baixado</small>
                                <strong
                                  className="d-block"
                                  title={`R$ ${
                                    itemCorretor.recebiveis_baixados
                                      ? itemCorretor.recebiveis_baixados.toLocaleString(
                                          'pt-BR'
                                        )
                                      : '0,00'
                                  }`}
                                >
                                  <small>R$</small>{' '}
                                  {this.formatarValor(
                                    itemCorretor.recebiveis_baixados
                                  )}
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col md={6}>
              <span className="text-center">Ranking de empreendimentos</span>
              {imobiliariaSelecionada.ranking_empreendimentos.map(
                itemEmpreendimento => (
                  <Row
                    className="ImobiliariaItem"
                    key={itemEmpreendimento.codigo}
                  >
                    <Col>
                      <Card className="border-0 shadow-sm">
                        <div className="flag-ranking">
                          {itemEmpreendimento.rank}°
                        </div>
                        <div className="d-flex flex-column flex-md-row produtividade-imobiliaria produtividade-corretor flex-wrap">
                          <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                            <div className="text-center img-resumo-imobiliaria d-flex flex-column">
                              <button
                                onClick={() =>
                                  AuthStorage.isCorretor()
                                    ? null
                                    : this.props.carregaProdutividadePorEmpreendimento(
                                        itemEmpreendimento.codigo
                                      )
                                }
                                className="btnTransparente"
                              >
                                <img
                                  src={
                                    itemEmpreendimento.url_imagem
                                      ? itemEmpreendimento.url_imagem
                                      : defaultLogo
                                  }
                                  title={itemEmpreendimento.nome}
                                  alt={itemEmpreendimento.nome}
                                />
                                <span>{itemEmpreendimento.nome}</span>
                              </button>
                            </div>
                            <div>
                              <ul
                                className="list-unstyled d-flex flex-wrap produtividade
                            produtividade-card-resumo text-center text-md-left ml-2"
                              >
                                <li>
                                  <small className="d-block">Cadastros</small>
                                  <strong className="d-block">
                                    {
                                      itemEmpreendimento.quantidade_atendimentos_leads
                                    }
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">VGV</small>
                                  <strong
                                    className="d-block"
                                    title={`R$ ${
                                      itemEmpreendimento.vendas
                                        ? itemEmpreendimento.vendas.toLocaleString(
                                            'pt-BR'
                                          )
                                        : '0,00'
                                    }`}
                                  >
                                    <small>R$</small>{' '}
                                    {this.formatarValor(
                                      itemEmpreendimento.vendas
                                    )}
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">Recebíveis</small>
                                  <strong
                                    className="d-block"
                                    title={`R$ ${
                                      itemEmpreendimento.recebiveis
                                        ? itemEmpreendimento.recebiveis.toLocaleString(
                                            'pt-BR'
                                          )
                                        : '0,00'
                                    }`}
                                  >
                                    <small>R$</small>{' '}
                                    {this.formatarValor(
                                      itemEmpreendimento.recebiveis
                                    )}
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">Conversão</small>
                                  <strong className="d-block">
                                    {numeral(
                                      itemEmpreendimento.conversao_periodo /
                                        itemEmpreendimento.quantidade_atendimentos_leads
                                    ).format('(0%)')}
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">Qtd. Vendas</small>
                                  <strong
                                    className="d-block"
                                    title="E = Vendas Efetivadas / P = Proposta Rápida"
                                  >
                                    {itemEmpreendimento.quantidade_vendas}
                                    (E:
                                    {itemEmpreendimento.quantidade_efetivadas}
                                    +P:
                                    {
                                      itemEmpreendimento.quantidade_propostas_rapidas
                                    }
                                    )
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">Em aberto</small>
                                  <strong
                                    className="d-block"
                                    title={`R$ ${
                                      itemEmpreendimento.recebiveis_em_aberto
                                        ? itemEmpreendimento.recebiveis_em_aberto.toLocaleString(
                                            'pt-BR'
                                          )
                                        : '0,00'
                                    }`}
                                  >
                                    <small>R$</small>{' '}
                                    {this.formatarValor(
                                      itemEmpreendimento.recebiveis_em_aberto
                                    )}
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">Comissão</small>
                                  <strong
                                    className="d-block"
                                    title={`R$ ${
                                      itemEmpreendimento.comissao_projetada
                                        ? itemEmpreendimento.comissao_projetada.toLocaleString(
                                            'pt-BR'
                                          )
                                        : '0,00'
                                    }`}
                                  >
                                    <small>R$</small>{' '}
                                    {this.formatarValor(
                                      itemEmpreendimento.comissao_projetada
                                    )}
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">
                                    VGV Rescisões
                                  </small>
                                  <strong
                                    className="d-block"
                                    title={`R$ ${
                                      itemEmpreendimento.rescisoes
                                        ? itemEmpreendimento.rescisoes.toLocaleString(
                                            'pt-BR'
                                          )
                                        : '0,00'
                                    }`}
                                  >
                                    <small>R$</small>{' '}
                                    {this.formatarValor(
                                      itemEmpreendimento.rescisoes
                                    )}
                                  </strong>
                                </li>
                                <li>
                                  <small className="d-block">Já baixado</small>
                                  <strong
                                    className="d-block"
                                    title={`R$ ${
                                      itemEmpreendimento.recebiveis_baixados
                                        ? itemEmpreendimento.recebiveis_baixados.toLocaleString(
                                            'pt-BR'
                                          )
                                        : '0,00'
                                    }`}
                                  >
                                    <small>R$</small>{' '}
                                    {this.formatarValor(
                                      itemEmpreendimento.recebiveis_baixados
                                    )}
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                )
              )}
            </Col>
          </Row>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  imobiliariaSelecionada: state.produtividade.imobiliariaSelecionada,
  corretorSelecionado: state.produtividade.corretorSelecionado,
  empreendimentoSelecionado: state.produtividade.empreendimentoSelecionado,
  carregandoProdutivdadeCorretor:
    state.produtividade.carregandoProdutivdadeCorretor,
  carregandoProdutivdadeEmpreendimento:
    state.produtividade.carregandoProdutivdadeEmpreendimento,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      carregaProdutividadePorCorretor,
      carregaProdutividadePorEmpreendimento,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardCorretoresEmpreendimentos)
);
