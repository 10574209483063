import React, { useState } from 'react';
import { Nav, Button } from 'react-bootstrap';
import _size from 'lodash/size';

import CamposDadosPessoais from './CamposDadosPessoais/CamposDadosPessoais';
import CamposEndereco from './CamposEndereco/CamposEndereco';
import VinculadorSenhaLancamento from './VinculadorSenhaLancamento';
//import IniciarAtendimento from './IniciarAtendimento';

const FormDadosPessoais = ({
  options,
  getAtualizarCidades,
  isRequiredField,
  isSubmitting,
  formProspecto,
  permitidoAdicionarDocumento,
  onCancel,
  onReImportarSuaHouse,
}) => {
  const {
    setFieldValue,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
  } = formProspecto;

  const [tabActive, setTabActive] = useState('pessoais');

  const temErrosDadosPessoais = (errors, touched) => {
    let camposDadosPessoais = [
      'tipo_pessoa',
      'codigo_perfil_comprador',
      'nome',
      'email',
      'tel_celular',
      'tel_residencial',
      'tel_comercial',
      'cpf',
      'estado_civil',
      'sexo',
      'nascimento',
      'rg',
      'orgao_emissor_rg',
      'data_emissao_rg',
      'cnh',
      'orgao_emissor_cnh',
      'codigo_status',
      'perfil_exterior_ddi',
      'perfil_exterior_telefone',
      'perfil_exterior_pais',
      'perfil_exterior_endereco',
    ];
    for (let key of camposDadosPessoais) {
      if (errors[key] && touched[key]) {
        return true;
      }
    }

    return false;
  };

  const temErrosEndereco = (errors, touched) => {
    let camposEndereco = [
      'cep',
      'estado',
      'codigo_cidade',
      'bairro',
      'endereco',
      'numero',
    ];
    for (let key of camposEndereco) {
      if (errors[key] && touched[key]) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className="FormDadosPessoais">
      <VinculadorSenhaLancamento />
      {/* <IniciarAtendimento prospecto={values} className="col-4" /> */}
      {values.TentouValidar &&
        !permitidoAdicionarDocumento &&
        !isSubmitting && (
          <div className="text-danger text-small mb-2">
            Conclua o cadastro para adicionar e editar cônjuge/sócio e
            documentos
          </div>
        )}

      <div className="d-lg-flex flex-row align-items-center justify-content-between">
        <nav className="nav nav-tabs mb-2">
          {[
            {
              tab: 'pessoais',
              isError: temErrosDadosPessoais(errors, touched, values),
              label: 'Pessoais',
            },
            {
              tab: 'endereco',
              isError: temErrosEndereco(errors, touched, values),
              label: 'Endereço',
            },
          ].map(({ tab, isError, label }, index) => (
            <Nav.Item className="btn-link" key={index}>
              <Nav.Link
                active={tabActive === tab}
                className={`btn-sm ${isError && 'is-error'}`}
                onClick={() => setTabActive(tab)}
              >
                {label}
              </Nav.Link>
            </Nav.Item>
          ))}
        </nav>

        <div className="ProspectDetails__bottom actions align-items-center align-items-lg-end d-flex flex-column">
          <div className="d-flex justify-content-center">
            {/* {values.codigo_cliente_sua_house && (
              <Button
                type="button"
                variant="secondary"
                disabled={isSubmitting}
                className="text-uppercase px-4 d-flex"
                onClick={() => onReImportarSuaHouse(values.codigo)}
              >
                Re-Importar da Sua House
              </Button>
            )} */}
            <Button
              type="button"
              variant="link"
              disabled={isSubmitting}
              className="text-uppercase px-4 d-flex"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={handleSubmit}
              className="text-uppercase px-4 d-flex"
              disabled={isSubmitting || values.codigo_familia === 0}
            >
              Salvar
            </Button>
          </div>
          {values.codigo_familia === 0 && (
            <small>
              Prospecto sem vínculo com empreendimento. Crie um vínculo válido
              para atualizar seus dados do prospecto.
            </small>
          )}
          <div className="FormDadosPessoais__error text-danger">
            {_size(errors) > 0 && 'Preenchas os campos corretamente'}
          </div>
        </div>
      </div>

      {tabActive === 'pessoais' && (
        <CamposDadosPessoais
          {...{
            isSubmitting,
            isRequiredField,
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            options,
          }}
        />
      )}

      {tabActive === 'endereco' && (
        <CamposEndereco
          {...{
            isSubmitting,
            isRequiredField,
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            getAtualizarCidades,
            setFieldValue,
            options,
            setValues,
          }}
        />
      )}
    </div>
  );
};

export default FormDadosPessoais;
