import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik } from 'formik';

const ModalSignatario = ({ show, onClosing, onSubmit, signatario }) => (
  <Formik
    enableReinitialize={true}
    onSubmit={onSubmit}
    initialValues={{
      nome: signatario.nome,
      email: signatario.email,
    }}
  >
    {({
      handleSubmit,
      handleChange,
      values,
      touched,
      errors,
      isSubmitting,
      resetForm,
    }) => (
      <Modal
        centered
        show={show}
        onHide={() => {
          onClosing();
          resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Signatário</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome"
              name="nome"
              value={values.nome}
              onChange={handleChange}
              disabled={isSubmitting}
              isInvalid={touched.nome && errors.nome}
            />
            <Form.Control.Feedback type="invalid">
              {errors.nome}
            </Form.Control.Feedback>

            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="text"
              placeholder="E-mail"
              name="email"
              value={values.email}
              onChange={handleChange}
              disabled={isSubmitting}
              isInvalid={touched.email && errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="mb-1"
            onClick={handleSubmit}
            // disabled={!values.cpf || !values.nome || !values.cpf}
          >
            Editar
          </Button>
          <Button
            variant="outline-primary"
            disabled={isSubmitting}
            onClick={() => {
              onClosing();
              resetForm();
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </Formik>
);

export default ModalSignatario;
