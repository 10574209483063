import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup,
  ListGroupItem,
  Form,
  Alert,
  Modal,
  Button,
} from 'react-bootstrap';

import { default as auth } from '~/Utils/AuthStorage';
import ModalProspectoEmpreendimento from '../ProspectoEmpreendimento/ModalProspectoEmpreendimento';
import Outsider from '~/Components/Outsider';

import ItemEmpreendimento from './ItemEmpreendimento';

class SelectEmpreendimentoProspecto extends Component {
  static propTypes = {
    prospecto: PropTypes.object.isRequired,
    empreendimentos: PropTypes.object.isRequired,
    motivosDescarte: PropTypes.array.isRequired,
    setProspecto: PropTypes.func.isRequired,
    gerentesCorretores: PropTypes.object.isRequired,
    removerEmpreendimento: PropTypes.func,
    atualizandoProspectoEmpreendimento: PropTypes.bool,
    erroAtualizarProspectoEmpreendimento: PropTypes.bool,
    sucessoAtualizarProspectoEmpreendimento: PropTypes.bool,
    onDismissAvisoDescarteSuaHouse: PropTypes.func,
  };

  static defaulProps = {
    atualizandoProspectoEmpreendimento: false,
    erroAtualizarProspectoEmpreendimento: false,
    sucessoAtualizarProspectoEmpreendimento: false,
  };

  state = {
    modoAdicionandoEmpreendimento: false,
    selecionandoEmpreendimento: false,
    novoEmpreendimento: {},
    editandoEmpreendimento: null,
    motivoDescarte: null,
  };

  alterarModoAdicionarEmpreendimento = (state = false) => {
    let newState = {
      modoAdicionandoEmpreendimento: state,
    };
    if (state === false) {
      newState.novoEmpreendimento = {};
    }

    this.setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.prospecto.codigo && !this.props.prospecto.codigo) {
      this.alterarModoAdicionarEmpreendimento(false);
    }

    if (
      !prevProps.sucessoAtualizarProspectoEmpreendimento &&
      this.props.sucessoAtualizarProspectoEmpreendimento
    ) {
      this.editarEmpreendimento(null);
    }
  }

  buildOptionsCorretor = (novoEmpreendimento, gerentesCorretores) => {
    let gerente = novoEmpreendimento.codigo_corretor
      ? gerentesCorretores.gerentesById[novoEmpreendimento.codigo_corretor]
      : null;

    if (auth.isGerente()) {
      gerente = gerentesCorretores.gerentesById[auth.getUser().usuario];
    }

    let corretores = [];

    if (gerente) {
      corretores = gerente.Corretores.map(item => ({
        value: item.codigo,
        label: item.nome,
      }));
    } else {
      corretores = gerentesCorretores.listaCorretores.map(item => ({
        value: item.codigo,
        label: item.nome,
      }));
    }

    let initialItem = {
      value: '',
      label: gerente ? `Corretores de ${gerente.nome}` : 'Todos os corretores',
    };
    return [initialItem, ...corretores];
  };

  buildValueCorretor = (novoEmpreendimento, gerentesCorretores) => {
    let corretor =
      gerentesCorretores.corretoresById[novoEmpreendimento.codigo_corretor];
    let gerente =
      gerentesCorretores.gerentesById[novoEmpreendimento.codigo_gerente];
    return novoEmpreendimento.codigo_corretor && corretor
      ? { value: corretor.codigo, label: corretor.nome }
      : {
          value: '',
          label:
            novoEmpreendimento.codigo_gerente && gerente
              ? `Corretores de ${gerente.nome}`
              : 'Selecione um corretor',
        };
  };

  buildOptionsEmpreendimento = (empreendimentos, novoEmpreendimento) => {
    let empreendimentosDoProspecto =
      novoEmpreendimento.prospecto_empreendimento || [];
    empreendimentosDoProspecto = empreendimentosDoProspecto.map(
      item => item.codigo_familia
    );
    return empreendimentos.sort
      .filter(item => empreendimentosDoProspecto.indexOf(item.codigo) < 0)
      .map(item => ({ value: item.codigo, label: item.descricao }));
  };

  buildValueEmpreendimento = (empreendimentos, novoEmpreendimento) => {
    return novoEmpreendimento.codigo_familia
      ? {
          value: parseInt(
            empreendimentos.byId[novoEmpreendimento.codigo_familia].codigo
          ),
          label:
            empreendimentos.byId[novoEmpreendimento.codigo_familia].descricao,
        }
      : { value: '', label: 'Selecione um empreendimento' };
  };

  selecionarEmpreendimento = codigo => {
    if (this.props.prospecto.prospecto_empreendimento.length <= 1) {
      return;
    }

    if (this.props.prospecto.codigo_familia === codigo) {
      this.setState({
        selecionandoEmpreendimento: !this.state.selecionandoEmpreendimento,
        motivoDescarte: null,
        dissertativaDescarte: '',
      });
    } else {
      this.props.selecionarEmpreendimento(codigo);
      this.setState({
        selecionandoEmpreendimento: false,
        novoEmpreendimento: {},
        motivoDescarte: null,
        dissertativaDescarte: '',
      });
    }
  };

  setNovoEmpreendimento = (key, value) => {
    let novoEmpreendimento = this.state.novoEmpreendimento;
    novoEmpreendimento[key] = value;

    if (auth.isCorretor()) {
      let corretor = this.props.gerentesCorretores.corretoresById[
        auth.getUser().usuario
      ];
      novoEmpreendimento.codigo_gerente = corretor.codigo_gerente;
      novoEmpreendimento.codigo_corretor = corretor.codigo;
    }

    this.setState(
      {
        novoEmpreendimento,
      },
      () => {
        let novoEmpreendimento = this.state.novoEmpreendimento;
        if (
          this.props.prospecto.prospecto_empreendimento.length === 0 &&
          novoEmpreendimento.codigo_familia &&
          novoEmpreendimento.codigo_corretor &&
          novoEmpreendimento.codigo_gerente
        ) {
          this.props.setProspecto(
            'codigo_familia',
            novoEmpreendimento.codigo_familia
          );
          this.props.setProspecto(
            'codigo_corretor',
            novoEmpreendimento.codigo_corretor
          );
          this.props.setProspecto(
            'codigo_gerente',
            novoEmpreendimento.codigo_gerente
          );
        }
      }
    );
  };

  adicionarEmpreendimento = () => {
    return this.props
      .adicionarEmpreendimento({
        ...this.state.novoEmpreendimento,
        codigo_prospecto: this.props.prospecto.codigo,
      })
      .then(() => {
        this.alterarModoAdicionarEmpreendimento(false);
      });
  };

  abrirListaEmpreendimento = () => {
    this.setState({
      selecionandoEmpreendimento: true,
      editandoEmpreendimento: null,
    });
  };

  editarEmpreendimento = codigo => {
    if (this.props.erroAtualizarProspectoEmpreendimento) {
      this.props.limpaErrosEditarEmpreendimento();
    }
    this.setState(prevState => ({
      ...prevState,
      editandoEmpreendimento: codigo,
      motivoDescarte: null,
      dissertativaDescarte: '',
    }));
  };

  selecionarMotivoDescarte = codigo => {
    this.setState({
      motivoDescarte: codigo === '' ? null : parseInt(codigo),
    });
  };

  handleDissertativaDescarteChange = value => {
    this.setState(prevState => ({
      ...prevState,
      dissertativaDescarte: value,
    }));
  };

  render() {
    const {
      modoAdicionandoEmpreendimento,
      selecionandoEmpreendimento,
      editandoEmpreendimento,
      dissertativaDescarte,
      motivoDescarte,
    } = this.state;
    const {
      empreendimentos,
      prospecto,
      removerEmpreendimento,
      atualizandoProspectoEmpreendimento,
      erroAtualizarProspectoEmpreendimento,
      sucessoAtualizarProspectoEmpreendimento,
      gerentesCorretores,
      exibeBotoes,
      motivosDescarte,
      onDismissAvisoDescarteSuaHouse,
    } = this.props;

    return (
      <Fragment>
        {sucessoAtualizarProspectoEmpreendimento && (
          <Alert variant="success" size="sm">
            Prospecto atualizado com sucesso
          </Alert>
        )}
        {!modoAdicionandoEmpreendimento &&
          prospecto.prospecto_empreendimento &&
          prospecto.prospecto_empreendimento.length > 0 && (
            <Outsider
              onClickOut={() => {
                if (this.state.editandoEmpreendimento) {
                  this.editarEmpreendimento(null);
                }
              }}
            >
              <ListGroup
                className={`SelectEmpreendimentoProspecto__list ${selecionandoEmpreendimento &&
                  'is-open'}  ${editandoEmpreendimento && 'is-editing'}`}
              >
                {!editandoEmpreendimento &&
                  !selecionandoEmpreendimento &&
                  prospecto.prospecto_empreendimento.length > 1 && (
                    <span
                      className="Empreendimento__contador"
                      onClick={() => this.abrirListaEmpreendimento()}
                    >
                      +{prospecto.prospecto_empreendimento.length - 1}
                    </span>
                  )}
                {prospecto.prospecto_empreendimento.map((item, index) => {
                  if (
                    item.codigo_familia === prospecto.codigo_familia ||
                    selecionandoEmpreendimento
                  ) {
                    return (
                      <ListGroupItem key={index}>
                        <ItemEmpreendimento
                          empreendimento={item}
                          editandoEmpreendimento={editandoEmpreendimento}
                          descricao={
                            empreendimentos.byId[item.codigo_familia]
                              ? empreendimentos.byId[item.codigo_familia]
                                  .descricao
                              : 'Empreendimento Desconhecido' // item.codigo_familia
                          }
                          isSelecionado={
                            item.codigo_familia === prospecto.codigo_familia &&
                            selecionandoEmpreendimento
                          }
                          onClickEmpreendimento={() =>
                            this.selecionarEmpreendimento(item.codigo_familia)
                          }
                          onClickEditarEmpreendimento={() =>
                            this.editarEmpreendimento(item.codigo_familia)
                          }
                          onClickCancelar={() =>
                            this.editarEmpreendimento(null)
                          }
                          exibeAcoes={
                            !selecionandoEmpreendimento &&
                            !editandoEmpreendimento &&
                            !modoAdicionandoEmpreendimento
                          }
                          exibeContador={
                            prospecto.prospecto_empreendimento.length > 1
                          }
                          linkReservar={`//www.revobrasil.com.br/Extranet/PDV/MapaEmpreendimento.aspx?Prospecto=${prospecto.codigo}&ID=${item.codigo_familia}`}
                          exibeBotoes={exibeBotoes}
                        />
                        {editandoEmpreendimento === item.codigo_familia && (
                          <div className="w-100 px-3 pb-3">
                            <Form.Group>
                              <Form.Label>
                                Escolha um motivo para realizar o descarte deste
                                vínculo com o empreendimento
                              </Form.Label>
                              <Form.Control
                                as="select"
                                name="CodigoMotivo"
                                disabled={atualizandoProspectoEmpreendimento}
                                value={item.value}
                                onChange={e =>
                                  this.selecionarMotivoDescarte(e.target.value)
                                }
                              >
                                <option value="">Selecione um motivo</option>
                                {motivosDescarte &&
                                  motivosDescarte.map((item, index) => (
                                    <option key={index} value={item.codigo}>
                                      {item.descricao}
                                    </option>
                                  ))}
                              </Form.Control>
                              <Form.Text className="text-muted">
                                Selecione uma etapa para atualizar
                              </Form.Text>

                              <Form.Control
                                type="text"
                                className="mt-2"
                                value={dissertativaDescarte}
                                onChange={e =>
                                  this.handleDissertativaDescarteChange(
                                    e.target.value
                                  )
                                }
                                placeholder="Justificativa (opcional)"
                              />
                            </Form.Group>
                            <div className="d-flex justify-content-between">
                              {
                                <button
                                  type="button"
                                  disabled={
                                    atualizandoProspectoEmpreendimento ||
                                    !motivoDescarte
                                  }
                                  className="btn btn-sm btn-outline-danger"
                                  onClick={() =>
                                    removerEmpreendimento(
                                      item.codigo_familia,
                                      motivoDescarte,
                                      dissertativaDescarte
                                    )
                                  }
                                >
                                  {atualizandoProspectoEmpreendimento
                                    ? 'Descartando...'
                                    : 'Descartar'}
                                </button>
                              }
                              {erroAtualizarProspectoEmpreendimento && (
                                <div className="text-danger">
                                  {erroAtualizarProspectoEmpreendimento}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </ListGroupItem>
                    );
                  }

                  return null;
                })}
              </ListGroup>
            </Outsider>
          )}

        <ModalProspectoEmpreendimento
          prospecto={prospecto}
          empreendimentos={empreendimentos}
          gerentesCorretores={gerentesCorretores}
        />

        <Modal
          show={prospecto.requer_acao_sua_house}
          onHide={onDismissAvisoDescarteSuaHouse}
        >
          <Modal.Header closeButton>
            <Modal.Title>Descarte Manual Requerido</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            O prospecto veio da Sua House porém não pode ser descartado
            automaticamente. Por favor, descarte o prospecto na Sua House
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onDismissAvisoDescarteSuaHouse}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default SelectEmpreendimentoProspecto;
