import { combineReducers } from 'redux';

const lista = (state = [], action) => {
  switch (action.type) {
    case 'RANKING_CARREGADO':
      return action.payload.ranking;
    case 'CARREGANDO_RANKING':
      return [];
    default:
      return state;
  }
};

const mensagem = (state = null, action) => {
  switch (action.type) {
    case 'RANKING_CARREGADO':
      return action.payload.mensagem;
    case 'CARREGANDO_RANKING':
      return null;
    default:
      return state;
  }
};

const recebiveis = (state = null, action) => {
  switch (action.type) {
    case 'RANKING_CARREGADO':
      return action.payload.recebiveis;
    case 'CARREGANDO_RANKING':
      return null;
    default:
      return state;
  }
};

const recebiveisBaixados = (state = null, action) => {
  switch (action.type) {
    case 'RANKING_CARREGADO':
      return action.payload.recebiveis_baixados;
    case 'CARREGANDO_RANKING':
      return null;
    default:
      return state;
  }
};
const recebiveisEmAberto = (state = null, action) => {
  switch (action.type) {
    case 'RANKING_CARREGADO':
      return action.payload.recebiveis_em_aberto;
    case 'CARREGANDO_RANKING':
      return null;
    default:
      return state;
  }
};

const campanhas = (state = [], action) => {
  switch (action.type) {
    case 'RANKING_CARREGADO':
      return action.payload.campanhas;

    default:
      return state;
  }
};

const carregando = (state = false, action) => {
  switch (action.type) {
    case 'CARREGANDO_RANKING':
      return true;
    case 'RANKING_CARREGADO':
      return false;
    default:
      return state;
  }
};

const imobiliariaSelecionada = (state = null, action) => {
  switch (action.type) {
    case 'SELECIONA_IMOBILIARIA':
      return action.payload.imobiliaria;
    default:
      return state;
  }
};

const campanhaSelecionada = (state = null, action) => {
  switch (action.type) {
    case 'RANKING_CARREGADO':
      return action.payload.codigo_campanha;
    case 'SELECIONAR_CAMPANHA':
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  lista,
  mensagem,
  recebiveisEmAberto,
  recebiveisBaixados,
  recebiveis,
  campanhas,
  carregando,
  imobiliariaSelecionada,
  campanhaSelecionada,
});
