import React, { useState, useEffect, useCallback } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import _ from 'lodash';
import * as api from '~/Services/Service';

const LoginSenhaEvaluator = ({ codigoUsuario, senha, onValidated }) => {
  const [validando, setValidando] = useState(false);
  const [validou, setValidou] = useState(false);
  const [valido, setValido] = useState(false);

  const debouncedValidarLoginSenha = useCallback(
    _.debounce(
      (codigoUsuario, senha) => validarLoginSenha(codigoUsuario, senha),
      1000
    ),
    []
  );

  const validarLoginSenha = async (codigoUsuario, senha) => {
    setValidando(true);
    setValido(false);
    setValidou(false);

    try {
      const isValid = await api.validarUsuarioSenha(codigoUsuario, senha);
      setValido(isValid);
      if (onValidated) onValidated(isValid);
    } catch (err) {
    } finally {
      setValidando(false);
      setValidou(true);
    }
  };

  useEffect(() => {
    setValidando(false);
    setValido(false);
    setValidou(false);

    if (codigoUsuario && senha) {
      debouncedValidarLoginSenha(codigoUsuario, senha);
    }
  }, [codigoUsuario, senha]);
  if (validando) return <BeatLoader size={8} color={'#ccc'} />;
  if (validou && valido)
    return <small className="text-success">Credenciais válidas</small>;
  if (validou && !valido)
    return <small className="text-danger">Credenciais inválidas</small>;
  return null;
};

export default LoginSenhaEvaluator;
