import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

export const Field = ({
  children,
  label,
  name,
  invalid,
  required = false,
  help = null,
  isTextArea = false,
}) => {
  children = {
    ...children,
    props: {
      ...children.props,
      className: invalid
        ? `is-invalid ${children.props.className}`
        : children.props.className,
    },
  };
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name}>
          <span dangerouslySetInnerHTML={{ __html: label }} />
          {(children.props.required || required) && (
            <sup className="text-danger">*</sup>
          )}
        </label>
      )}
      {children}
      {children.props.maxLength && isTextArea && (
        <small className="form-text text-muted">
          {children.props.defaultValue ? children.props.defaultValue.length : 0}
          /{children.props.maxLength} caracteres
        </small>
      )}
      {help && !invalid && (
        <small className="form-text text-muted">{help}</small>
      )}
      {invalid && <div className="invalid-feedback">{invalid}</div>}
    </div>
  );
};
Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  helpClass: PropTypes.string,
};

export const SelectField = ({
  options,
  label,
  name,
  placeholder,
  invalid,
  help = null,
  ...rest
}) => (
  <Field label={label} name={name} help={help} invalid={invalid}>
    <select className="form-control" id={name} name={name} {...rest}>
      {placeholder && (
        <option value="">
          {typeof placeholder === 'string'
            ? placeholder
            : 'Selecione uma opção'}
        </option>
      )}
      {options.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label ? item.label : item.value}
        </option>
      ))}
    </select>
  </Field>
);
SelectField.propTypes = {
  ...Field.propTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ),
};

const Checkbox = ({ name, children, ...rest }) => (
  <div className="field">
    <div className="control">
      <input
        type="checkbox"
        className="switch"
        name={name}
        id={name}
        {...rest}
      />
      <label htmlFor={name} className="checkbox">
        {children}
      </label>
    </div>
  </div>
);
Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultChecked: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
};
export { Checkbox };

export const RadioField = ({ name, label, ...rest }) => (
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      name={name}
      id={name}
      {...rest}
    />
    <label className="form-check-label" htmlFor={name}>
      {label}
    </label>
  </div>
);

export const RangeField = ({ name, label, children, ...rest }) => (
  <Field name={name} label={label}>
    <input
      className="slider is-info"
      type="range"
      id={name}
      name={name}
      {...rest}
    />
    <span
      style={{
        display: 'inline-block',
        marginLeft: '10px',
        position: 'relative',
        top: '-7px',
      }}
    >
      {children}
    </span>
  </Field>
);

export const TextAreaField = ({ name, label, help, invalid, ...rest }) => (
  <Field
    name={name}
    label={label}
    help={help}
    invalid={invalid}
    isTextArea={true}
  >
    <textarea
      name={name}
      id={name}
      rows="4"
      className={`form-control ${invalid && 'is-invalid'}`}
      {...rest}
    />
  </Field>
);

export const InputField = ({ name, label, help, invalid, ...rest }) => (
  <Field name={name} label={label} help={help} invalid={invalid}>
    <input
      name={name}
      id={name}
      className={`form-control ${invalid && 'is-invalid'}`}
      {...rest}
    />
  </Field>
);

export const MaskInput = ({ invalid, help, ...rest }) => (
  <Field invalid={invalid} help={help} {...rest}>
    <MaskedInput className="form-control" {...rest} />
  </Field>
);

export const Selector = ({ invalid, required, defaultValue, ...rest }) => {
  let value = rest.options.filter(item => item.value === defaultValue);
  return (
    <div className="form-group">
      <label htmlFor={rest.name}>
        {rest.label}
        {required && <sup className="text-danger">*</sup>}
      </label>
      <Select value={value} {...rest} />
      {invalid && <div className="invalid-feedback">{invalid}</div>}
    </div>
  );
};
Selector.propTypes = {
  ...SelectField.propTypes,
};

export const SelectorAsync = ({ invalid, required, label, name, ...rest }) => {
  return (
    <Field
      label={label}
      name={name}
      invalid={invalid}
      required={required}
      {...rest}
    >
      <AsyncSelect
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        cacheOptions
        className="AsyncSelect-container"
        classNamePrefix="AsyncSelect"
        defaultOptions
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          spacing: {
            ...theme.spacing,
            controlHeight: '45px',
          },
          colors: {
            ...theme.colors,
            text: 'orangered',
            primary: 'black',
          },
        })}
        {...rest}
      />
    </Field>
  );
};

class Form extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = { initialValues: {} };

  state = {
    values: this.props.initialValues,
    dirtyValues: [],
    submitted: false,
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.initialValues, nextProps.initialValues)) {
      this.setState(prevState => ({
        values: { ...nextProps.initialValues, ...prevState.values },
      }));
    }
  }

  isPristine = path => {
    if (path) {
      return !this.state.dirtyValues.find(dirtyValue => dirtyValue === path);
    }
    return !this.state.dirtyValues.length;
  };

  isDirty = path => !this.isPristine(path);

  isSubmitted = () => this.state.submitted;

  getValue = path => {
    if (!path) {
      throw new Error('getValue() requires a path');
    }
    return get(this.state.values, path, '');
  };

  setValue = (path, value) => {
    if (!path) {
      throw new Error('setValue() requires a path');
    }
    if (value === undefined) {
      throw new Error('setValue() requires a non-undefined value');
    }
    this.setState(prevState => {
      const prevValues = prevState.values;
      // Lo-Dash's set() mutates the original value, so we need to make a copy
      const prevValuesCopy = cloneDeep(prevValues);
      const nextValues = set(prevValuesCopy, path, value);
      return { values: nextValues };
    });
    this.setDirty(path);
  };

  setPristine = path => {
    if (!path) {
      throw new Error('setPristine() requires a path');
    }
    this.setState(prevState => ({
      dirtyValues: this.isPristine(path)
        ? prevState.dirtyValues
        : prevState.dirtyValues.filter(dirtyValue => dirtyValue !== path),
    }));
  };

  setDirty = path => {
    if (!path) {
      throw new Error('setDirty() requires a path');
    }
    this.setState(prevState => ({
      dirtyValues: this.isDirty(path)
        ? prevState.dirtyValues
        : [...prevState.dirtyValues, path],
    }));
  };

  reset = () =>
    this.setState({
      values: this.props.initialValues,
      dirtyValues: [],
      submitted: false,
    });

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ submitted: true });
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.values);
    }
  };

  render() {
    // eslint-disable-next-line
    const { initialValues, children, ...rest } = this.props;
    return (
      <form {...rest} onSubmit={this.handleSubmit}>
        {children({
          values: this.state.values,
          isPristine: this.isPristine,
          isDirty: this.isDirty,
          isSubmitted: this.isSubmitted,
          getValue: this.getValue,
          setValue: this.setValue,
          setPristine: this.setPristine,
          setDirty: this.setDirty,
          reset: this.reset,
        })}
      </form>
    );
  }
}
export default Form;
