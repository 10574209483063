import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ListGroup, ListGroupItem, Row, Col, Button } from 'react-bootstrap';
import * as helpers from '../../../../Utils/Helpers';
import { DATETIME_DEFAULT_FORMAT } from '../../../../constant';
import ModalOcorrencia from './ModalOcorrencia';
import {
  toggleModalOcorrencia,
  iniciarEditarOcorrencia,
} from '~/Store/Prospecto/actions';
import { FaPen } from 'react-icons/fa';
import { default as auth } from '../../../../Utils/AuthStorage';

class Ocorrencias extends Component {
  state = {
    isActive: null,
  };
  setActive = codigo => {
    codigo = codigo === this.state.isActive ? null : codigo;
    this.setState({
      isActive: codigo,
    });
  };
  render() {
    const { isActive } = this.state;
    const {
      ocorrencias,
      toggleModalOcorrencia,
      iniciarEditarOcorrencia,
    } = this.props;
    return (
      <div className="Ocorrencias">
        <div className="text-center mb-2">
          <Button
            variant="outline-primary"
            onClick={() => toggleModalOcorrencia(true)}
          >
            Adicionar Ocorrencia
          </Button>
        </div>
        <Row>
          <Col xs="12" md={{ span: 8, offset: 2 }}>
            <ListGroup>
              {ocorrencias.map((item, index) => (
                <ListGroupItem
                  key={index}
                  className={`Ocorrencia ${item.nova_ocorrencia &&
                    'is-new'} ${item.ocorrencia_editada &&
                    'is-update'} ${item.codigo === isActive && 'is-active'}`}
                >
                  {item.ocorrencia_editada && (
                    <small className="d-block bg-primary text-light text-right Ocorrencia__feedback">
                      atualizada com sucesso
                    </small>
                  )}
                  {item.nova_ocorrencia && (
                    <small className="d-block bg-success text-light text-right Ocorrencia__feedback">
                      criada com sucesso
                    </small>
                  )}
                  <div
                    className="d-flex w-100 justify-content-between"
                    onClick={() => this.setActive(item.codigo)}
                  >
                    <h5 className="mb-1 Ocorrencia__Titulo">
                      {item.titulo}{' '}
                      <small className="text-muted">#{item.codigo}</small>
                    </h5>
                    <small className="text-muted">
                      {helpers.format.date(item.data, DATETIME_DEFAULT_FORMAT)}
                    </small>
                  </div>
                  <div className="card-text">
                    <ul className="list-unstyled d-flex">
                      <li className="mr-2 ml-auto">
                        <small className="d-block text-muted">Usuário</small>
                        <strong className="d-block font-weight-normal">
                          {item.corretor
                            ? item.corretor.nome
                            : auth.getUser().nome}
                        </strong>
                      </li>
                      <li className="mr-2">
                        <small className="d-block text-muted">
                          Imobiliária
                        </small>
                        <strong className="d-block font-weight-normal">
                          <strong
                            className="bullet"
                            style={{
                              backgroundColor: item.imobiliaria
                                ? item.imobiliaria.cor
                                : '#ffffff',
                            }}
                          ></strong>{' '}
                          {item.imobiliaria ? item.imobiliaria.nome : '-'}
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <p className="mb-1 clearfix Ocorrencia__Texto">
                    {item.texto}
                    {item.tipo === 1 &&
                      item.codigo_usuario === auth.getUser().usuario && (
                        <button
                          type="button"
                          onClick={() => iniciarEditarOcorrencia(item)}
                          className="Ocorencia__btn-editar btn btn-sm btn-link float-right text-muted"
                          title={`Editar ocorrência #${item.codigo}`}
                        >
                          <FaPen />
                        </button>
                      )}
                  </p>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>

        <ModalOcorrencia />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ocorrencias: state.prospecto.detail.ocorrencias,
});

const mapStateToDispatch = dispatch =>
  bindActionCreators(
    {
      toggleModalOcorrencia,
      iniciarEditarOcorrencia,
    },
    dispatch
  );

export default connect(mapStateToProps, mapStateToDispatch)(Ocorrencias);
