import styled from 'styled-components';

export const ContainerResume = styled.div`
  strong.header-section {
    border-bottom: 1px solid #eee;
    display: block;
    margin-bottom: 10px;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;

export const TitleLineResume = styled.div`
  text-align: left;
  margin-bottom: 10px;

  @media (min-width: 992px) {
    text-align: right;
    margin-bottom: 0;
  }

  color: #555;
  width: 150px;
  font-size: 13px;
  font-weight: 500;
  margin-right: 15px;
  line-height: 1;
`;

export const LineResume = styled.li`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
  }

  justify-content: flex-start;
  margin-bottom: ${props => (props.small ? '0' : '15px')};

  ul {
    flex: 1;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;
      line-height: 1;
      margin-right: 40px;
      margin-bottom: ${props => (props.small ? '0' : '15px')};
      small {
        font-size: 12px;
        color: #bbb;
      }
      strong {
        font-weight: 500;
      }
    }
  }
`;
