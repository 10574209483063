import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import api from './Services/Service';
import Routes from './Routes';
import configureStore from './Store';
import history from './Config/history';
import { URL_BASE } from './constant';
import './index.scss';

const store = configureStore();

api.setupInterceptors(store, history);

ReactDOM.render(
  <Provider store={store}>
    <Router basename={URL_BASE} history={history}>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById('root')
);
