import {Component} from 'react'
import { bindActionCreators } from 'redux'
import { buscarEndereco } from '~/Store/Prospecto/actions'
import { connect } from 'react-redux'
import { TAMANHO_CEP } from '../../../constant'
import PropTypes from 'prop-types'

class BuscaEnderecoPorCEP extends Component{
    state = {
        erroBuscaEndereco: null
    }

    static propTypes = {
        quandoCarregarEndereco: PropTypes.func.isRequired,
        onChangeCEP: PropTypes.func.isRequired
    }

    handleChange = (event) => {
        if (this.state.erroBuscaEndereco){
            this.setState({
                erroBuscaEndereco: null
            })
        }
        let CEP = event.target.value.replace(/\D/g, '')

        if (CEP.length === TAMANHO_CEP){
            this.props.buscarEndereco(CEP)
                .then(response => {
                    this.props.quandoCarregarEndereco(response)
                })
                .catch(() => {
                    this.setState({
                        erroBuscaEndereco: 'O CEP não foi encontrado'
                    })
                })
        }

        this.props.onChangeCEP(event)
    }

    render(){
        return this.props.children({
            buscandoEndereco: this.props.buscandoEndereco,
            erroBuscaEndereco: this.state.erroBuscaEndereco,
            onChangeCEP: this.handleChange
        })
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    buscarEndereco
}, dispatch)

const mapStateToProps = (state) => ({
    buscandoEndereco: state.prospecto.buscandoEndereco
})

export default connect(mapStateToProps, mapDispatchToProps)(BuscaEnderecoPorCEP)
