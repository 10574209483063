import { combineReducers } from 'redux';

import auth from '~/Components/Login/authReducer';
import empreendimentos from '~/Components/Empreendimentos/empreendimentosReducer';
import prospecto from '~/Store/Prospecto/reducer';
import options from '~/Store/Options/reducer';
import produtividade from './Produtividade/reducer';
import comissoes from './Comissao/reducer';
import corretor from '~/Store/Corretor/reducer';
import ranking from '~/Store/Ranking/reducer';
import vendas from '~/Store/Vendas/reducer';

import { DO_LOGOUT } from '~/Components/Login/AuthActions';

const appReducer = combineReducers({
  prospecto,
  empreendimentos,
  auth,
  options,
  produtividade,
  comissoes,
  corretor,
  ranking,
  vendas,
});

const rootReducer = (state, action) => {
  if (action.type === DO_LOGOUT) {
    // eslint-disable-next-line no-undefined
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
