import React, { Component } from 'react';
import {
  Col,
  Form,
  Card,
  Button,
  ListGroup,
  Row,
  Modal,
} from 'react-bootstrap';
import { FiSave } from 'react-icons/fi';
import { MdEdit, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import * as api from '~/Services/Service';
import Header from '~/Components/Header/Header';
import Hint from '~/Components/Hint/Hint';

export class ConfiguracoesFluxoProspecto extends Component {
  state = {
    motivos: [],
    codigo_motivo_editando: 0,
    codigo_motivo_deletando: 0,
    descricao: '',
    tag: '',
    limite: null,
    tag_invalida: false,
  };

  validTagExpression = /^([a-z0-9]+_?)*$/;

  componentDidMount() {
    api
      .getParametrosGerais()
      .then(response => {
        this.setState({
          Limite: response.LimiteFluxoProspecto,
        });
      })
      .catch(error => {
        this.onError(error, 'Erro ao obter o parâmetro limite.');
      });

    api
      .getMotivosDescarte()
      .then(response => {
        this.setState({
          motivos: response,
        });
      })
      .catch(error => {
        this.onError(error, 'Erro ao obter os motivos de descarte.');
      });
  }

  onError = (error, mensagem) => {
    console.error(error);
    toast.error(mensagem, {
      position: 'top-right',
    });
  };

  onSuccess = mensagem => {
    toast.success(mensagem, {
      position: 'top-right',
    });
  };

  salvarLimiteProspectosPorCorretor = async e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    api
      .updateLimiteFluxoProspectos(this.state.limite)
      .then(() => {
        this.onSuccess('Limite atualizado com sucesso!');
        this.setState({ isSubmitting: false });
      })
      .catch(error => {
        this.onError(error, 'Erro ao atualizar o limite.');
        this.setState({ isSubmitting: false });
      });
  };

  adicionarMotivoDescarte = async e => {
    e.preventDefault();
    if (!this.state.tag.match(this.validTagExpression)) {
      this.setState({ tagInvalida: true });
      return;
    }
    this.setState({ tagInvalida: false });
    const motivo = {
      tag: this.state.tag,
      descricao: this.state.descricao,
      codigo: this.state.codigo_motivo_editando,
    };
    this.inserirOuAtualizarMotivo(motivo);
  };

  iniciarEditarMotivo = codigo => {
    const motivo = this.state.motivos.filter(m => m.codigo === codigo)[0];
    this.tagInput.focus();
    this.setState({
      tag: motivo.tag,
      descricao: motivo.descricao,
      codigo_motivo_editando: codigo,
    });
  };

  cancelarEditarMotivo = () => {
    this.setState({
      tag: '',
      descricao: '',
      codigo_motivo_editando: 0,
    });
  };

  inserirOuAtualizarMotivo = motivo => {
    this.setState({ isSubmitting: true });
    const editando = motivo.codigo === 0;
    api
      .inserirOrAtualizarMotivoDescarte(motivo)
      .then(response => {
        let motivos = this.state.motivos;
        if (motivo.codigo === 0) {
          motivo.codigo = response.codigo;
          motivos.push(motivo);
        } else
          motivos = motivos.map(m => (m.codigo === motivo.codigo ? motivo : m));
        this.setState({
          motivos: motivos,
          tag: '',
          descricao: '',
          codigo_motivo_editando: 0,
          isSubmitting: false,
        });
        const acao = editando ? 'adicionado' : 'editado';
        this.onSuccess(`Motivo ${acao} com sucesso!`);
      })
      .catch(error => {
        const acao = editando ? 'adicionar' : 'editar';
        this.onError(error, `Erro ao ${acao} o motivo.`);
        this.setState({
          tag: '',
          descricao: '',
          codigo_motivo_editando: 0,
          isSubmitting: false,
        });
      });
  };

  confirmarExcluirMotivo = codigo => {
    this.setState({
      confirmarExcluirMotivo: true,
      CodigoMotivoDeletando: codigo,
    });
  };

  cancelarExcluirMotivo = () => {
    this.setState({
      confirmarExcluirMotivo: false,
      CodigoMotivoDeletando: 0,
    });
  };

  excluirMotivo = () => {
    const codigo = this.state.codigo_motivo_deletando;
    this.setState({
      confirmarExcluirMotivo: false,
      CodigoMotivoDeletando: 0,
    });
    api
      .desativarMotivoDescarte(codigo)
      .then(() => {
        let motivos = this.state.motivos;
        motivos = motivos.filter(m => m.codigo !== codigo);
        this.setState({
          Motivos: motivos,
        });
        this.onSuccess('Motivo removido com sucesso!');
      })
      .catch(error => {
        this.onError(error, 'Erro ao remover o motivo.');
      });
  };

  handleChange = e => {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  };

  render() {
    const {
      motivos,
      descricao,
      tag,
      limite,
      isSubmitting,
      confirmarExcluirMotivo,
      codigo_motivo_editando,
      tagInvalida,
    } = this.state;

    const motivoDesativando = this.state.motivos.filter(
      m => m.codigo === this.state.codigo_motivo_deletando
    )[0];
    const podeSalvarMotivo = descricao !== '' && tag !== '';
    return (
      <>
        <Header title="Configurações do Fluxo de Prospecto" />

        <Card className="m-4">
          <Card.Header>Parâmetro Geral</Card.Header>
          <Card.Body>
            <Col>
              <Form onSubmit={this.salvarLimiteProspectosPorCorretor}>
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Limite
                      <Hint
                        title="Limite"
                        content={
                          <span>
                            Limite geral de prospectos por corretor, utilizado
                            quando não há limite definido por empreendimento{' '}
                            <a
                              target="blank"
                              href="https://www.revobrasil.com.br/Extranet/PDV/Consultar.aspx"
                            >
                              nas configurações do PDV
                            </a>
                            .
                          </span>
                        }
                      />
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        type="number"
                        name="limite"
                        onChange={this.handleChange}
                        disabled={isSubmitting}
                        value={limite}
                        placeholder="Limite de prospectos por corretor"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="12">
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="float-right"
                        disabled={isSubmitting}
                      >
                        <FiSave />
                        <span>&nbsp;Salvar</span>
                      </Button>
                    </Col>
                  </Form.Group>
                </>
              </Form>
            </Col>
          </Card.Body>
        </Card>

        <Card className="m-4">
          <Card.Header>Motivos de Descarte</Card.Header>
          <Card.Body>
            <Col>
              <Form onSubmit={this.adicionarMotivoDescarte}>
                <>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Tag
                      <Hint
                        title="Tag"
                        content="Tag inserida no contato do RdStation num evento de descarte do vínculo, se este for originado da Central de Atendimento. Deve conter apenas letras minúsculas, números e underscore. Exemplo: cliente_nao_responde."
                      />
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control
                        type="text"
                        name="tag"
                        className={tagInvalida && 'is-invalid'}
                        ref={input => {
                          this.tagInput = input;
                        }}
                        onChange={this.handleChange}
                        disabled={isSubmitting}
                        value={tag}
                        placeholder="Tag do motivo (ex. cliente_nao_responde)"
                      />
                      <Form.Control.Feedback type="invalid">
                        Tag pode conter apenas letras minúsculas, números e
                        underscore. <br />
                        Exemplo: cliente_nao_responde.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="2">
                      Descrição
                    </Form.Label>
                    <Col sm="6">
                      <Form.Control
                        type="text"
                        name="descricao"
                        onChange={this.handleChange}
                        disabled={isSubmitting}
                        value={descricao}
                        placeholder="Descrição do motivo"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col sm="12">
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="float-right"
                        disabled={isSubmitting || !podeSalvarMotivo}
                      >
                        <FiSave />
                        <span>&nbsp;Salvar</span>
                      </Button>
                      {codigo_motivo_editando !== 0 && (
                        <Button
                          variant="outline-secondary"
                          className="float-right mr-2"
                          onClick={this.cancelarEditarMotivo}
                        >
                          <FiSave />
                          <span>&nbsp;Cancelar</span>
                        </Button>
                      )}
                    </Col>
                  </Form.Group>
                </>
              </Form>
            </Col>
            <ListGroup>
              {motivos.map(m => {
                return (
                  <ListGroup.Item key={m.codigo}>
                    <Row>
                      <Col sm={10}>
                        <p>{m.descricao}</p>
                        <small>{m.tag || '(tag não informada)'}</small>
                      </Col>
                      <Col sm={2}>
                        <Button
                          variant="link"
                          onClick={() => this.confirmarExcluirMotivo(m.codigo)}
                          className="float-right text-danger"
                        >
                          <MdDelete />
                        </Button>
                        <Button
                          variant="link"
                          className="float-right text-warning"
                          onClick={() => this.iniciarEditarMotivo(m.codigo)}
                        >
                          <MdEdit />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Card.Body>
        </Card>

        {/* TODO: fazer disso um componente */}
        <Modal
          show={confirmarExcluirMotivo}
          onHide={this.cancelarExcluirMotivo}
        >
          <Modal.Header closeButton>
            <Modal.Title>Desativando Motivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Deseja desativar o motivo de descarte '
            {motivoDesativando == null ? '' : motivoDesativando.descricao}'?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.cancelarExcluirMotivo}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={this.excluirMotivo}>
              Desativar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ConfiguracoesFluxoProspecto;
