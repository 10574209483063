import { MAX_SIZE_DOCUMENTO, TIPO_UPLOAD_PDF } from '../constant';
import jsPDF from 'jspdf';
import Compressor from 'compressorjs';

const getImageSize = file => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = error => reject(error);
    img.src = file;
  });
};

const generateBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const verifyCompressImage = file => {
  return new Promise((resolve, reject) => {
    if (file.size > MAX_SIZE_DOCUMENTO) {
      let percetualBase = 8;
      let percentualParaTamanhoIdeal =
        100 - (MAX_SIZE_DOCUMENTO / file.size) * 100 - percetualBase;

      let quality;
      switch (true) {
        case percentualParaTamanhoIdeal >= 1 &&
          percentualParaTamanhoIdeal <= 25:
          quality = 0.9;
          break;
        case percentualParaTamanhoIdeal > 26 &&
          percentualParaTamanhoIdeal <= 40:
          quality = 0.8;
          break;
        case percentualParaTamanhoIdeal > 41 &&
          percentualParaTamanhoIdeal <= 55:
          quality = 0.7;
          break;
        case percentualParaTamanhoIdeal > 56 &&
          percentualParaTamanhoIdeal <= 70:
          quality = 0.6;
          break;
        case percentualParaTamanhoIdeal > 71 &&
          percentualParaTamanhoIdeal <= 76:
          quality = 0.5;
          break;
        case percentualParaTamanhoIdeal > 76 &&
          percentualParaTamanhoIdeal <= 79:
          quality = 0.4;
          break;
        case percentualParaTamanhoIdeal > 80 &&
          percentualParaTamanhoIdeal <= 82:
          quality = 0.3;
          break;
        case percentualParaTamanhoIdeal > 83:
          quality = 0.2;
          break;
        default:
          quality = NaN;
          break;
      }

      new Compressor(file, {
        quality,
        maxWidth: 1920,
        maxHeight: 1920, //TO DO Teste
        success: result => resolve(result),
        error: error => reject(error),
      });
    } else {
      resolve(file);
    }
  });
};

export default function buildPDFFromImage(file) {
  return verifyCompressImage(file).then(newFile => {
    return generateBase64(newFile).then(data => {
      return getImageSize(data).then(({ width, height }) => {
        let heightRatio = height / width;

        let doc = new jsPDF({ orientation: 'p', unit: 'pt', format: 'a4' });

        let widthDoc = doc.internal.pageSize.width;
        let heightDoc = doc.internal.pageSize.height;
        let margin = 20;

        const limitByHeight = heightRatio >= 1.5; //valor arbitrário, ajustar conforme.
        let heightImage = widthDoc * heightRatio - margin * 2;
        let widthImage = widthDoc - margin * 2;
        if (limitByHeight) {
          heightImage = heightDoc - margin * 2;
          widthImage = heightDoc * (1 / heightRatio) - margin * 2;
        }

        let x = (widthDoc - widthImage) / 2;
        let y = (heightDoc - heightImage) / 2;

        doc.addImage(data, 'JPEG', x, y, widthImage, heightImage);

        let nomeDoc = 'Novo Documento.pdf';

        return new File([doc.output('blob')], nomeDoc, {
          type: TIPO_UPLOAD_PDF,
        });
      });
    });
  });
}
