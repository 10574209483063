import React, { useEffect } from 'react';
import { Button, Modal, Row, Col, Badge, Card, Alert } from 'react-bootstrap';
import { Field, Form, Formik, useFormikContext } from 'formik';

import { formatarMonetario } from '~/Utils/Helpers.js';
import dialog from '~/Utils/dialog';
import MoneyField from '~/Components/Form/MoneyField';
import {
  calcularValorChequeBonus,
  calcularValorSinalEsperado,
  calcularComissaoEsperada,
  TiposAplicacaoChequeBonus,
  TiposValorChequeBonus,
  r2,
} from '~/Pages/Vendas/helpers';

const VerificacaoValores = ({ ativoEmEdicao, chequeBonus }) => {
  const form = useFormikContext();

  useEffect(() => {
    verificaValorVenda(form.values.valor_venda);
  }, [form.values.valor_venda]);

  useEffect(() => {
    verificaValorSinal(form.values.valor_sinal);
  }, [form.values.valor_sinal]);

  const verificaValorSinal = valorSinal => {
    const { valor_venda, aplicou_cheque_bonus_sinal } = form.values;

    const valorSinalEsperado = r2(
      calcularValorSinalEsperado(ativoEmEdicao, valor_venda)
    );

    const comissaoEsperada = calcularComissaoEsperada(
      ativoEmEdicao,
      valor_venda,
      valorSinal
    );

    // const diffComissao = r2(comissaoEsperada - valorSinal);

    const valorInformadoOuFixo =
      valorSinal > valorSinalEsperado ? valorSinalEsperado : valorSinal;

    form.setFieldValue('valor_sinal', valorInformadoOuFixo);
    form.setFieldValue('comissao_esperada', comissaoEsperada);
    // form.setFieldValue('valor_venda_efetivo', r2(valor_venda - diffComissao));
    let descontoSinal = r2(valorSinalEsperado - valorInformadoOuFixo);
    if (descontoSinal < 0) descontoSinal = 0;
    if (aplicou_cheque_bonus_sinal && descontoSinal < chequeBonus.valor) {
      descontoSinal = chequeBonus.valor;
      form.setFieldValue('valor_sinal', valorSinalEsperado - chequeBonus.valor);
    }
    form.setFieldValue('desconto_sinal', descontoSinal);
  };

  const verificaValorVenda = valorVenda => {
    const { valor_venda_original } = ativoEmEdicao;
    const valorVendaInformadoOuOriginal = r2(valorVenda);
    // valorVenda > valor_venda_original ? valor_venda_original : valorVenda);
    form.setFieldValue('valor_venda', valorVendaInformadoOuOriginal);
    let descontoValorVenda = r2(
      valor_venda_original - valorVendaInformadoOuOriginal
    );
    // if (descontoValorVenda < 0) descontoValorVenda = 0;
    form.setFieldValue('desconto_valor_venda', descontoValorVenda);

    const valorSinalEsperado = r2(
      calcularValorSinalEsperado(ativoEmEdicao, valorVendaInformadoOuOriginal)
    );
    form.setFieldValue('valor_sinal_esperado', valorSinalEsperado);
    verificaValorSinal(valorSinalEsperado);
  };

  return null;
};

const ModalSinal = ({
  onCancel,
  ativoEmEdicao,
  salvarSinal,
  chequeBonus,
  ativosComChequeBonus,
  readonly,
  temParcelasSinal,
}) => {
  if (!ativoEmEdicao) return null;

  const {
    valor_venda_original,
    valor_sinal_original,
    desconto,
  } = ativoEmEdicao;

  const valorVendaFormatado = formatarMonetario(valor_venda_original);
  const valorSinalFormatado = formatarMonetario(valor_sinal_original);

  const {
    aplicou_cheque_bonus_sinal,
    aplicou_cheque_bonus_valor_venda,
    desconto_sinal,
    desconto_valor_venda,
  } = desconto || {};

  const chequeBonusAplicadoEmOutroLote =
    ativosComChequeBonus.length > 0 &&
    !ativosComChequeBonus.find(ativo => ativo.codigo === ativoEmEdicao.codigo);

  const printChequeBonus = (chequeBonus, valorBase) => {
    if (!chequeBonus) return null;

    const valorAplicadoCB = calcularValorChequeBonus(chequeBonus, valorBase);
    if (chequeBonus.tipo_valor_cheque_bonus === TiposValorChequeBonus.Fixo)
      return formatarMonetario(valorAplicadoCB);
    //porcentagem
    return `${chequeBonus.valor.toFixed(2)}% (${formatarMonetario(
      valorAplicadoCB
    )})`;
  };

  const aplicacaoChequeBonus = (chequeBonus, valorBase) => {
    if (!chequeBonus) return valorBase;
    if (chequeBonus.tipo_valor_cheque_bonus === TiposValorChequeBonus.Fixo)
      return valorBase - chequeBonus.valor;
    //porcentagem
    return valorBase - valorBase * chequeBonus.valor;
  };

  return (
    <Modal show={!!ativoEmEdicao} onHide={onCancel}>
      <Formik
        initialValues={{
          codigo: ativoEmEdicao.codigo,
          valor_sinal: r2(ativoEmEdicao.valor_sinal || valor_sinal_original),
          valor_venda: r2(ativoEmEdicao.valor_venda || valor_venda_original),
          valor_sinal_esperado: r2(valor_sinal_original),
          aplicou_cheque_bonus_sinal: aplicou_cheque_bonus_sinal,
          aplicou_cheque_bonus_valor_venda: aplicou_cheque_bonus_valor_venda,
          comissao_esperada: r2(valor_sinal_original),
          // valor_venda_efetivo: r2(valor_venda_original),
          desconto_sinal: desconto_sinal,
          desconto_valor_venda: desconto_valor_venda,
        }}
        enableReinitialization
        onSubmit={async values => {
          if (temParcelasSinal) {
            const result = await dialog.confirm({
              title: 'Editar valores do ativo?',
              message:
                'Ao editar os valores do ativo, as parcelas de sinal informadas serão removidas. Deseja continuar?',
            });
            if (!result.proceed) return;
          }

          salvarSinal(values);
          onCancel();
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>{`Valores do lote #${ativoEmEdicao.codigo}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{`Quadra: ${ativoEmEdicao.quadra} / Lote: ${ativoEmEdicao.lote}`}</p>
                <Row className="mb-2">
                  <Col md={4}>
                    <SmallTitleValue
                      title="Valor original"
                      value={formatarMonetario(
                        ativoEmEdicao.valor_venda_original
                      )}
                    />
                  </Col>
                  <Col md={4}>
                    <SmallTitleValue
                      title="Sinal original"
                      value={formatarMonetario(
                        ativoEmEdicao.valor_sinal_original
                      )}
                    />
                  </Col>
                  <Col md={4}>
                    <SmallTitleValue
                      title="Entrada mínima"
                      value={formatarMonetario(
                        values.valor_sinal_esperado,
                        false
                      )}
                    />
                  </Col>
                </Row>
                <Card className="mb-2 card-small" bg="light">
                  <Card.Header>Valor da Venda</Card.Header>
                  <Card.Body>
                    {chequeBonus &&
                      chequeBonus.tipo_aplicacao_cheque_bonus ===
                        TiposAplicacaoChequeBonus.ValorVenda && (
                        <Alert variant="success">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              Cheque bônus:
                              <strong className="ml-2">
                                {printChequeBonus(
                                  chequeBonus,
                                  ativoEmEdicao.valor_venda_original
                                )}
                              </strong>
                            </span>

                            {values.aplicou_cheque_bonus_valor_venda && (
                              <Badge variant="success" className="float-right">
                                Aplicado
                              </Badge>
                            )}
                            {chequeBonusAplicadoEmOutroLote && (
                              <Badge variant="warning">
                                Aplicado em outro lote
                              </Badge>
                            )}

                            {!chequeBonusAplicadoEmOutroLote &&
                              !values.aplicou_cheque_bonus_valor_venda && (
                                <Button
                                  variant="outline-primary"
                                  size="sm"
                                  className="float-right"
                                  onClick={() => {
                                    setFieldValue(
                                      'valor_venda',
                                      aplicacaoChequeBonus(
                                        chequeBonus,
                                        ativoEmEdicao.valor_venda_original
                                      )
                                    );
                                    setFieldValue(
                                      'aplicou_cheque_bonus_valor_venda',
                                      true
                                    );
                                  }}
                                >
                                  Aplicar
                                </Button>
                              )}
                            {values.aplicou_cheque_bonus_valor_venda && (
                              <Button
                                variant="outline-danger"
                                size="sm"
                                onClick={() => {
                                  setFieldValue(
                                    'valor_venda',
                                    ativoEmEdicao.valor_venda_original
                                  );
                                  setFieldValue(
                                    'aplicou_cheque_bonus_valor_venda',
                                    false
                                  );
                                }}
                              >
                                Remover
                              </Button>
                            )}
                          </div>
                        </Alert>
                      )}

                    <Row>
                      <Col md="6">
                        <div className="form-group">
                          <label htmlFor="valor_venda">Valor da venda</label>
                          <Field
                            name="valor_venda"
                            id="valor_venda"
                            type="text"
                            component={MoneyField}
                            className="form-control"
                            placeholder={valorVendaFormatado}
                            disabled={readonly}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        {values.desconto_valor_venda > 0 && (
                          <>
                            <small> Desconto total</small>
                            <strong className="d-block text-success">
                              {formatarMonetario(values.desconto_valor_venda)}
                            </strong>
                          </>
                        )}
                        {values.desconto_valor_venda < 0 && (
                          <>
                            <small> Acréscimo total</small>
                            <strong className="d-block text-warning">
                              {formatarMonetario(
                                Math.abs(values.desconto_valor_venda)
                              )}
                            </strong>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="card-small" bg="light">
                  <Card.Header>Valor do Sinal</Card.Header>
                  <Card.Body>
                    {chequeBonus &&
                      chequeBonus.tipo_aplicacao_cheque_bonus ===
                        TiposAplicacaoChequeBonus.ValorSinal && (
                        <Alert variant="success">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              Cheque bônus:
                              <strong className="ml-2">
                                {printChequeBonus(
                                  chequeBonus,
                                  ativoEmEdicao.valor_sinal_original
                                )}
                              </strong>
                            </span>

                            {values.aplicou_cheque_bonus_sinal && (
                              <Badge variant="success" className="float-right">
                                Aplicado
                              </Badge>
                            )}
                            {chequeBonusAplicadoEmOutroLote && (
                              <Badge variant="warning">
                                Aplicado em outro lote
                              </Badge>
                            )}
                            {!readonly && (
                              <>
                                {!chequeBonusAplicadoEmOutroLote &&
                                  !values.aplicou_cheque_bonus_sinal && (
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      className="float-right"
                                      onClick={() => {
                                        setFieldValue(
                                          'valor_sinal',
                                          aplicacaoChequeBonus(
                                            chequeBonus,
                                            values.valor_sinal_esperado
                                          )
                                        );
                                        setFieldValue(
                                          'aplicou_cheque_bonus_sinal',
                                          true
                                        );
                                      }}
                                    >
                                      Aplicar
                                    </Button>
                                  )}
                                {values.aplicou_cheque_bonus_sinal && (
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => {
                                      setFieldValue(
                                        'valor_sinal',
                                        values.valor_sinal_esperado
                                      );
                                      setFieldValue(
                                        'aplicou_cheque_bonus_sinal',
                                        false
                                      );
                                    }}
                                  >
                                    Remover
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </Alert>
                      )}

                    <Row>
                      <Col md="6">
                        <div className="form-group">
                          <label htmlFor="valor_sinal">Valor do sinal</label>
                          <Field
                            id="valor_sinal"
                            type="tel"
                            placeholder={valorSinalFormatado}
                            name="valor_sinal"
                            value={values.valor_sinal}
                            component={MoneyField}
                            className="form-control"
                            disabled={
                              readonly || values.aplicou_cheque_bonus_sinal
                            }
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <small>Desconto total</small>
                        <strong className="d-block">
                          {values.desconto_sinal > 0
                            ? formatarMonetario(values.desconto_sinal)
                            : '-'}
                        </strong>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <VerificacaoValores
                  ativoEmEdicao={ativoEmEdicao}
                  chequeBonus={chequeBonus}
                />
              </Modal.Body>
              <Modal.Footer className="">
                {readonly ? (
                  <Button
                    variant="outline-primary"
                    type="button"
                    onClick={onCancel}
                  >
                    Fechar
                  </Button>
                ) : (
                  <>
                    <Button variant="primary" className="mb-1" type="submit">
                      Salvar
                    </Button>
                    <Button
                      variant="outline-primary"
                      type="button"
                      onClick={onCancel}
                    >
                      Cancelar
                    </Button>
                  </>
                )}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const SmallTitleValue = ({ title, value }) => {
  return (
    <small>
      <strong className="d-block">{title}:</strong>
      {value}
    </small>
  );
};

export default ModalSinal;
