import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CardProdutividadeResumo from '../Components/Produtividade/CardProdutividadeResumo';
import CardProdutividadeFiltro from '../Components/Produtividade/CardProdutividadeFiltro';
import CardResumoImobiliaria from '../Components/Produtividade/CardResumoImobiliaria';
import Loading from '../Components/Loading';
import CardCorretoresEmpreendimentos from '../Components/Produtividade/CardCorretoresEmpreendimentos';
import CardDetalhesVenda from '~/Components/Produtividade/CardDetalhesVenda';
import { FaChevronRight } from 'react-icons/fa';
import {
  selecionaCorretor,
  selecionaImobiliaria,
  selecionaEmpreendimento,
} from '~/Store/Produtividade/actions';

class Produtividade extends Component {
  state = { isSearchActive: false };

  onClickTodos() {
    const {
      selecionaEmpreendimento,
      selecionaCorretor,
      selecionaImobiliaria,
    } = this.props;
    selecionaImobiliaria(null);
    selecionaCorretor(null);
    selecionaEmpreendimento(null);
  }

  onClickImobiliaria() {
    const {
      corretorSelecionado,
      empreendimentoSelecionado,
      selecionaEmpreendimento,
      selecionaCorretor,
    } = this.props;
    if (corretorSelecionado) selecionaCorretor(null);
    else if (empreendimentoSelecionado) selecionaEmpreendimento(null);
  }

  render() {
    const {
      carregandoProdutividade,
      imobiliariaSelecionada,
      corretorSelecionado,
      empreendimentoSelecionado,
    } = this.props;
    const detalhesSelecionado =
      corretorSelecionado || empreendimentoSelecionado;

    return (
      <>
        <div className="ProdutividadePage mt-4">
          <CardProdutividadeFiltro />
          {carregandoProdutividade && (
            <Loading label="Carregando relatório de produtividade..." />
          )}
          {!carregandoProdutividade && (
            <>
              {imobiliariaSelecionada && (
                <div className="text-center mt-3 produtividade-imob-corr">
                  <button
                    onClick={() => this.onClickTodos()}
                    type="button"
                    className="btn btn-outline-secondary btnTransparente btnTodos"
                  >
                    Todos
                  </button>
                  <FaChevronRight />
                  <button
                    onClick={() => this.onClickImobiliaria()}
                    className="btnTransparente imobiliariaSelecionada"
                  >
                    <img
                      src={imobiliariaSelecionada.url_imagem}
                      alt={imobiliariaSelecionada.nome}
                    />
                  </button>
                  {detalhesSelecionado && (
                    <>
                      <FaChevronRight />
                      <div
                        className={`${
                          corretorSelecionado ? 'corretor' : 'empreendimento'
                        }Selecionado`}
                      >
                        <img
                          alt={detalhesSelecionado.nome}
                          src={detalhesSelecionado.url_imagem}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              <CardProdutividadeResumo />
              <CardDetalhesVenda />
              <CardCorretoresEmpreendimentos />
              <CardResumoImobiliaria />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  carregandoProdutividade: state.produtividade.carregandoProdutividade,
  imobiliariaSelecionada: state.produtividade.imobiliariaSelecionada,
  corretorSelecionado: state.produtividade.corretorSelecionado,
  empreendimentoSelecionado: state.produtividade.empreendimentoSelecionado,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selecionaCorretor,
      selecionaImobiliaria,
      selecionaEmpreendimento,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Produtividade);
