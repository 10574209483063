import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  fetchProspectos,
  applyFilter,
  limparProspectoFiltro,
} from '~/Store/Prospecto/actions';
import ProspectoBusca from './ProspectoBusca/ProspectoBusca';
import { serialize, unserialize } from '../../Utils/Helpers';
import ModalAdicionarProspecto from './ModalAdicionarProspecto';
import AuthStorage from '../../Utils/AuthStorage';

const mapStateToProps = state => ({
  ProspectoLista: state.prospecto.list,
  isLoadingProspect: state.prospecto.isLoading,
  isErrorProspect: state.prospecto.isError,
  pagination: state.prospecto.pagination,
  isSearchActive: state.prospecto.isSearchActive,
  filter: state.prospecto.filter,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProspectos,
      applyFilter,
      limparProspectoFiltro,
    },
    dispatch
  );

const termsToFilter = [
  'tipo_filtro',
  'filtro',
  'codigo_corretor',
  'codigo_gerente',
  'pagina_atual',
  'quantidade_por_pagina',
  'ordem',
  'codigo_empreendimento',
  'data_inicial',
  'data_final',
  'tipo_pasta',
];

const Propspects = withRouter(
  class extends Component {
    buildFilter = (filter = {}) => {
      for (const key in filter) {
        if (
          filter.hasOwnProperty(key) &&
          typeof filter[key] === 'object' &&
          filter[key].value
        ) {
          filter[key] = filter[key].value;
        }
      }

      return filter;
    };
    getSearch = () => {
      return unserialize(this.props.location.search);
    };
    verifySearch = () => {
      let search = this.getSearch();

      if (AuthStorage.isGerente() && search.gerente) {
        search.gerente = AuthStorage.getUser().usuario;
        this.props.history.push({
          search: `?${serialize(this.buildFilter(search))}`,
        });
      }

      if (AuthStorage.isCorretor() && search.corretor) {
        search.corretor = AuthStorage.getUser().usuario;
        this.props.history.push({
          search: `?${serialize(this.buildFilter(search))}`,
        });
      }

      if (Object.keys(search).length > 0) {
        this.fetchSearch(search);
      }
    };
    fetchSearch = search => {
      this.props.applyFilter(search);
      this.props.fetchProspectos(search);
    };
    componentDidMount() {
      if (this.props.location.pathname === '/prospectos/lista') {
        this.verifySearch();
      }
    }
    componentDidUpdate(prevProps) {
      if (
        prevProps.match.params.id !== this.props.match.params.id &&
        this.props.match.params.id
      ) {
        this.props.limparProspectoFiltro();
      }

      // Verifica se as querystrings foram alteradas
      if (prevProps.location.search !== this.props.location.search) {
        // Verifica se as querystrings alteradas são algum tipo de filtro
        let isFilter = termsToFilter.reduce((prev, curr) => {
          return prev || this.props.location.search.indexOf(`${curr}=`) > -1;
        }, false);
        if (isFilter) {
          this.verifySearch();
        }
      }
    }
    doSearch = search => {
      let isEmptySearch = Object.keys(search).reduce((prev, curr) => {
        return search[curr] === '' && prev;
      }, true);
      if (isEmptySearch) {
        this.fetchSearch({});
      } else {
        this.props.history.push({
          pathname: '/prospectos/lista',
          search: `?${serialize(this.buildFilter(search))}`,
        });
      }
    };
    doNewSearch = search => {
      // eslint-disable-next-line no-unused-vars
      const { pagina_atual, ...rest } = search;

      this.doSearch(rest);
    };
    doPaginate = params => {
      params = {
        ...this.getSearch(),
        ...params,
      };

      this.doSearch(params);
    };
    onReset = () => {
      this.props.history.push({
        search: '',
      });
    };
    render() {
      const { hiddenSearch } = this.props;
      return (
        <>
          {!hiddenSearch && (
            <ProspectoBusca
              onSubmit={this.doNewSearch}
              onReset={this.onReset}
              readonly={AuthStorage.temCaracteristica(495)}
            />
          )}

          {this.props.children({ doPaginate: this.doPaginate })}

          <ModalAdicionarProspecto />
        </>
      );
    }
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Propspects);
