import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import MoneyField from '~/Components/Form/MoneyField';
import { DEPOSITO, MASK_DATE } from '~/constant';
import GeradorData from '~/Components/GeradorData';

export default function Deposito() {
  const { values, setFieldValue } = useFormikContext();
  if (values.tipo_parcela !== DEPOSITO) return null;

  return (
    <Row>
      <Col md="6">
        <Form.Group>
          <Form.Label>Valor</Form.Label>
          <Field
            name={`${DEPOSITO}.valor`}
            className="form-control"
            type="tel"
            placeholder="Ex.: 1.000,00"
            component={MoneyField}
          />
          <ErrorMessage
            name={`${DEPOSITO}.valor`}
            className="text-danger"
            component="small"
          />
        </Form.Group>
      </Col>
      <Col md="6">
        <Form.Group>
          <Form.Label>Vencimento</Form.Label>
          <Field
            name={`${DEPOSITO}.vencimento_mask`}
            as={MaskedInput}
            mask={MASK_DATE}
            className="form-control"
            type="tel"
            placeholder="Ex.: 10/10/2020"
          />
          <ErrorMessage
            name={`${DEPOSITO}.vencimento_mask`}
            className="text-danger"
            component="small"
          />
          <GeradorData
            original={values[DEPOSITO].vencimento_mask}
            onUpdate={date => setFieldValue(`${DEPOSITO}.vencimento`, date)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
