const INITIAL_STATE = {};

export const divisoes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@vendas/DIVIDIR_COMISSAO':
      const { codigo, porcentagem, codigo_usuario } = action.payload;
      return {
        ...state,
        [codigo]: {
          porcentagem,
          codigo_usuario,
        },
      };

    default:
      return state;
  }
};

export default divisoes;
