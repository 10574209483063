import { combineReducers } from 'redux';

export const COMISSAO_CARREGADA = 'COMISSAO_CARREGADA';
export const CARREGANDO_COMISSAO = 'CARREGANDO_COMISSAO';

const carregandoComissoes = (state = false, action) => {
  switch (action.type) {
    case CARREGANDO_COMISSAO:
      return true;
    case COMISSAO_CARREGADA:
      return false;
    default:
      return state;
  }
};

const dados = (state = null, action) => {
  switch (action.type) {
    case COMISSAO_CARREGADA:
      return action.payload.comissoes;
    default:
      return state;
  }
};

export default combineReducers({
  carregandoComissoes,
  dados,
});
