import { Component } from 'react';
import PropTypes from 'prop-types';

import * as constants from '../constant';
import * as helpers from '../Utils/Helpers';

export default class DateMask extends Component {
  state = {
    error: null,
    value: '',
  };

  static propTypes = {
    onValidate: PropTypes.func,
    allowFuture: PropTypes.bool,
    allowPast: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    restrictToSqlMinDate: PropTypes.bool,
  };

  static defaultProps = {
    allowFuture: false,
    allowPast: true,
    allowEmpty: false,
  };

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;

    if (
      (!this.state.value &&
        !!initialValue &&
        prevProps.initialValue !== initialValue) ||
      (!!initialValue && prevProps.initialValue !== initialValue)
    ) {
      this.setDate(helpers.format.date(initialValue));
    }
    if (
      this.state.value &&
      !initialValue &&
      prevProps.initialValue !== initialValue
    ) {
      this.setDate('');
    }
  }

  componentDidMount() {
    if (!this.state.value && !!this.props.initialValue) {
      this.setDate(helpers.format.date(this.props.initialValue));
    }
  }

  setDate = (dateString = '') => {
    let date = helpers.createDate(dateString, constants.DATE_DEFAULT_FORMAT);
    let error = null;

    const { allowPast, allowFuture, restrictToSqlMinDate } = this.props;

    let timeRule =
      (allowPast || date.isAfter(new Date())) &&
      (allowFuture || date.isBefore(new Date())) &&
      (!restrictToSqlMinDate || date.isAfter(helpers.MIN_SQL_DATE));

    if (
      dateString &&
      dateString.replace(/\D/g, '').length === 8 &&
      date.isValid() &&
      timeRule
    ) {
      this.props.onValidate(date.format(constants.DATE_FORMAT_SERVER));
    } else if (this.props.allowEmpty /*&& this.props.initialValue === ''*/) {
      this.props.onValidate('');
    } else {
      error = 'Data inválida';
    }

    this.setState({
      value: dateString,
      error,
    });
  };

  render() {
    return this.props.children({
      dateError: this.state.error,
      dateValue: this.state.value,
      setDate: this.setDate,
    });
  }
}
