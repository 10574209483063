import styled from 'styled-components';

export const Ranking = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (min-width: 992px) {
      max-width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @media (min-width: 1200px) {
      align-items: stretch;
      justify-content: center;
      margin-top: 33px;
    }
  }
`;

export const CorretorItem = styled.li.attrs({
  className: 'arrow-bottom-left',
})`
  background: #fff;
  box-shadow: 0 2px 10px
    ${props => (props.classificado ? '#fdd94b' : 'rgba(0, 0, 0, 0.2)')};
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.1;

  @media (min-width: 992px) {
    width: 31%;
    margin: 5px 1%;
  }

  @media (min-width: 1200px) {
    width: 14%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;

    small {
      margin: 0 auto;
    }
    span {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  @media (min-width: 1366px) {
    width: 12%;
  }

  img {
    width: 40px;
    height: 40px;
    max-width: 40px;
    margin: 0 5px;
    position: relative;
  }

  img.star {
    top: 10px;
    right: 10px;
    position: absolute;
    height: 20px;
    width: 20px;
    margin: 0;
  }

  span {
    font-weight: 700;
  }

  small {
    margin-left: auto;
    white-space: nowrap;
  }

  &::before {
    border-left-color: ${props => props.corImobiliaria || '#ccc'} !important;
  }
`;

export const CorretorDestaque = styled(CorretorItem)`
  box-shadow: 0 2px 10px #014dff5c;
  max-width: 400px;
  margin: 0 auto 20px;
  color: #014dff;
  flex-wrap: wrap;
  .menssagemCorretor {
    color: #6c757d;
    flex-basis: 100%;
    text-align: center;
  }

  @media (min-width: 992px) {
    margin: 0 auto 20px;
    width: 100%;
    flex-direction: row;
    small {
      margin-right: 0;
    }
    span {
      position: relative;
      top: auto;
      left: auto;
    }
  }
`;
