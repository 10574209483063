import React from 'react';
import { Modal, Button, Col, Tabs, Tab, Accordion } from 'react-bootstrap';

import AuthStorage from '~/Utils/AuthStorage';
import defaultLogo from '~/Images/logo.svg';

import { formatarMonetario } from '~/Utils/Helpers';

import {
  agruparPorEmpreendimento,
  agruparPorCpfCliente,
  agruparPorVenda,
  agruparPorCorretor,
  distinctTitulos,
  obterTotalComissao,
  obterTotalTitulosSinal,
  valoresComissaoSupervisor,
  TIPO_COMISSAO_COR,
  TIPO_COMISSAO_IMO,
  TIPO_COMISSAO_MKT,
} from '../helpers';

const ModalDetalhesEstatisticasInadimplencia = ({
  show,
  onHide,
  registros,
  empreendimentos,
  corretores,
}) => {
  const isCor = AuthStorage.isCorretor();

  const renderVisaoPorCorretor = (registros, canExpand) => {
    const porCorretor = agruparPorCorretor(registros);
    return porCorretor.map(({ corretor: corKey, registros }, index) => {
      const corretor =
        corretores.find(cor => cor.codigo === corKey.codigo_usuario_corretor) ||
        {};
      return (
        <div className="my-2" key={corKey.codigo_usuario_corretor}>
          <Col>
            <div>
              <strong>{corKey.apelido_conta_corretor}</strong>
              <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                <div className="text-center img-resumo-imobiliaria d-flex flex-column">
                  <img
                    src={corretor.foto ? corretor.foto : defaultLogo}
                    title={corKey.apelido_conta_corretor}
                    alt={corKey.apelido_conta_corretor}
                  />
                </div>

                {renderEstatisticas(registros)}
                {canExpand && (
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={index}
                    className="ml-auto"
                  >
                    Detalhes
                  </Accordion.Toggle>
                )}
              </div>
            </div>
            {canExpand && (
              <Accordion.Collapse eventKey={index}>
                <div className="bg-light p-2">
                  {renderVisaoPorEmpreendimento(registros, false, false, false)}
                </div>
              </Accordion.Collapse>
            )}
          </Col>
        </div>
      );
    });
  };

  const renderVisaoPorEmpreendimento = (
    registros,
    canExpand,
    incluirComissaoImob,
    incluirComissaoSup
  ) => {
    const porEmpreendimento = agruparPorEmpreendimento(registros);
    return porEmpreendimento.map(
      ({ empreendimento: empKey, registros }, index) => {
        const empreendimento =
          empreendimentos.find(emp => emp.codigo === empKey.codigo_familia) ||
          {};
        return (
          <div className="my-2" key={empKey.codigo_familia}>
            <Col>
              <div>
                <strong>{empreendimento.descricao}</strong>
                <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                  <div className="text-center img-resumo-imobiliaria d-flex flex-column">
                    <img
                      src={
                        empreendimento.logo ? empreendimento.logo : defaultLogo
                      }
                      title={empreendimento.nome}
                      alt={empreendimento.nome}
                    />
                  </div>

                  {renderEstatisticas(
                    registros,
                    incluirComissaoImob,
                    incluirComissaoSup
                  )}
                  {canExpand && (
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={index}
                      className="ml-auto"
                    >
                      Detalhes
                    </Accordion.Toggle>
                  )}
                </div>
              </div>
              {canExpand && (
                <Accordion.Collapse eventKey={index}>
                  <div className="bg-light p-2">
                    {renderVisaoPorCorretor(registros, false)}
                  </div>
                </Accordion.Collapse>
              )}
            </Col>
          </div>
        );
      }
    );
  };

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <h5>Estatísticas</h5>
      </Modal.Header>
      <Modal.Body>
        {isCor && renderVisaoPorEmpreendimento(registros, false, false, false)}
        {!isCor && (
          <Tabs defaultActiveKey="porCorretor">
            <Tab eventKey="porCorretor" title="Corretores">
              <Accordion>{renderVisaoPorCorretor(registros, true)}</Accordion>
            </Tab>
            <Tab eventKey="porEmpreendimento" title="Empreendimentos">
              <Accordion>
                {renderVisaoPorEmpreendimento(
                  registros,
                  true,
                  true,
                  AuthStorage.isADM()
                )}
              </Accordion>
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDetalhesEstatisticasInadimplencia;

export const renderEstatisticas = (
  registros,
  incluirComissaoImob = false,
  incluirComissaoSup = false
) => (
  <ul className="list-unstyled d-flex produtividade text-center text-md-left ml-2 flex-wrap justify-content-center">
    <li className="mx-2">
      <small className="d-block">Parcelas</small>
      <strong className="d-block">{distinctTitulos(registros).length}</strong>
    </li>
    <li className="mx-2">
      <small className="d-block">Clientes</small>
      <strong className="d-block">
        {agruparPorCpfCliente(registros).length}
      </strong>
    </li>
    <li className="mx-2">
      <small className="d-block">Vendas</small>
      <strong className="d-block">{agruparPorVenda(registros).length}</strong>
    </li>
    <li className="mx-2">
      <small className="d-block">Recebíveis</small>
      <strong className="d-block">
        {formatarMonetario(obterTotalTitulosSinal(registros)) || '-'}
      </strong>
    </li>
    {incluirComissaoSup && (
      <li className="mx-2">
        <small className="d-block">Comissão Comer.</small>
        <strong className="d-block">
          {valoresComissaoSupervisor(registros)}
        </strong>
      </li>
    )}
    {incluirComissaoImob && (
      <>
        <li className="mx-2">
          <small className="d-block">Comissão Imob.</small>
          <strong className="d-block">
            {formatarMonetario(
              obterTotalComissao(registros, TIPO_COMISSAO_IMO)
            ) || '-'}
          </strong>
        </li>
        <li className="mx-2">
          <small className="d-block">Comissão Imob. Mkt.</small>
          <strong className="d-block">
            {formatarMonetario(
              obterTotalComissao(registros, TIPO_COMISSAO_MKT)
            ) || '-'}
          </strong>
        </li>
      </>
    )}
    <li className="mx-2">
      <small className="d-block">Comissão Cor.</small>
      <strong className="d-block">
        {formatarMonetario(obterTotalComissao(registros, TIPO_COMISSAO_COR)) ||
          '-'}
      </strong>
    </li>
  </ul>
);
