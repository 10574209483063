import React from 'react';
import { useSelector } from 'react-redux';

import { AvatarImage } from '~/Styles';
import star from '~/Images/star.svg';

import { Ranking, CorretorDestaque, CorretorItem } from './styles';
import numeral from 'numeral';

export default function ListaRanking({
  corretorAtual,
  mensagem,
  ranking,
  tipoRanking = null,
}) {
  const imobiliariasById = useSelector(state => state.corretor.gerentesById);
  const formataVlResumo = valor => numeral(valor).format('(R$ 0.00 a)');
  return (
    <Ranking>
      {corretorAtual && corretorAtual.rank > 0 && (
        <>
          <CorretorDestaque
            corImobiliaria={
              imobiliariasById[corretorAtual.codigo_imobiliaria].cor_imobiliaria
            }
            classificado={corretorAtual.classificado}
          >
            <span>{corretorAtual.rank}</span>
            {corretorAtual.classificado && (
              <img className="star" src={star} alt="classificado" />
            )}
            <div className="podium-avatar">
              <AvatarImage
                src={corretorAtual.url_imagem}
                alt={corretorAtual.nome}
              />
            </div>
            <strong>
              {corretorAtual.nome.split(/(\s).+\s/).join('')} (você)
            </strong>
            {tipoRanking === 'VGV' ? (
              <>
                <small
                  className="d-block"
                  title={`R$ ${
                    corretorAtual.efetivadas
                      ? corretorAtual.efetivadas.toLocaleString('pt-BR')
                      : '0,00'
                  }`}
                >
                  R$
                  {formataVlResumo(corretorAtual.efetivadas)}
                </small>
              </>
            ) : (
              <>
                <small className="d-block">
                  {corretorAtual.efetivadas} venda
                  {corretorAtual.efetivadas > 1 ? 's' : ''}
                </small>
              </>
            )}
            {mensagem && (
              <small className="menssagemCorretor">{mensagem}</small>
            )}
          </CorretorDestaque>
        </>
      )}

      <ul>
        {ranking.map((corretor, index) => (
          <CorretorItem
            corImobiliaria={
              imobiliariasById[corretor.codigo_imobiliaria]
                ? imobiliariasById[corretor.codigo_imobiliaria].cor_imobiliaria
                : null
            }
            classificado={corretor.classificado}
            key={index}
          >
            <span>{corretor.rank !== 0 ? corretor.rank : ''}</span>
            {corretor.classificado && (
              <img className="star" src={star} alt="classificado" />
            )}
            <div className="podium-avatar">
              <AvatarImage src={corretor.url_imagem} alt={corretor.nome} />
            </div>
            <strong>{corretor.nome.split(/(\s).+\s/).join('')}</strong>
            {tipoRanking === 'VGV' ? (
              corretor.efetivadas !== 0 ? (
                <>
                  <small
                    className="d-block"
                    title={`R$ ${
                      corretor.efetivadas
                        ? corretor.efetivadas.toLocaleString('pt-BR')
                        : '0,00'
                    }`}
                  >
                    <small>R$</small>
                    {formataVlResumo(corretor.efetivadas)}
                  </small>
                </>
              ) : (
                <small>Sem classificação</small>
              )
            ) : corretor.quantidade_efetivadas !== 0 ? (
              <>
                <small className="d-block">
                  {corretor.quantidade_efetivadas} venda
                  {corretor.quantidade_efetivadas > 1 ? 's' : ''}
                </small>
              </>
            ) : (
              <small>Sem classificação</small>
            )}
          </CorretorItem>
        ))}
      </ul>
    </Ranking>
  );
}
