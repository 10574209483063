import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Container, Row, Col, Navbar } from 'react-bootstrap';
import { FiList, FiBarChart2, FiSearch } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { limparProspectoFiltro } from '~/Store/Prospecto/actions';
import ProspectoBuscaFormulario from './ProspectoBuscaFormulario';
import Outsider from '~/Components/Outsider';

class ProspectoBusca extends Component {
  state = {
    isSearchActive: false,
  };

  toggleSearch = () =>
    this.setState({ isSearchActive: !this.state.isSearchActive });

  closeSearch = () => this.setState({ isSearchActive: false });

  handleSubmit = values => {
    this.closeSearch();
    this.props.onSubmit(values);
  };

  render() {
    const { isSearchActive } = this.state;

    const {
      filterIsDirty,
      filter,
      isLoadingOptions,
      isLoading,
      empreendimentos,
      limparProspectoFiltro,
      readonly,
    } = this.props;

    return (
      <Outsider onClickOut={() => isSearchActive && this.closeSearch()}>
        <Navbar className="bg-white mb-3" expand="lg">
          <div className="mr-lg-auto ml-lg-0 mx-auto">
            <h5 className="mr-3 navbar-text mb-0 text-body">Prospectos</h5>
            {!readonly && (
              <Link
                className="d-inline-flex mr-1 btn btn-success align-items-center justify-content-center"
                to={{ search: '?novo=true' }}
              >
                <FaPlus /> <span className="ml-2 d-xs-none">Adicionar</span>
              </Link>
            )}
            <Button
              className="mr-2"
              variant="outline-secondary"
              title="Visão Funil"
              as={Link}
              to="/prospectos"
            >
              <FiBarChart2 size={15} style={{ transform: 'rotate(180deg)' }} />
            </Button>
            <Button
              className="mr-2 active btn btn-outline-secondary"
              title="Visão Lista"
            >
              <FiList size={15} />
            </Button>
            <Button
              size={20}
              variant="outline-secondary"
              onClick={this.toggleSearch}
              className={`mr-2 ${filterIsDirty &&
                !isSearchActive &&
                'have-search'}`}
            >
              {isSearchActive ? <MdClose size={15} /> : <FiSearch size={15} />}
            </Button>
          </div>
        </Navbar>
        {isSearchActive && (
          <div className="bg-white p-2">
            <Container>
              <Row>
                <Col>
                  <ProspectoBuscaFormulario
                    onSubmit={this.handleSubmit}
                    onReset={limparProspectoFiltro}
                    filter={filter}
                    empreendimentos={empreendimentos}
                    isLoading={isLoading}
                    isLoadingOptions={isLoadingOptions}
                    filterIsDirty={filterIsDirty}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Outsider>
    );
  }
}

const mapStateToProps = state => ({
  empreendimentos: state.empreendimentos,
  isLoadingOptions: state.prospecto.isLoadingOptions,
  filter: state.prospecto.filter,
  isSearchActive: state.prospecto.isSearchActive,
  isLoading: state.prospecto.isLoading,
  filterIsDirty: Object.keys(state.prospecto.filter)
    .filter(item => item !== 'tipo_filtro')
    .reduce((prev, curr) => {
      return !!state.prospecto.filter[curr] || prev;
    }, false),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      limparProspectoFiltro,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProspectoBusca);
