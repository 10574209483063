import {createGlobalStyle} from 'styled-components';

export const Container = createGlobalStyle`
  #modalNotificacao {
    .modal-content {
      background-color: transparent;
      border: 0;
    }
    button{
      right: 0;
      width: 40px;
      height: 40px;
      @media(min-width: 768px){
      width: 50px;
      height: 50px;
      }
    }
  }
`;
