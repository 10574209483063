import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import numeral from 'numeral';
import { Bullet } from '~/Styles';
import { ContainerCorretores, FiltroData, FiltrosRanking } from './styles';
import AuthStorage from '~/Utils/AuthStorage';
import { getToday, createDate } from '~/Utils/Helpers';
import Loading from '~/Components/Loading';
import {
  getRanking,
  selecionaImobiliaria,
  selecionarCampanha,
} from '~/Store/Ranking/actions';
import Podium from './Podium';
import ListaRanking from './ListaRanking';
import MaskedInput from 'react-text-mask';
import DateMask from '../DateMask';
import moment from 'moment';
import { FaSearch } from 'react-icons/fa';

const MASK_DATE = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const formatarValor = valor => numeral(valor).format('(R$ 0.00 a)');

class Corretores extends Component {
  state = {
    codigoCampanha: 0,
    dataInicio: '',
    dataFim: '',
    tipoRanking: 'VGV',
    errors: {},
    descricaoCompletaRanking: 'Valor de Venda',
    descricaoRanking: 'VGV',
    dataInicioFormatada: '',
    dataFimFormatada: '',
    dataVerificacao: false,
    showModal: false,
  };

  async componentDidMount() {
    if (!AuthStorage.temCaracteristica(501)) {
      this.props.getRanking();
      this.state.tipoRanking = 'VGV';
    }
  }

  descTipoRanking = tipoRanking => {
    const descricaoCompletaRanking =
      tipoRanking === 'VGV' ? 'Valor de Venda' : 'Quantidades de Vendas';
    const descricaoRanking = tipoRanking === 'VGV' ? 'VGV' : 'Quantidade';
    this.setState({ descricaoCompletaRanking, descricaoRanking });
  };

  handleDataInicioChange = value => {
    this.setState({ dataInicio: value });
  };

  handleDataFimChange = value => {
    this.setState({ dataFim: value });
  };

  validarFormData = () => {
    const { dataInicio, dataFim } = this.state;
    const errors = {};

    const hoje = getToday();
    const cincoAnosAtras = hoje.clone().subtract(5, 'years');
    const cincoAnosAFrente = hoje.clone().add(5, 'years');

    if (!dataInicio) {
      errors.dataInicio = 'Data inicial é requerida';
    } else {
      const dataInicioDate = createDate(dataInicio);
      if (dataInicioDate.isBefore(cincoAnosAtras)) {
        errors.dataInicio = 'Data inicial não pode ser mais de 5 anos atrás';
      } else if (dataInicioDate.isAfter(cincoAnosAFrente)) {
        errors.dataInicio = 'Data inicial não pode ser mais de 5 anos à frente';
      }
    }

    if (!dataFim) {
      errors.dataFim = 'Data final é requerida';
    } else {
      const dataFimDate = createDate(dataFim);
      if (dataFimDate.isBefore(cincoAnosAtras)) {
        errors.dataFim = 'Data final não pode ser mais de 5 anos atrás';
      } else if (dataFimDate.isAfter(cincoAnosAFrente)) {
        errors.dataFim = 'Data final não pode ser mais de 5 anos à frente';
      }
    }

    if (
      dataInicio &&
      dataFim &&
      createDate(dataFim).isBefore(createDate(dataInicio))
    ) {
      errors.dataInicio = 'Data inicial deve ser antes da data final';
      errors.dataFim = 'Data final deve ser depois da data inicial';
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleFilterClick = () => {
    if (this.validarFormData()) {
      const { codigoCampanha, tipoRanking, dataInicio, dataFim } = this.state;
      const dataInicioFormatada = createDate(dataInicio).format('YYYY-MM-DD');
      const dataFimFormatada = createDate(dataFim).format('YYYY-MM-DD');
      this.props.getRanking(
        codigoCampanha,
        dataInicioFormatada,
        dataFimFormatada,
        tipoRanking
      );
      this.setState({
        dataInicio: '',
        dataFim: '',
        errors: {},
        dataInicioFormatada,
        dataFimFormatada,
        dataVerificacao: true,
        showModal: false,
      });
    }
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  resetFilter = () => {
    this.setState({
      dataInicio: '',
      dataFim: '',
      errors: {},
    });
  };

  selecionarCampanha = (codigoCampanha, tipo) => {
    this.setState({ tipoRanking: tipo });
    this.descTipoRanking(tipo);

    // const campanhaAtual = this.props.campanha;
    // if (
    //   (codigoCampanha && !campanhaAtual) ||
    //   (campanhaAtual && codigoCampanha !== campanhaAtual.codigo)
    // ) {
    //   this.setState({ dataVerificacao: false });
    //   this.props.getRanking(codigoCampanha, null, null, tipo);
    // }
    // this.props.selecionarCampanha(codigoCampanha, tipo);

    this.setState({ dataVerificacao: false });
    this.props.getRanking(codigoCampanha, null, null, tipo);
  };

  renderCabecalhoCampanha = campanha => {
    if (!campanha) return null;
    const fim = createDate(campanha.data_fim);
    const hoje = getToday();
    let diff = null;
    if (campanha.dias_contador) diff = fim.diff(hoje, 'days');

    return (
      <>
        <h4>Ranking - {campanha.descricao}</h4>
        <small>
          {`${
            campanha.criterio === 1 ? 'Valor' : 'Quantidade'
          } de vendas efetivadas`}
        </small>
        <h2>{`${createDate(campanha.data_inicio).format(
          'DD/MM/YYYY'
        )} a ${fim.format('DD/MM/YYYY')}`}</h2>

        {diff > 0 && diff < campanha.dias_contador && (
          <p>
            Faltam <strong>{diff}</strong> dias para o fim da campanha!
          </p>
        )}

        {fim < hoje && (
          <p>
            <strong>FINALIZADA!</strong>
          </p>
        )}
      </>
    );
  };

  render() {
    const {
      rankingAtivo,
      imobiliarias,
      imobiliariasById,
      carregandoRanking,
      podium,
      corretorAtual,
      mensagem,
      campanhas,
      imobiliariaSelecionada,
      campanha,
    } = this.props;

    const {
      dataInicio,
      dataFim,
      errors,
      descricaoCompletaRanking,
      descricaoRanking,
      dataVerificacao,
      dataInicioFormatada,
      dataFimFormatada,
      showModal,
    } = this.state;

    if (AuthStorage.temCaracteristica(501)) return null;

    return (
      <ContainerCorretores>
        {carregandoRanking && <Loading label="Carregando ranking..." />}

        {imobiliarias.length > 0 && (
          <div className="container-fluid">
            <FiltrosRanking>
              <li>
                <small className="text-white">Imobiliária</small>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {imobiliariaSelecionada ? (
                      <>
                        <Bullet
                          cor={
                            imobiliariasById[imobiliariaSelecionada]
                              .cor_imobiliaria
                          }
                        />{' '}
                        {
                          imobiliariasById[imobiliariaSelecionada]
                            .nome_imobiliaria
                        }
                      </>
                    ) : (
                      'Todas'
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      type="button"
                      as="button"
                      onClick={() => this.props.selecionaImobiliaria(null)}
                    >
                      Todas
                    </Dropdown.Item>
                    {imobiliarias.map(imobiliaria => (
                      <Dropdown.Item
                        key={imobiliaria.codigo}
                        type="button"
                        as="button"
                        onClick={() =>
                          this.props.selecionaImobiliaria(imobiliaria.codigo)
                        }
                      >
                        <Bullet cor={imobiliaria.cor_imobiliaria} />
                        {imobiliaria.nome_imobiliaria}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              {campanhas.length >= 0 && (
                <li>
                  <small className="text-white">Ranking</small>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {campanha
                        ? campanhas.find(
                            camp => camp.codigo === campanha.codigo
                          ).descricao
                        : descricaoRanking}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        type="button"
                        as="button"
                        onClick={() => this.selecionarCampanha(null, 'QTD')}
                      >
                        Quantidade
                      </Dropdown.Item>
                      {campanhas.map(camp => (
                        <Dropdown.Item
                          key={camp.codigo}
                          type="button"
                          as="button"
                          onClick={() => this.selecionarCampanha(camp.codigo)}
                        >
                          {camp.descricao}
                        </Dropdown.Item>
                      ))}
                      <Dropdown.Item
                        type="button"
                        as="button"
                        onClick={() => this.selecionarCampanha(null, 'VGV')}
                      >
                        VGV
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              )}
            </FiltrosRanking>

            {/* Filtros de Data */}
            {AuthStorage.isADM() && (
              <FiltroData>
                <Button
                  variant="primary"
                  onClick={this.handleShowModal}
                >
                  <FaSearch />
                  <span>&nbsp;Filtrar por Data</span>
                </Button>
              </FiltroData>
            )}

            <Modal
              show={showModal}
              onHide={() => this.setState({ showModal: false })}
            >
              <Modal.Header>
                <Modal.Title>Filtro de Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="data_inicio">
                  <Form.Label>Data Inicial</Form.Label>
                  <DateMask
                    initialValue={dataInicio}
                    allowEmpty
                    allowFuture
                    allowPast
                    onValidate={this.handleDataInicioChange}
                  >
                    {({ dateError, dateValue, setDate }) => (
                      <>
                        <MaskedInput
                          name="data_inicio"
                          guide={false}
                          className={`form-control ${
                            dateError || errors.dataInicio ? 'is-invalid' : ''
                          }`}
                          placeholder="Ex.: 01/01/2023"
                          mask={MASK_DATE}
                          value={dateValue}
                          onChange={e => setDate(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dataInicio || dateError}
                        </Form.Control.Feedback>
                      </>
                    )}
                  </DateMask>
                </Form.Group>
                <Form.Group controlId="data_fim">
                  <Form.Label>Data Final</Form.Label>
                  <DateMask
                    initialValue={dataFim}
                    allowEmpty
                    allowFuture
                    allowPast
                    onValidate={this.handleDataFimChange}
                  >
                    {({ dateError, dateValue, setDate }) => (
                      <>
                        <MaskedInput
                          name="data_fim"
                          guide={false}
                          className={`form-control ${
                            dateError || errors.dataFim ? 'is-invalid' : ''
                          }`}
                          placeholder="Ex.: 31/12/2023"
                          mask={MASK_DATE}
                          value={dateValue}
                          onChange={e => setDate(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dataFim || dateError}
                        </Form.Control.Feedback>
                      </>
                    )}
                  </DateMask>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.setState({ showModal: false })}
                >
                  Fechar
                </Button>
                <Button variant="primary" onClick={this.handleFilterClick}>
                  Aplicar Filtro
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Renderização do Ranking */}
            {rankingAtivo.length > 0 && (
              <>
                <header className="row text-center">
                  <div className="col-12">
                    {campanha ? (
                      this.renderCabecalhoCampanha(campanha)
                    ) : (
                      <>
                        <h4>Ranking - {descricaoCompletaRanking}</h4>
                        {dataVerificacao ? (
                          <h3 style={{ color: '#fac83d' }}>
                            De{' '}
                            {moment(dataInicioFormatada).format('DD/MM/YYYY')}{' '}
                            até {moment(dataFimFormatada).format('DD/MM/YYYY')}
                          </h3>
                        ) : (
                          <h2>{getToday().format('MMMM/YYYY')}</h2>
                        )}
                      </>
                    )}
                    <Podium
                      corretores={podium}
                      tipoRanking={descricaoRanking}
                    />
                  </div>
                </header>

                <ListaRanking
                  mensagem={mensagem}
                  corretorAtual={corretorAtual}
                  ranking={rankingAtivo}
                  tipoRanking={descricaoRanking}
                />
              </>
            )}
          </div>
        )}
      </ContainerCorretores>
    );
  }
}

const selecionaPodium = ranking => {
  return ranking
    .filter(Corretor => Corretor.rank > 0 && Corretor.rank < 4)
    .slice(0, 3);
};

const selecionaCorretorAtual = ranking => {
  if (AuthStorage.isCorretor()) {
    const usuario = AuthStorage.getUser().usuario;
    const index = ranking.findIndex(item => {
      return item.codigo === usuario;
    });
    if (index >= 3) {
      return ranking[index];
    }
  }
  return null;
};

const mapStateToProps = state => {
  let rankingAtivo = state.ranking.lista; // ranking original
  if (state.ranking.imobiliariaSelecionada) {
    rankingAtivo = rankingAtivo.filter(
      corretor =>
        Number(corretor.codigo_imobiliaria) ===
        Number(state.ranking.imobiliariaSelecionada)
    );
  }

  let rankingComImobiliaria = rankingAtivo.map(item => ({
    ...item,
    rank: item.rank === 0 ? null : item.rank,
    nom: state.corretor.gerentesById[item.codigo_imobiliaria]
      ? state.corretor.gerentesById[item.codigo_imobiliaria].nome
      : '',
  }));

  const campanha = state.ranking.campanhas.find(
    camp => camp.codigo === state.ranking.campanhaSelecionada
  );
  const rankingOrdenado = _orderBy(rankingComImobiliaria, [
    'rank',
    'nom',
    'nome',
  ]).map((rankEntry, index) => ({
    ...rankEntry,
    classificado: campanha
      ? !campanha.numero_corretores || index < campanha.numero_corretores
      : false,
  }));

  const podium = selecionaPodium(rankingOrdenado);

  return {
    imobiliarias: state.corretor.listaGerentes.filter(
      imobiliaria => imobiliaria.codigo !== 1077
    ),
    imobiliariasById: state.corretor.gerentesById,
    mensagem: state.ranking.mensagem,
    recebiveis: {
      abreviado: formatarValor(state.ranking.recebiveis),
      completo: state.ranking.recebiveis
        ? 'R$' + state.ranking.recebiveis.toLocaleString('pt-BR')
        : '0,00',
    },
    recebiveisBaixados: {
      abreviado: formatarValor(state.ranking.recebiveisBaixados),
      completo: state.ranking.recebiveisBaixados
        ? 'R$' + state.ranking.recebiveisBaixados.toLocaleString('pt-BR')
        : '0,00',
    },
    recebiveisEmAberto: {
      abreviado: formatarValor(state.ranking.recebiveisEmAberto),
      completo: state.ranking.recebiveisEmAberto
        ? 'R$' + state.ranking.recebiveisEmAberto.toLocaleString('pt-BR')
        : '0,00',
    },
    campanhas: state.ranking.campanhas,
    carregandoRanking: state.ranking.carregando,
    corretorAtual: selecionaCorretorAtual(state.ranking.lista),
    podium,
    ranking: state.ranking.lista,
    rankingAtivo: rankingOrdenado.slice(podium.length, rankingOrdenado.length),
    imobiliariaSelecionada: state.ranking.imobiliariaSelecionada,
    campanha,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRanking,
      selecionaImobiliaria,
      selecionarCampanha,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Corretores);
