import React from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';

import { CHEQUE, MASK_DATE } from '~/constant';
import MoneyField from '~/Components/Form/MoneyField';
import GeradorData from '~/Components/GeradorData';

export default function Cheque({ erroValidacaoCheque }) {
  const { values, setFieldValue } = useFormikContext();

  const handleInputChange = (fieldName, event) => {
    const { value } = event.target;
    setFieldValue(fieldName, value);
    if (value.length >= event.target.maxLength) {
      // Encontra o próximo campo de entrada pelo índice de tabIndex
      const nextInputIndex = parseInt(event.target.tabIndex, 10) + 1;
      const nextInput = document.querySelector(
        `[tabIndex='${nextInputIndex}']`
      );
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  if (values.tipo_parcela !== CHEQUE) return null;

  return (
    <>
      <Row>
        <Col md="6" lg="3">
          <Form.Group>
            <Form.Label>Valor</Form.Label>
            <Field
              name={`${CHEQUE}.valor`}
              className="form-control"
              type="tel"
              placeholder="Ex.: 1.000,00"
              component={MoneyField}
              tabIndex="2"
            />
            <ErrorMessage
              name={`${CHEQUE}.valor`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="3">
          <Form.Group>
            <Form.Label>Vencimento</Form.Label>
            <Field
              name={`${CHEQUE}.vencimento_mask`}
              as={MaskedInput}
              mask={MASK_DATE}
              className="form-control"
              type="tel"
              placeholder="Ex.: 10/10/2020"
              tabIndex="2"
            />
            <ErrorMessage
              name={`${CHEQUE}.vencimento_mask`}
              className="text-danger"
              component="small"
            />
            <GeradorData
              original={values[CHEQUE].vencimento_mask}
              onUpdate={date => setFieldValue(`${CHEQUE}.vencimento`, date)}
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="3">
          <Form.Group>
            <Form.Label>Banco</Form.Label>
            <Field
              name={`${CHEQUE}.banco`}
              className="form-control"
              type="tel"
              maxLength="3"
              tabIndex="3"
              onChange={event => handleInputChange(`${CHEQUE}.banco`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.banco`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="6" lg="3">
          <Form.Group>
            <Form.Label>Agência</Form.Label>
            <Field
              name={`${CHEQUE}.agencia`}
              className="form-control"
              type="tel"
              maxLength="4"
              tabIndex="4"
              onChange={event => handleInputChange(`${CHEQUE}.agencia`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.agencia`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="2" lg="1">
          <Form.Group>
            <Form.Label>D2</Form.Label>
            <Field
              name={`${CHEQUE}.digito2`}
              className="form-control"
              type="tel"
              maxLength="1"
              tabIndex="5"
              onChange={event => handleInputChange(`${CHEQUE}.digito2`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.digito2`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="4" lg="2">
          <Form.Group>
            <Form.Label>Compensação</Form.Label>
            <Field
              name={`${CHEQUE}.camara_compensacao`}
              className="form-control"
              type="tel"
              maxLength="3"
              tabIndex="6"
              onChange={event =>
                handleInputChange(`${CHEQUE}.camara_compensacao`, event)
              }
            />
            <ErrorMessage
              name={`${CHEQUE}.camara_compensacao`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="3" lg="3">
          <Form.Group>
            <Form.Label>Número</Form.Label>
            <Field
              name={`${CHEQUE}.numero`}
              className="form-control"
              type="tel"
              maxLength="6"
              tabIndex="7"
              onChange={event => handleInputChange(`${CHEQUE}.numero`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.numero`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="3" lg="2">
          <Form.Group>
            <Form.Label>Tipificação</Form.Label>
            <Field
              name={`${CHEQUE}.tipificacao`}
              className="form-control"
              type="tel"
              maxLength="1"
              tabIndex="8"
              onChange={event =>
                handleInputChange(`${CHEQUE}.tipificacao`, event)
              }
            />
            <ErrorMessage
              name={`${CHEQUE}.tipificacao`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="2" lg="1">
          <Form.Group>
            <Form.Label>D1</Form.Label>
            <Field
              name={`${CHEQUE}.digito1`}
              className="form-control"
              type="tel"
              maxLength="1"
              tabIndex="9"
              onChange={event => handleInputChange(`${CHEQUE}.digito1`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.digito1`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="8" lg="3">
          <Form.Group>
            <Form.Label>Conta</Form.Label>
            <Field
              name={`${CHEQUE}.conta`}
              className="form-control"
              type="tel"
              maxLength="10"
              tabIndex="10"
              onChange={event => handleInputChange(`${CHEQUE}.conta`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.conta`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="2" lg="1">
          <Form.Group>
            <Form.Label>D3</Form.Label>
            <Field
              name={`${CHEQUE}.digito3`}
              className="form-control"
              type="tel"
              maxLength="1"
              tabIndex="11"
              onChange={event => handleInputChange(`${CHEQUE}.digito3`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.digito3`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="5" lg="4">
          <Form.Group>
            <Form.Label>CPF</Form.Label>
            <Field
              name={`${CHEQUE}.cgccpf`}
              className="form-control"
              type="tel"
              maxLength="14"
              tabIndex="12"
              onChange={event => handleInputChange(`${CHEQUE}.cgccpf`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.cgccpf`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
        <Col md="7">
          <Form.Group>
            <Form.Label>Emitente</Form.Label>
            <Field
              name={`${CHEQUE}.emitente`}
              className="form-control"
              type="tel"
              maxLength="30"
              tabIndex="13"
              onChange={event => handleInputChange(`${CHEQUE}.emitente`, event)}
            />
            <ErrorMessage
              name={`${CHEQUE}.emitente`}
              className="text-danger"
              component="small"
            />
          </Form.Group>
        </Col>
      </Row>
      {erroValidacaoCheque && (
        <Alert variant="danger">{erroValidacaoCheque}</Alert>
      )}
    </>
  );
}
