export const MOTIVOS_RECUSA_DOCUMENTOS = [
  {
    value: 'D1',
    label: 'Ilegível',
  },
  {
    value: 'D2',
    label: 'Valor Incompatível',
  },
  {
    value: 'D3',
    label: 'Empreendimento Incorreto',
  },
  {
    value: 'D4',
    label: 'Quadra e Lote Incorretos',
  },
  {
    value: 'D5',
    label: 'Anexo não é comprovante',
  },
  {
    value: 'F1',
    label: 'Comprovante agendado, sem crédito em conta. ',
  },
  {
    value: 'F2',
    label: 'Depósito em conta incorreta.',
  },
  {
    value: 'Sub',
    label: 'Comprovante já utilizado.',
    subGrupo: [
      {
        value: 'F3',
        label: 'Empreendimento',
        outros: 'Especifique o empreendimento...',
      },
      {
        value: 'F4',
        label: 'Quadra e Lote',
        outros: 'Especifique o lote..',
      },
      {
        value: 'F5',
        label: 'Cliente',
        outros: 'Especifique o cliente..',
      },
      {
        value: 'F6',
        label: 'Data da Baixa',
        outros: 'Especifique a data...',
      },
    ],
  },
];
