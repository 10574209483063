import styled from 'styled-components';

export const TabelaInadimplenciaContainer = styled.div`
  div.table-responsive {
    width: 95vw !important;
    @media (max-width: 576px) {
      width: 85vw !important;
    }
  }
  table > tbody > tr > td {
    vertical-align: middle;
  }
`;
