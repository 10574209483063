import { useEffect } from 'react';
import { DATE_DEFAULT_FORMAT } from '~/constant';
import { createDate } from '~/Utils/Helpers';

export default function GeradorData({ original, onUpdate }) {
  useEffect(() => {
    const date = createDate(original, DATE_DEFAULT_FORMAT);
    onUpdate(date);
  }, [original]);

  return null;
}
