import styled from 'styled-components';

import fundo from '~/Images/fundo-onda-revo.svg';

import { AvatarImage } from '~/Styles';

export const FiltrosRanking = styled.ul`
  margin: 10px 0 0;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end !important;
  text-align: center;

  @media (min-width: 992px) {
    z-index: 15;
    position: absolute;
    right: 50px;
    text-align: right;
    flex-direction: column !important;
  }
`;

export const Recebiveis = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: flex-start !important;
  justify-content: left;
  text-align: left;
  color: #fff;
  margin: 10px 0;
  line-height: 1;

  li {
    margin: 0 10px;
  }

  @media (min-width: 992px) {
    z-index: 16;
    flex-direction: column !important;
    position: absolute;

    li {
      margin: 0 0 10px;
    }
    strong {
      font-size: 1.6em;
      small {
        font-size: 12px;
      }
    }
  }
`;

export const FiltroData = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: flex-start !important;
  justify-content: left;
  text-align: left;
  color: #fff;
  margin: 10px 0;
  line-height: 1;

  @media (min-width: 992px) {
    z-index: 16;
    flex-direction: column !important;
    position: absolute;
    left: 50px;
  }
`;

export const ContainerCorretores = styled.div`
  background: url(${fundo}) no-repeat top center;
  background-size: auto 450px;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 40px;

  @media (min-width: 1366px) {
    background-size: auto 410px;
  }

  ${AvatarImage} {
    border-radius: 50%;
    border: 1px solid #eee;
  }

  .Loading {
    color: #fff;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    justify-content: center;
    max-width: 400px;
  }

  header {
    color: #fff;
    margin-top: 20px;
    margin-bottom: 10px;

    @media (min-width: 1200px) {
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 0;
      font-weight: 400;
      @media (min-width: 1200px) {
        font-size: 30px;
      }
    }
    h2 {
      color: #fac83d;
      font-weight: 700;
      @media (min-width: 1200px) {
        font-size: 34px;
      }
    }

    ul {
      margin-top: 10px;
      align-items: flex-start;
      max-width: 600px;
    }
  }
`;
