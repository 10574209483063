import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './EditorTexto.css';
import Loading from '../Loading';

// Array com os tamanhos de fonte desejados
const fontSizeArray = [
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '28px',
  '36px',
  '48px',
];

// Registrando tamanhos de fonte personalizados no Quill
const fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = fontSizeArray;
Quill.register(fontSizeStyle, true);

const EditorTexto = ({ initialHTML, onContentChange}) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    // Conteúdo inicial
    setContent(initialHTML);
  }, [initialHTML]);

  const handleContentChange = (value) => {
    setContent(value);
    const cleanedHTML = cleanHTML(value);
    onContentChange(cleanedHTML);
  };

  const cleanHTML = (html) => {
    // Cria um elemento temporário para manipulação do HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const applyInlineStyles = (element) => {
      // Remove as classes e aplica os estilos inline
      if (element.nodeType === 1) {
        fontSizeArray.forEach((size) => {
          if (element.classList.contains(`ql-size-${size}`)) {
            element.style.fontSize = size;
          }
        });

        // Aplicando alinhamentos
        if (element.classList.contains('ql-align-center')) {
          element.style.textAlign = 'center';
        } else if (element.classList.contains('ql-align-right')) {
          element.style.textAlign = 'right';
        } else if (element.classList.contains('ql-align-left')) {
          element.style.textAlign = 'left';
        } else if (element.classList.contains('ql-align-justify')) {
          element.style.textAlign = 'justify';
        }

        // Remove todas as classes
        element.removeAttribute('class');
      }

      // Recursivamente aplica estilos inline para os filhos
      Array.from(element.childNodes).forEach((child) => applyInlineStyles(child));
    };

    applyInlineStyles(tempDiv);

    return tempDiv.innerHTML;
  };

  return (
    <>
      {/* Você pode adicionar estilos aqui diretamente usando uma tag style */}
      <style>
        {fontSizeArray.map(size => `
          .ql-snow .ql-picker.ql-size .ql-picker .ql-picker-item[data-value="${size}"]::before {
            content: "${size}";
            font-size: ${size} !important;
          }
        `).join('')}
      </style>

      <div className="shadow-sm bg-white rounded">
        {content ? (
          <ReactQuill
            value={content}
            onChange={handleContentChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ size: fontSizeArray }], // Tamanhos personalizados
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: [] }], // Alinhamentos variados
                ['link', 'image'],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'align', // Incluindo alinhamento nos formatos
              'link',
              'image',
            ]}
            className="caixa-edicao"
          />
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
};

export default EditorTexto;
