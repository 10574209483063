import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import propTypes from 'prop-types';

import { GRAVAR_OCORRENCIA, ENCAMINHAR_COBRANCA } from '../helpers';

const ModalGravarOcorrencia = ({ onHide, onSubmit, type, isBusy }) => {
  const [validated, setValidated] = useState(false);
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    setDescricao('');
    setValidated(false);
  }, [type]);

  const textAreaPlaceholder =
    type === GRAVAR_OCORRENCIA
      ? 'Descreva aqui o contato realizado com o cliente'
      : 'Descreva aqui a negociação acordada com o cliente';
  const variant = type === GRAVAR_OCORRENCIA ? 'primary' : 'warning';
  const submitButtonText =
    type === GRAVAR_OCORRENCIA ? 'Gravar Ocorrência' : 'Encaminhar Cobrança';

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    const isValid = form.checkValidity();

    setValidated(true);
    if (isValid) onSubmit(descricao);
  };

  return (
    <Modal
      show={!!type}
      onHide={onHide}
      backdropClassName="z-index-1060"
      className="z-index-1060"
    >
      <Modal.Header closeButton={!isBusy}>
        {type === GRAVAR_OCORRENCIA
          ? 'Gravar Ocorrência'
          : 'Enviar para Cobrança'}
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              required
              name="descricao"
              defaultValue={descricao}
              onChange={e => setDescricao(e.target.value)}
              placeholder={textAreaPlaceholder}
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              Texto da ocorrência obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={onHide} disabled={isBusy}>
            Cancelar
          </Button>
          <Button variant={variant} type="submit" disabled={isBusy}>
            {isBusy ? 'Aguarde...' : submitButtonText}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
ModalGravarOcorrencia.propTypes = {
  type: propTypes.oneOf([GRAVAR_OCORRENCIA, ENCAMINHAR_COBRANCA]),
};
export default ModalGravarOcorrencia;
