import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button, Spinner } from 'react-bootstrap';
import { FaSave } from 'react-icons/fa';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { toast } from 'react-toastify';
import EditorTexto from '~/Components/EditorTexto/EditorTexto';
import Header from '~/Components/Header/Header';
import * as api from '~/Services/Service';

const EditorMioloContrato = () => {
  const [imobiliaria, setImobiliaria] = useState('');
  const [htmlInicial, setHtmlInicial] = useState('');
  const [conteudoEditado, setConteudoEditado] = useState('');
  const [pagina_inicial, setPaginaInicial] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pesquisando, setPesquisando] = useState(false);
  const codigoFamilia = useParams().id;

  useEffect(async () => {
    setPesquisando(true);
    try {
      const response = await api.obterMioloContrato(codigoFamilia);
      setHtmlInicial(response.contrato_clausulas || ' ');
      setPaginaInicial(response.pagina_inicial);
      setImobiliaria(response.nome_familia);
      setPesquisando(false);
    } catch (error) {
      setPesquisando(false)
      console.error(error);
      toast.error('Não foi possível localizar o contrato');
    }
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    const objResponse = {
      codigo_familia: codigoFamilia,
      pagina_inicial: pagina_inicial,
      contrato_clausulas: conteudoEditado,
    };
    try {
      await api.SalvarMioloContrato(objResponse);
      setIsLoading(false);
      toast.success('Documento salvo com sucesso!');
      //mensagem de sucesso
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast.error('Erro ao salvar o documento!');
    }
  };

  const handleChangePaginaInicial = e => {
    const valorValidado = limparPagina(e.target.value);
    setPaginaInicial(valorValidado);
  };

  const limparPagina = string => {
    // Remove espaços em branco antes e depois da string
    const trimmedString = string.trim();

    // Verifica se a string está vazia e retorna um valor padrão, se necessário
    if (trimmedString === '') return '';

    // Regex para verificar se a string é um número positivo válido
    const regex = /^(0|[1-9][0-9]*)$/;

    // Verifica se a string corresponde à regex
    if (regex.test(trimmedString)) {
      return trimmedString;
    } else {
      // Se não corresponder, retorna uma string vazia ou outro valor padrão, se necessário
      return '';
    }
  };

  return (
    <>
      <Header title="Miolo do contrato" />
      <Col>
        <Card className="mt-2">
          <Card.Body>
            <Row className="m-0 p-0 align-items-center">
              <Col mb="6">
                <span>Empreendimento: {imobiliaria}</span>
              </Col>
              <Col md="6">
                <Row className="justify-content-end">
                  <label className="align-self-end">
                    Página de início do contrato:
                  </label>
                  <Col md="2">
                    <Form.Control
                      placeholder="0"
                      value={pagina_inicial}
                      onChange={handleChangePaginaInicial}
                      aria-label="Página de início do contrato"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col>
                <EditorTexto
                  initialHTML={htmlInicial}
                  onContentChange={setConteudoEditado}
                />
              </Col>
            </Row>
            <Row className="justify-content-end mt-3 m-0 p-0">
              <Col md="auto" className="">
                <Button onClick={handleSave} className="" disabled={isLoading || pesquisando}>
                  {isLoading ? (
                    <>
                      <Spinner /> Salvando...
                    </>
                  ) : (
                    <>
                      <FaSave className="mb-1" /> Salvar
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default EditorMioloContrato;
