import styled from 'styled-components';

export const DividerWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  width: auto;
  height: 50%;
  line-height: 0;
  text-align: center;
  transform: translateX(-50%);

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    content: '';
    z-index: 9;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    width: 0;
    height: calc(100% - 1rem);
    top: 0
  }

  &:before {
    top: -100%;
  }
  &:after {
    top: auto;
    bottom: 0;
  }

/**TODO: passar parâmetro para ajustar a responsividade. Por enquanto vai ficar assim */
  @media screen and (max-width: 768px) { 
    margin-bottom: 0;
    margin-right: 2em;
    width: 100%;
    position: relative !important;
    top: auto;
    padding: 16px 0;

    &:before,
    &:after {
      width: 35% !important;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      border-left: none;
      border-right: none;
    }

    &:before {
      left: 5%;
      top: auto
    }
    &:after {
      left: auto
      right: 5%;
      bottom: auto;
    }
  }
`;
