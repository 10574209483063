import React, { Component } from 'react';
import qs from 'qs';

import * as api from '../../Services/Service';
import { format, formatarComZero } from '../../Utils/Helpers';
import * as broadcast from '../../Utils/Broadcast';

const DEPARTAMENTOS = {
  13: {
    rotulo: 'Diretoria',
    cor: '#00A19B',
    ordem: 3,
  },
  12: {
    rotulo: 'Suporte - TI',
    cor: '#1671B8',
    ordem: 2,
  },
  // 14: {
  //   rotulo: 'Cartão',
  //   cor: '#D60D53',
  //   ordem: 2,
  // },
  15: {
    rotulo: 'Financeiro',
    cor: '#94C11E',
    ordem: 5,
  },
  16: {
    rotulo: 'Jurídico',
    cor: '#F39300',
    ordem: 4,
  },
  19: {
    rotulo: 'Apoio',
    cor: '#bfbfbf',
    ordem: 1,
  },
};

export default class LancamentoSolicitacoes extends Component {
  state = {
    solicitacoes: [],
  };

  async componentDidMount() {
    const { env } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    const response = await api.consultarSolicitacoesPendentes(env);
    let solicitacoes = response
      .sort((a, b) => {
        return new Date(a.Data) - new Date(b.Data);
      })
      .reduce((obj, item) => {
        if (!obj[item.departamento]) {
          obj[item.departamento] = [];
        }
        obj[item.departamento].push(item);
        return obj;
      }, {});

    this.setState({
      solicitacoes,
    });

    broadcast.connect();
    let channel = 'painel-solicitacoes';
    if (env) {
      channel += `-${env}`;
    }
    broadcast.addListerner(channel, 'adicionar-solicitacao', solicitacao =>
      this.adicionarSolicitacao(solicitacao)
    );
    broadcast.addListerner(channel, 'remover-solicitacao', solicitacao =>
      this.removerSolicitacao(solicitacao)
    );
  }

  adicionarSolicitacao = solicitacao => {
    let { solicitacoes } = this.state;
    if (!solicitacoes[solicitacao.departamento]) {
      solicitacoes[solicitacao.departamento] = [];
    }

    solicitacoes[solicitacao.departamento].push(solicitacao);

    this.setState({
      solicitacoes,
    });
  };

  removerSolicitacao = solicitacao => {
    let { solicitacoes } = this.state;

    solicitacoes[solicitacao.departamento] = solicitacoes[
      solicitacao.departamento
    ].filter(item => item.codigo !== solicitacao.codigo);

    this.setState({
      solicitacoes,
    });
  };

  render() {
    const { solicitacoes } = this.state;

    const departamentosOrdenados = Object.keys(DEPARTAMENTOS).sort(
      (a, b) => DEPARTAMENTOS[a].ordem - DEPARTAMENTOS[b].ordem
    );

    return (
      <div className="container-fluid LancamentoSolicitacoes">
        <div className="row">
          {departamentosOrdenados.map((item, index) => {
            return (
              <div
                className="col h-100"
                key={index}
                style={{ backgroundColor: DEPARTAMENTOS[item].cor }}
              >
                <h3 style={{ color: '#fff' }}>{DEPARTAMENTOS[item].rotulo}</h3>
                {solicitacoes[item] && (
                  <ul className="solicitacao-list">
                    {solicitacoes[item].map((solicitacao, itemindex) => (
                      <li className="solicitacao" key={itemindex}>
                        <div className="solicitacao__row solicitacao__mesa_descricao">
                          <div className="solicitacao__mesa solicitacao__col">
                            <strong>
                              {formatarComZero(solicitacao.mesa.numero)}
                            </strong>
                          </div>
                          <div className="solicitacao__col solicitacao__descricao">
                            <div className="solicitacao__row">
                              <div className="solicitacao__nome">
                                {solicitacao.mesa.nome_colaborador
                                  .split(/(\s).+\s/)
                                  .join('')}
                              </div>
                            </div>
                            <div className="solicitacao__row solicitacao__data">
                              <strong>
                                {format.date(solicitacao.data_inicio, 'HH:mm')}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
