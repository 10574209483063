import { combineReducers } from 'redux';

import { BOLETO, CARTAO } from '~/constant';

import moment from 'moment';
require('moment/locale/pt-br');
moment.locale('pt-br');

const INITIAL_STATE = {
  listaFormasDePagamento: [],
};

const criaParcelamento = ({ qtdParcelas, vencimento, ...formaPgto }) => {
  let parcelamento = [];
  let novaData;

  for (let index = 0; index < qtdParcelas; index++) {
    novaData = moment(vencimento).add(index, 'M');

    parcelamento.push({
      ...formaPgto,
      vencimento: novaData,
    });
  }
  return parcelamento;
};

const listaFormasDePagamento = (
  state = INITIAL_STATE.listaFormasDePagamento,
  { type, payload }
) => {
  switch (type) {
    case '@vendas/ADICIONA_FORMA_PAGAMENTO_ENTRADA': {
      let novasFormasPgto = [];
      const { tipo_parcela, qtdParcelas } = payload;
      if ([BOLETO, CARTAO].includes(tipo_parcela) && qtdParcelas > 1) {
        novasFormasPgto = criaParcelamento(payload);
      } else {
        novasFormasPgto.push(payload);
      }

      const newState = [...novasFormasPgto, ...state];

      return newState;
    }

    case '@vendas/REMOVE_FORMA_PAGAMENTO_ENTRADA': {
      const newState = [
        ...state.slice(0, payload.index),
        ...state.slice(payload.index + 1),
      ];
      return newState;
    }

    case '@vendas/ATUALIZA_FORMA_PAGAMENTO_ENTRADA':
      return state.map((item, index) => {
        if (index !== payload.index) return item;

        return {
          ...item,
          ...payload.formaPgto,
        };
      });
    case '@vendas/REMOVE_TOTAS_PARCELAS_ENTRADA':
    case '@vendas/SELECIONA_TABELA_PRECO':
    case '@vendas/SELECIONA_ATIVO':
    case '@vendas/SALVAR_SINAL':
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  listaFormasDePagamento,
});
