import * as constants from '../constant';
import LocalStorage from './LocalStorage';

const AuthStorage = {
  is: NIVEL => {
    return constants.HIE_NIVEL[LocalStorage.get(constants.HN)] === NIVEL;
  },
  setUser: user => {
    LocalStorage.set(constants.AUTH_KEY, user.access_token);
    LocalStorage.set(constants.HN, user.hn);
    LocalStorage.set(constants.USER, user);
    LocalStorage.set(constants.ATENDENTE_LANCAMENTO, user.atendente_lancamento);
  },
  setCaracteristicas: caracteristicas => {
    LocalStorage.set(constants.CARACTERISTICAS, caracteristicas);
  },
  getLevelLogged: () => {
    return constants.HIE_NIVEL[AuthStorage.getUser().hn];
  },
  isADM: () => AuthStorage.is(constants.NIVELADM),
  isGerente: () => AuthStorage.is(constants.NIVELGER),
  isCorretor: () => AuthStorage.is(constants.NIVELCOR),
  isUsuarioTeste: () => {
    const usuario = AuthStorage.getUser();
    if (!usuario) return false;
    return [55, 1418, 901].includes(usuario.usuario);
  },
  getUser: () => {
    return LocalStorage.get(constants.USER);
  },
  getCaracteristicas: () => {
    return LocalStorage.get(constants.CARACTERISTICAS) || [];
  },
  temCaracteristica: codigo => {
    return AuthStorage.getCaracteristicas().includes(codigo);
  },
  temCaracteristicas: (permitidos, rejeitados = []) => {
    const caracteristicas = AuthStorage.getCaracteristicas();
    if (caracteristicas.some(r => rejeitados.includes(r))) return false;
    return (
      permitidos.length === 0 ||
      caracteristicas.some(r => permitidos.includes(r))
    );
  },
  temCaracteristicaGerParametros: exceto => {
    return AuthStorage.temCaracteristicas([347, 279, 295], exceto);
  },
  temCaracteristicaGerContratoDigital: exceto => {
    return AuthStorage.temCaracteristicas([513, 514, 515], exceto);
  },
  isLogged() {
    return LocalStorage.get(constants.AUTH_KEY);
  },
  removeToken: () => {
    LocalStorage.remove(constants.AUTH_KEY);
    LocalStorage.remove(constants.HN);
    LocalStorage.remove(constants.USER);
  },
  isIframe: () => {
    return AuthStorage.isLogged() && AuthStorage.getUser().isIframe;
  },
  getEnv: () => {
    const user = AuthStorage.getUser();
    return user ? user.env : null;
  },
  isAtendenteLancamento: () => {
    const user = AuthStorage.getUser();
    return user && user.atendente_lancamento;
  },
};

export default AuthStorage;
