import React from 'react';
import Calendar from '@toast-ui/react-calendar';
import moment from 'moment';

import 'tui-calendar/dist/tui-calendar.css';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

const today = new Date();
const getDate = (type, start, value, operator) => {
  start = new Date(start);
  type = type.charAt(0).toUpperCase() + type.slice(1);
  if (operator === '+') {
    start[`set${type}`](start[`get${type}`]() + value);
  } else {
    start[`set${type}`](start[`get${type}`]() - value);
  }

  return start;
};

const mock = {
  Template: {
    timegridDisplayPrimaryTime: function(time) {
      var hour = time.hour;
      if (time.hour < 10) {
        hour = `0${hour}`;
      }
      return `${hour}:00`;
    },
    popupIsAllDay: function() {
      return 'Dia todo';
    },
    popupSave: function() {
      return 'Salvar';
    },
    titlePlaceholder: function() {
      return 'Assunto';
    },
    locationPlaceholder: function() {
      return 'Local';
    },
    popupEdit: function() {
      return 'Editar';
    },
    popupDelete: function() {
      return 'Deletar';
    },
    popupDetailDate: function(isAllDay, start, end) {
      var isSameDate = moment(start).isSame(end);
      var endFormat = (isSameDate ? '' : 'DD/MM/YYYY ') + 'hh:mm a';

      if (isAllDay) {
        return (
          moment(start).format('DD/MM/YYYY') +
          (isSameDate ? '' : ' - ' + moment(end).format('DD/MM/YYYY'))
        );
      }

      return (
        moment(start).format('DD/MM/YYYY hh:mm a') +
        ' - ' +
        moment(end).format(endFormat)
      );
    },
  },
  Calendars: [
    {
      id: 162,
      name: 'HPG',
      bgColor: '#E50F0D',
      borderColor: '#E50F0D',
    },
    {
      id: 396,
      name: 'APL',
      bgColor: '#E2610C',
      borderColor: '#E2610C',
    },
    {
      id: 939,
      name: 'PRIME',
      bgColor: '#E9ED02',
      borderColor: '#E9ED02',
    },
    {
      id: 1335,
      name: 'SGI',
      bgColor: '#0060B7',
      borderColor: '#0060B7',
    },
    {
      id: 1567,
      name: 'R&A',
      bgColor: '#01AA23',
      borderColor: '#01AA23',
    },
  ],
  Schedules: [
    {
      id: 0,
      calendarId: 162,
      title: 'Primeiro',
      category: 'time',
      start: today.toISOString(),
      end: getDate('hours', today, 1, '+').toISOString(),
    },
    {
      id: 1,
      calendarId: 396,
      title: 'Segundo',
      category: 'time',
      start: today.toISOString(),
      end: getDate('hours', today, 2, '+').toISOString(),
    },
    {
      id: 2,
      calendarId: 939,
      title: 'Terceiro',
      category: 'time',
      start: today.toISOString(),
      end: getDate('hours', today, 3, '+').toISOString(),
    },
    {
      id: 3,
      calendarId: 1335,
      title: 'Quarto',
      category: 'time',
      start: today.toISOString(),
      end: getDate('hours', today, 4, '+').toISOString(),
    },
    {
      id: 4,
      calendarId: 1567,
      title: 'Quinto',
      category: 'time',
      start: today.toISOString(),
      end: getDate('hours', today, 5, '+').toISOString(),
    },
    {
      id: 5,
      calendarId: 939,
      title: 'Sexto',
      category: 'time',
      start: getDate('hours', today, -3, '+').toISOString(),
      end: getDate('hours', today, 3, '+').toISOString(),
    },
  ],
};

export default class MyComponent extends React.Component {
  calendarRef = React.createRef();

  getCalendarInstance = () => {
    return this.calendarRef.current.getInstance();
  };

  handleClickNextButton = () => {
    const calendar = this.getCalendarInstance();
    calendar.changeView('month', true);

    calendar.next();
  };

  //https://nhn.github.io/tui.calendar/latest/Calendar
  updateSchedule = () => {
    const calendar = this.getCalendarInstance();

    calendar.updateSchedule(4, 1567, {
      title: 'ALTERADO',
      start: today.toISOString(),
      end: getDate('hours', today, 4, '+').toISOString(),
      category: 'time',
    });
  };

  render() {
    return (
      <>
        <button onClick={this.handleClickNextButton}>mês seguinte</button>
        <button onClick={this.handleClickNextButton}>Dia Seguinte</button>
        <button onClick={this.handleClickNextButton}>Hoje</button>

        <Calendar
          ref={this.calendarRef}
          height="calc(100vh - 140px)"
          calendars={mock.Calendars}
          schedules={mock.Schedules}
          disableDblClick={true}
          disableClick={false}
          isReadOnly={false}
          month={{
            startDayOfWeek: 0,
          }}
          scheduleView={['time']}
          taskView={[]}
          template={mock.Template}
          timezones={[
            {
              timezoneOffset: -180,
            },
          ]}
          useDetailPopup
          useCreationPopup
          week={{
            showTimezoneCollapseButton: false,
            timezonesCollapsed: false,
            daynames: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          }}
        />
      </>
    );
  }
}
