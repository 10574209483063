import * as api from '~/Services/Service';
import * as actions from '~/actionTypes';
import * as constants from '~/constant';
import * as helpers from '~/Utils/Helpers';
import PDFThumb from '~/Utils/PDFThumb';
import history from '~/Config/history';

const ERROR_ENUM = {
  PROSPECTO_NAO_PERTENCE_A_ESTE_USUARIO:
    'Não é possível remover o empreendimento, vinculado a outro corretor',
};

export const fetchProspectos = params => dispatch => {
  dispatch({
    type: actions.FETCHING_PROSPECTS,
  });

  api
    .consultarProspectos(params)
    .then(response => {
      dispatch({
        type: actions.ALL_PROSPECTS_FETCH,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_ERROR_PROSPECTS,
        payload: error,
      });
    });
};

export const applyFilter = search => dispatch => {
  dispatch({
    type: actions.UPDATE_FILTER,
    payload: search,
  });
};

export const buscarOpcoesProspecto = () => dispatch => {
  dispatch(fetchNacionalidades());
  dispatch(fetchPDV());
  dispatch(fetchMotivosDescarte());
};

export const fetchProfissoes = () => dispatch => {
  dispatch({
    type: actions.FETCHING_OCCUPATIONS,
  });
  api
    .getProfissoes()
    .then(response => {
      dispatch({
        type: actions.FETCH_OCCUPATIONS_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_ERROR_OCCUPATIONS,
        payload: error,
      });
    });
};

export const fetchMotivosDescarte = () => dispatch => {
  dispatch({
    type: actions.FETCHING_MOTIVOS_DESCARTE,
  });
  api
    .getMotivosDescarte()
    .then(response => {
      dispatch({
        type: actions.FETCH_MOTIVOS_DESCARTE_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_ERROR_MOTIVOS_DESCARTE,
        payload: error,
      });
    });
};

export const buscaProfissoes = value => {
  return api.getProfissoes(value);
};

export const fetchNacionalidades = () => dispatch => {
  dispatch({
    type: actions.FETCHING_NATIONALITIES,
  });
  api
    .getNacionalidades()
    .then(response => {
      dispatch({
        type: actions.FETCH_NATIONALITIES_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_ERROR_NATIONALITIES,
        payload: error,
      });
    });
};

export const fetchPDV = () => dispatch => {
  dispatch({
    type: actions.FETCHING_PDV,
  });
  api
    .getPDV()
    .then(response => {
      dispatch({
        type: actions.FETCH_PDV_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_ERROR_PDV,
        payload: error,
      });
    });
};

export const fetchCidades = uf => dispatch => {
  dispatch({
    type: actions.FETCHING_CITIES,
  });
  api
    .getCidades(uf)
    .then(response => {
      dispatch({
        type: actions.FETCH_CITIES_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_ERROR_CITIES,
        payload: error,
      });
    });
};

export const carregarPesquisa = () => dispatch => {
  dispatch({
    type: actions.CARREGANDO_PESQUISA,
  });
  api
    .getPesquisa()
    .then(response => {
      dispatch({
        type: actions.CARREGAR_PESQUISA_SUCESSO,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.CARREGAR_PESQUISA_ERRO,
        payload: error,
      });
    });
};

export const salvarPesquisa = pesquisa => (dispatch, getState) => {
  dispatch({
    type: actions.SALVANDO_PESQUISA,
  });
  let state = getState();
  pesquisa = Object.keys(pesquisa).map(CodigoPergunta => {
    let itemResposta = {
      codigo_pergunta: parseInt(CodigoPergunta),
      codigo_resposta: null,
      dissertativa: null,
    };

    let resposta = pesquisa[CodigoPergunta];

    if (isNaN(resposta)) {
      itemResposta.Dissertativa = resposta;
      let questao = state.options.pesquisaByCodigo[CodigoPergunta];
      if (questao.tipo === constants.TIPOS_PESQUISA.ESCOLHA) {
        itemResposta.codigo_resposta =
          questao.respostas.find(i => i.comentavel).codigo || null;
      }
    } else {
      itemResposta.codigo_resposta = parseInt(resposta);
    }

    return itemResposta;
  });

  let prospecto = state.prospecto.detail;

  api
    .salvarPesquisa(pesquisa, prospecto.codigo, prospecto.codigo_grupo_empresa)
    .then(response => {
      dispatch({
        type: actions.SALVAR_PESQUISA_SUCESSO,
        payload: pesquisa,
      });
      setTimeout(() => {
        dispatch({
          type: actions.SALVAR_PESQUISA_SUCESSO_FECHAR,
        });
      }, 4000);
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.SALVAR_PESQUISA_ERRO,
        payload: error,
      });
    });
};

export const getAtualizarCidades = uf => dispatch => {
  dispatch(fetchCidades(uf));

  dispatch({
    type: actions.UPDATE_CLIENTE,
    payload: {
      key: 'codigo_cidade',
      value: null,
    },
  });
};

export const limparProspectoFiltro = () => ({
  type: actions.CLEAR_FILTER_PROSPECT,
});

export const fetchProspectoDetalhes = (id, familia = null, liberarConsulta = false) => dispatch => {
  dispatch(setProspecto(null));
  dispatch({
    type: actions.FETCHING_PROSPECT_DETAILS,
  });
  api
    .getProspecto(id, liberarConsulta)
    .then(response => {
      dispatch({
        type: actions.FETCH_PROSPECT_DETAILS_SUCCESS,
        payload: response,
      });
      if (familia) {
        dispatch(selecionarEmpreendimento(familia));
      }
      if (response.estado && response.codigo_cidade) {
        dispatch(getAtualizarCidades(response.estado));
      }
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_PROSPECT_DETAILS_ERROR,
        payload: error,
      });
    });
};

export const setProspecto = (key, value) => dispatch => {
  if (key === null) {
    dispatch({
      type: actions.INICIAR_NOVO_PROSPECTO,
    });
    return;
  }
  dispatch({
    type: actions.UPDATE_PROSPECT,
    payload: { [key]: value },
  });
};

export const criarProspecto = prospecto => dispatch => {
  dispatch({
    type: actions.CREATING_PROSPECT,
  });

  let request = {
    ...prospecto,
    codigo_nacionalidade: 1,
    codigo_tipo: constants.CODIGO_TIPO_PROSPECTO,
    codigo_origem_cadastro: constants.CODIGO_ORIGEM_CADASTRO_PROSPECTO,
    tipo_pessoa: parseInt(prospecto.tipo_pessoa),
    codigo_perfil_comprador: parseInt(prospecto.codigo_perfil_comprador),
    tel_celular: prospecto.tel_celular.replace(/\D/g, ''),
    tel_comercial: prospecto.tel_comercial.replace(/\D/g, ''),
    tel_residencial: prospecto.tel_residencial.replace(/\D/g, ''),
    cpf: prospecto.cpf ? prospecto.cpf.replace(/\D/g, '') : '',
    cep: prospecto.cep ? prospecto.cep.replace(/\D/g, '') : '',
    prospecto_empreendimento: [
      {
        codigo_familia: prospecto.codigo_familia,
        codigo_corretor: prospecto.codigo_corretor,
        codigo_gerente: prospecto.codigo_gerente,
        senha: 0,
      },
    ],
    codigo_perfil_exterior: parseInt(prospecto.codigo_perfil_exterior),
  };

  for (const key in request) {
    if (request[key] === '') {
      request[key] = null;
    }
  }

  return api
    .criarProspecto(request)
    .then(response => {
      onSucessoCriacaoProspecto(dispatch, request, response.codigo);
      return response;
    })
    .catch(error => {
      onErrorCriacaoProspecto(error, dispatch);
      return error;
    });
};

export const importarProspectoSuaHouse = prospecto => dispatch => {
  dispatch({
    type: actions.CREATING_PROSPECT,
  });

  const request = {
    email: prospecto.email,
    cpf: prospecto.cpf,
    tipo_pessoa: parseInt(prospecto.tipo_pessoa),
  }; // só precisa desses campos, então vou deixar bem explícito

  return api
    .importarProspectoSuaHouse(request)
    .then(response => {
      const dadosProspecto = {
        ...response.prospecto,
        ...response.prospecto.prospecto_empreendimento[0], //weird-ass mapping...
      };
      onSucessoCriacaoProspecto(
        dispatch,
        dadosProspecto,
        response.prospecto.codigo
      );
      return response;
    })
    .catch(error => {
      onErrorCriacaoProspecto(error, dispatch);
      return error;
    });
};

function onSucessoCriacaoProspecto(dispatch, dadosProspecto, codigoProspecto) {
  dispatch({
    type: actions.CREATE_PROSPECT_SUCCESS,
    payload: {
      ...dadosProspecto,
      codigo: codigoProspecto,
    },
  });

  //Fechar modal e redireciona para detalhes prospecto
  let newLocation = { search: '' };
  if (history.location.pathname.includes('lista')) {
    newLocation['pathname'] = `/prospectos/lista/${codigoProspecto}`;
  }

  history.push(newLocation);

  setTimeout(() => {
    dispatch({
      type: actions.CREATE_PROSPECT_SUCCESS_CLOSE,
    });
  }, 4000);
}

function onErrorCriacaoProspecto(error, dispatch) {
  console.error(error);
  dispatch({
    type: actions.CREATE_PROSPECT_ERROR,
    payload: error,
  });
}

export const atualizarProspecto = prospecto => (dispatch, getState) => {
  dispatch({
    type: actions.UPDATING_PROSPECT,
  });

  let detail = getState().prospecto.detail;

  let request = {
    ...detail,
    ...prospecto,
    codigo_tipo: constants.CODIGO_TIPO_PROSPECTO,
    //CodigoOrigemCadastro: constants.CODIGO_ORIGEM_CADASTRO_PROSPECTO,
    tipo_pessoa: parseInt(prospecto.tipo_pessoa),
    codigo_perfil_comprador: parseInt(prospecto.codigo_perfil_comprador),
    tel_celular: prospecto.tel_celular.replace(/\D/g, ''),
    tel_comercial: prospecto.tel_comercial.replace(/\D/g, ''),
    tel_residencial: prospecto.tel_residencial.replace(/\D/g, ''),
    cpf: prospecto.cpf.replace(/\D/g, ''),
    cep: prospecto.cep.replace(/\D/g, ''),
    prospecto_empreendimento: [
      {
        codigo_familia: detail.codigo_familia,
        codigo_corretor: detail.codigo_corretor,
        codigo_gerente: detail.codigo_gerente,
        codigo_conta: detail.codigo_conta,
        codigo_grupo_empresa: detail.codigo_grupo_empresa,
        senha: 0,
      },
    ],
    codigo_perfil_exterior: parseInt(prospecto.codigo_perfil_exterior),
  };

  //TODO remover do prospectoReducer
  delete request.documentos;

  for (const key in request) {
    if (request[key] === '') {
      request[key] = null;
    }
  }

  return api
    .atualizarProspecto(request)
    .then(response => {
      onSucessoAtualizacaoProspecto(dispatch, request);
      return response;
    })
    .catch(error => {
      console.error(error);
      var tipo;
      if (error.Message === 'CPF_INVALIDO')
        tipo = actions.UPDATE_PROSPECT_ERROR;
      else if (error.Message === 'CPF_INDISPONIVEL') {
        tipo = actions.CONSULTA_CPF_SUCESSO;
        dispatch({
          type: actions.UPDATE_PROSPECT_ERROR,
          payload: error,
        });
      } else tipo = actions.UPDATE_PROSPECT_ERROR;

      dispatch({
        type: tipo,
        payload: error,
      });

      return error;
    });
};

export const reImportarSuaHouse = codigoProspecto => dispatch => {
  dispatch({
    type: actions.UPDATING_PROSPECT,
  });

  return api
    .reImportarSuaHouse(codigoProspecto)
    .then(response => {
      onSucessoAtualizacaoProspecto(dispatch, response);
      return response;
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.UPDATE_PROSPECT_ERROR,
        payload: error,
      });
      return error;
    });
};

function onSucessoAtualizacaoProspecto(dispatch, request) {
  dispatch({
    type: actions.UPDATE_PROSPECT_SUCCESS,
    payload: request,
  });

  setTimeout(() => {
    dispatch({
      type: actions.UPDATE_PROSPECT_SUCCESS_CLOSE,
    });
  }, 4000);
}

export const dismissAvisoDescarteSuaHouse = () => {
  return {
    type: actions.DISMISS_AVISO_DESCARTE_SUA_HOUSE,
  };
};
export const uploadDocumento = (file, documento) => (dispatch, getState) => {
  dispatch({
    type: actions.UPLOADING_DOCUMENTO,
    payload: documento,
  });

  let prospecto = getState().prospecto.detail;

  let request = new FormData();

  request.append('arquivo', file);
  request.append('codigo_familia', prospecto.codigo_familia);
  request.append('codigo_prospecto', prospecto.codigo);
  request.append('codigo_conta', prospecto.codigo_conta);
  request.append('codigo_grupo_empresa', prospecto.codigo_grupo_empresa);
  request.append('tipo_pessoa', prospecto.tipo_pessoa);
  request.append('codigo', documento.codigo);
  request.append('tipo', documento.tipo);
  request.append('descricao', documento.descricao);
  request.append('ordem', documento.ordem);
  request.append('sequencia', documento.sequencia);

  api
    .uploadDocumento(request)
    .then(async response => {
      let novoDocumento = response.prospecto_empreendimento.documentos.find(
        item => {
          return (
            item.codigo === documento.codigo && item.tipo === documento.tipo
          );
        }
      );
      dispatch({
        type: actions.UPLOAD_DOCUMENTO_SUCCESS,
        payload: novoDocumento,
      });
      const Thumb = await PDFThumb.create(novoDocumento.url);
      dispatch(atualizarProspecto(prospecto));

      dispatch(atualizaDocumento({ ...novoDocumento, Thumb }));
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.UPLOAD_DOCUMENTO_ERROR,
        payload: { ...documento, error: error.message }, // oh god, is this shit?
      });
    });
};

export const atualizaDocumento = documento => ({
  type: actions.ATUALIZA_DOCUMENTO,
  payload: documento,
});

export const iniciarAdicionarSocio = () => ({
  type: actions.INICIAR_ADICIONAR_SOCIO,
});

export const cancelarAdicionarSocio = () => ({
  type: actions.CANCELAR_ADICIONAR_SOCIO,
});

export const consultaCPF = cpf => dispatch => {
  dispatch({
    type: actions.CONSULTANDO_CPF,
  });

  cpf = cpf.replace(/\D/g, '');

  api
    .consultaCPF(cpf)
    .then(response => {
      response = response.codigo ? response : null;
      dispatch({
        type: actions.CONSULTA_CPF_SUCESSO,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.CONSULTA_CPF_ERRO,
      });
    });
};

export const consultaEmail = email => dispatch => {
  dispatch({
    type: actions.CONSULTANDO_EMAIL,
  });

  api
    .consultaEmail(email)
    .then(response => {
      response = response.codigo ? response : null;
      dispatch({
        type: actions.CONSULTA_EMAIL_SUCESSO,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.CONSULTA_EMAIL_ERRO,
      });
    });
};

export const limpaProspectoEncaminhar = () => ({
  type: actions.LIMPA_PROSPECTO_ENCAMINHAR,
});

export const criaContaGE = prospecto => (dispatch, getState) => {
  dispatch({
    type: actions.UPDATING_PROSPECT,
  });

  let detail = getState().prospecto.detail;

  let request = {
    ...detail,
    ...prospecto,
    codigo_tipo: constants.CODIGO_TIPO_PROSPECTO,
    codigo_origem_cadastro: constants.CODIGO_ORIGEM_CADASTRO_PROSPECTO,
    tipo_pessoa: parseInt(prospecto.tipo_pessoa),
    tel_celular: prospecto.tel_celular
      ? prospecto.tel_celular.replace(/\D/g, '')
      : null,
    tel_comercial: prospecto.tel_comercial
      ? prospecto.tel_comercial.replace(/\D/g, '')
      : null,
    tel_residencial: prospecto.tel_residencial
      ? prospecto.tel_residencial.replace(/\D/g, '')
      : null,
    cpf: prospecto.cpf.replace(/\D/g, ''),
    cep: prospecto.cep.replace(/\D/g, ''),
    prospecto_empreendimento: [
      {
        codigo_familia: detail.codigo_familia,
        codigo_corretor: detail.codigo_corretor,
        codigo_gerente: detail.codigo_gerente,
        senha: 0,
      },
    ],
  };

  for (const key in request) {
    if (request[key] === '') {
      request[key] = null;
    }
  }

  return api
    .atualizarProspecto(request)
    .then(() => {
      return api
        .criaContaGE(request, request.codigo_familia)
        .then(response => {
          dispatch({
            type: actions.UPDATE_PROSPECT_SUCCESS,
            payload: {
              ...request,
              ...response,
            },
          });

          setTimeout(() => {
            dispatch({
              type: actions.UPDATE_PROSPECT_SUCCESS_CLOSE,
            });
          }, 4000);

          return response;
        })
        .catch(error => {
          console.error(error);
          dispatch({
            type: actions.UPDATE_PROSPECT_ERROR,
            payload: error,
          });

          return error;
        });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.UPDATE_PROSPECT_ERROR,
        payload: error,
      });

      return error;
    });
};

export const adicionarEmpreendimento = prospecto => (dispatch, getState) => {
  dispatch({
    type: actions.VINCULANDO_EMPREENDIMENTO,
  });

  let request = {
    codigo_prospecto: prospecto.codigo,
    codigo_familia: prospecto.codigo_familia,
    codigo_corretor: prospecto.codigo_corretor,
    codigo_gerente: prospecto.codigo_gerente,
  };

  return api
    .vincularEmpreendimento(request)
    .then(response => {
      dispatch({
        type: actions.EMPRENDIMENTO_VINCULADO,
        payload: {
          ...request,
          ...response,
        },
      });
      dispatch({
        type: actions.UPDATE_PROSPECT_SUCCESS,
        payload: response,
      });

      if (getState().prospecto.detail.codigo) {
        dispatch(selecionarEmpreendimento(request.codigo_familia));
      }

      //Fechar modal Vincular Empreendimento
      history.push({ search: '' });
      setTimeout(() => {
        dispatch({
          type: actions.UPDATE_PROSPECT_SUCCESS_CLOSE,
        });
      }, 4000);
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.EMPREENDIMENTO_VINCULADO_ERROR,
        payload: error,
      });
    });
};

export const buscarEndereco = CEP => dispatch => {
  dispatch({
    type: actions.BUSCANDO_CEP,
  });

  return api
    .buscarEnderecoPorCEP(CEP)
    .then(response => {
      dispatch({
        type: actions.BUSCA_CEP_SUCESSO,
        payload: response,
      });
      return response;
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.BUSCA_CEP_ERRO,
      });
      return error;
    });
};

// const redirecionaParaTabInicial = codigo => {
//   let prospecto = codigo;
//   let pathToPush = `/prospectos/${prospecto}/dados-cliente`;
//   history.push(pathToPush);
// };

export const selecionarEmpreendimento = codigo => (dispatch, getState) => {
  //redirecionaParaTabInicial(getState().prospecto.detail.codigo);
  dispatch({
    type: actions.SELECIONA_EMPREENDIMENTO,
    payload: codigo,
  });
};

export const selecionarSocio = codigo => ({
  type: actions.SELECIONA_SOCIO,
  payload: codigo,
});

const atualizarSocio = socio => dispatch => {
  return api
    .atualizarSocio(socio)
    .then(response => {
      dispatch({
        type: actions.ATUALIZAR_SOCIO_SUCESSO,
        payload: socio,
      });
      history.push({ search: '' });
      setTimeout(() => {
        dispatch({
          type: actions.ATUALIZAR_SOCIO_SUCESSO_FECHAR,
        });
      }, 4000);

      return response;
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.ATUALIZAR_SOCIO_ERRO,
        payload: error,
      });

      return error;
    });
};

export const salvarSocio = socio => (dispatch, getState) => {
  dispatch({
    type: actions.SALVANDO_SOCIO,
  });

  let request = {
    ...socio,
    codigo_tipo: constants.CODIGO_TIPO_PROSPECTO,
    codigo_origem_cadastro: constants.CODIGO_ORIGEM_CADASTRO_PROSPECTO,
    tipo_pessoa: parseInt(socio.tipo_pessoa),
    tel_celular: socio.tel_celular ? socio.tel_celular.replace(/\D/g, '') : '',
    tel_comercial: socio.tel_comercial
      ? socio.tel_comercial.replace(/\D/g, '')
      : '',
    tel_residencial: socio.tel_residencial
      ? socio.tel_residencial.replace(/\D/g, '')
      : '',
    cpf: socio.cpf ? socio.cpf.replace(/\D/g, '') : '',
    cep: socio.cep ? socio.cep.replace(/\D/g, '') : '',
  };

  if (getState().prospecto.detail.pre_venda) {
    request.PreVenda = getState().prospecto.detail.pre_venda;
  }

  if (!request.codigo_prospecto) {
    delete request.codigo_prospecto;
  }

  for (const key in request) {
    if (request[key] === '') {
      request[key] = null;
    }
  }

  if (socio.codigo) {
    return dispatch(atualizarSocio(request));
  }

  return api
    .salvarSocio(request)
    .then(response => {
      dispatch({
        type: actions.SALVAR_SOCIO_SUCESSO,
        payload: {
          ...socio,
          codigo: response.codigo,
          sequencia: response.sequencia,
        },
      });
      //Fechar modal salvar socio
      history.push({ search: '' });

      setTimeout(() => {
        dispatch({
          type: actions.SALVAR_SOCIO_SUCESSO_FECHAR,
        });
      }, 4000);

      return response;
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.SALVAR_SOCIO_ERRO,
        payload: error,
      });

      return error;
    });
};

export const toggleModalOcorrencia = value => ({
  type: actions.TOGGLE_MODAL_OCORRENCIA,
  payload: value,
});

export const iniciarEditarOcorrencia = ocorrencia => dispatch => {
  dispatch(toggleModalOcorrencia(true));

  dispatch({
    type: actions.EDITAR_OCORRENCIA,
    payload: ocorrencia,
  });
};

export const salvarOcorrencia = ocorrencia => (dispatch, getState) => {
  dispatch({
    type: actions.SALVANDO_OCORRENCIA,
  });

  for (const key in ocorrencia) {
    if (ocorrencia[key] === '') {
      ocorrencia[key] = null;
    }
  }

  let request = {
    codigo_prospecto: getState().prospecto.detail.codigo,
    ...ocorrencia,
  };

  return api
    .salvarOcorrencia(request)
    .then(response => {
      //Estava editando uma ocorrencia
      if (request.codigo) {
        dispatch({
          type: actions.EDITAR_OCORRENCIA_SUCESSO,
          payload: {
            ...request,
            data: helpers.createDate(new Date()),
            codigo: response.ocorrencia.codigo,
            ocorrencia: response.ocorrencia,
          },
        });

        setTimeout(() => {
          dispatch({
            type: actions.EDITAR_OCORRENCIA_SUCESSO_FECHAR,
          });
        }, 4000);
      } else {
        dispatch({
          type: actions.SALVAR_OCORRENCIA_SUCESSO,
          payload: {
            ocorrencia: {
              ...response.ocorrencia,
              data: helpers.createDate(new Date()),
            },
          },
        });
        setTimeout(() => {
          dispatch({
            type: actions.SALVAR_OCORRENCIA_SUCESSO_FECHAR,
          });
        }, 4000);
      }

      return response;
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.SALVAR_OCORRENCIA_ERRO,
        payload: error,
      });

      return error;
    });
};

export const atualizarStatusProspecto = (codigo_status, codigo_familia) => (
  dispatch,
  getState
) => {
  dispatch({ type: actions.ATUALIZANDO_STATUS_PROSPECTO });

  let codigo_prospecto = getState().prospecto.detail.codigo;

  api
    .atualizarStatusProspecto({
      codigo_prospecto,
      codigo_familia,
      codigo_status,
    })
    .then(response => {
      dispatch({
        type: actions.ATUALIZAR_STATUS_PROSPECTO_SUCESSO,
        payload: response,
      });
      setTimeout(() => {
        dispatch({ type: actions.ATUALIZAR_STATUS_PROSPECTO_SUCESSO_FECHAR });
      }, 4000);
    })
    .catch(error => {
      console.warn(error);
      dispatch({
        type: actions.ATUALIZAR_STATUS_PROSPECTO_ERRO,
        payload: 'Erro ao atualizar o status do prospecto',
      });
    });
};

export const removerEmpreendimento = (
  codigo_familia,
  codigo_motivo_descarte,
  justificativa
) => (dispatch, getState) => {
  dispatch({ type: actions.REMOVENDO_PROSPECTO_EMPREENDIMENTO });
  let codigo_prospecto = getState().prospecto.detail.codigo;

  api
    .removerEmpreendimento({
      codigo_prospecto,
      codigo_familia,
      codigo_motivo_descarte,
      justificativa,
    })
    .then(response => {
      dispatch({
        type: actions.REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO,
        payload: response,
      });
      setTimeout(() => {
        dispatch({
          type: actions.REMOVER_PROSPECTO_EMPREENDIMENTO_SUCESSO_FECHAR,
        });
      }, 4000);
    })
    .catch(error => {
      console.warn(error);
      dispatch({
        type: actions.REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO,
        payload:
          error.Message && ERROR_ENUM[error.Message]
            ? ERROR_ENUM[error.Message]
            : 'Erro ao descartar o empreendimento',
      });
    });
};

export const limpaErrosEditarEmpreendimento = () => ({
  type: actions.REMOVER_PROSPECTO_EMPREENDIMENTO_ERRO,
  payload: null,
});
