import React, { useState, useEffect } from 'react';
import { default as Selector } from 'react-select';
import { Card, Row, Col } from 'react-bootstrap';
import _isEmpty from 'lodash/isEmpty';
import {
  IoIosAddCircleOutline,
  IoIosRemoveCircleOutline,
} from 'react-icons/io';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  FaRegEdit,
  FaRegWindowClose,
  FaPlus,
  FaTrash,
  FaCheckCircle,
  FaRegEye,
} from 'react-icons/fa';

import { Container } from './styles';
import ModalSinal from '../ModalSinal';
import ModalFormaPgto from '../ModalFormaPgto';
import ModalDivisaoComissao from '../ModalDivisaoComissao';
import SendToClipboardButton from '~/Components/SendToClipboardButton';

import { getToday } from '~/Utils/Helpers';
import { format, formatarMonetario } from '~/Utils/Helpers.js';
import dialog from '~/Utils/dialog';
import {
  FORMAS_PAGAMENTO_BY_CODIGO,
  BOLETO,
  CARTAO,
  DEPOSITO,
  DINHEIRO,
  CHEQUE,
  DIAS_TOLERANCIA_VENCIMENTO_CHEQUE_DEPOSITO,
  DATE_FORMAT_SERVER,
} from '~/constant';
import ConfirmButton from '~/Components/ConfirmButton';
import Hint from '~/Components/Hint/Hint';
import {
  calcularTotalEntradaAVista,
  calcularDataVencimentoMinimaBoleto,
} from '~/Pages/Vendas/helpers';
import ModalDataVencimento from '../ModalDataVencimento';
import * as api from '~/Services/Service';
import { selecionaConstrutor } from '~/Store/Vendas/actions';
import { useDispatch } from 'react-redux';

export default function FormularioSinal({
  empreendimento,
  selecionaTabela,
  tabelaSelecionada,
  tabelasPorCodigo,
  selecionaAtivo,
  ativosSelecionados,
  ativosDaTabelaSelecionadaPorCodigo,
  salvarSinal,
  salvarDivisaoComissao,
  listaFormasDePagamento,
  totalEntrada,
  totalFaltanteEntrada,
  totalTitulosSinal,
  adicionaFormaPgtoEntrada,
  removeTodasParcelasEntrada,
  removeFormaPgtoEntrada,
  atualizaFormaPgtoEntrada,
  readonly,
  corretores,
  divisaoComissao,
  cartoes,
  dataVenda,
  setDataVenda,
}) {
  const dataVencimentoMinima = calcularDataVencimentoMinimaBoleto(
    empreendimento
  );
  const dispatch = useDispatch();

  const FORMA_PGTO_INICIAL = {
    tipo_parcela: '',
    [BOLETO]: {
      qtdParcelas: '1',
      valor: '',
      vencimento: '',
      vencimento_mask: dataVencimentoMinima
        ? format.date(dataVencimentoMinima)
        : '',
    },
    [DINHEIRO]: {
      valor: '',
      vencimento: '',
      vencimento_mask: format.date(getToday()),
    },
    [DEPOSITO]: {
      valor: '',
      vencimento: '',
      vencimento_mask: format.date(getToday()),
    },
    [CARTAO]: {
      cartao: '',
      qtdParcelas: '1',
      valor: '',
      vencimento: '',
      vencimento_mask: format.date(getToday()),
      numero_documento: '',
    },
    [CHEQUE]: {
      valor: '',
      vencimento: '',
      vencimento_mask: '',
      banco: '',
      agencia: '',
      digito2: '',
      camara_compensacao: '',
      numero: '',
      tipificacao: '',
      digito1: '',
      conta: '',
      digito3: '',
      cgccpf: '',
      emitente: '',
    },
  };

  const [ativoEmEdicao, setAtivoEmEdicao] = useState(null);
  const [divisaoComissaoEmEdicao, setDivisaoComissaoEmEdicao] = useState(null);
  const [adicionandoFormaPgto, setAdicionandoFormaPgto] = useState(false);
  const [alterarDataVenda, setAlterarDataVenda] = useState(false);
  const [formaPagamentoEmEdicao, setFormaPagamentoEmEdicao] = useState(
    FORMA_PGTO_INICIAL
  );
  const [construtores, setConstrutores] = useState([]);
  const [construtorSelecionado, setConstrutorSelecionado] = useState(null);

  useEffect(() => {
    const buscarConstrutores = async () => {
      try {
        const response = await api.obterConstrutor();
        setConstrutores(
          response.map(c => ({ value: c.codigo, label: c.nome }))
        );
      } catch (error) {
        console.error('Erro ao buscar construtores:', error);
      }
    };

    buscarConstrutores();
  }, []);

  useEffect(() => {
    setDataVenda(getToday().format(DATE_FORMAT_SERVER));
  }, []);

  useEffect(() => {
    //reseta os formulário quando a lista é atualizada
    setAdicionandoFormaPgto(false);
    setFormaPagamentoEmEdicao(FORMA_PGTO_INICIAL);
  }, [listaFormasDePagamento]);

  useEffect(() => {
    if (!adicionandoFormaPgto) setFormaPagamentoEmEdicao(FORMA_PGTO_INICIAL);
  }, [adicionandoFormaPgto]);

  const selecionarConstrutor = construtor => {
    if (construtor && construtor.value) {
      setConstrutorSelecionado(construtor);
      dispatch(selecionaConstrutor(construtor.value));
    }
  };

  const inserirOuAtualizarFormaPagamento = ({
    tipo_parcela,
    index,
    ...values
  }) => {
    if (values[tipo_parcela]) {
      if (index > -1) {
        atualizaFormaPgtoEntrada(
          { ...values[tipo_parcela], tipo_parcela },
          index
        );
      } else {
        adicionaFormaPgtoEntrada({
          ...values[tipo_parcela],
          tipo_parcela,
        });
      }
    }
  };

  const editarFormaPagamento = (formaPagamento, index) => {
    setFormaPagamentoEmEdicao({
      ...FORMA_PGTO_INICIAL,
      tipo_parcela: formaPagamento.tipo_parcela,
      [formaPagamento.tipo_parcela]: {
        qtdParcelas: 1,
        ...formaPagamento,
        vencimento_mask: formaPagamento.vencimento
          ? format.date(formaPagamento.vencimento)
          : '',
      },
      index,
    });
    setAdicionandoFormaPgto(true);
  };

  const totalEntradaDCC = calcularTotalEntradaAVista(listaFormasDePagamento);

  const ativosComChequeBonus = Object.values(
    ativosDaTabelaSelecionadaPorCodigo
  ).filter(
    ativo =>
      ativo.desconto &&
      (!!ativo.desconto.aplicou_cheque_bonus_sinal ||
        !!ativo.desconto.aplicou_cheque_bonus_valor_venda)
  );

  const temCheque = listaFormasDePagamento.find(p => p.tipo_parcela === CHEQUE);

  return (
    <Container className="mb-2">
      <Card className="border-0">
        <Card.Body className="p-2">
          <div className="d-flex justify-content-between">
            <span>Tabela de preços</span>
            {dataVenda && (
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  setAlterarDataVenda(true);
                }}
              >
                Data venda:
                <strong> {format.date(dataVenda)}</strong>
              </button>
            )}
          </div>
          <small className="d-block mt-1">Selecione uma tabela</small>
          <Selector
            noOptionsMessage={() => 'Nenhuma tabela encontrada'}
            placeholder="Selecione uma tabela..."
            options={empreendimento.tabelas.map(({ codigo, descricao }) => ({
              value: codigo,
              label: descricao,
            }))}
            isDisabled={readonly}
            onChange={async option => {
              if (ativosSelecionados.length > 0) {
                const result = await dialog.confirm({
                  title: 'Trocar a tabela de preço?',
                  message:
                    'Esta ação removerá todos os ativos selecionados, bem como as parcelas de sinal e financiamentos informadas. Deseja continuar?',
                });
                if (!result.proceed) return;
              }
              selecionaTabela(option.value);
            }}
            value={
              tabelaSelecionada
                ? {
                    value: tabelasPorCodigo[tabelaSelecionada.codigo].codigo,
                    label: tabelasPorCodigo[tabelaSelecionada.codigo].descricao,
                  }
                : ''
            }
          />
        </Card.Body>
      </Card>

      <Card className="border-0">
        <Card.Body className="p-2">
          <div className="d-flex justify-content-between">
            <span>Indicar construtor</span>
          </div>
          <small className="d-block mt-1">Selecione um construtor</small>
          <Selector
            noOptionsMessage={() => 'Nenhum construtor encontrado'}
            placeholder="Selecione um construtor..."
            options={construtores}
            isDisabled={readonly}
            onChange={async option => {
              if (ativosSelecionados.length > 0) {
                const result = await dialog.confirm({
                  title: 'Trocar o construtor?',
                  message:
                    'Esta ação removerá todos os ativos selecionados. Deseja continuar?',
                });
                if (!result.proceed) return;
              }
              selecionarConstrutor(option);
            }}
            value={construtorSelecionado}
          />
        </Card.Body>
      </Card>

      <Card className="mt-2 border-0">
        <Card.Body className="p-2 ">
          <div className="d-block">
            <span>
              Selecione o{empreendimento.ativos.lenght > 1 ? 's' : ''} lote
              {empreendimento.ativos.lenght > 1 ? 's' : ''}
            </span>
            {empreendimento.cheque_bonus &&
              empreendimento.cheque_bonus.valor && (
                <small className="text-success float-right">
                  Cheque Bônus de{' '}
                  {formatarMonetario(empreendimento.cheque_bonus.valor)}
                </small>
              )}
          </div>

          {!tabelaSelecionada && (
            <span className="d-block text-danger">
              Selecione uma tabela para continuar
            </span>
          )}
          {tabelaSelecionada && _isEmpty(ativosSelecionados) && (
            <span className="d-block text-warning">
              Selecione um ativo para continuar
            </span>
          )}
          {empreendimento.ativos.map((item, index) => (
            <Card
              className={`${
                ativosSelecionados.includes(item.codigo)
                  ? 'border border-primary bg-white'
                  : 'bg-light text-muted'
              } my-2 `}
              key={index}
            >
              <Card.Body className="p-2 d-flex flex-column flex-sm-row align-items-center position-relative">
                <div className="d-flex justify-content-center">
                  {tabelaSelecionada && !readonly && (
                    <button
                      className="btn btn-link mr-1"
                      type="button"
                      onClick={async () => {
                        if (!tabelaSelecionada) return;
                        if (listaFormasDePagamento.length > 0) {
                          const result = await dialog.confirm({
                            title: 'Confirmar ação',
                            message:
                              'Todas as parcelas de entrada e financiamento serão excluídas, deseja continuar?',
                          });
                          if (!result.proceed) return;
                        }
                        selecionaAtivo(item.codigo);

                        // if (!ativosSelecionados.includes(item.codigo))
                        //   setAtivoEmEdicao({
                        //     ...item,
                        //     ...ativosDaTabelaSelecionadaPorCodigo[item.codigo],
                        //   });
                      }}
                    >
                      {ativosSelecionados.includes(item.codigo) ? (
                        <IoIosRemoveCircleOutline
                          className="text-danger"
                          fontSize={24}
                        />
                      ) : (
                        <IoIosAddCircleOutline
                          className="text-success"
                          fontSize={24}
                        />
                      )}
                    </button>
                  )}
                  <div>
                    <Card.Text className="m-0">
                      <span>
                        Quadra
                        <strong> {item.quadra}</strong> / Lote
                        <strong> {item.lote}</strong> / Área
                        <strong> {item.area} m²</strong>
                      </span>
                    </Card.Text>
                    {tabelaSelecionada && (
                      <div>
                        <p className="m-0">
                          Valor da venda{' '}
                          <strong>
                            {formatarMonetario(
                              ativosDaTabelaSelecionadaPorCodigo[item.codigo]
                                .valor_venda
                            )}
                          </strong>
                        </p>
                        <p className="m-0">
                          Valor do sinal{' '}
                          <strong>
                            {formatarMonetario(
                              ativosDaTabelaSelecionadaPorCodigo[item.codigo]
                                .valor_sinal
                            )}
                          </strong>
                        </p>

                        {item.codigo_solicitacao_desconto && (
                          <span>
                            Solicitação de desconto aprovado:
                            <strong>
                              # {item.codigo_solicitacao_desconto}
                            </strong>
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {ativosSelecionados.includes(item.codigo) && (
                  <div className="ml-auto d-flex flex-row flex-sm-column align-items-end">
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={() => {
                        setAtivoEmEdicao({
                          ...item,
                          ...ativosDaTabelaSelecionadaPorCodigo[item.codigo],
                        });
                      }}
                    >
                      {readonly ? (
                        <>
                          <FaRegEye className="text-info" /> Ver Valores
                        </>
                      ) : (
                        <>
                          <FaRegEdit className="text-info" /> Editar Valores
                        </>
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline"
                      onClick={() => {
                        setDivisaoComissaoEmEdicao({
                          codigo: item.codigo,
                          quadra: item.quadra,
                          lote: item.lote,
                          ...divisaoComissao[item.codigo],
                        });
                      }}
                    >
                      {readonly ? (
                        <>
                          <FaRegEye className="text-info" /> Ver Divisão
                          Comissão
                        </>
                      ) : (
                        <>
                          <FaRegEdit className="text-info" /> Dividir Comissão
                        </>
                      )}
                    </button>
                  </div>
                )}
                <small className="position-absolute codigo_ativo">
                  #{item.codigo}
                </small>
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
      </Card>

      {!_isEmpty(ativosSelecionados) && (
        <Card className="mt-2 border-0">
          <Card.Body className="p-2">
            <span>Entrada/Sinal</span>
            <Row className="mb-2 align-items-end mt-2">
              <Col md="8">
                <div className="d-flex flex-column">
                  <div
                    className={
                      totalFaltanteEntrada > 0 ? 'text-warning' : 'text-success'
                    }
                  >
                    <small className="d-block">Entrada informada</small>
                    <p className="m-0 d-flex flex-column flex-sm-row align-items-sm-center">
                      {`${formatarMonetario(
                        totalTitulosSinal,
                        false
                      )}/${formatarMonetario(totalEntrada)}`}
                      {totalFaltanteEntrada > 0 ? (
                        <span className="ml-2 align-items-center d-flex">
                          (faltam
                          <SendToClipboardButton
                            className="text-warning p-0"
                            valueToSend={formatarMonetario(
                              totalFaltanteEntrada
                            )}
                          >
                            {formatarMonetario(totalFaltanteEntrada)}
                          </SendToClipboardButton>
                          )
                        </span>
                      ) : (
                        <FaCheckCircle className="ml-1" />
                      )}
                    </p>
                  </div>
                  {empreendimento.sinal_minimo > 0 && (
                    <div
                      className={
                        totalEntradaDCC < empreendimento.sinal_minimo
                          ? 'text-warning'
                          : 'text-success'
                      }
                    >
                      <small className="d-block">
                        Entrada mínima
                        <Hint
                          title="Entrada mínima"
                          content={`Entrada mínima obrigatória em dinheiro ou cartão, ou de depósito e cheque com vencimento em até ${DIAS_TOLERANCIA_VENCIMENTO_CHEQUE_DEPOSITO} dias a partir da data da realização da proposta`}
                          size={12}
                        />
                      </small>
                      <p className="m-0 d-flex align-items-center">
                        {formatarMonetario(empreendimento.sinal_minimo)}
                        {totalEntradaDCC >= empreendimento.sinal_minimo && (
                          <FaCheckCircle className="ml-1" />
                        )}
                      </p>
                    </div>
                  )}
                  {ativosSelecionados.length > 1 && (
                    <div
                      className={temCheque ? 'text-success' : 'text-warning'}
                    >
                      <small className="d-block">
                        Cheque
                        <Hint
                          title="Cheque"
                          content="Vendas com mais de um lote exigem pelo menos uma parcela de entrada do tipo cheque."
                          size={12}
                        />
                        {temCheque && <FaCheckCircle className="ml-1" />}
                      </small>
                    </div>
                  )}
                </div>
              </Col>

              {!readonly && (
                <Col className="ml-lg-auto d-flex justify-content-end">
                  {totalTitulosSinal > 0 && (
                    <ConfirmButton
                      variant="outline-danger"
                      confirmTitle="Remover tudo?"
                      confirmText="Deseja remover todas as parcelas de sinal?"
                      onConfirm={() => removeTodasParcelasEntrada()}
                      className="btn-sm d-flex align-items-center text-uppercase mr-2"
                    >
                      <FaTrash className="mr-2" />
                      Limpar
                    </ConfirmButton>
                  )}

                  <button
                    type="button"
                    className="btn btn-sm btn-primary d-flex align-items-center text-uppercase"
                    onClick={() => setAdicionandoFormaPgto(true)}
                  >
                    <FaPlus className="mr-2" /> Inserir
                  </button>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <PerfectScrollbar style={{ maxHeight: '200px' }}>
                  <Table className="table table-striped table-sm table-bordeless">
                    <Thead>
                      <Tr>
                        <Th>Tipo</Th>
                        <Th>Vencimento</Th>
                        <Th colSpan={2}>Valor</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {listaFormasDePagamento
                        .sort((a, b) => a.vencimento - b.vencimento)
                        .map((item, index) => (
                          <Tr key={index}>
                            <Td>
                              {
                                FORMAS_PAGAMENTO_BY_CODIGO[item.tipo_parcela]
                                  .label
                              }
                            </Td>
                            <Td>{format.date(item.vencimento)}</Td>
                            <Td>{formatarMonetario(item.valor)}</Td>
                            {!readonly && (
                              <Td className="no-pivot">
                                <button
                                  type="button"
                                  className="mr-2 border-0 bg-transparent text-info d-inline-flex outline-none"
                                  title="Editar"
                                  onClick={() =>
                                    editarFormaPagamento(item, index)
                                  }
                                >
                                  <FaRegEdit className="mr-2" /> Editar
                                </button>
                                <button
                                  type="button"
                                  className="border-0 bg-transparent text-danger d-inline-flex"
                                  title="Remover"
                                  onClick={() =>
                                    removeFormaPgtoEntrada(item, index)
                                  }
                                >
                                  <FaRegWindowClose className="mr-2" /> Remover
                                </button>
                              </Td>
                            )}
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>
                </PerfectScrollbar>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      <ModalFormaPgto
        show={adicionandoFormaPgto}
        onCancel={() => setAdicionandoFormaPgto(false)}
        onSave={inserirOuAtualizarFormaPagamento}
        initialValues={formaPagamentoEmEdicao}
        cartoes={cartoes}
      />

      <ModalDataVencimento
        show={alterarDataVenda}
        onCancel={() => setAlterarDataVenda(false)}
        setDataVenda={setDataVenda}
        readonly={readonly}
        dataVenda={dataVenda}
        removeTodasParcelasEntrada={removeTodasParcelasEntrada}
        temParcelasSinal={listaFormasDePagamento.length > 0}
      />

      <ModalSinal
        onCancel={() => setAtivoEmEdicao(null)}
        ativoEmEdicao={ativoEmEdicao}
        salvarSinal={salvarSinal}
        chequeBonus={empreendimento.cheque_bonus}
        ativosComChequeBonus={ativosComChequeBonus}
        readonly={readonly}
        temParcelasSinal={listaFormasDePagamento.length > 0}
      />

      <ModalDivisaoComissao
        onCancel={() => setDivisaoComissaoEmEdicao(null)}
        divisao={divisaoComissaoEmEdicao}
        salvarDivisaoComissao={salvarDivisaoComissao}
        corretores={corretores}
        readonly={readonly}
      />
    </Container>
  );
}
