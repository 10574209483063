import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import numeral from 'numeral';
import defaultLogo from '../../Images/logo.svg';
import { selecionaImobiliaria } from '~/Store/Produtividade/actions';

class CardResumoImobiliaria extends Component {
  formataVlResumo = valor => numeral(valor).format('(R$ 0.00 a)');

  render() {
    const {
      imobiliarias,
      ranking_corretores,
      ranking_empreendimentos,
      imobiliariaSelecionada,
    } = this.props;

    if (imobiliariaSelecionada) return null;

    return (
      <>
        <div className="mb-4">
          {imobiliarias.map((imobiliaria, index) => (
            <div
              className="ImobiliariaItem"
              key={imobiliaria.codigo}
              id={`card_reumo_imobiliaria_${index}`}
            >
              <Col>
                <Card className="border-0 shadow-sm">
                  <div className="flag-ranking">
                    <span>{imobiliaria.rank}°</span>
                  </div>
                  <div className="d-flex flex-column flex-md-row produtividade-imobiliaria">
                    <Col
                      xs={12}
                      md={6}
                      className="d-flex flex-column flex-md-row align-items-center justify-space-between"
                    >
                      <button
                        onClick={() =>
                          this.props.selecionaImobiliaria(imobiliaria)
                        }
                        className="btnTransparente"
                      >
                        <img
                          src={
                            imobiliaria.url_imagem
                              ? imobiliaria.url_imagem
                              : defaultLogo
                          }
                          className="img-imobiliaria"
                          title={imobiliaria.nome}
                          alt={imobiliaria.nome}
                        />
                      </button>

                      <ul className="list-unstyled d-flex flex-wrap ImobiliariaItemResumo produtividade text-center text-md-left ml-2">
                        <li>
                          <small className="d-block">VGV</small>
                          <strong
                            className="d-block"
                            title={`R$ ${
                              imobiliaria.vendas
                                ? imobiliaria.vendas.toLocaleString('pt-BR')
                                : '0,00'
                            }`}
                          >
                            <small>R$</small>
                            {this.formataVlResumo(imobiliaria.vendas)}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Recebíveis</small>
                          <strong
                            className="d-block"
                            title={`R$ ${
                              imobiliaria.recebiveis
                                ? imobiliaria.recebiveis.toLocaleString('pt-BR')
                                : '0,00'
                            }`}
                          >
                            <small>R$</small>
                            {this.formataVlResumo(imobiliaria.recebiveis)}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Comissão</small>
                          <strong
                            className="d-block"
                            title={`R$ ${
                              imobiliaria.comissao_projetada
                                ? imobiliaria.comissao_projetada.toLocaleString(
                                    'pt-BR'
                                  )
                                : '0,00'
                            }`}
                          >
                            <small>R$</small>
                            {this.formataVlResumo(
                              imobiliaria.comissao_projetada
                            )}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Qtd. Vendas</small>
                          <strong
                            className="d-block"
                            title="E = Vendas Efetivadas / P = Proposta Rápida"
                          >
                            {imobiliaria.quantidade_vendas}
                            (E:{imobiliaria.quantidade_efetivadas}+P:
                            {imobiliaria.quantidade_propostas_rapidas})
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Em aberto</small>
                          <strong
                            className="d-block"
                            title={`R$ ${
                              imobiliaria.recebiveis_em_aberto
                                ? imobiliaria.recebiveis_em_aberto.toLocaleString(
                                    'pt-BR'
                                  )
                                : '0,00'
                            }`}
                          >
                            <small>R$</small>
                            {this.formataVlResumo(
                              imobiliaria.recebiveis_em_aberto
                            )}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">&nbsp;</small>
                        </li>
                        <li>
                          <small className="d-block">VGV Rescisões</small>
                          <strong
                            className="d-block"
                            title={`R$ ${
                              imobiliaria.rescisoes
                                ? imobiliaria.rescisoes.toLocaleString('pt-BR')
                                : '0,00'
                            }`}
                          >
                            <small>R$</small>
                            {this.formataVlResumo(imobiliaria.rescisoes)}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Já baixado</small>
                          <strong
                            className="d-block"
                            title={`R$ ${
                              imobiliaria.recebiveis_baixados
                                ? imobiliaria.recebiveis_baixados.toLocaleString(
                                    'pt-BR'
                                  )
                                : '0,00'
                            }`}
                          >
                            <small>R$</small>
                            {this.formataVlResumo(
                              imobiliaria.recebiveis_baixados
                            )}
                          </strong>
                        </li>
                      </ul>
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      className="produtividade-imobiliaria-atend"
                    >
                      <ul className="list-unstyled produtividade text-center text-md-left">
                        <li>
                          <small className="d-block">Cadastros</small>
                          <strong className="d-block">
                            {imobiliaria.quantidade_atendimentos_leads}
                          </strong>
                        </li>
                        <li>
                          <small className="d-block">Taxa de conversão</small>
                          <strong className="d-block">
                            {numeral(
                              imobiliaria.conversao_periodo /
                                imobiliaria.quantidade_atendimentos_leads
                            ).format('(0.00%)')}
                          </strong>
                        </li>
                      </ul>
                    </Col>
                    <Col
                      xs={12}
                      md={3}
                      className="d-flex flex-column miniaturas"
                    >
                      <ul className="list-unstyled d-flex flex-wrap produtividade produtividade-imobiliaria-corr">
                        {ranking_corretores[imobiliaria.codigo].map(
                          (corretor, index) => (
                            <li
                              className={`${corretor.label && 'link-mais'}`}
                              key={index}
                              title={corretor.nome}
                            >
                              {corretor.label ? (
                                <span className="circle">
                                  {corretor.label}&nbsp;
                                </span>
                              ) : (
                                <img
                                  src={
                                    corretor.url_imagem
                                      ? corretor.url_imagem
                                      : defaultLogo
                                  }
                                  alt="corretor"
                                />
                              )}
                            </li>
                          )
                        )}
                      </ul>
                      <ul className="list-unstyled d-flex flex-wrap produtividade produtividade-imobiliaria-corr">
                        {ranking_empreendimentos[imobiliaria.codigo].map(
                          (empreendimento, index) => (
                            <li
                              className={`${empreendimento.label &&
                                'link-mais'}`}
                              key={index}
                              title={empreendimento.nome}
                            >
                              {empreendimento.label ? (
                                <span className="circle">
                                  {empreendimento.label}&nbsp;
                                </span>
                              ) : (
                                <img
                                  src={empreendimento.url_imagem}
                                  alt="empreendimento"
                                />
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </Col>
                  </div>
                </Card>
              </Col>
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  imobiliarias: state.produtividade.imobiliarias,
  ranking_corretores: state.produtividade.imobiliarias.reduce(
    (Ranking, imob) => {
      let totalCorretores = imob.ranking_corretores.length;
      if (totalCorretores <= 5) {
        Ranking[imob.codigo] = imob.ranking_corretores;
        return Ranking;
      }

      let novoRanking = imob.ranking_corretores.slice(0, 4);
      novoRanking.push({ label: `+${totalCorretores - 4}` });
      Ranking[imob.codigo] = novoRanking;

      return Ranking;
    },
    {}
  ),
  ranking_empreendimentos: state.produtividade.imobiliarias.reduce(
    (Ranking, imob) => {
      let totalEmpreendimentos = imob.ranking_empreendimentos.length;
      if (totalEmpreendimentos <= 5) {
        Ranking[imob.codigo] = imob.ranking_empreendimentos;
        return Ranking;
      }

      let novoRanking = imob.ranking_empreendimentos.slice(0, 4);
      novoRanking.push({ label: `+${totalEmpreendimentos - 4}` });
      Ranking[imob.codigo] = novoRanking;

      return Ranking;
    },
    {}
  ),
  imobiliariaSelecionada: state.produtividade.imobiliariaSelecionada,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      selecionaImobiliaria,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardResumoImobiliaria)
);
