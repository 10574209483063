import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getToday, createDate } from '~/Utils/Helpers';
import { Modal } from 'react-bootstrap';
import { DATETIME_DEFAULT_FORMAT, DATE_FORMAT_SERVER } from '~/constant';
import AuthStorage from '~/Utils/AuthStorage';

import { Container } from './styles';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

const TEMPO_REPETICAO = 24; //horas
const FIM_CAMPANHA = '2020-04-10T23:59:00';

export default function ModalNotificacao() {
  const [showBanner, setShowBanner] = useState(false);

  const user = useSelector(state => state.auth.user);

  const verifyBanner = () => {
    if (!AuthStorage.isLogged()) return;

    let hoje = getToday();
    let fimCampanha = createDate(FIM_CAMPANHA, DATE_FORMAT_SERVER);

    if (fimCampanha.isBefore(hoje)) return;

    const user = AuthStorage.getUser();

    let bannerVisualizado = user.bannerVisualizado;
    if (bannerVisualizado) {
      if (TEMPO_REPETICAO) {
        bannerVisualizado = createDate(
          bannerVisualizado,
          DATETIME_DEFAULT_FORMAT
        );

        let ultimas24Horas = hoje.subtract(TEMPO_REPETICAO, 'hours');

        if (bannerVisualizado.isBefore(ultimas24Horas)) {
          setShowBanner(true);
        }
      }
    } else {
      setShowBanner(true);
    }
  };

  const handleClose = () => {
    const hoje = getToday().format(DATETIME_DEFAULT_FORMAT);
    setShowBanner(false);
    const user = AuthStorage.getUser();

    AuthStorage.setUser({ ...user, bannerVisualizado: hoje });
  };

  useEffect(() => {
    verifyBanner();
  }, []);

  useEffect(() => {
    verifyBanner();
  }, [user]);

  return (
    <>
      <Container />

      <Modal id="modalNotificacao" show={showBanner} onHide={handleClose}>
        <Modal.Header className="d-flex align-items-end border-0 p-0"></Modal.Header>
        <Modal.Body className="p-0">
          <button
            onClick={handleClose}
            type="button"
            className="ml-auto position-absolute rounded-circle h1 bg-light border-0 d-flex align-items-center"
          >
            <IoMdClose />
          </button>
          <Link
            to="/comercial/ranking/trimestre"
            title="Ver ranking trimestral"
            className="d-flex"
            onClick={handleClose}
          >
            <img
              className="img-fluid mx-auto"
              srcSet="/images/campeoes-trimestre-1-2020-350w.png 320w,
      /images/campeoes-trimestre-1-2020-600w.png 600w"
              sizes="(max-width: 768px) 350px, 600px"
              src="/images/campeoes-trimestre-1-2020-600w.png"
              alt=""
            />
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
}
