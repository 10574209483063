import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FaFacebook } from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';

import { format } from '~/Utils/Helpers';

import {
  ProspectoContainer,
  ProspectoData,
  ProspectoNome,
  TagStatus,
  ProspectoContato,
} from './styles';
import { Bullet } from '~/Styles';

function ProspectoItem({ data, corretor, imobiliaria, onClick, isActive }) {
  const {
    descricao_status,
    cor_status,
    data_cadastro,
    nome,
    contato,
    facebook,
    empreendimento,
  } = data;
  const dataCadastroFormatado = format.date(data_cadastro, 'DD/MM/YYYY HH:mm');

  const celular = format.celular(contato);

  return (
    <ProspectoContainer
      corImobiliaria={imobiliaria && imobiliaria.cor_imobiliaria}
      isActive={isActive}
    >
      {descricao_status && (
        <TagStatus cor_status={cor_status}>{descricao_status}</TagStatus>
      )}

      <button type="button" onClick={onClick}>
        <ProspectoData>{dataCadastroFormatado}</ProspectoData>
        <ProspectoNome>{nome}</ProspectoNome>
      </button>

      <ProspectoContato>
        {celular}
        {contato && contato.length > 10 && (
          <a
            className="ml-2"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://api.whatsapp.com/send?phone=55${contato}`}
          >
            <IoLogoWhatsapp color="25d366" size={22} />
          </a>
        )}
        {facebook && (
          <a
            className="ml-2"
            target="_blank"
            rel="noopener noreferrer"
            href={`http://facebook.com/${facebook}`}
          >
            <FaFacebook color="3b5998" size={22} />
          </a>
        )}
      </ProspectoContato>

      <ul className="list-unstyled d-flex mb-0 mt-1">
        <li className="mr-2">
          <small className="d-block">Empreendimento</small>
          <strong className="d-block font-weight-normal">
            {empreendimento}
          </strong>
        </li>
        <li className="ml-auto" />
        {corretor && (
          <li className="mr-2">
            <small className="d-block">Corretor</small>
            <strong className="d-block font-weight-normal">
              {corretor.apelido || corretor.nome}
            </strong>
          </li>
        )}
        {imobiliaria && (
          <li className="mr-2">
            <small className="d-block">Imobiliária</small>
            <strong className="d-block font-weight-normal">
              <Bullet cor={imobiliaria.cor_imobiliaria} />
              {imobiliaria.nome}
            </strong>
          </li>
        )}
      </ul>
    </ProspectoContainer>
  );
}

const mapStateToProps = (state, ownProps) => {
  const corretor = state.corretor.corretoresById[ownProps.data.codigo_corretor];
  const imobiliaria = state.corretor.gerentesById[ownProps.data.codigo_gerente];

  return {
    corretor,
    imobiliaria,
  };
};

export default connect(mapStateToProps)(ProspectoItem);

ProspectoItem.propTypes = {
  data: PropTypes.shape({
    descricao_status: PropTypes.string,
    cor_status: PropTypes.string,
    data_cadastro: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    contato: PropTypes.string,
    facebook: PropTypes.string,
    empreendimento: PropTypes.string.isRequired,
    codigo_gerente: PropTypes.number.isRequired,
    codigo_corretor: PropTypes.number.isRequired,
  }),
};
