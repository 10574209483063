import * as Yup from 'yup';

const socioSchemaValidacoes = Yup.object().shape({
  tipo_pessoa: Yup.number().required('Selecione o tipo de pessoa'),
  nome: Yup.string().required('Informe o nome'),
  email: Yup.string().email('Informe um email válido'),
  tel_celular: Yup.string(),
  tipo_conjuge_socio: Yup.string().required('Informe o tipo do sócio/cônjuge'),
  sexo: Yup.string().required('Informe o genero'),
  cpf: Yup.string().required('Informe o CPF'),
  // rg: Yup.string().required('Informe o RG'),
  // orgao_emissor_rg: Yup.string().required('Informe o orgão emissor do RG'),
  // data_emissao_rg: Yup.string().required('Informe a data de emissão do RG'),
  // codigo_profissao: Yup.string().required('Selecione uma profissão'),
  // codigo_nacionalidade: Yup.string().required('Selecione uma nacionalidade'),
  estado_civil: Yup.string().required('Informe o estado civil'),
  cep: Yup.string().required('Informe o CEP'),
  estado: Yup.string().required('Selecione um estado'),
  codigo_cidade: Yup.string().required('Selecione uma cidade'),
  endereco: Yup.string().required('Informe o endereço'),
  numero: Yup.string().required('Informe o número do imóvel'),
  bairro: Yup.string().required('Informe um bairro'),
});

export default socioSchemaValidacoes;
