import React, { Component } from 'react';
import $ from 'jquery';
import AuthStorage from '../Utils/AuthStorage';
import * as constant from '../constant';
import * as broadcast from '../Utils/Broadcast';
import { format, formatarComZero } from '../Utils/Helpers';
import { Link } from 'react-router-dom';
import * as api from '../Services/Service';
import { default as auth } from '../Utils/AuthStorage';

export default class AtendimentoGerente extends Component {
  state = {
    atendimentos: [],
  };

  estruturarCard = ticket => ({
    ...ticket,
    data_inicio_atendimento: format.date(
      ticket.data_inicio_atendimento,
      'HH:mm'
    ),
    data_inicio_notificacao: ticket.data_inicio_notificacao
      ? format.date(ticket.data_inicio_notificacao, 'HH:mm')
      : null,
    mesa: {
      ...ticket.mesa,
      Numero: formatarComZero(ticket.mesa.numero),
    },
    prospecto: {
      ...ticket.prospecto,
      nome: ticket.prospecto.nome.split(/(\s).+\s/).join(''),
    },
  });

  async componentDidMount() {
    const env = AuthStorage.getEnv();
    const response = await api.consultarAtendimentoMesas(env);
    const atendimentos = response.map(ticket => this.estruturarCard(ticket));

    this.setState({ atendimentos });

    broadcast.connect();
    let channel = 'painel-atendimento';
    if (env) {
      channel += `-${env}`;
    }
    broadcast.addListerner(channel, 'finalizar-atendimento', data =>
      this.finalizarAtendimento(data)
    );
    broadcast.addListerner(channel, 'chamar-gerente', data =>
      this.chamarGerente(data)
    );
    broadcast.addListerner(channel, 'remove-chamar-gerente', data =>
      this.removerItemDestaque(data)
    );
  }

  alertaSonoro(tipo) {
    let audio = $(
      tipo === 'iniciarAtendimento'
        ? '#audioNovoAtendimento'
        : '#audioChamarCorretor'
    );
    if (window.navigator) {
      navigator.vibrate(3000);
    }
    try {
      $(audio)[0].currentTime = 0;
      $(audio)[0].play();
    } catch (error) {
      console.error(error);
    }
  }

  removerItemDestaque = ticket => {
    let novoAtendimento = this.state.atendimentos.map(item => {
      if (item.codigo !== ticket.codigo) {
        return item;
      }
      return {
        ...item,
        iniciou_atendimento: false,
        data_inicio_notificacao: null,
        reservou: false,
      };
    });
    this.setState({ atendimentos: novoAtendimento });
  };

  finalizarAtendimento = ticket => {
    let index = this.state.atendimentos.reduce((prev, curr, index) => {
      return curr.codigo === ticket.codigo ? index : prev;
    }, null);

    if (index === null) return;

    let atendimentos = [
      ...this.state.atendimentos.slice(0, index),
      ...this.state.atendimentos.slice(index + 1),
    ];
    this.setState({ atendimentos });
  };

  chamarGerente = ticket => {
    this.alertaSonoro('chamarCorretor');
    let atendimentos = this.state.atendimentos.map(item => {
      if (item.codigo !== ticket.codigo) {
        return item;
      }
      return {
        ...item,
        status: 2,
        data_inicio_notificacao: format.date(new Date(), 'HH:mm'),
      };
    });
    this.setState({ atendimentos });
  };

  render() {
    let { atendimentos } = this.state;
    const isCorretor = auth.isCorretor();

    atendimentos = atendimentos.sort(atendimento => {
      return atendimento.data_inicio_notificacao ||
        atendimento.iniciou_atendimento
        ? -1
        : 1;
    });

    return (
      <div className="SequentialTicket">
        <h5 className="w-100 text-center">Atendimentos ativos - Imobiliária</h5>
        <ul className="ticket-list">
          {atendimentos.map((ticket, index) => (
            <li
              className={`ticket ${(ticket.iniciou_atendimento && 'novo') ||
                (ticket.reservou && 'reservou') ||
                (ticket.data_inicio_notificacao &&
                  ticket.corretor.gerente_solicitado &&
                  'chamou')}`}
              key={index}
            >
              <div className="ticket__row ticket__mesa_descricao">
                <div className="ticket__mesa ticket__col">
                  <strong>{ticket.mesa.numero}</strong>
                </div>
                <div className="ticket__col ticket__descricao">
                  <div className="ticket__row">
                    <Link
                      className="ticket__nome"
                      to={`/prospectos/${ticket.prospecto.codigo}/dados-cliente/pessoais`}
                    >
                      {ticket.prospecto.nome}
                    </Link>
                  </div>
                  <div className="ticket__row ticket__data">
                    <span className="ticket__tipo">iniciou:</span>
                    <strong>{ticket.data_inicio_atendimento}</strong>
                  </div>

                  {ticket.data_inicio_notificacao && (
                    <div className="ticket__row ticket__data">
                      <strong className="text-warning">
                        {' '}
                        Corretor solicitado:
                      </strong>
                      <strong>
                        {ticket.data_inicio_notificacao
                          ? ticket.data_inicio_notificacao
                          : '--:--'}
                      </strong>
                    </div>
                  )}
                </div>
              </div>
              {!isCorretor && (
                <div className="ticket__corretor">
                  <span className="ticket__tipo">corretor:</span>
                  <span className="ticket__nome__corretor">
                    {ticket.corretor.nome}
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
        <audio style={{ display: 'none' }} id="audioChamarCorretor">
          <source src={constant.audio2} type="audio/ogg" />
        </audio>
        <audio style={{ display: 'none' }} id="audioNovoAtendimento">
          <source src={constant.audio3} type="audio/ogg" />
        </audio>
      </div>
    );
  }
}
