import styled from 'styled-components';

export const AvisoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  p {
    font-size: 18px;
  }

  img {
    width: 240px;
    height: 240px;
    margin-bottom: 20px;
    opacity: 0.3;
  }
`;
