import * as constants from '../constant';
import LocalStorage from '../Utils/LocalStorage';
import { serialize } from '../Utils/Helpers';
import axios from 'axios';
import qs from 'querystring';
import { doLogout } from '../Components/Login/AuthActions';
import { EMPREENDIMENTO_LANCAMENTO } from '~/constant';

export const httpService = {
  //we pass the redux store and history in order to dispatch the logout actions
  //and push the user to login

  setupInterceptors: (store, history) => {
    axios.interceptors.response.use(
      response => {
        //simply return the response if there is no error
        return response;
      },
      error => {
        //in this case we only care about unauthorized errors
        if (error.response.status === 403 || error.response.status === 401) {
          //we dispatch our logout action (more than likely changes a boolean
          //somewhere in your store ex. isAuthenticated: false)
          store.dispatch(doLogout());
          //this could just as easily be localStorage.removeItem('your-token')
          //but it's best to encapsulate this logic so it can be used elsewhere
          //by just importing it.
          //AuthStorage.removeToken()
          //send the user to the login page since the user/token is not valid
          //history.push('/login?logout=true')
        }
        return Promise.reject(error.response.data);
      }
    );
  },
  fetch: ({ url, data, headers, ...rest }, usarApiBase = true) => {
    let accessToken = LocalStorage.get(constants.AUTH_KEY)
      ? `Bearer ${LocalStorage.get(constants.AUTH_KEY)}`
      : null;

    headers = {
      ...headers,
      Authorization: accessToken,
    };

    url = usarApiBase ? constants.APIBASE + url : url;

    return axios({
      url,
      data,
      headers,
      ...rest,
    }).then(res => res.data);
  },
  post: (url, data, headers) =>
    httpService.fetch({ url, data, headers, method: 'post' }),
  get: (url, usarApiBase) => httpService.fetch({ url }, usarApiBase),
  put: (url, data, headers) =>
    httpService.fetch({ url, data, headers, method: 'put' }),
};

export default httpService;

//Endpoints da API
export const doLogin = (credentials, env = constants.DEFAULT_ENV) =>
  httpService.post(
    '/usuario/login',
    qs.stringify({ ...credentials, env, grant_type: 'password' }), //data
    { 'Content-Type': 'application/x-www-form-urlencoded' } //headers
  );

/**
 * @description
 * rota para poder recuperar senha, 
 */
export const recuperarSenha = data => 
  httpService.post('/usuario/recuperar/senha', data);
/**
 * @description
 * rota para poder recuperar usuário, 
 */
export const recuperarUser = data => 
  httpService.post('/usuario/recuperar', data);

export const consultarProspectos = data =>
  httpService.post('/prospecto/buscar', data);

export const consultarEtapasProspectos = data =>
  httpService.post('/prospecto/etapas', data);

export const getGerentes = () => httpService.get('/opcao/gerente');

export const getOpcoes = () => httpService.get('/opcao');

export const getCidades = uf => httpService.get(`/opcao/estado/${uf}`);

export const getCities = () => httpService.get('/opcao/cidades/');

export const verificarProspectoCPF = cpf =>
  httpService.get(`/prospecto/cpf/${cpf}`);

export const getProfissoes = (value = '') =>
  httpService.get(`/profissao?${serialize({ q: value })}`);

export const getNacionalidades = () => httpService.get('/opcao/nacionalidade');

export const getPDV = () => httpService.get('/opcao/pdv');

export const getProspecto = (id, liberarConsulta) =>
  httpService.get(
    `/prospecto/${id}${liberarConsulta ? '?liberarConsulta=s' : ''}`
  );

export const criarProspecto = data => httpService.post('/prospecto', data);

export const importarProspectoSuaHouse = data =>
  httpService.post('/integration/suahouse/importar', data);

export const reImportarSuaHouse = codigoProspecto =>
  httpService.get(`/integration/suahouse/reimportar/${codigoProspecto}`);

export const atualizarProspecto = data =>
  httpService.post('/prospecto/atualizar', data);

export const uploadDocumento = formData =>
  httpService.post('/prospecto/upload', formData);

export const consultaCPF = cpf => httpService.get(`/prospecto/cpf/${cpf}`);

export const consultaEmail = email =>
  httpService.get(`/prospecto/email?email=${email}`);

export const criaContaGE = (data, familia) =>
  httpService.post(`/prospecto/conta/familia/${familia}`, data);

export const vincularEmpreendimento = data =>
  httpService.post('/empreendimento/prospecto', data);

export const buscarEnderecoPorCEP = cep => httpService.get(`/busca/cep/${cep}`);

export const salvarSocio = data => httpService.post('/prospecto/socio', data);

export const atualizarSocio = data =>
  httpService.post('/prospecto/socio/atualizar', data);

export const getPesquisa = () => httpService.get('/prospecto/questionario');

export const salvarPesquisa = (data, prospecto, GE) =>
  httpService.post(`/prospecto/questionario/${prospecto}/ge/${GE}`, data);

export const getQuestionarioPosVenda = () =>
  httpService.get(`/questionario/-1`);

export const getPesquisaPosVenda = codigoBoleto =>
  httpService.get(`/cliente/pesquisa-pos-venda/${codigoBoleto}`);

export const salvarPesquisaPosVenda = (data, codigo_boleto) =>
  httpService.post(`/cliente/pesquisa-pos-venda/${codigo_boleto}`, data);

export const salvarOcorrencia = data =>
  httpService.post('/prospecto/ocorrencia', data);

export const atualizarStatusProspecto = data =>
  httpService.post('/empreendimento/prospecto', data);

export const removerEmpreendimento = data =>
  httpService.post('/empreendimento/prospecto/remover', data);

export const vincularSenhaLancamento = data =>
  httpService.post('/prospecto/senha', data);

export const getFolhaConferenciaCliente = (ge, conta) =>
  httpService.get(`/cliente/ge/${ge}/conta/${conta}/folha-conferencia`);

//COMERCIAL
export const consultarComprovantes = (preVenda, ge) =>
  httpService.get(`/comercial/arquivos/preVenda/${preVenda}/comprovantes`);

export const buscarHistoricoDocumentacao = preVenda =>
  httpService.get(
    `/comercial/arquivos/preVenda/${preVenda}/comprovantes/historico`
  );

export const buscarTitulosSinalPreVenda = preVenda =>
  httpService.get(`/preVenda/${preVenda}/titulos/sinal`);

export const obterDadosBancariosPreVenda = preVenda =>
  httpService.get(`/preVenda/${preVenda}/dados-bancarios`);

export const atualizarStatusComprovante = data =>
  httpService.post('/comercial/arquivos/alterarStatus', data);

export const removerComprovante = sequencia =>
  httpService.post(`/comercial/arquivos/${sequencia}/remover`);

export const uploadComprovante = (formData, ge, prevenda) =>
  httpService.post(
    `/comercial/arquivos/ge/${ge}/preVenda/${prevenda}/upload`,
    formData
  );

export const getPesquisaStatusSituacaoCliente = cpf =>
  httpService.get(`/cliente/pesquisa-status-situacao/${cpf}`);

//PRODUTIVIDADE
export const consultarProdutividade = data =>
  httpService.post('/dashboard-produtividade/geral', data);

export const consultarProdutividadePorCorretor = (data, gerente, corretor) =>
  httpService.post(
    `/dashboard-produtividade/imobiliaria/${gerente}/corretor/${corretor}`,
    data
  );

export const consultarProdutividadePorEmprendimento = (data, empreendimento) =>
  httpService.post(
    `/dashboard-produtividade/empreendimento/${empreendimento}`,
    data
  );

export const consultarRankingCorretores = (
  codigoCampanha = null,
  dataInicial = null,
  dataFinal = null,
  tipoRanking = null
) => {
  let queryParams = [];

  if (codigoCampanha !== null) {
    queryParams.push(`codigoCampanha=${codigoCampanha}`);
  }
  if (tipoRanking !== null) {
    queryParams.push(`tipoRanking=${tipoRanking}`);
  }
  if (dataInicial !== null) {
    queryParams.push(`dataInicial=${dataInicial}`);
  }
  if (dataFinal !== null) {
    queryParams.push(`dataFinal=${dataFinal}`);
  }

  const queryString = queryParams.length > 0 ? '?' + queryParams.join('&') : '';
  const url = '/dashboard-produtividade/ranking-corretor' + queryString;

  return httpService.get(url);
};

//ATENDIMENTO
export const consultarAtendimentoMesas = env =>
  httpService.get(
    `/lancamento/atendimento/empreendimento/${EMPREENDIMENTO_LANCAMENTO}${
      env ? '?env=' + env : ''
    }`
  );

export const consultarMinhasSolicitacoesPendentes = () =>
  httpService.get(
    `/lancamento/atendimento/${EMPREENDIMENTO_LANCAMENTO}/minhas-solicitacoes-pendentes`
  );

export const NotificarDepartamento = departamento =>
  httpService.post(
    `/lancamento/atendimento/${EMPREENDIMENTO_LANCAMENTO}/notificar-departamento/${departamento}`,
    null
  );

export const consultarSolicitacoesPendentes = env =>
  httpService.get(
    `/lancamento/atendimento/${EMPREENDIMENTO_LANCAMENTO}/solicitacoes-pendentes${
      env ? '?env=' + env : ''
    }`
  );

export const notificaCliente = data =>
  httpService.post(`/contato/acesso`, data);

export const getProspectoAtendimento = (tipo, value) =>
  httpService.get(
    `/lancamento/atendimento/${EMPREENDIMENTO_LANCAMENTO}/buscar?codigo=${value}&tipo=${tipo}`
  );

export const salvarPesquisaAtendimento = pesquisa =>
  httpService.post(`/lancamento/atendimento/avaliar`, pesquisa);

// ESTORNO DE PROPOSTA
export const getSolicitacoesDeEstornoProposta = () =>
  httpService.get('/proposta/estorno/solicitacoes');

export const estornarProposta = data =>
  httpService.post(`/proposta/estorno/solicitacoes`, data);

export const getSolicitacaoParaEstornoProposta = proposta =>
  httpService.get(`/proposta/estorno/solicitacoes/${proposta}`);

// ATRIBUIR PROSPECTO
export const buscarListaAtribuirProspectos = data =>
  httpService.post(`/empreendimento/vinculos`, data);

export const atribuirProspectos = data =>
  httpService.post(`/empreendimento/vinculos/trocar-corretor`, data);

//USUARIO
export const getClaims = () => httpService.get('/usuario/claims');

//PARAMETROS
export const getParametrosGerais = () => httpService.get('/parametros/gerais');

export const getMotivosDescarte = () =>
  httpService.get('/parametros/fluxo-prospecto/motivos-descarte');

export const updateLimiteFluxoProspectos = limite =>
  httpService.post('/parametros/fluxo-prospecto/limite', {
    LimiteFluxoProspecto: limite,
  });

export const inserirOrAtualizarMotivoDescarte = data =>
  httpService.post('/parametros/fluxo-prospecto/motivos-descarte', data);

export const desativarMotivoDescarte = codigoMotivo =>
  httpService.get(
    `/parametros/fluxo-prospecto/motivos-descarte/${codigoMotivo}/desativar`
  );

export const buscarPreVenda = numeroPreVenda =>
  httpService.get(`/preVenda/${numeroPreVenda}`);

export const buscarProcuradoresEmpreendimento = codigoFamilia =>
  httpService.get(`/empreendimento/${codigoFamilia}/procuradores`);

//DOCUMENTO DIGITAL
export const buscarParametrosDocumentoDigital = () =>
  httpService.get('/documento-digital/parametros');

export const solicitarAssinaturaDigital = data =>
  httpService.post('/documento-digital/solicitar', data);

export const buscarDocumentoDigital = codigoDocumentoDigital =>
  httpService.get(`/documento-digital/${codigoDocumentoDigital}`);

export const reprovarDocumentoDigital = (
  codigoDocumentoDigital,
  message,
  codigoMotivoCancelamento
) =>
  httpService.post(
    `/documento-digital/${codigoDocumentoDigital}/reprovar?codigoMotivoCancelamento=${codigoMotivoCancelamento}`,
    {
      value: message,
    }
  );

export const destartarDocumentoDigital = (codigoDocumentoDigital, message) =>
  httpService.post(`/documento-digital/${codigoDocumentoDigital}/descartar`, {
    value: message,
  });

export const reSolicitarAssinaturaDigital = data =>
  httpService.post('/documento-digital/re-solicitar', data);

export const aprovarAssinaturaDigital = (formData, enviarCartaDesconto) =>
  httpService.post(
    `/documento-digital/aprovar?enviarCartaDesconto=${enviarCartaDesconto}`,
    formData
  );

export const uploadDocumentoDigital = (
  codigoDocumentoDigital,
  formData,
  enviarCartaDesconto
) =>
  httpService.post(
    `/documento-digital/${codigoDocumentoDigital}/upload?enviarCartaDesconto=${enviarCartaDesconto}`,
    formData
  );

export const cancelarDocumentoDigital = (
  codigoDocumentoDigital,
  message,
  codigoMotivoCancelamento
) =>
  httpService.post(
    `/documento-digital/${codigoDocumentoDigital}/cancelar?codigoMotivoCancelamento=${codigoMotivoCancelamento}`,
    {
      value: message,
    }
  );

export const buscarMotivosCancelamento = tipoContrato =>
  httpService.get(
    `/documento-digital/motivoCancelamentoReprovacao?codigoTipoContrato=${tipoContrato}`
  );

export const notificarSignatario = codigoSignatario =>
  httpService.get(
    `/documento-digital/signatarios/${codigoSignatario}/notificar`
  );

export const incluirSignatario = (codigoDocumentoDigital, signatario) =>
  httpService.post(
    `/documento-digital/${codigoDocumentoDigital}/signatarios/incluir`,
    signatario
  );

export const removerSignatario = codigoSignatario =>
  httpService.get(`/documento-digital/signatarios/${codigoSignatario}/remover`);

export const editarSignatario = signatario =>
  httpService.post(
    `/documento-digital/signatarios/${signatario.codigo}/editar`,
    signatario
  );

export const getDownloadUrl = codigoDocumentoDigital =>
  httpService.get(`/documento-digital/${codigoDocumentoDigital}/url-download`);

export const buscarPreviaDocDigital = (numeroPreVenda, tipo) =>
  httpService.get(
    `/documento-digital/previa?numeroPreVenda=${numeroPreVenda}&tipo=${tipo}`
  );

//CAMPANHA (Empreendimento_Campanha)
export const buscarCampanhas = () => httpService.get('/campanha');

export const criarCampanha = campanha =>
  httpService.post('/campanha/inserir', campanha);

export const editarCampanha = campanha =>
  httpService.post('/campanha/editar', campanha);

export const removerCampanha = codigoCampanha =>
  httpService.get(`/campanha/remover?codigoCampanha=${codigoCampanha}`);

export const finalizarCampanha = codigoCampanha =>
  httpService.get(`/campanha/finalizar?codigoCampanha=${codigoCampanha}`);

export const buscarResultadosCampanha = codigoCampanha =>
  httpService.get(`/campanha/resultados?codigoCampanha=${codigoCampanha}`);

//Inadimplencia
export const buscarListaInadimplencias = filtros =>
  httpService.post('/cliente/inadimplencia', filtros);

export const buscarOcorrenciasCliente = (
  codigoConta,
  codigoGrupoEmpresa,
  codigoBoleto = null,
  somenteOcorrenciaDoUsuario = null
) =>
  httpService.get(
    `/cliente/ocorrencias?${qs.stringify({
      codigoConta,
      codigoGrupoEmpresa,
      codigoBoleto,
      somenteOcorrenciaDoUsuario,
    })}`
  );

export const gravarOcorrenciaCliente = (
  ocorrencia,
  numeroPreVenda,
  encaminhamento
) =>
  httpService.post(
    // numeroPreVenda é opcional, mas por enquanto estou sempre passando. Mude conforme necessário
    `/cliente/ocorrencia/gravar?numeroPreVenda=${numeroPreVenda}&encaminhamentoCobranca=${encaminhamento}`,
    ocorrencia
  );

export const obterUrlSegundaViaBoleto = codigoTitulo =>
  httpService.get(
    `/titulos/segunda-via-boleto/url?codigoTitulo=${codigoTitulo}`
  );

export const enviarEmailSegundaViaBoleto = (email, codigoTitulo) =>
  httpService.get(
    `/titulos/segunda-via-boleto/enviar?codigoTitulo=${codigoTitulo}&email=${email}`
  );

//VENDA
export const obterPropostaInicial = (codigoFamilia, idProspecto) =>
  httpService.get(
    `/venda/empreendimento/${codigoFamilia}/prospecto/${idProspecto}`
  );

export const gravarVenda = (codigoFamilia, codigoProspecto, request) =>
  httpService.post(
    `/venda/empreendimento/${codigoFamilia}/prospecto/${codigoProspecto}`,
    request
  );

export const buscarPropostasGravadas = () =>
  httpService.get('/venda/propostas/gravadas');

export const excluirPropostaRapidaAuxiliar = codigoProposta =>
  httpService.get(`/venda/proposta/${codigoProposta}/excluir`);

export const buscarPropostaAuxiliar = codigoProposta =>
  httpService.get(`/venda/proposta/${codigoProposta}/auxiliar`);

export const buscarUsuariosAutorizadoresVenda = () =>
  httpService.get(`/usuario/autorizadores/venda`);

export const validarCheque = cheque =>
  httpService.post('/venda/validacao/cheque', cheque);

export const validarUsuarioSenha = (codigo_usuario, senha) =>
  httpService.post('/usuario/credenciais/validar', { codigo_usuario, senha });

export const autorizarVenda = (codigoProposta, autorizacao) =>
  httpService.post(`/venda/proposta/${codigoProposta}/aprovar`, autorizacao);

export const consultarComissao = filtros =>
  httpService.post('/comissao/buscar', filtros);

//ANAPRO - FOI UNIFICADO NO "CRM Genérico"
// export const buscarLeadParaImportacao = atendimentoID =>
//   httpService.get(`/integration/anapro/buscar-atendimento/${atendimentoID}`);

// export const importarOuAtualizarLeadNaExtranet = (
//   atendimentoID,
//   codigoFamilia
// ) =>
//   httpService.post(
//     `/integration/anapro/importar/cliente/atendimento/${atendimentoID}/empreendimento/${codigoFamilia}`
//   );

//CRM Genérico
export const obterLeadDoCRM = id =>
  httpService.get(`/integration/crm/lead/${id}`);

export const importarLeadCRM = async (atendimentoID, codigoFamilia) => {
  try {
    return await httpService.post(
      `/integration/crm/lead/${atendimentoID}/empreendimento/${codigoFamilia}`
    );
  } catch (error) {
    throw new Error(
      error.message || 'Erro ao importar lead do CRM para a Extranet'
    );
  }
};

export const obterConstrutor = () => httpService.get(`/prospecto/construtor`);

/**
 * 
 * @param {int} id - id do empreendimento
 * @returns string
 */
export const obterMioloContrato = (id) =>
  httpService.get(`/empreendimento/${id}/miolo-contrato`);

/**
 * @description Função que salva o miolo de um contrato de um empreendimento
 * @returns bool
 */
export const SalvarMioloContrato = async (contrato) =>
  httpService.post(`/empreendimento/miolo-contrato`, contrato);
