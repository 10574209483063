import styled from 'styled-components';

import { primaryColor } from '~/Styles';

export const ProspectoData = styled.div`
  margin-top: 5px;
  @media screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 0px;
  }
  font-size: 10px;
  color: #666;
  line-height: 1;
`;

export const ProspectoNome = styled.div`
  font-weight: 700;
`;

export const ProspectoContato = styled.div`
  display: flex;
  align-items: center;
`;

export const ProspectoContainer = styled.div.attrs({
  className: 'mb-2 arrow-bottom-left',
})`
  & {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: #fff;
    border-radius: 2px;
    position: relative;

    ${props => props.isActive && `box-shadow: 0 0 0 2px ${primaryColor};`}

    ${ProspectoNome} {
      color: ${props => (props.isActive ? primaryColor : 'inherit')};
    }
    &&::before {
      border-left-color: ${props => props.corImobiliaria || '#ccc'};
    }

    .prospect-name {
      color: ${primaryColor};
      font-weight: 500;
    }

    button {
      border: 0;
      background: 0;
      text-align: left;
      padding: 0;
      &,
      &:focus {
        outline-width: 0;
      }
      &:hover {
        color: ${primaryColor};
      }
    }

    ul {
      li {
        display: flex;
        flex-direction: column;
        line-height: 1;
        small {
          font-size: 12px;
          color: #666;
        }
        strong {
          font-size: 0.8rem;
        }
      }
    }
  }
`;

export const TagStatus = styled.div.attrs({
  className: 'rounded position-absolute',
})`
  right: 0.5rem;
  top: 0.5rem;
  font-size: 60%;
  padding: 0.1rem 0.6rem 0.08rem;
  border: 1px solid ${props => props.CorStatus};
  color: ${props => props.CorStatus};
`;
