import * as Yup from 'yup';

const schemaToValidation = Yup.object().shape(
  {
    tipo_pessoa: Yup.number().required('Selecione o tipo de pessoa'),
    nome: Yup.string().required('Informe o nome'),

    codigo_perfil_comprador: Yup.string().required(
      'Selecione um perfil do comprador'
    ),
    perfil_exterior_ddi: Yup.string().when(
      ['codigo_perfil_comprador'],
      (codigo_perfil_comprador, field) =>
        parseInt(codigo_perfil_comprador) === 4
          ? field.required('Informe o DDI (exterior)')
          : field
    ),
    perfil_exterior_telefone: Yup.string().when(
      ['codigo_perfil_comprador'],
      (codigo_perfil_comprador, field) =>
        parseInt(codigo_perfil_comprador) === 4
          ? field.required('Informe o Telefone (exterior)')
          : field
    ),
    perfil_exterior_endereco: Yup.string().when(
      ['codigo_perfil_comprador'],
      (codigo_perfil_comprador, field) =>
        parseInt(codigo_perfil_comprador) === 4
          ? field.required('Informe o Endereço (exterior)')
          : field
    ),
    perfil_exterior_pais: Yup.string().when(
      ['codigo_perfil_comprador'],
      (codigo_perfil_comprador, field) =>
        parseInt(codigo_perfil_comprador) === 4
          ? field.required('Informe o Pais (exterior)')
          : field
    ),
    email: Yup.string()
      .when('tel_celular', {
        is: tel_celular => !tel_celular || tel_celular.length === 0,
        then: Yup.string().required('Informe ao menos um contato'),
      })
      .email('Informe um email válido'),
    tel_celular: Yup.string().when('email', {
      is: email => !email || email.length === 0,
      then: Yup.string().required('Informe ao menos um contato'),
    }),
    codigo_nacionalidade: Yup.string().required('Selecione uma nacionalidade'),
    cpf: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta ? field.required('Informe o CPF') : field
    ),
    // rg: Yup.string().when(
    //   ['TentouValidar', 'codigo_conta'],
    //   (TentouValidar, codigo_conta, field) =>
    //     TentouValidar || codigo_conta
    //       ? field.required('Informe o RG/IE')
    //       : field
    // ),
    codigo_profissao: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta
          ? field.required('Selecione uma profissão')
          : field
    ),
    cep: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta ? field.required('Informe o CEP') : field
    ),
    estado: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta
          ? field.required('Selecione um estado')
          : field
    ),
    codigo_cidade: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta
          ? field.required('Selecione uma cidade')
          : field
    ),
    endereco: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta
          ? field.required('Informe o endereço')
          : field
    ),
    numero: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta
          ? field.required('Informe o número do imóvel')
          : field
    ),
    bairro: Yup.string().when(
      ['TentouValidar', 'codigo_conta'],
      (TentouValidar, codigo_conta, field) =>
        TentouValidar || codigo_conta
          ? field.required('Informe um bairro')
          : field
    ),
    // orgao_emissor_rg: Yup.string().when(
    //   ['TentouValidar', 'codigo_conta', 'tipo_pessoa'],
    //   (TentouValidar, codigo_conta, tipo_pessoa, field) =>
    //     (TentouValidar || codigo_conta) && parseInt(tipo_pessoa) === 0
    //       ? field.required('Informe o orgão emissor do RG')
    //       : field
    // ),
    // data_emissao_rg: Yup.string().when(
    //   ['TentouValidar', 'codigo_conta', 'tipo_pessoa'],
    //   (TentouValidar, codigo_conta, tipo_pessoa, field) =>
    //     (TentouValidar || codigo_conta) && parseInt(tipo_pessoa) === 0
    //       ? field.required('Informe a data de emissão do RG')
    //       : field
    // ),
    orgao_emissor_cnh: Yup.string().when(['cnh'], (cnh, field) =>
      cnh ? field.required('Informe o orgão emissor da cnh') : field
    ),
    estado_civil: Yup.string().when(
      ['TentouValidar', 'codigo_conta', 'tipo_pessoa'],
      (TentouValidar, codigo_conta, tipo_pessoa, field) =>
        (TentouValidar || codigo_conta) && parseInt(tipo_pessoa) === 0
          ? field.required('Selecione um estado civil')
          : field
    ),
    sexo: Yup.string().when(
      ['TentouValidar', 'codigo_conta', 'tipo_pessoa'],
      (TentouValidar, codigo_conta, tipo_pessoa, field) =>
        (TentouValidar || codigo_conta) && parseInt(tipo_pessoa) === 0
          ? field.required('Selecione um gênero')
          : field
    ),
  },
  ['tel_celular', 'email'] // Resolve problema de ouvir o tel_celular e email
);
export default schemaToValidation;
