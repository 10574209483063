import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';

import { Link } from 'react-router-dom';

export const MainNavbar = styled(Navbar)`
  background: #333;
  z-index: 1000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  .navbar-brand {
    padding: 0;

    img {
      height: 30px;
    }
  }

  .dropdown-header {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 0.775rem;
  }

  .navbar-collapse {
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 992px) {
      max-height: initial;
      overflow-y: initial;
    }
  }

  &.navbar-dark .navbar-nav .nav-link {
    color: #ffffffcf;
  }

  .dropdown-item,
  .nav-link {
    display: flex;
    font-size: 0.9rem;
    align-items: center;

    @media (min-width: 1440px) {
      font-size: 1rem;
    }

    img,
    svg {
      height: 20px;
      margin-right: 5px;
    }
  }

  .item-dpto {
    background: #fff !important;
    position: relative;
  }

  .item-dpto button {
    color: #000 !important;
    background: #fff;
    border-width: 1px;
    border-style: ridge;
    border-color: #cecece;
  }

  .item-dpto:hover {
    background: #cecece !important;
    color: #1caf9a !important;
  }

  .posicao {
    position: absolute;
    top: 27px;
    right: 2px;
    border-radius: 50%;
    background: #0747a6;
    color: white;
    width: 23px;
    text-align: center;
  }

  .notificar {
    display: flex;
    opacity: 1;
    animation: chamarDptoLancamento 1s linear infinite;
  }

  @keyframes chamarDptoLancamento {
    0% {
      background: #adb5bd;
      color: #000;
    }

    50% {
      background: #ffc700;
      color: #000;
    }
  }
`;

export const BtnRanking = styled(Link)`
  && {
    background: rgba(252, 176, 69, 1);
    background: linear-gradient(
      80deg,
      rgba(253, 130, 50, 1) 0%,
      rgba(252, 176, 69, 1) 100%
    );

    line-height: 1;
  }
`;
