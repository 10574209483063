import * as api from '../../Services/Service';
import * as actions from '../../actionTypes';

export const buscarOpcoes = () => (dispatch, getState) => {
  const { optionsCarregado } = getState().options;
  const { isLoadingOptions } = getState().prospecto;

  if (isLoadingOptions || optionsCarregado) return;

  dispatch({
    type: actions.FETCHING_OPTIONS,
  });

  api
    .getOpcoes()
    .then(response => {
      dispatch({
        type: actions.FETCH_OPTIONS_SUCCESS,
        payload: response,
      });
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.FETCH_ERROR_OPTIONS,
        payload: error,
      });
    });
};

export const buscarUsuariosAutorizadoresVenda = () => async dispatch => {
  dispatch({
    type: actions.FETCHING_USUARIOS_AUTORIZADORES_VENDA,
    payload: true,
  });
  try {
    const response = await api.buscarUsuariosAutorizadoresVenda();
    dispatch({
      type: actions.FETCH_USUARIOS_AUTORIZADORES_VENDA_SUCCESS,
      payload: response,
    });
  } catch (err) {
    console.error(err);
  } finally {
    dispatch({
      type: actions.FETCHING_USUARIOS_AUTORIZADORES_VENDA,
      payload: false,
    });
  }
};
