import React, { Component } from 'react';
import { Card, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { carregaProdutividadePorCorretor } from '~/Store/Produtividade/actions';
import defaultLogo from '../../Images/logo.svg';
import Loading from '~/Components/Loading';
import { format } from '~/Utils/Helpers';

class CardDetalhesVenda extends Component {
  formatarValor = valor => numeral(valor).format('(R$ 0.00 a)');

  onClickCardResumo(codigo) {
    const elementId = '#tabelaResumo_' + codigo;
    if ($(elementId).hasClass('d-none')) $(elementId).removeClass('d-none');
    else $(elementId).addClass('d-none');
  }

  render() {
    const {
      carregandoProdutivdadeCorretor,
      corretorSelecionado,
      carregandoProdutivdadeEmpreendimento,
      empreendimentoSelecionado,
    } = this.props;

    if (
      carregandoProdutivdadeCorretor ||
      carregandoProdutivdadeEmpreendimento
    ) {
      return (
        <div className="mb-4">
          <Loading
            label={`Carregando produtividade do ${
              carregandoProdutivdadeCorretor ? 'corretor' : 'empreendimento'
            }...`}
          />
        </div>
      );
    }

    const detalhes = corretorSelecionado || empreendimentoSelecionado;
    if (!detalhes) return null;
    return (
      <div className="CardDetalhesVenda mb-4">
        <div className="linha-resumo-imobiliaria">
          <div className="nome-selecionado">
            Vendas de <strong>{detalhes.nome}</strong>
          </div>
          {detalhes.ranking.map(item => (
            <div className="ImobiliariaItem" key={item.codigo}>
              <Col>
                <Card className="border-0 shadow-sm">
                  <div
                    onClick={() => this.onClickCardResumo(item.codigo)}
                    className="cardHover"
                  >
                    <div className="flag-ranking">{item.rank}°</div>
                    <div className="nomeEmpreendimentoCorretor">
                      <span>{item.nome}</span>
                    </div>
                    <div className="d-flex flex-column flex-md-row produtividade-imobiliaria produtividade__detalhes-venda flex-wrap">
                      <div className="d-flex flex-column flex-md-row align-items-center justify-space-between w-100">
                        <div className="text-center img-resumo-imobiliaria d-flex flex-column">
                          <img
                            src={
                              item.url_imagem ? item.url_imagem : defaultLogo
                            }
                            title={item.nome}
                            alt={item.nome}
                          />
                        </div>
                        <div className="produtividade-imobiliaria-atend">
                          <ul className="list-unstyled d-flex flex-wrap produtividade text-center text-md-left ml-2">
                            <li>
                              <small className="d-block">Cadastros</small>
                              <strong className="d-block">
                                {item.quantidade_atendimentos_leads}
                              </strong>
                            </li>
                            <li>
                              <small className="d-block">Conversão</small>
                              <strong className="d-block">
                                {numeral(
                                  item.conversao_periodo /
                                    item.quantidade_atendimentos_leads
                                ).format('(0%)')}
                              </strong>
                            </li>
                          </ul>
                        </div>
                        <ul className="list-unstyled d-flex produtividade text-center text-md-left ml-2">
                          <li>
                            <small className="d-block">VGV</small>
                            <strong
                              className="d-block"
                              title={`R$ ${
                                item.vendas
                                  ? item.vendas.toLocaleString('pt-BR')
                                  : '0,00'
                              }`}
                            >
                              <small>R$</small>{' '}
                              {this.formatarValor(item.vendas)}
                            </strong>
                          </li>
                          <li>
                            <small className="d-block">Total Vendas</small>
                            <strong className="d-block">
                              {item.quantidade_vendas}
                            </strong>
                          </li>
                          <li>
                            <small>Qtd. Vendas</small>
                            <strong
                              className="d-block"
                              title="E = Vendas Efetivadas / P = Proposta Rápida"
                            >
                              {item.quantidade_vendas}
                              (E:{item.quantidade_efetivadas}+P:
                              {item.quantidade_propostas_rapidas})
                            </strong>
                          </li>
                          <li>
                            <small className="d-block">Recebíveis</small>
                            <strong
                              className="d-block"
                              title={`R$ ${
                                item.recebiveis
                                  ? item.recebiveis.toLocaleString('pt-BR')
                                  : '0,00'
                              }`}
                            >
                              <small>R$</small>{' '}
                              {this.formatarValor(item.recebiveis)}
                            </strong>
                          </li>
                          <li>
                            <small className="d-block">Em aberto</small>
                            <strong
                              className="d-block"
                              title={`R$ ${
                                item.recebiveis_em_aberto
                                  ? item.recebiveis_em_aberto.toLocaleString(
                                      'pt-BR'
                                    )
                                  : '0,00'
                              }`}
                            >
                              <small>R$</small>{' '}
                              {this.formatarValor(item.recebiveis_em_aberto)}
                            </strong>
                          </li>
                          <li>
                            <small className="d-block">Já baixado</small>
                            <strong
                              className="d-block"
                              title={`R$ ${
                                item.recebiveis_baixados
                                  ? item.recebiveis_baixados.toLocaleString(
                                      'pt-BR'
                                    )
                                  : '0,00'
                              }`}
                            >
                              <small>R$</small>{' '}
                              {this.formatarValor(item.recebiveis_baixados)}
                            </strong>
                          </li>
                          <li>
                            <small className="d-block">VGV Rescisões</small>
                            <strong
                              className="d-block"
                              title={`R$ ${
                                item.rescisoes
                                  ? item.rescisoes.toLocaleString('pt-BR')
                                  : '0,00'
                              }`}
                            >
                              <small>R$</small>{' '}
                              {this.formatarValor(item.rescisoes)}
                            </strong>
                          </li>
                          <li>
                            <small className="d-block">Comissão</small>
                            <strong
                              className="d-block"
                              title={`R$ ${
                                item.comissao_projetada
                                  ? item.comissao_projetada.toLocaleString(
                                      'pt-BR'
                                    )
                                  : '0,00'
                              }`}
                            >
                              <small>R$</small>{' '}
                              {this.formatarValor(item.comissao_projetada)}
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="resumo-vendas d-none"
                    id={`tabelaResumo_${item.codigo}`}
                  >
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>Pré-Venda</Th>
                          <Th>Valor</Th>
                          <Th>Data</Th>
                          <Th>Efetivada</Th>
                          <Th>Quadra</Th>
                          <Th>Lote</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {item.dados_vendas.map((itemDetalhe, index) => (
                          <Tr
                            key={index}
                            className={
                              itemDetalhe.efetivada ? '' : 'text-danger'
                            }
                          >
                            <Td>{itemDetalhe.pre_venda}</Td>
                            <Td>
                              {`R$ ${
                                itemDetalhe.valor
                                  ? itemDetalhe.valor.toLocaleString('pt-BR')
                                  : '0,00'
                              }`}
                            </Td>
                            <Td>
                              {format.date(itemDetalhe.data, 'DD/MM/YYYY')}
                            </Td>
                            <Td>{itemDetalhe.efetivada ? 'Sim' : 'Não'}</Td>
                            <Td>{itemDetalhe.quadra}</Td>
                            <Td>{itemDetalhe.lote}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </Card>
              </Col>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  carregandoProdutivdadeCorretor:
    state.produtividade.carregandoProdutivdadeCorretor,
  corretorSelecionado: state.produtividade.corretorSelecionado,
  carregandoProdutivdadeEmpreendimento:
    state.produtividade.carregandoProdutivdadeEmpreendimento,
  empreendimentoSelecionado: state.produtividade.empreendimentoSelecionado,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      carregaProdutividadePorCorretor,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardDetalhesVenda)
);
