import React from 'react';

import { ContainerCorretores } from '~/Components/Ranking/styles';

import Podium from '~/Components/Ranking/Podium';
import ListaRanking from '~/Components/Ranking/ListaRanking';

import trimestre from '~/Dados/rankingTrimestre.json';

export default function RankingCorretoresTrimestre() {
  const { ranking } = trimestre;
  const podium = ranking.slice(0, 3);
  const restoRanking = ranking.slice(3, ranking.length);

  return (
    <ContainerCorretores>
      <div className="container-fluid">
        <header className="row text-center">
          <div className="col-12">
            <h4 className="mb-0 text-uppercase font-weight-bold">
              Campeões de Vendas
            </h4>
            <p className="text-uppercase">Vendas de estoque</p>
            <h2>2º Trimestre de 2021</h2>
            <Podium corretores={podium} />
          </div>
        </header>

        <ListaRanking ranking={restoRanking} />
      </div>
    </ContainerCorretores>
  );
}
