import React, { Component, Fragment } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _groupBy from 'lodash/groupBy';

import {
  uploadDocumento,
  atualizaDocumento,
  setProspecto,
} from '~/Store/Prospecto/actions';
import CardDocumento from '~/Components/Prospectos/ProspectoDetalhes/CamposDocumentos/CardDocumento';

class CamposDocumentos extends Component {
  state = {
    documentoModal: null,
  };
  static propTypes = {
    documentos: PropTypes.array.isRequired,
    atualizaDocumento: PropTypes.func.isRequired,
    uploadDocumento: PropTypes.func.isRequired,
  };

  setDocumentoModal = documento => {
    this.setState({
      documentoModal: documento,
    });
  };

  onRemove = documento => {
    documento = {
      ...documento,
      old_url: documento.url,
      url: null,
    };

    this.props.atualizaDocumento(documento);
  };

  onSelectImage = (file, documento) => {
    this.props.uploadDocumento(file, documento);
  };

  onThumbCreated = (Thumb, documento) =>
    this.props.atualizaDocumento({ ...documento, Thumb });

  render() {
    const { documentoModal } = this.state;
    const { documentos } = this.props;

    let documentosGrupo = _groupBy(documentos, 'ordem');

    return (
      <div className="CamposDocumentos">
        <div className="alert alert-info border-0">
          <small>Os documentos agora são salvos automaticamente</small>
        </div>
        <small className="d-block text-secondary">
          *arquivos permitidos: PDF de até 500Kb
        </small>
        <Row>
          {Object.keys(documentosGrupo)
            .sort()
            .map((item, index) => {
              const itemDocumento = documentosGrupo[item];

              return (
                <Col xs="12" sm="6" md="4" key={index}>
                  <h4>{itemDocumento[0].tipo}</h4>
                  <hr />
                  {itemDocumento.length > 0 && (
                    <Fragment>
                      <ul className="list-unstyled">
                        {itemDocumento.map((documento, index) => {
                          return (
                            <CardDocumento
                              key={index}
                              documento={documento}
                              onRemove={this.onRemove}
                              onClickDocumento={this.setDocumentoModal}
                              onSelectImage={this.onSelectImage}
                              onThumbCreated={this.onThumbCreated}
                            />
                          );
                        })}
                      </ul>
                    </Fragment>
                  )}
                </Col>
              );
            })}
        </Row>

        {documentoModal && (
          <Modal
            show={documentoModal !== null}
            onHide={() => this.setDocumentoModal(null)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <small>{documentoModal.descricao}</small>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <a
                href={documentoModal.url}
                title="Clique na imagem para visualizar o PDF"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={documentoModal.Thumb}
                  alt={documentoModal.descricao}
                  className="img-fluid"
                />
              </a>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  documentos: state.prospecto.detail.documentos,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadDocumento,
      atualizaDocumento,
      setProspecto,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CamposDocumentos);
