import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { default as Selector } from 'react-select';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { FaSearch } from 'react-icons/fa';
import { Formik } from 'formik';

import { carregarComissoes } from '~/Store/Comissao/actions';
import * as constants from '../../constant';
import DateMask from '../DateMask';

import SelectGerenteCorretor from '../GerentesCorretores/SelectGerenteCorretor';

class CardComissaoFiltro extends Component {
  state = {};

  buscarComissoes = ({
    data_inicial,
    data_final,
    codigo_empreendimento,
    codigo_gerente,
    codigo_corretor,
  }) => {
    let params = {
      data_inicial,
      data_final,
      codigo_empreendimento,
      codigo_gerente,
      codigo_corretor,
    };

    this.props.carregarComissoes(params);
  };

  verificaEmpreendimento = (codigo_empreendimento, values, setFieldValue) => {
    setFieldValue('codigo_empreendimento', codigo_empreendimento);
    let tipo_periodo = values.tipo_periodo;
    if (codigo_empreendimento === '') {
      setFieldValue('tipo_periodo', tipo_periodo);
    }
  };

  buildValueEmpreendimento = codigo => {
    return codigo
      ? {
          value: parseInt(codigo),
          label: this.props.empreendimentos.byId[codigo].descricao,
        }
      : { value: '', label: 'Todos empreendimentos' };
  };

  render() {
    const { empreendimentos, isLoadingOptions } = this.props;

    const optionsEmpreendimentos = [
      { value: '', label: 'Todos empreendimentos' },
      ...empreendimentos.sort.map(item => ({
        value: item.codigo,
        label: item.descricao,
      })),
    ];

    return (
      <Formik onSubmit={this.buscarComissoes} initialValues={{}}>
        {({ handleSubmit, values, setFieldValue, errors, touched }) => (
          <div className="CardProdutividadeFiltro">
            <Col>
              <Card>
                <Card.Body>
                  <form onSubmit={handleSubmit}>
                    <SelectGerenteCorretor
                      disabledCorretor={false}
                      disabledGerente={false}
                      showLabels={true}
                      gerente={values.codigo_gerente}
                      corretor={values.codigo_corretor}
                      ColSizeMd={6}
                      onChangeCorretor={corretor =>
                        setFieldValue('codigo_corretor', corretor)
                      }
                      onChangeGerente={gerente =>
                        setFieldValue('codigo_gerente', gerente)
                      }
                    />
                    <Row className="align-items-end">
                      <Col xs="12" sm="8" lg="6">
                        <Form.Group controlId="search.codigo_empreendimento">
                          <Form.Label>Empreendimento</Form.Label>
                          <Selector
                            noOptionsMessage={() =>
                              'Nenhum empreendimentos encontrado'
                            }
                            placeholder="Pesquisar empreendimentos..."
                            options={optionsEmpreendimentos}
                            onChange={({ value, label }) =>
                              this.verificaEmpreendimento(
                                value,
                                values,
                                setFieldValue
                              )
                            }
                            isLoading={isLoadingOptions}
                            value={this.buildValueEmpreendimento(
                              values.codigo_empreendimento
                            )}
                          />
                        </Form.Group>
                      </Col>

                      <Col xs="12" sm="4" lg="2">
                        <DateMask
                          initialValue={values.data_inicial}
                          allowEmpty
                          onValidate={value =>
                            setFieldValue('data_inicial', value)
                          }
                        >
                          {({ dateError, dateValue, setDate }) => (
                            <>
                              <Form.Label>Data Inicial</Form.Label>
                              <Form.Group controlId="produtividade.data_inicial">
                                <MaskedInput
                                  name="data_inicial"
                                  type="tel"
                                  guide={false}
                                  className={`form-control ${((errors.data_inicial &&
                                    touched.data_inicial) ||
                                    dateError) &&
                                    'is-invalid'}`}
                                  placeholder="Ex.: 01/01/2019"
                                  mask={constants.MASK_DATE}
                                  value={dateValue}
                                  onChange={e => setDate(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.data_inicial}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                  {dateError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </>
                          )}
                        </DateMask>
                      </Col>

                      <Col xs="12" sm="4" lg="2">
                        <DateMask
                          initialValue={values.data_final}
                          allowEmpty
                          onValidate={value =>
                            setFieldValue('data_final', value)
                          }
                        >
                          {({ dateError, dateValue, setDate }) => (
                            <>
                              <Form.Label>Data Final</Form.Label>
                              <Form.Group controlId="produtividade.data_final">
                                <MaskedInput
                                  name="data_final"
                                  type="tel"
                                  guide={false}
                                  className={`form-control ${((errors.data_final &&
                                    touched.data_final) ||
                                    dateError) &&
                                    'is-invalid'}`}
                                  placeholder="Ex.: 01/01/2019"
                                  mask={constants.MASK_DATE}
                                  value={dateValue}
                                  disabled={
                                    values.tipo_periodo ===
                                    constants.TIPO_PERIODO_LANCAMENTO
                                  }
                                  onChange={e => setDate(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.data_final}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                  {dateError}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </>
                          )}
                        </DateMask>
                      </Col>

                      <Col xs="12" sm="6" lg="2" className="text-center btn-sm">
                        <Form.Group controlId="produtividade.tipo_visao_ranking">
                          <Button
                            variant="outline-primary"
                            type="submit"
                            className="ml-auto p-1 col-12"
                            onClick={() =>
                              this.props.history.push(
                                '/comercial/relatorio/comissao'
                              )
                            }
                          >
                            <FaSearch />
                            <span>&nbsp;Gerar Relatório</span>
                          </Button>
                        </Form.Group>
                      </Col>
                    </Row>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </div>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => ({
  empreendimentos: state.empreendimentos,
  isLoadingOptions: state.prospecto.isLoadingOptions,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ carregarComissoes }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CardComissaoFiltro));
