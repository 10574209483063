import React, { useEffect, useState } from 'react';
import { default as Selector } from 'react-select';
import { Card, Row, Col } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _maxBy from 'lodash/maxBy';

import FormPlanoFinanciamento from '../FormPlanoFinanciamento';

import { format, formatarMonetario } from '~/Utils/Helpers.js';
import { BOLETO } from '~/constant';

import {
  calcularSaldoAFinanciar,
  calcularValorFuturo,
  calcularValorPresente,
  reCalcularPlanosFinanciamento,
} from '~/Pages/Vendas/helpers';
import { FaCheck, FaPlus, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import ConfirmButton from '~/Components/ConfirmButton';

export default function FormularioFinanciamento({
  empreendimento,
  ativosDaTabelaSelecionadaPorCodigo,
  listaFormasDePagamento,
  adicionarParcelaFinanciamento,
  ativosSelecionados,
  ativosPorCodigo,
  financiamentos,
  totalTitulosSinal,
  tabelaSelecionada,
  readonly,
  removerTodasParcelasFinanciamento,
  taxaParcelaIntermediaria,
  taxaTabela,
  dataVenda,
}) {
  if (!ativosSelecionados) return null;
  const [planoFinanciamento, setPlanoFinanciamento] = useState(null);
  const [parcelasCondicionantes, setParcelasCondicionantes] = useState([]);
  const [exibirFinanciamento, setExibirFinanciamento] = useState(true);
  const [parcelasEmSeriesEscadinha, setParcelasEmSeriesEscadinha] = useState(
    []
  );
  const [
    possuiParcelasFinanciamento,
    setPossuiParcelasFinanciamento,
  ] = useState([]);
  const [diaVencimento, setDiaVencimento] = useState(10);
  const [ultimaParcelaEscadinhas, setUltimaParcelaEscadinhas] = useState(null);

  const beginPlanoFinanciamento = (parcelas, codigoAtivo, planos) => {
    const plano = planos.find(plano => plano.parcelas === parcelas);

    setPlanoFinanciamento({
      valor: plano ? plano.valor : '',
      parcelas: plano ? plano.parcelas : '',
      codigoAtivo,
      vencimento:
        ultimaParcelaEscadinhas || sugerirDiaVencimento().format('DD/MM/YYYY'),
    });
  };

  const beginCalcularParcelasCondicionantes = (
    codigoCondicionante,
    codigoAtivo,
    saldoAFinanciar,
    totalFinanciamento
  ) => {
    if (totalFinanciamento > 0) return;
    var parcelaIntermediaria = empreendimento.intermediarias.find(
      x => x.codigo === codigoCondicionante
    );

    var ativoSelecionado = ativosDaTabelaSelecionadaPorCodigo[codigoAtivo];
    var taxaPI = parseFloat(taxaParcelaIntermediaria) / parseFloat(100);
    var valorDaVenda = ativoSelecionado.valor_venda;
    var saldoFinRestante = saldoAFinanciar;

    //CONSTRUÇÃO
    var mesesConstrucao = parcelaIntermediaria.meses_construcao;
    var vencimentoConstrucao = moment()
      .date(20)
      .add(mesesConstrucao, 'months')
      .format('YYYY-MM-DD');

    if (parcelaIntermediaria.percentual_construcao > 0) {
      var descontoConstrucao =
        valorDaVenda * (parcelaIntermediaria.percentual_construcao / 100);
      var valorConstrucaoComJuros = calcularValorFuturo(
        descontoConstrucao,
        taxaParcelaIntermediaria,
        moment(dataVenda).toDate(),
        moment(vencimentoConstrucao).toDate()
      );
      saldoFinRestante = saldoFinRestante - descontoConstrucao;
    }

    //ADIMPLENCIA
    var mesesAdimplencia = parcelaIntermediaria.meses_adimplencia;
    var vencimentoAdimplencia = moment()
      .date(20)
      .add(mesesAdimplencia, 'months')
      .format('YYYY-MM-DD');
    if (parcelaIntermediaria.percentual_adimplencia > 0) {
      var descontoAdimplencia =
        valorDaVenda * (parcelaIntermediaria.percentual_adimplencia / 100);
      var valorAdimplenciaComJuros = calcularValorFuturo(
        descontoAdimplencia,
        taxaParcelaIntermediaria,
        moment(dataVenda).toDate(),
        moment(vencimentoAdimplencia).toDate()
      );
      saldoFinRestante = saldoFinRestante - descontoAdimplencia;
    }

    console.log(
      'CONDICIONANTES PARA O ATIVO: ' + codigoAtivo,
      ' | Taxa: ' + taxaParcelaIntermediaria,
      ' | Taxa dividida: ' + taxaPI,
      ' | valorDaVenda: ' + valorDaVenda,
      ' | saldoFinRestante: ' + saldoFinRestante,
      '>>>> CONSTRUÇÃO >>>>',
      ', descontoConstrucao: ' + descontoConstrucao,
      ', valorConstrucaoComJuros: ' + valorConstrucaoComJuros,
      ', saldoFinRestante: ' + saldoFinRestante,
      ', mesesConstrucao: ' + mesesConstrucao,
      ', vencimentoConstrucao: ' + vencimentoConstrucao,
      '>>>> ADIMPLENCIA >>>>',
      ', descontoAdimplencia: ' + descontoAdimplencia,
      ', valorAdimplenciaComJuros: ' + valorAdimplenciaComJuros,
      ', saldoFinRestante: ' + saldoFinRestante,
      ', mesesAdimplencia' + mesesAdimplencia,
      ', vencimentoAdimplencia: ' + vencimentoAdimplencia
    );

    const condicionanteAtual = {
      descricao: parcelaIntermediaria.descricao,
      codigoCondicionante,
      valorConstrucao: descontoConstrucao,
      valorConstrucaoComJuros,
      valorAdimplencia: descontoAdimplencia,
      valorAdimplenciaComJuros,
      parcelas: 1,
      vencimentoConstrucao,
      vencimentoAdimplencia,
      codigoAtivo,
      taxa: taxaPI,
      condicaoAdimplenciaAplicada: false,
      condicaoConstrucaoAplicada: false,
    };

    const index = parcelasCondicionantes.findIndex(
      item => item.codigoAtivo === codigoAtivo
    );
    if (index !== -1) {
      // Objeto já existe na lista, faz o merge dos dados
      setParcelasCondicionantes(prevParcelas => {
        const novaLista = [...prevParcelas];
        novaLista[index] = { ...novaLista[index], ...condicionanteAtual };
        return novaLista;
      });
    } else {
      // Objeto não existe na lista, adiciona na lista
      setParcelasCondicionantes(prevParcelas => [
        ...prevParcelas,
        condicionanteAtual,
      ]);
    }
  };

  const criarParcelasFinanciamento = values => {
    const { parcelas, valor, vencimento, codigoAtivo } = values;

    const saldoAFinanciar = calcularSaldoAFinanciar(
      ativosDaTabelaSelecionadaPorCodigo[codigoAtivo],
      ativosDaTabelaSelecionados,
      totalTitulosSinal
    );

    const condicionantesPorAtivo = obterParcelasCondicionantesPorCodigoAtivo(
      codigoAtivo
    );

    const valorConstrucao =
      condicionantesPorAtivo &&
      condicionantesPorAtivo.condicaoConstrucaoAplicada
        ? condicionantesPorAtivo.valorConstrucao
        : 0;

    const valorAdimplencia =
      condicionantesPorAtivo &&
      condicionantesPorAtivo.condicaoAdimplenciaAplicada
        ? condicionantesPorAtivo.valorAdimplencia
        : 0;

    const saldoAFinanciarRestante =
      saldoAFinanciar - (valorConstrucao + valorAdimplencia);

    const valorPresente = saldoAFinanciarRestante / parcelas;

    adicionarParcelaFinanciamento(
      codigoAtivo,
      valorPresente,
      valor,
      vencimento,
      BOLETO,
      parcelas
    );

    setPossuiParcelasFinanciamento([
      ...possuiParcelasFinanciamento,
      codigoAtivo,
    ]);

    setPlanoFinanciamento(null);
  };

  const criarParcelasIntermediarias = (codigoAtivo, tipoConstrucao) => {
    var tipoBalao = tipoConstrucao ? 1 : 2;
    const condicionantesPorAtivo = obterParcelasCondicionantesPorCodigoAtivo(
      codigoAtivo
    );

    var valor = tipoConstrucao
      ? condicionantesPorAtivo.valorConstrucaoComJuros
      : condicionantesPorAtivo.valorAdimplenciaComJuros;

    var valorPresente = tipoConstrucao
      ? condicionantesPorAtivo.valorConstrucao
      : condicionantesPorAtivo.valorAdimplencia;

    var vencimento = tipoConstrucao
      ? condicionantesPorAtivo.vencimentoConstrucao
      : condicionantesPorAtivo.vencimentoAdimplencia;
    var parcelas = 1;

    adicionarParcelaFinanciamento(
      codigoAtivo,
      valorPresente,
      valor,
      vencimento,
      condicionantesPorAtivo.codigoCondicionante,
      parcelas,
      0, //numeroDaParcela
      false, //tipoParcelaEscadinha
      tipoBalao,
      condicionantesPorAtivo.taxa
    );

    setParcelasCondicionantes(prevParcelas => {
      return prevParcelas.map(item => {
        if (item.codigoAtivo === codigoAtivo) {
          return {
            ...item,
            condicaoAdimplenciaAplicada: !tipoConstrucao
              ? true
              : item.condicaoAdimplenciaAplicada,
            condicaoConstrucaoAplicada: tipoConstrucao
              ? true
              : item.condicaoConstrucaoAplicada,
          };
        }
        return item;
      });
    });
  };

  const limparFinanciamento = codigoAtivo => {
    setParcelasCondicionantes(prevParcelas => {
      return prevParcelas.filter(item => item.codigoAtivo !== codigoAtivo);
    });

    removerTodasParcelasFinanciamento(codigoAtivo);
    const novaLista = possuiParcelasFinanciamento.filter(
      codigo => codigo !== codigoAtivo
    );
    setPossuiParcelasFinanciamento(novaLista);
    setUltimaParcelaEscadinhas(null);
    setExibirFinanciamento(!parcelasEmSeriesEscadinha[codigoAtivo]);
  };

  const ativosDaTabelaSelecionados = Object.values(
    ativosDaTabelaSelecionadaPorCodigo
  ).filter(ativo => ativosSelecionados.includes(ativo.codigo));

  const obterParcelasCondicionantesPorCodigoAtivo = codigoAtivo => {
    return parcelasCondicionantes.find(
      item => item.codigoAtivo === codigoAtivo
    );
  };

  const montarOpcoesParcelasIntemediarias = () => {
    return empreendimento.intermediarias.map(({ codigo, descricao }) => ({
      value: codigo,
      label: descricao,
    }));
  };

  useEffect(() => {
    if (!readonly) {
      ativosSelecionados.map(codigoAtivo => {
        limparFinanciamento(codigoAtivo);
        return null;
      });
    }
    montarObjetoCondicionanteReadOnly();
    montarListaDeParcelasEmSeriesPorAtivoSelecionado();
  }, []);

  const montarObjetoCondicionanteReadOnly = () => {
    if (!readonly) return;
    ativosSelecionados.map(codigoAtivo => {
      const financiamentoAtivo =
        (financiamentos[codigoAtivo] || {}).parcelasFinanciamento || [];

      const parcelasIntermediarias = financiamentoAtivo.filter(
        f => f.periodicidade_balao
      );
      if (!parcelasIntermediarias.length) return null;

      const parcelaIntermediariaEncontrada = empreendimento.intermediarias.find(
        i => i.codigo === parcelasIntermediarias[0].codigo_intermediaria
      );
      if (!parcelaIntermediariaEncontrada) return null;

      let condicionantePorAtivo = {};

      parcelasIntermediarias.forEach(parcela => {
        if (parcela.periodicidade_balao === 1) {
          condicionantePorAtivo.valorConstrucao = parcela.valor_presente;
          condicionantePorAtivo.valorConstrucaoComJuros = parcela.valor;
          condicionantePorAtivo.vencimentoConstrucao = parcela.vencimento;
          condicionantePorAtivo.condicaoConstrucaoAplicada = true;
          //desativa o oposto
          condicionantePorAtivo.valorAdimplencia = '0,00';
          condicionantePorAtivo.valorAdimplenciaComJuros = '0,00';
          condicionantePorAtivo.condicaoAdimplenciaAplicada = false;
        } else {
          condicionantePorAtivo.valorAdimplencia = parcela.valor_presente;
          condicionantePorAtivo.valorAdimplenciaComJuros = parcela.valor;
          condicionantePorAtivo.vencimentoAdimplencia = parcela.vencimento;
          condicionantePorAtivo.condicaoAdimplenciaAplicada = true;
          //desativa o oposto
          condicionantePorAtivo.valorConstrucao = '0,00';
          condicionantePorAtivo.valorConstrucaoComJuros = '0,00';
          condicionantePorAtivo.condicaoConstrucaoAplicada = false;
        }
      });
      condicionantePorAtivo.descricao =
        parcelaIntermediariaEncontrada.descricao;
      condicionantePorAtivo.codigoCondicionante =
        parcelaIntermediariaEncontrada.codigo;
      condicionantePorAtivo.codigoAtivo = codigoAtivo;
      condicionantePorAtivo.parcelas = 1;

      setParcelasCondicionantes(prevParcelas => [
        ...prevParcelas,
        condicionantePorAtivo,
      ]);

      setPossuiParcelasFinanciamento([
        ...possuiParcelasFinanciamento,
        codigoAtivo,
      ]);
      return null;
    });
  };

  const ultimoVencimentoSinal = moment(
    _maxBy(listaFormasDePagamento, 'vencimento').vencimento
  );

  const sugerirDiaVencimento = () => {
    let diaVenc = ultimoVencimentoSinal.format('D');

    if (diaVenc <= 5) diaVenc = 5;
    else if (diaVenc > 5 && diaVenc <= 10) diaVenc = 10;
    else if (diaVenc > 10 && diaVenc <= 15) diaVenc = 15;
    else if (diaVenc > 15 && diaVenc <= 20) diaVenc = 20;
    else if (diaVenc > 20) diaVenc = 25;

    return moment(ultimoVencimentoSinal.add(1, 'M')).set('date', diaVenc);
  };

  const montarListaDeParcelasEmSeriesPorAtivoSelecionado = () => {
    if (readonly || taxaTabela === 0) return;

    const novasParcelasEmSeriesEscadinha = {};
    const diferencaMeses = Math.round(
      ultimoVencimentoSinal.diff(moment(), 'months', true)
    );
    ativosSelecionados.forEach(ativoSelecionado => {
      const ativoEscadinha = ativosPorCodigo[ativoSelecionado].ativo_escadinha;
      if (!ativoEscadinha) return;

      const planos =
        ativosDaTabelaSelecionadaPorCodigo[ativoSelecionado].planos;
      const maiorParcela = planos[planos.length - 1].parcelas;
      const totalParcelasProgramadas =
        ativoEscadinha.parcelas_por_serie * ativoEscadinha.quantidade_de_series;
      if (maiorParcela <= totalParcelasProgramadas) return;

      let seriesEscadinha = [];
      let vencimentoInicialSerie = sugerirDiaVencimento();
      let valorSerie = ativoEscadinha.parcela_inicial;

      for (let i = 0; i < ativoEscadinha.quantidade_de_series; i++) {
        const parcelas =
          i === 0
            ? ativoEscadinha.parcelas_por_serie - diferencaMeses
            : ativoEscadinha.parcelas_por_serie;

        seriesEscadinha.push({
          valorSerie,
          vencimento: vencimentoInicialSerie.format('YYYY-MM-DD'),
          parcelas,
        });
        valorSerie += ativoEscadinha.degrau;
        vencimentoInicialSerie.add(parcelas, 'months');
      }

      novasParcelasEmSeriesEscadinha[ativoSelecionado] = seriesEscadinha;
    });

    setParcelasEmSeriesEscadinha(prevSeries => ({
      ...prevSeries,
      ...novasParcelasEmSeriesEscadinha,
    }));

    setExibirFinanciamento(
      Object.keys(novasParcelasEmSeriesEscadinha).length === 0
    );
  };

  const incluirParcelasProgramadasPorAtivo = codigoAtivo => {
    const seriesDoAtivo = parcelasEmSeriesEscadinha[codigoAtivo];
    var vencimentoDaParcela;
    var numeroDaParcela = 1;
    const taxa = taxaTabela;

    seriesDoAtivo.forEach(serie => {
      vencimentoDaParcela = moment(serie.vencimento).set('date', diaVencimento);
      for (let i = 1; i <= serie.parcelas; i++) {
        const valorPresente = calcularValorPresente(
          serie.valorSerie,
          taxa,
          moment(dataVenda).toDate(),
          vencimentoDaParcela.toDate()
        );

        const vencimento = vencimentoDaParcela;
        adicionarParcelaFinanciamento(
          codigoAtivo,
          valorPresente,
          serie.valorSerie,
          vencimento.format('YYYY-MM-DD'),
          BOLETO,
          1,
          numeroDaParcela,
          true //tipoParcelaEscadinha
        );
        numeroDaParcela++;
        vencimentoDaParcela.add(1, 'months');
      }
    });

    setUltimaParcelaEscadinhas(vencimentoDaParcela.format('DD/MM/YYYY'));
    setExibirFinanciamento(true);
  };

  const prosseguirSemParcelasProgramadas = codigoAtivo => {
    setExibirFinanciamento(true);
  };

  const calcularSaldoAFinanciarRestante = (
    parcelasEmSerie,
    saldoAFinanciar,
    valorConstrucao,
    valorAdimplencia
  ) => {
    const somaParcelasVPEmSerie = parcelasEmSerie.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );

    const descontos = valorConstrucao + valorAdimplencia;
    console.log('Total VP PP: ', somaParcelasVPEmSerie);
    console.log('Total PIs: ', descontos);
    const saldoAFinanciarRestante = saldoAFinanciar - descontos;
    if (somaParcelasVPEmSerie > 0) {
      const ultimoVencimentoEmSerie =
        parcelasEmSerie[parcelasEmSerie.length - 1].vencimento;

      const saldoAFinanciarParaProjetar =
        saldoAFinanciarRestante - somaParcelasVPEmSerie;

      console.log('saldoAFinanciarParaProjetar: ', saldoAFinanciarParaProjetar);
      const saldoAFinanciarFuturo = calcularValorFuturo(
        saldoAFinanciarParaProjetar,
        taxaTabela,
        moment(dataVenda).toDate(),
        moment(ultimoVencimentoEmSerie)
          .add(1, 'days')
          .toDate()
      );

      console.log('>>>>saldoAFinanciarFuturo: ', saldoAFinanciarFuturo);
      return saldoAFinanciarFuturo;
    } else {
      return saldoAFinanciarRestante;
    }
  };

  const montarLabelGridParcela = (
    parcela,
    quantidadeParcelasFinanciamentoRegular,
    quantidadeParcelasEmSeries
  ) => {
    if (parcela.periodicidade_balao) return '1/1';
    else if (parcela.tipo_escadinha) {
      return `${parcela.parcela} / ${quantidadeParcelasEmSeries}`;
    } else
      return `${parcela.parcela} / ${quantidadeParcelasFinanciamentoRegular}`;
  };

  const calcularSaldoRestanteExibicao = (
    saldoAFinanciar,
    parcelasFinanciamentoRegular,
    saldoAFinanciarRestante,
    parcelasEmSerie,
    valorConstrucao,
    valorAdimplencia
  ) => {
    console.log('<<<DADOS PARA RECALCULAR SALDO RESTANTE DE EXIBIÇÃO>>>');
    console.log('Saldo a Financiar original: ', saldoAFinanciar);
    console.log('Saldo a Financiar real', saldoAFinanciarRestante);
    console.log('PI Construcao  aplicado', valorConstrucao);
    console.log('PI Adimplencia aplicado', valorAdimplencia);

    const somaParcelasVPFinanciamento = parcelasFinanciamentoRegular.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );

    const somaParcelasVPEmSerie = parcelasEmSerie.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );

    console.log('somaParcelasVPFinanciamento', somaParcelasVPFinanciamento);
    console.log('somaParcelasVPEmSerie', somaParcelasVPEmSerie);
    const total =
      saldoAFinanciarRestante -
      somaParcelasVPFinanciamento -
      somaParcelasVPEmSerie;
    if (total <= 0) return '0,00';
    else if (parcelasEmSerie.length > 0) {
      const totalSerie =
        saldoAFinanciar -
        somaParcelasVPEmSerie -
        valorConstrucao -
        valorAdimplencia;
      if (totalSerie <= 0) return '0,00';
      console.log('Saldo a financiar a ser exibido', totalSerie);
      return totalSerie;
    }
    console.log('Saldo a financiar a ser exibido', total);
    return total;
  };

  return (
    <>
      {ativosSelecionados &&
        ativosSelecionados.map((codigo, index) => {
          const ativoDaTabela = ativosDaTabelaSelecionadaPorCodigo[codigo];
          const financiamentoAtivo =
            (financiamentos[codigo] || {}).parcelasFinanciamento || [];
          const condicionantesPorAtivo = obterParcelasCondicionantesPorCodigoAtivo(
            codigo
          );

          const totalFinanciamento = financiamentoAtivo.reduce(
            (total, parcela) => total + parcela.valor,
            0
          );

          const parcelasFinanciamentoRegular = financiamentoAtivo.filter(
            x => !x.periodicidade_balao && !x.tipo_escadinha
          );

          const valorConstrucao =
            condicionantesPorAtivo &&
            condicionantesPorAtivo.condicaoConstrucaoAplicada
              ? condicionantesPorAtivo.valorConstrucao
              : 0;

          const valorAdimplencia =
            condicionantesPorAtivo &&
            condicionantesPorAtivo.condicaoAdimplenciaAplicada
              ? condicionantesPorAtivo.valorAdimplencia
              : 0;

          const saldoAFinanciar = calcularSaldoAFinanciar(
            ativoDaTabela,
            ativosDaTabelaSelecionados,
            totalTitulosSinal
          );

          const parcelasEmSerie = financiamentoAtivo.filter(
            parcela => parcela.tipo_escadinha
          );

          const saldoAFinanciarRestante = calcularSaldoAFinanciarRestante(
            parcelasEmSerie,
            saldoAFinanciar,
            valorConstrucao,
            valorAdimplencia
          );

          const saldoRestanteExibicao = calcularSaldoRestanteExibicao(
            saldoAFinanciar,
            parcelasFinanciamentoRegular,
            saldoAFinanciarRestante,
            parcelasEmSerie,
            valorConstrucao,
            valorAdimplencia
          );

          const planosRecalculados = reCalcularPlanosFinanciamento(
            ativoDaTabela.planos,
            saldoAFinanciarRestante,
            tabelaSelecionada
          );

          const optionsPlanos = planosRecalculados.map(
            ({ parcelas, valor }) => ({
              value: parcelas,
              label: `${parcelas} parcelas de ${formatarMonetario(valor)}`,
            })
          );

          optionsPlanos.push({
            value: 0,
            label: 'Plano específico',
          });

          const optionsIntermediarias = montarOpcoesParcelasIntemediarias();

          const condicaoAplicada =
            condicionantesPorAtivo &&
            (condicionantesPorAtivo.condicaoConstrucaoAplicada ||
              condicionantesPorAtivo.condicaoAdimplenciaAplicada);

          return (
            <div className="mb-2" key={codigo}>
              <Card className="mt-1 border-0" key="index">
                <Card.Body className="p-2">
                  <span>
                    Quadra{' '}
                    <strong className="text-dark">
                      {ativosPorCodigo[codigo].quadra}
                    </strong>{' '}
                    / Lote{' '}
                    <strong className="text-dark">
                      {ativosPorCodigo[codigo].lote}
                    </strong>
                  </span>

                  {empreendimento.intermediarias.length > 0 && (
                    <Row>
                      <Col>
                        <strong className="mt-2">
                          Parcelas condicionantes
                        </strong>
                        <Row className="mt-2">
                          <Col md="12">
                            <small className="mt-2">Condições</small>
                            {possuiParcelasFinanciamento.includes(codigo) ||
                            condicaoAplicada ? (
                              <span className="d-block">
                                {condicionantesPorAtivo
                                  ? condicionantesPorAtivo.descricao
                                  : 'NENHUMA CONDICIONANTE INFORMADA'}
                              </span>
                            ) : (
                              <Selector
                                noOptionsMessage={() =>
                                  'Nenhuma condicionante encontrada'
                                }
                                placeholder="Selecione uma condicionante..."
                                className="mb-2"
                                options={optionsIntermediarias}
                                onChange={option => {
                                  beginCalcularParcelasCondicionantes(
                                    option.value,
                                    codigo,
                                    saldoAFinanciar,
                                    totalFinanciamento
                                  );
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        {condicionantesPorAtivo && (
                          <Row>
                            {condicionantesPorAtivo.valorAdimplencia > 0 && (
                              <Col md="6" className="pr-0">
                                <div className="border p-3">
                                  <button
                                    type="button"
                                    className={`btn btn-sm btn-outline-${
                                      condicionantesPorAtivo.condicaoAdimplenciaAplicada
                                        ? 'success'
                                        : 'primary'
                                    } text-uppercase w-100`}
                                    onClick={() =>
                                      criarParcelasIntermediarias(codigo, false)
                                    }
                                    disabled={
                                      readonly ||
                                      condicionantesPorAtivo.condicaoAdimplenciaAplicada ||
                                      possuiParcelasFinanciamento.includes(
                                        codigo
                                      )
                                    }
                                  >
                                    {condicionantesPorAtivo.condicaoAdimplenciaAplicada ? (
                                      <>
                                        <FaCheck className="mr-2" />
                                      </>
                                    ) : (
                                      <>
                                        <FaPlus className="mr-2" />
                                      </>
                                    )}
                                    Adimplência
                                  </button>

                                  <p className="my-1">
                                    <small className="d-block">
                                      Valor descontado
                                    </small>
                                    <strong className="text-success">
                                      {formatarMonetario(
                                        condicionantesPorAtivo.valorAdimplencia
                                      )}
                                    </strong>
                                  </p>
                                  <div className="text-warning d-flex justify-content-between">
                                    <div>
                                      <small className="d-block">
                                        Valor projetado
                                      </small>
                                      {formatarMonetario(
                                        condicionantesPorAtivo.valorAdimplenciaComJuros
                                      )}
                                    </div>
                                    <div>
                                      <small className="d-block">
                                        Vencimento
                                      </small>
                                      {format.date(
                                        condicionantesPorAtivo.vencimentoAdimplencia
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                            {condicionantesPorAtivo.valorConstrucao > 0 && (
                              <Col md="6" className="pl-0">
                                <div className="border p-3">
                                  <button
                                    type="button"
                                    className={`btn btn-sm btn-outline-${
                                      condicionantesPorAtivo.condicaoConstrucaoAplicada
                                        ? 'success'
                                        : 'primary'
                                    } text-uppercase w-100`}
                                    onClick={() =>
                                      criarParcelasIntermediarias(codigo, true)
                                    }
                                    disabled={
                                      readonly ||
                                      condicionantesPorAtivo.condicaoConstrucaoAplicada ||
                                      possuiParcelasFinanciamento.includes(
                                        codigo
                                      )
                                    }
                                  >
                                    {condicionantesPorAtivo.condicaoConstrucaoAplicada ? (
                                      <>
                                        <FaCheck className="mr-2" />
                                      </>
                                    ) : (
                                      <>
                                        <FaPlus className="mr-2" />
                                      </>
                                    )}
                                    Construção
                                  </button>
                                  <p className="my-1">
                                    <small className="d-block">
                                      Valor descontado
                                    </small>
                                    <strong className="text-success">
                                      {formatarMonetario(
                                        condicionantesPorAtivo.valorConstrucao
                                      )}
                                    </strong>
                                  </p>

                                  <div className="text-warning d-flex justify-content-between">
                                    <div>
                                      <small className="d-block">
                                        Valor projetado
                                      </small>
                                      {formatarMonetario(
                                        condicionantesPorAtivo.valorConstrucaoComJuros
                                      )}
                                    </div>
                                    <div>
                                      <small className="d-block">
                                        Vencimento
                                      </small>
                                      {format.date(
                                        condicionantesPorAtivo.vencimentoConstrucao
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Col>
                      <strong className="mt-2 d-block">
                        Parcelas do financiamento
                      </strong>
                      <Row className="my-2 align-items-center">
                        <Col md="4">
                          <p className="m-0">
                            <small className="d-block">Saldo a financiar</small>
                            {formatarMonetario(saldoAFinanciar)}
                          </p>
                        </Col>
                        <Col md="4">
                          <p className="m-0">
                            <small className="d-block">Saldo restante</small>
                            {formatarMonetario(saldoRestanteExibicao)}
                          </p>
                        </Col>
                        <Col md="4">
                          <p className="m-0">
                            <small className="d-block">
                              Valor total do financiamento
                            </small>
                            {formatarMonetario(totalFinanciamento, false)}
                          </p>
                        </Col>
                      </Row>
                      {!readonly && parcelasEmSeriesEscadinha[codigo] && (
                        <div>
                          <hr />
                          <h4 className="text-center">Parcelas Programadas</h4>
                          <Row>
                            {exibirFinanciamento && ultimaParcelaEscadinhas && (
                              <Col md="12">
                                <p className="text-center text-success">
                                  O cliente optou em utilizar as parcelas
                                  programadas
                                </p>
                              </Col>
                            )}
                            {exibirFinanciamento && !ultimaParcelaEscadinhas && (
                              <Col md="12">
                                <p className="text-center text-danger">
                                  O cliente optou em não utilizar as parcelas
                                  programadas
                                </p>
                              </Col>
                            )}
                            {!exibirFinanciamento && (
                              <>
                                <Col md="4">
                                  <small className="d-block">
                                    Vencimento do financiamento
                                  </small>
                                  <Selector
                                    className="mb-2"
                                    options={[
                                      { value: 5, label: 'Dia 5' },
                                      { value: 10, label: 'Dia 10' },
                                      { value: 15, label: 'Dia 15' },
                                      { value: 20, label: 'Dia 20' },
                                      { value: 25, label: 'Dia 25' },
                                    ]}
                                    placeholder="Vencimento..."
                                    defaultValue={{
                                      label: 'Dia 10',
                                      value: 10,
                                    }}
                                    onChange={option => {
                                      setDiaVencimento(option.value);
                                    }}
                                    isDisabled={exibirFinanciamento}
                                  />
                                </Col>
                                <Col md="4">
                                  <small className="d-block">&nbsp;</small>
                                  <button
                                    type="button"
                                    className="btn btn-success w-100"
                                    onClick={() =>
                                      incluirParcelasProgramadasPorAtivo(codigo)
                                    }
                                    disabled={exibirFinanciamento}
                                  >
                                    Incluir
                                  </button>
                                </Col>
                                <Col md="4">
                                  <small className="d-block">&nbsp;</small>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger w-100"
                                    onClick={() =>
                                      prosseguirSemParcelasProgramadas(codigo)
                                    }
                                    disabled={exibirFinanciamento}
                                  >
                                    Não incluir
                                  </button>
                                </Col>
                              </>
                            )}
                          </Row>
                          <hr className="mt-2" />
                        </div>
                      )}

                      {!readonly &&
                        exibirFinanciamento &&
                        parcelasFinanciamentoRegular.length === 0 && (
                          <>
                            <small className="mt-2">Formas de pagamentos</small>
                            <Selector
                              noOptionsMessage={() => 'Nenhum plano encontrado'}
                              placeholder="Selecione um plano..."
                              className="mb-2"
                              options={optionsPlanos}
                              onChange={option => {
                                beginPlanoFinanciamento(
                                  option.value,
                                  codigo,
                                  planosRecalculados
                                );
                              }}
                            />
                          </>
                        )}

                      {planoFinanciamento &&
                        exibirFinanciamento &&
                        planoFinanciamento.codigoAtivo === codigo && (
                          <FormPlanoFinanciamento
                            onCancel={() => setPlanoFinanciamento(null)}
                            onConfirm={criarParcelasFinanciamento}
                            initialValues={planoFinanciamento}
                            parcelasAtuais={parcelasFinanciamentoRegular}
                            saldoAFinanciar={saldoAFinanciarRestante}
                            tabela={tabelaSelecionada}
                            vencimentoPlanejado={sugerirDiaVencimento().format(
                              'DD/MM/YYYY'
                            )}
                            vencimentoObrigatorioEscadinha={
                              ultimaParcelaEscadinhas
                            }
                          />
                        )}
                    </Col>
                  </Row>

                  {financiamentoAtivo.length > 0 && (
                    <>
                      <Row>
                        {!readonly && (
                          <Col className="ml-lg-auto d-flex justify-content-end">
                            <ConfirmButton
                              variant="outline-danger"
                              confirmTitle="Remover tudo?"
                              confirmText="Deseja remover todas as parcelas do financiamento?"
                              onConfirm={() => limparFinanciamento(codigo)}
                              className="btn-sm d-flex align-items-center text-uppercase mr-2"
                            >
                              <FaTrash className="mr-2" />
                              Limpar
                            </ConfirmButton>
                          </Col>
                        )}
                      </Row>

                      <Row className="mt-2">
                        <Col className="my-auto">
                          <small>Resumo das parcelas</small>
                          <PerfectScrollbar
                            style={{ maxHeight: '200px' }}
                            className="border"
                          >
                            <Table className="table table-striped table-sm table-bordeless">
                              <Thead>
                                <Tr>
                                  <Th>Parcela</Th>
                                  <Th>Tipo</Th>
                                  <Th>Vencimento</Th>
                                  <Th colSpan={2}>Valor</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {financiamentoAtivo.map((item, index) => (
                                  <Tr key={index}>
                                    <Td>
                                      {montarLabelGridParcela(
                                        item,
                                        parcelasFinanciamentoRegular.length,
                                        parcelasEmSerie.length
                                      )}
                                    </Td>
                                    <Td>
                                      {item.periodicidade_balao
                                        ? 'Balão'
                                        : 'Financ.'}
                                    </Td>
                                    <Td>{format.date(item.vencimento)}</Td>
                                    <Td>{formatarMonetario(item.valor)}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>
          );
        })}
    </>
  );
}
