import React from 'react';

import { Container, Step } from './styles';

export default function WizardSteps({ steps, current, complete, onClick }) {
  return (
    <Container className="d-flex">
      <ul className="navbar-nav CounterStep__nav mx-auto">
        {steps.map((item, index) => (
          <Step
            key={index}
            isComplete={complete.includes(item)}
            isCurrent={item === current}
            onClick={() => {
              complete.includes(item) && onClick(item);
            }}
          >
            <span className="CounterStep__item__number">{++index}</span>
            <span className="CounterStep__item__label">
              {item} {item === current && <small>Você está aqui</small>}
            </span>
          </Step>
        ))}
      </ul>
    </Container>
  );
}
