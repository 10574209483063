import styled, { css } from 'styled-components';
import { successColor, warningColor, primaryColor } from '~/Styles';

export const Container = styled.div`
  .CounterStep__nav {
    width: 100%;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;

    @media (min-width: 992px) {
      width: auto;
      display: block;
    }
  }
`;

export const Step = styled.li`
  display: inline-flex;
  user-select: none;
  cursor: default;
  margin: 0px 8px 12px;
  border-radius: 2rem;
  line-height: 1.7;
  background: #ebebeb;
  color: #999;
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;

  @media (min-width: 768px) {
    padding: 0.2rem 0.5rem;
    line-height: 1;
  }

  @media (min-width: 992px) {
    width: auto;
    margin-bottom: 0;
  }

  .CounterStep__item__label {
    position: absolute;
    top: auto;
    bottom: -15px;
    width: 100%;
    left: 0;
    text-align: center;
    font-size: 0.5rem;

    small {
      min-width: 80px;
      position: absolute;
      top: 140%;
      left: -10px;
      font-size: 0.6rem;
      right: -10px;
      background: ${warningColor};
      border-radius: 10px;
      padding: 2px 3px;
      color: #000;
      line-height: 1;
      &::before {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid ${warningColor};
        margin-bottom: 0 - ${Math.round(6 / 2.5)}px;
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        bottom: 100%;
        left: 50%;
        margin-left: -3px;
      }
    }

    @media (min-width: 768px) {
      position: relative;
      bottom: auto;
      font-size: 0.7rem;
    }
    @media (min-width: 992px) {
      font-size: 0.9rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .CounterStep__item__number {
    display: inline-block;
    font-size: 1rem;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;

    @media (min-width: 576px) {
      width: 2rem;
      line-height: 1.8;
    }
  }

  ${props =>
    props.isComplete &&
    css`
      background-color: ${successColor};
      color: ${successColor};

      .CounterStep__item__number {
        background-size: 30%;
        background-repeat: no-repeat;
        background-position: 50%;
        background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNNTA0LjUwMiw3NS40OTZjLTkuOTk3LTkuOTk4LTI2LjIwNS05Ljk5OC0zNi4yMDQsMEwxNjEuNTk0LDM4Mi4yMDNMNDMuNzAyLDI2NC4zMTFjLTkuOTk3LTkuOTk4LTI2LjIwNS05Ljk5Ny0zNi4yMDQsMCAgICBjLTkuOTk4LDkuOTk3LTkuOTk4LDI2LjIwNSwwLDM2LjIwM2wxMzUuOTk0LDEzNS45OTJjOS45OTQsOS45OTcsMjYuMjE0LDkuOTksMzYuMjA0LDBMNTA0LjUwMiwxMTEuNyAgICBDNTE0LjUsMTAxLjcwMyw1MTQuNDk5LDg1LjQ5NCw1MDQuNTAyLDc1LjQ5NnoiIGZpbGw9IiNGRkZGRkYiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
        text-indent: 900px;
        overflow: hidden;
        position: relative;
      }

      @media (min-width: 768px) {
        &,
        .CounterStep__item__label {
          color: #fff;
        }
        .CounterStep__item__number {
          top: auto;
          background-size: 40%;
        }
      }
    `}

  ${props =>
    props.isCurrent &&
    css`
      background: transparent;
      box-shadow: inset 0 0 0 2px ${primaryColor};

      &,
      .CounterStep__item__label {
        color: ${primaryColor};
      }
    `}

  @media (min-width: 768px) {
    height: auto;
    padding: 8px 12px;

    .CounterStep__item__number,
    .CounterStep__item__label {
      line-height: 1;
      top: 0;
      font-size: 1rem;
      padding: 0;
    }
  }
`;
