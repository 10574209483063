import _ from 'lodash';
import { formatarMonetario } from '~/Utils/Helpers';

export const agruparPorCpfCliente = registros => {
  return _(registros)
    .groupBy(reg => reg.cpf)
    .map(values => ({
      cliente: values[0],
      registros: values,
    }))
    .value();
};

export const agruparPorContaFamiliaCorretor = registros => {
  return _(registros)
    .filter(r => r.tipo_comissao === TIPO_COMISSAO_COR)
    .groupBy(
      reg =>
        `${reg.codigo_familia}-${reg.codigo_conta_cliente}-${reg.codigo_usuario_corretor}`
    )
    .map((values, key) => {
      const [codigo_familia, codigo_conta_cliente] = key.split('-'); // this is just insane...
      return {
        cliente: values[0],
        registros: registros.filter(
          reg =>
            reg.codigo_familia === Number(codigo_familia) &&
            reg.codigo_conta_cliente === Number(codigo_conta_cliente)
        ),
      };
    })
    .value();
};

export const agruparPorVenda = registros => {
  return _(registros)
    .groupBy(p => p.numero_pre_venda)
    .map(values => ({
      preVenda: values[0],
      titulos: distinctTitulos(values),
    }))
    .value();
};

export const agruparPorCorretor = registros => {
  return _(registros)
    .filter(r => r.tipo_comissao === TIPO_COMISSAO_COR)
    .groupBy(p => p.codigo_usuario_corretor)
    .map(values => ({
      corretor: values[0],
      registros: values,
    }))
    .sortBy(r => r.corretor.apelido_conta_corretor)
    .value();
};

export const agruparPorGerente = registros => {
  var porGerente = _(registros)
    .groupBy(p => p.codigo_usuario_gerente)
    .map(values => ({
      gerente: values[0],
      registros: values,
    }))
    .value();
  const semImobiliaria = porGerente.find(
    g =>
      !g.gerente.codigo_usuario_gerente ||
      g.gerente.codigo_usuario_gerente === 0
  );
  if (!porGerente.find(g => g.gerente.codigo_usuario_gerente === 1077))
    porGerente.push({
      gerente: {
        codigo_usuario_gerente: 1077,
        nome_imobiliaria: 'Revo',
      },
      registros: [],
    });
  return porGerente
    .map(g => {
      if (
        !g.gerente.codigo_usuario_gerente ||
        g.gerente.codigo_usuario_gerente === 0
      )
        return null;
      if (g.gerente.codigo_usuario_gerente !== 1077) return g;
      if (semImobiliaria) g.registros.push(...semImobiliaria.registros);
      if (g.registros.length === 0) return null;
      return g;
    })
    .filter(g => g != null);
};

export const agruparPorEmpreendimento = registros => {
  return _(registros)
    .groupBy(p => p.codigo_familia)
    .map(values => ({
      empreendimento: values[0],
      registros: values,
    }))
    .value();
};

export const distinctTitulos = registros => {
  return _(registros)
    .uniqBy(p => p.codigo_titulo_sinal)
    .value();
};

export const obterNumeroVendasRegistro = registro => {
  return agruparPorVenda(registro).length;
};

export const obterTotalTitulosSinal = registros => {
  return distinctTitulos(registros).reduce(
    (totalCliente, titulo) => totalCliente + titulo.valor_titulo_sinal,
    0
  );
};
export const obterTotalComissao = (registros, tipo = TIPO_COMISSAO_COR) => {
  return registros
    .filter(titulo => titulo.tipo_comissao === tipo)
    .reduce((total, titulo) => total + titulo.valor_titulo_comissao, 0);
};

export const obterTotalComissaoVirtual = registros => {
  return (
    registros
      // .filter(titulo => titulo.tipo_comissao === tipo)
      .reduce((total, titulo) => total + titulo.valor_virtual_comissao || 0, 0)
  );
};

export const valoresComissaoSupervisor = registros => {
  const valorComissao = obterTotalComissao(registros, TIPO_COMISSAO_SUP);
  const valorComissaoVirtual = obterTotalComissaoVirtual(registros);
  if (valorComissaoVirtual) {
    const v1 = formatarMonetario(valorComissao, false);
    const v2 = formatarMonetario(valorComissaoVirtual, false);
    return `${v1} + ${v2}`;
  }
  return formatarMonetario(valorComissao) || '-';
};
export const GRAVAR_OCORRENCIA = 'gravar';
export const ENCAMINHAR_COBRANCA = 'encaminhar';

export const TIPO_COMISSAO_COR = 1;
export const TIPO_COMISSAO_IMO = 3;
export const TIPO_COMISSAO_MKT = 10;
export const TIPO_COMISSAO_SUP = 5;
// export const TIPO_COMISSAO_VIR = 999; // comissão virtual, aka "1% vagabundo"
