import { combineReducers } from 'redux';

const dataVenda = (state = null, action) => {
  switch (action.type) {
    case '@vendas/SET_DATA_VENDA':
      return action.payload;
    default:
      return state;
  }
};

const codigoPontoVenda = (state = null, action) => {
  switch (action.type) {
    case '@vendas/SET_PDV':
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  dataVenda,
  codigoPontoVenda,
});
