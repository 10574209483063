import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import { MdError } from 'react-icons/md';

const ErrorAlert = ({ title, children, danger }) => {
  return (
    <Alert variant={danger ? 'danger' : ''}>
      <Row>
        <Col sm={12} md={1} className="text-center">
          <MdError fontSize={42} color="#DC3545" />
        </Col>
        <Col sm={12} md={11}>
          <p>
            <strong>{title || 'Ops. Algo deu errado...'}</strong>
          </p>
          {children}
        </Col>
      </Row>
    </Alert>
  );
};

export default ErrorAlert;
