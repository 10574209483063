import { useEffect } from 'react';

const EfetivacaoEvaluator = ({
  contraSenha,
  resultadoContraSenha,
  codigoUsuarioAutorizacao,
  senha,
  credenciaisValidas,
  onUpdate,
  autorizando,
}) => {
  useEffect(() => {
    let podeEfetivar = false;
    if (contraSenha && contraSenha === resultadoContraSenha.toString())
      podeEfetivar = true;
    if (codigoUsuarioAutorizacao && senha && credenciaisValidas)
      podeEfetivar = true;
    onUpdate(podeEfetivar || autorizando);
  }, [contraSenha, codigoUsuarioAutorizacao, senha, credenciaisValidas]);
  return null;
};

export default EfetivacaoEvaluator;
