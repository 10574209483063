import React from 'react';
import { Link } from 'react-router-dom';
import _keyBy from 'lodash/keyBy';
import { FaMapMarkedAlt, FaTimes, FaPlus, FaTrash } from 'react-icons/fa';

import {
  PROSPECTO_STATUS,
  EMPREENDIMENTO_LANCAMENTO,
  PROSPECTO_STATUS_ENUM,
} from '~/constant';
import { format } from '~/Utils/Helpers';
import { default as auth } from '~/Utils/AuthStorage';

const PROSPECTO_STATUS_BY_ID = _keyBy(PROSPECTO_STATUS, 'value');

export default function ItemEmpreendimento({
  onClickEmpreendimento,
  exibeAcoes,
  exibeContador,
  descricao,
  isSelecionado,
  empreendimento,
  onClickEditarEmpreendimento,
  editandoEmpreendimento,
  onClickCancelar,
  linkReservar,
  exibeBotoes = true,
}) {
  const vinculoValido =
    empreendimento.codigo_status !== PROSPECTO_STATUS_ENUM.descartado;
  const codigoUsuario = auth.getUser().usuario;
  const podeDescartar =
    vinculoValido &&
    (empreendimento.codigo_corretor === codigoUsuario ||
      empreendimento.codigo_gerente === codigoUsuario ||
      auth.isADM()) &&
    empreendimento.codigo_status !== PROSPECTO_STATUS_ENUM.proposta_realizada &&
    empreendimento.codigo_status !== PROSPECTO_STATUS_ENUM.pre_venda_efetivada;

  let selectoStyle = `.empreendimento-${empreendimento.codigo_familia}::before{ border-left-color: ${empreendimento.imobiliaria.cor} }`;

  const styleProspecto = (
    <style dangerouslySetInnerHTML={{ __html: selectoStyle }}></style>
  );
  return (
    <div
      className={`arrow-bottom-left d-flex w-100 justify-content-between align-items-stretch align-items-lg-center ${isSelecionado &&
        'text-primary'} ${exibeAcoes &&
        exibeContador &&
        'Empreedimento__etiqueta-contador'} Empreendimento__item empreendimento-${
        empreendimento.codigo_familia
      }`}
    >
      {styleProspecto}
      <div
        className="d-flex justify-content-between flex-column Empreendimento__detalhes-wrapper "
        onClick={onClickEmpreendimento}
      >
        <div className="Empreendimento__detalhes">
          <p className=" align-middle line-height-1 m-0">{descricao}</p>
          <ul className="list-unstyled flex-wrap d-flex mb-0 mt-1 Empreendimento__lista-detalhes">
            {!editandoEmpreendimento &&
              PROSPECTO_STATUS_BY_ID[empreendimento.codigo_status] && (
                <li className="mr-2 mb-1">
                  <small className="d-block text-muted">Status</small>
                  <strong className="d-block font-weight-normal">
                    {PROSPECTO_STATUS_BY_ID[empreendimento.codigo_status].label}
                  </strong>
                </li>
              )}
            <li className="mr-2 mb-1">
              <small className="d-block text-muted">Data de vínculo</small>
              <strong className="d-block font-weight-normal">
                {format.date(empreendimento.data_vinculo)}
              </strong>
            </li>
            <li className="mr-2 mb-1">
              <small className="d-block text-muted">Corretor</small>
              <strong className="d-block font-weight-normal">
                {empreendimento.corretor.nome}
              </strong>
            </li>
            <li className="mr-2 mb-1">
              <small className="d-block text-muted">Imobiliária</small>
              <strong className="d-block font-weight-normal">
                <strong
                  className="bullet"
                  style={{ backgroundColor: empreendimento.imobiliaria.cor }}
                ></strong>{' '}
                {empreendimento.imobiliaria.nome}
              </strong>
            </li>
          </ul>
        </div>
      </div>
      {exibeAcoes && exibeBotoes && (
        <div className="Empreendimento__item__actions d-flex flex-row align-items-stretch align-items-lg-center">
          <div className="d-flex flex-column flex-lg-row align-items-lg-center Empreendimento__action-reservar">
            {empreendimento &&
              empreendimento.codigo_familia !== EMPREENDIMENTO_LANCAMENTO && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={linkReservar}
                  className="d-block btn btn-outline-success"
                >
                  <FaMapMarkedAlt />
                  <span className="ml-xl-1 d-none d-lg-block d-xl-inline">
                    Reservar Lote
                  </span>
                </a>
              )}
            {podeDescartar && (
              <button
                className="d-block btn btn-outline-secondary"
                type="button"
                onClick={onClickEditarEmpreendimento}
              >
                <FaTrash />
                <span className="ml-xl-1 d-none d-lg-block d-xl-inline">
                  Descartar
                </span>
              </button>
            )}
          </div>
          <div className="Empreendimento__action-adicionar">
            <Link
              className="d-block h-100 btn btn-outline-primary Empreendimtno__btn-adicionar"
              to={{ search: '?vincular-empreendimento=true' }}
            >
              <FaPlus />
              <span className="ml-xl-1 d-none d-lg-block d-xl-inline">
                Adicionar
              </span>
            </Link>
          </div>
        </div>
      )}
      {editandoEmpreendimento === empreendimento.codigo_familia && (
        <div
          className="Empreendimento__item__actions Empreendimento__item__action--cancelar  btn btn-link cursor-pointer btn-sm"
          onClick={onClickCancelar}
        >
          <FaTimes />
          <span className="ml-1">Cancelar</span>
        </div>
      )}
    </div>
  );
}
