import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Container, Button, Row, Col, Dropdown, Navbar } from 'react-bootstrap';
import { FiList, FiBarChart2, FiSearch } from 'react-icons/fi';
import { FaPlus } from 'react-icons/fa';

import { IoIosPeople } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

import ProspectoBuscaFormulario from '~/Components/Prospectos/ProspectoBusca/ProspectoBuscaFormulario';
import Outsider from '~/Components/Outsider';

import { Avatar } from '~/Pages/Prospectos/styles';
import { AvatarImage } from '~/Styles';
import { ContainerBusca, DropdownCorretor } from './styles';

export default function ProspectosNavbar({
  filterIsDirty,
  isSearchActive,
  setSearchActive,
  filtros,
  visualizarGerentes,
  imobiliarias,
  visualizarCorretores,
  corretoresSelecionaveis,
  onSubmitSearch,
  onResetSearch,
  empreendimentos,
  isLoading,
  readonly,
}) {
  const [filtroCorretor, setFiltroCorretor] = useState('');
  const [corretoresFiltrados, setCorretoresFiltrados] = useState([]);

  useEffect(() => {
    if (corretoresFiltrados.length === 0) {
      setCorretoresFiltrados(corretoresSelecionaveis);
    }
  }, [corretoresSelecionaveis]);

  useEffect(() => {
    setCorretoresFiltrados(
      corretoresSelecionaveis
        .filter(
          item =>
            !filtros.codigo_gerente ||
            item.codigo_gerente === filtros.codigo_gerente
        )
        .filter(item => {
          return (
            !filtroCorretor ||
            item.nome.toLowerCase().includes(filtroCorretor.toLowerCase())
          );
        })
    );
  }, [filtros, filtroCorretor]);

  const imobiliariaSelecionada = imobiliarias.find(
    item => item.codigo === filtros.codigo_gerente
  );
  const corretorSelecionado = corretoresSelecionaveis.find(
    item => item.codigo === filtros.codigo_corretor
  );

  function onClickSelecionaCorretor(codigo_corretor) {
    onSubmitSearch({ ...filtros, codigo_corretor });
  }

  function onClickSelecionaGerente(codigo_gerente) {
    onSubmitSearch({ ...filtros, codigo_gerente });
  }

  return (
    <Outsider onClickOut={() => setSearchActive(false)}>
      <Navbar className="bg-white" expand="lg">
        <div className="mr-lg-auto ml-lg-0 mx-auto">
          <h5 className="mr-3 navbar-text mb-0 text-body">Prospectos</h5>
          {!readonly && (
            <Link
              className="d-inline-flex mr-1 btn btn-success align-items-center justify-content-center"
              to={{ search: '?novo=true' }}
            >
              <FaPlus /> <span className="ml-2 d-xs-none">Adicionar</span>
            </Link>
          )}
          <Button
            className="mr-2 active"
            variant="outline-secondary"
            title="Visão Funil"
          >
            <FiBarChart2 size={15} style={{ transform: 'rotate(180deg)' }} />
          </Button>
          <Link
            className="mr-2 btn btn-outline-secondary"
            title="Visão Lista"
            to="/prospectos/lista"
          >
            <FiList size={15} />
          </Link>
          <Button
            size={20}
            variant="outline-secondary"
            onClick={() => setSearchActive(!isSearchActive)}
            className={`mr-2 ${filterIsDirty &&
              !isSearchActive &&
              'have-search'}`}
          >
            {isSearchActive ? <MdClose size={15} /> : <FiSearch size={15} />}
          </Button>
        </div>
        <div className="mt-2 mt-lg-0 ml-lg-auto mr-lg-0 mx-auto d-flex flex-row z20">
          {visualizarGerentes && (
            <Dropdown className="mr-2" alignRight>
              <Dropdown.Toggle
                disabled={isLoading}
                className="d-flex align-items-center justify-content-center"
                variant="light"
                id="dropdown-gerente"
              >
                {imobiliariaSelecionada ? (
                  <>
                    <Avatar>
                      <AvatarImage
                        src={imobiliariaSelecionada.logo}
                        alt={imobiliariaSelecionada.nome}
                      />
                    </Avatar>
                    <span className="ml-2">{imobiliariaSelecionada.nome}</span>
                  </>
                ) : (
                  <>
                    <Avatar>
                      <IoIosPeople />
                    </Avatar>
                    <span className="ml-2">Todas Imobiliárias</span>
                  </>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={!filtros.codigo_gerente}
                  onClick={() => onClickSelecionaGerente(null)}
                >
                  <div className="d-flex flex-row">
                    <Avatar>
                      <IoIosPeople color="#000" />
                    </Avatar>
                    <span className="ml-2">Todas Imobiliárias</span>
                  </div>
                </Dropdown.Item>
                {imobiliarias.map(imobiliaria => (
                  <Dropdown.Item
                    key={imobiliaria.codigo}
                    active={
                      filtros.codigo_gerente &&
                      filtros.codigo_gerente === imobiliaria.codigo
                    }
                    as="button"
                    onClick={() => onClickSelecionaGerente(imobiliaria.codigo)}
                  >
                    <div className="d-flex align-item-center">
                      <Avatar>
                        <AvatarImage
                          src={imobiliaria.logo}
                          alt={imobiliaria.nome}
                        />
                      </Avatar>
                      <span className="ml-2">{imobiliaria.nome}</span>
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {visualizarCorretores && (
            <DropdownCorretor className="mr-2" alignRight>
              <Dropdown.Toggle
                disabled={isLoading}
                className="d-flex align-items-center justify-content-center"
                variant="light"
                id="dropdown-gerente"
              >
                {corretorSelecionado ? (
                  <>
                    <Avatar>
                      <AvatarImage
                        src={corretorSelecionado.foto}
                        alt={corretorSelecionado.nome}
                      />
                    </Avatar>
                    <span className="ml-2">
                      {corretorSelecionado.nome.split(/(\s).+\s/).join('')}
                    </span>
                  </>
                ) : (
                  <>
                    <Avatar>
                      <IoIosPeople color="#000" />
                    </Avatar>
                    <span className="ml-2">Todos Corretores</span>
                  </>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="m-2">
                  <div className="input-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Procurar corretor..."
                      onChange={e => setFiltroCorretor(e.target.value)}
                      value={filtroCorretor}
                    />
                  </div>
                </div>
                <PerfectScrollbar style={{ maxHeight: 300, paddingBottom: 30 }}>
                  {!filtroCorretor && (
                    <Dropdown.Item
                      active={!filtros.codigo_corretor}
                      onClick={() => onClickSelecionaCorretor(null)}
                    >
                      <div className="d-flex flex-row align-items-center">
                        <Avatar>
                          <IoIosPeople color="#000" />
                        </Avatar>
                        <span className="ml-2">Todos Corretores</span>
                      </div>
                    </Dropdown.Item>
                  )}
                  {corretoresFiltrados.map(corretor => (
                    <Dropdown.Item
                      key={corretor.codigo}
                      active={
                        filtros.codigo_corretor &&
                        filtros.codigo_corretor === corretor.codigo
                      }
                      onClick={() => onClickSelecionaCorretor(corretor.codigo)}
                    >
                      <div className="d-flex flex-row align-items-center">
                        <Avatar>
                          <AvatarImage
                            src={corretor.foto}
                            alt={corretor.nome}
                          />
                        </Avatar>
                        <span className="ml-2">
                          {corretor.nome.split(/(\s).+\s/).join('')}
                        </span>
                      </div>
                    </Dropdown.Item>
                  ))}
                </PerfectScrollbar>
              </Dropdown.Menu>
            </DropdownCorretor>
          )}
        </div>
      </Navbar>
      {isSearchActive && (
        <ContainerBusca>
          <Container>
            <Row>
              <Col>
                <ProspectoBuscaFormulario
                  onSubmit={onSubmitSearch}
                  onReset={onResetSearch}
                  filter={filtros}
                  empreendimentos={empreendimentos}
                  filterIsDirty={filterIsDirty}
                  exibirFiltroEtapa={false}
                  exibirFiltroGerenteCorretor={false}
                />
              </Col>
            </Row>
          </Container>
        </ContainerBusca>
      )}
    </Outsider>
  );
}
