import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import { FiAlertCircle } from 'react-icons/fi';
import { FcMoneyTransfer, FcSalesPerformance, FcBullish } from 'react-icons/fc';

import { default as auth } from '~/Utils/AuthStorage';

class CardComissaoResumo extends Component {
  formatarReal = valor =>
    valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });

  render() {
    const { resumo, carregandoComissoes } = this.props;
    const isAdm = auth.isADM();
    if (!resumo || carregandoComissoes) return null;
    return (
      <Col>
        <Row className="CardProdutividadeResumo">
          {isAdm && (
            <Col xs={12} md={6} xl={4}>
              <Card>
                <Card.Body>
                  <div className="produtividade-icon responsive">
                    <FcBullish />
                  </div>
                  <ul className="produtividade produtividade-resumo">
                    <li className="Destaque">
                      <small>Valor total de comisões</small>
                      <strong className="d-block">
                        <span>R$ </span>
                        {this.formatarReal(resumo.valor_total_comissoes)}
                      </strong>
                    </li>
                    <ul className="ul-inline">
                      <li>
                        <small>Clientes</small>
                        <strong className="d-block">
                          {resumo.qtd_clientes_com_comissao_aberta}
                        </strong>
                      </li>
                      <li>
                        <small>Contratos</small>
                        <strong className="d-block">
                          {resumo.qtd_contratos_com_comissao_aberta}
                        </strong>
                      </li>
                    </ul>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col xs={12} md={6} xl={4} className="mx-auto">
            <Card className="border border-primary">
              <Card.Body>
                <div className="produtividade-icon responsive">
                  <FcMoneyTransfer />
                </div>
                <ul className="produtividade produtividade-resumo">
                  <li className="Destaque">
                    <small>
                      Projeção de comissões em aberto
                      <OverlayTrigger
                        trigger={['focus', 'hover']}
                        placement={'right'}
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Title as="h3">AVISO LEGAL</Popover.Title>
                            <Popover.Content className="text-danger">
                              Valores atrelados ao efetivo pagamento do sinal
                              pelo adquirente
                            </Popover.Content>
                          </Popover>
                        }
                      >
                        <FiAlertCircle
                          color="#f00"
                          size={18}
                          className="ml-2"
                        />
                      </OverlayTrigger>
                    </small>
                    <strong className="d-block">
                      <span>R$ </span>
                      {this.formatarReal(resumo.projecao_comissao_aberta)}
                    </strong>
                  </li>
                  <ul className="ul-inline">
                    <li>
                      <small>
                        <strong className="text-warning">Vencidas</strong>
                      </small>
                      <strong className="d-block" title={`R$ 5.000,00`}>
                        <span>R$ </span>
                        {this.formatarReal(resumo.valor_total_inadimplente)}
                      </strong>
                    </li>
                    <li>
                      <small>
                        <strong className="text-success">A vencer</strong>
                      </small>
                      <strong className="d-block" title={`R$ 5.000,01`}>
                        <span>R$ </span>
                        {this.formatarReal(resumo.valor_total_a_vencer)}
                      </strong>
                    </li>
                  </ul>
                </ul>
              </Card.Body>
            </Card>
          </Col>
          {isAdm && (
            <Col xs={12} md={6} xl={4}>
              <Card>
                <Card.Body>
                  <div className="produtividade-icon responsive">
                    <FcSalesPerformance />
                  </div>
                  <ul className="produtividade produtividade-resumo">
                    <li className="Destaque">
                      <small>Comissões pagas baixadas</small>
                      <strong className="d-block">
                        <span>R$ </span>
                        {this.formatarReal(resumo.valor_total_comissao_baixada)}
                      </strong>
                    </li>
                    <ul className="ul-inline">
                      <li>
                        <small>Clientes</small>
                        <strong className="d-block">
                          {resumo.qtd_clientes_com_comissao_baixada}
                        </strong>
                      </li>
                      <li>
                        <small>Contratos</small>
                        <strong className="d-block">
                          {resumo.qtd_contratos_com_comissao_baixada}
                        </strong>
                      </li>
                    </ul>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Col>
    );
  }
}

const mapStateToProps = state => ({
  resumo: state.comissoes.dados,
  carregandoComissoes: state.comissoes.carregandoComissoes,
});

export default withRouter(connect(mapStateToProps)(CardComissaoResumo));
