import React from 'react';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { FaUser, FaCalendar } from 'react-icons/fa';

import notFound from '~/Images/not-found.svg';
import Aviso from '../../Aviso';
import { format } from '~/Utils/Helpers';

const ModalOcorrenciasInadimplencias = ({ ocorrencias, show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdropClassName="z-index-1060"
      className="z-index-1060"
    >
      <Modal.Header closeButton>Ocorrências</Modal.Header>
      <Modal.Body>
        {ocorrencias.length === 0 && (
          <Aviso
            icon={<img src={notFound} alt="not found" style={{ height: 60 }} />}
            text="Nenhuma ocorrência registrada"
          />
        )}
        {ocorrencias.map((o, index) => (
          <React.Fragment key={index}>
            <h5>
              <strong>{o.titulo}</strong>
            </h5>
            <Row>
              <Col>
                <small className="text-muted d-flex align-items-center">
                  <FaUser className="mr-1" />
                  {o.usuario.nome}
                </small>
              </Col>
              <Col>
                <small className="float-right text-muted d-flex align-items-center">
                  <FaCalendar className="mr-1" />
                  {format.datetime(o.data)}
                </small>
              </Col>
            </Row>
            <p>{o.texto.replace('==================', '')}</p>
            <hr />
          </React.Fragment>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalOcorrenciasInadimplencias;
