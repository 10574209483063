import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { format } from '../../../Utils/Helpers';

const ModalProspectoExistente = ({
  show,
  onCancel,
  prospectoParaEncaminhar,
  onRedirect,
  isEmail,
}) => {
  return (
    <Modal show={!!show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Prospecto já existente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Esse {isEmail ? 'e-mail' : 'CPF'} já está sendo utilizado por
        {prospectoParaEncaminhar && (
          <dl>
            <dt>Código</dt>
            <dd>{prospectoParaEncaminhar.codigo}</dd>
            <dt>Nome</dt>
            <dd>{prospectoParaEncaminhar.nome}</dd>
            <dt>Cadastrado em</dt>
            <dd>{format.date(prospectoParaEncaminhar.data_cadastro)}</dd>
          </dl>
        )}
      </Modal.Body>
      <Modal.Footer className="flex-column flex-md-row">
        <Button variant="primary" className="mb-1" onClick={onRedirect}>
          Carregar dados do cliente
        </Button>
        <Button variant="outline-primary" onClick={onCancel}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProspectoExistente;
