import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import * as api from '~/Services/Service';
import { format } from '~/Utils/Helpers';
import { SEXO } from '~/constant';

import { LineResume, TitleLineResume, ContainerResume } from './styles';

function CardImportacaoLead({
  AtendimentoID,
  empreendimentos,
  isLoadingOptions,
}) {
  const [dadosLead, setDadosLead] = useState({});
  const [prospecto, setProspecto] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [empreendimentoSelecionado, setEmpreendimentoSelecionado] = useState(0);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { lead_crm, prospecto } = await api.obterLeadDoCRM(AtendimentoID);
        setDadosLead(lead_crm);
        setProspecto(prospecto);
        setEmpreendimentoSelecionado(lead_crm.codigo_familia);
        RedirecionarProspectoSeCadastrado(prospecto);
        setIsLoading(false);
      } catch (err) {
        var msg = err.message || 'Erro ao buscar dados';
        setErrorMessage(msg);
        setIsLoading(false);
        toast.error(msg);
      }
    })();
  }, []);

  const RedirecionarProspectoSeCadastrado = prospectoExtranet => {
    if (
      !prospectoExtranet ||
      (!prospectoExtranet.codigo_cliente_anapro && !prospectoExtranet.id_crm)
    )
      return;

    setIsSubmitting(true);
    toast.warning(`Prospecto já cadastrado! Redirecionando para o cadastro...`);

    setTimeout(() => {
      history.push(`/prospectos/lista/${prospectoExtranet.hash_codigo}`);
      setIsSubmitting(false);
    }, 5000);
  };

  const ImportarOuAtualizarLeadNaExtranet = async () => {
    setIsSubmitting(true);
    try {
      const resposta = await api.importarLeadCRM(
        AtendimentoID,
        empreendimentoSelecionado
      );

      toast.success(
        `Lead ${
          prospecto ? 'atualizado' : 'importado'
        } com sucesso! Você será redirecionado, aguarde...`
      );

      setTimeout(() => {
        history.push(`/prospectos/lista/${resposta.codigo}`);
        setIsSubmitting(false);
      }, 5000);
    } catch (err) {
      toast.error(err.message || 'Erro ao atualizar dados');
      setIsSubmitting(false);
    }
  };

  const optionsEmpreendimento = empreendimentos.map(e => ({
    value: e.codigo,
    label: e.descricao,
  }));

  return (
    <div>
      {isLoading && (
        <div className="text-center mt-5">Carregando dados do CRM...</div>
      )}
      {errorMessage && (
        <div className="text-center mt-5 text-danger">{errorMessage}</div>
      )}

      {!isLoadingOptions && !isLoading && !errorMessage && (
        <>
          {prospecto && (
            <>
              <div className="my-2 text-center p-1 m-1">
                <span
                  className={`p-1 text-wrap badge badge-${
                    dadosLead && dadosLead.bloqueia_atualizacao
                      ? 'danger'
                      : 'warning'
                  }`}
                >
                  ATENÇÃO!{' '}
                  {dadosLead && dadosLead.bloqueia_atualizacao
                    ? 'O cliente está atribuido a outro corretor nesse empreendimento e não pode ser atualizado. Informe a central de vendas!'
                    : 'Cliente já cadastrado na Extranet, por favor, revise os dados abaixo antes da atualização'}
                </span>
              </div>
              <Card className="col-12 col-md-9 mx-auto">
                <Card.Body>
                  <ContainerResume>
                    <strong className="header-section">
                      Perfil do cliente (Extranet)
                    </strong>
                    <ul>
                      <LineResume>
                        <TitleLineResume>Informações pessoais</TitleLineResume>
                        <ul>
                          <li>
                            <small>Nome</small>
                            <strong>{prospecto.nome}</strong>
                          </li>
                          <li>
                            <small>CPF</small>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              title="Abrir cadastro existente"
                              href={`/prospectos/lista/${prospecto.hash_codigo}`}
                            >
                              <strong>
                                {prospecto.cpf
                                  ? format.cpf(prospecto.cpf)
                                  : '-'}
                              </strong>
                            </a>
                          </li>
                          <li>
                            <small>Tipo do Cadastro</small>
                            <strong>{'Pessoa Física'}</strong>
                          </li>
                          <li>
                            <small>Gênero</small>
                            <strong>
                              {prospecto.sexo
                                ? SEXO.find(
                                    item => item.value === prospecto.sexo
                                  ).label
                                : '-'}
                            </strong>
                          </li>
                          <li>
                            <small>Data de nascimento</small>
                            <strong>
                              {prospecto.nascimento
                                ? format.date(prospecto.nascimento)
                                : '-'}
                            </strong>
                          </li>
                        </ul>
                      </LineResume>
                      <LineResume>
                        <TitleLineResume>Contatos</TitleLineResume>
                        <ul>
                          <li>
                            <small>Telefone Celular</small>
                            <strong>
                              {prospecto.tel_celular
                                ? format.celular(prospecto.tel_celular)
                                : '-'}
                            </strong>
                          </li>
                          <li>
                            <small>Telefone Residencial</small>
                            <strong>
                              {prospecto.tel_residencial
                                ? format.celular(prospecto.tel_residencial)
                                : '-'}
                            </strong>
                          </li>
                          <li>
                            <small>Telefone Comercial</small>
                            <strong>
                              {prospecto.tel_comercial
                                ? format.celular(prospecto.tel_comercial)
                                : '-'}
                            </strong>
                          </li>
                          <li>
                            <small>E-mail</small>
                            <strong>{prospecto.email || '-'}</strong>
                          </li>
                        </ul>
                      </LineResume>
                      <LineResume className="mb-0">
                        <TitleLineResume>Endereço</TitleLineResume>
                        <ul>
                          <li>
                            <small>Endereço</small>
                            <strong>{prospecto.endereco || '-'}</strong>
                          </li>
                          <li>
                            <small>Número</small>
                            <strong>{prospecto.numero || '-'}</strong>
                          </li>
                          <li>
                            <small>Bairro</small>
                            <strong>{prospecto.bairro || '-'}</strong>
                          </li>
                          <li>
                            <small>Complemento</small>
                            <strong>{prospecto.complemento || '-'}</strong>
                          </li>
                          <li>
                            <small>CEP</small>
                            <strong>
                              {prospecto.cep ? format.cep(prospecto.cep) : '-'}
                            </strong>
                          </li>
                          <li>
                            <small>Cidade</small>
                            <strong>{prospecto.cidade || '-'}</strong>
                          </li>
                          <li>
                            <small>Estado</small>
                            <strong>{prospecto.estado || '-'}</strong>
                          </li>
                        </ul>
                      </LineResume>
                    </ul>
                  </ContainerResume>
                </Card.Body>
              </Card>
              <div className="my-3"></div>
            </>
          )}

          {dadosLead && optionsEmpreendimento && (
            <Card className="col-12 col-md-9 mx-auto">
              <Card.Body>
                <ContainerResume>
                  <strong className="header-section">Interesse</strong>
                  <ul>
                    <LineResume>
                      <TitleLineResume>Empreendimento</TitleLineResume>
                      <div className="col-12 col-md-8 col-lg-6">
                        <Select
                          isLoading={isLoadingOptions}
                          isDisabled={
                            isLoadingOptions ||
                            (dadosLead && dadosLead.codigo_familia)
                          }
                          value={optionsEmpreendimento.find(
                            option => option.value === empreendimentoSelecionado
                          )}
                          name="codigo_familia"
                          options={optionsEmpreendimento}
                          placeholder={'Selecione um empreendimento'}
                          onChange={e => setEmpreendimentoSelecionado(e.value)}
                        />
                      </div>
                    </LineResume>
                  </ul>
                  <strong className="header-section">
                    Perfil do cliente (CRM)
                  </strong>
                  <ul>
                    <LineResume>
                      <TitleLineResume>Informações pessoais</TitleLineResume>
                      <ul>
                        <li>
                          <small>Nome</small>
                          <strong>{dadosLead.nome}</strong>
                        </li>
                        <li>
                          <small>CPF</small>
                          <strong>
                            {dadosLead.cpf ? format.cpf(dadosLead.cpf) : '-'}
                          </strong>
                        </li>
                        <li>
                          <small>Tipo do Cadastro</small>
                          <strong>{'Pessoa Física'}</strong>
                        </li>
                        <li>
                          <small>Gênero</small>
                          <strong>
                            {dadosLead.genero
                              ? SEXO.find(
                                  item => item.value === dadosLead.genero
                                ).label
                              : '-'}
                          </strong>
                        </li>
                        <li>
                          <small>Data de nascimento</small>
                          <strong>
                            {dadosLead.nascimento
                              ? format.date(dadosLead.nascimento)
                              : '-'}
                          </strong>
                        </li>
                      </ul>
                    </LineResume>
                    <LineResume>
                      <TitleLineResume>Contatos</TitleLineResume>
                      <ul>
                        <li>
                          <small>Telefone Celular</small>
                          <strong>
                            {dadosLead.tel_celular
                              ? format.celular(dadosLead.tel_celular)
                              : '-'}
                          </strong>
                        </li>
                        <li>
                          <small>Telefone Residencial</small>
                          <strong>
                            {dadosLead.tel_residencial
                              ? format.celular(dadosLead.tel_residencial)
                              : '-'}
                          </strong>
                        </li>
                        <li>
                          <small>Telefone Comercial</small>
                          <strong>
                            {dadosLead.tel_comercial
                              ? format.celular(dadosLead.tel_comercial)
                              : '-'}
                          </strong>
                        </li>
                        <li>
                          <small>E-mail</small>
                          <strong>
                            {dadosLead.email ? dadosLead.email : '-'}
                          </strong>
                        </li>
                      </ul>
                    </LineResume>
                    <LineResume className="mb-0">
                      <TitleLineResume>Endereço</TitleLineResume>
                      <ul>
                        <li>
                          <small>Endereço</small>
                          <strong>
                            {dadosLead.endereco.logradouro || '-'}
                          </strong>
                        </li>
                        <li>
                          <small>Número</small>
                          <strong>{dadosLead.endereco.numero || '-'}</strong>
                        </li>
                        <li>
                          <small>Bairro</small>
                          <strong>{dadosLead.endereco.bairro || '-'}</strong>
                        </li>
                        <li>
                          <small>Complemento</small>
                          <strong>
                            {dadosLead.endereco.complemento || '-'}
                          </strong>
                        </li>
                        <li>
                          <small>CEP</small>
                          <strong>
                            {dadosLead.endereco.cep
                              ? format.cep(dadosLead.endereco.cep)
                              : '-'}
                          </strong>
                        </li>
                        <li>
                          <small>Cidade</small>
                          <strong>{dadosLead.endereco.cidade || '-'}</strong>
                        </li>
                        <li>
                          <small>Estado</small>
                          <strong>{dadosLead.endereco.estado || '-'}</strong>
                        </li>
                      </ul>
                    </LineResume>
                  </ul>
                </ContainerResume>
              </Card.Body>
              <Card.Footer className="bg-white mb-1 text-right">
                <Button
                  disabled={
                    isLoading ||
                    (dadosLead && dadosLead.bloqueia_atualizacao) ||
                    isSubmitting
                  }
                  variant={prospecto ? 'warning' : 'success'}
                  onClick={ImportarOuAtualizarLeadNaExtranet}
                >
                  {isSubmitting
                    ? 'Enviando informações'
                    : prospecto
                    ? 'Atualizar Cadastro'
                    : 'Importar Prospecto'}
                </Button>
              </Card.Footer>
            </Card>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    empreendimentos: state.empreendimentos.sort,
    isLoadingOptions: state.prospecto.isLoadingOptions,
  };
};

export default connect(mapStateToProps)(CardImportacaoLead);
