import React from 'react';
import { Button } from 'react-bootstrap';
import {
  verificarBloqueioContratoSemConjuge,
  verificarPermissaoExibicaoContratoVendaPJ,
  verificarPodeGerenciar,
  isStatusPreUpload,
} from '../helpers';

const DisplayDocumentosContratoVenda = ({
  documento,
  onArquivoSelecionado,
  enviarCartaDesconto,
  setEnviarCartaDesconto,
}) => {
  const { pre_venda } = documento;
  const fileInputRef = React.createRef();
  return (
    <section>
      <h5>Documentos Envolvidos</h5>
      <strong className="d-block">
        Documentos a serem enviados para assinatura
      </strong>
      <small>
        Se o processo automático for utilizado, estes são os documentos enviados
        automaticamente para assinatura. Os documentos listados serão unificados
        num arquivo PDF antes de serem encaminhados à plataforma de assinatura
        digital.
      </small>
      <ul className="list-group">
        {pre_venda.documentos_para_assinatura.map(nomeLink => {
          const isCartaDesconto = nomeLink.nome === 'Carta de Desconto';
          const isContratoVenda = nomeLink.nome === 'Contrato da Venda';
          if (
            isContratoVenda &&
            (verificarBloqueioContratoSemConjuge(documento) ||
              !verificarPermissaoExibicaoContratoVendaPJ(documento))
          )
            return (
              <li className="list-group-item" key="Contrato da Venda">
                <span>Contrato da Venda (visualização não permitida)</span>
              </li>
            );
          else
            return (
              <li
                className={`list-group-item
                  ${isCartaDesconto ? ' d-flex justify-content-between' : ''}`}
                key={nomeLink.nome}
              >
                <a
                  href={nomeLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={
                    isCartaDesconto && !enviarCartaDesconto ? 'text-danger' : ''
                  }
                >
                  {nomeLink.nome}
                  {isCartaDesconto &&
                    !enviarCartaDesconto &&
                    ' (este documento não vai para assinatura)'}
                </a>
                {isCartaDesconto && (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="chkEnviarCartaDesconto"
                      name="chkEnviarCartaDesconto"
                      onChange={e => setEnviarCartaDesconto(e.target.checked)}
                      defaultChecked={enviarCartaDesconto}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="chkEnviarCartaDesconto"
                    >
                      Anexar Carta
                    </label>
                  </div>
                )}
              </li>
            );
        })}
      </ul>

      {pre_venda.documentos_extras.length > 0 && (
        <div className="mt-2">
          <strong className="d-block">Documentos extras</strong>
          <small>
            Estes documentos não requerem assinatura, e serão enviados por anexo
            no e-mail ao cliente no final do processo de assinatura, se o mesmo
            optar a receber a documentação por e-mail.
          </small>
          <ul className="list-group">
            {pre_venda.documentos_extras.map(nomeLink => (
              <li className="list-group-item" key={nomeLink}>
                <a
                  href={nomeLink.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {nomeLink.nome}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {isStatusPreUpload(documento) && verificarPodeGerenciar(documento) && (
        <section className="my-2">
          <p>
            O documento final de assinatura (contrato da venda, juntamente com
            os documentos associados) é gerado e enviado automaticamente ao
            realizar o upload do documento digital. Opcionalmente, você pode
            enviar um contrato personalizado, escolhendo um arquivo abaixo:
          </p>
          <Button
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            Escolher Contrato Personalizado
          </Button>
          <input
            type="file"
            className="d-none"
            capture
            ref={fileInputRef}
            onChange={event => {
              onArquivoSelecionado(event.target.files[0]);
            }}
          />
        </section>
      )}
    </section>
  );
};

export default DisplayDocumentosContratoVenda;
