import React from 'react';
import { Badge } from 'react-bootstrap';
import { MdWarning, MdEmail } from 'react-icons/md';
import { FaRegIdCard } from 'react-icons/fa';

import { format } from '~/Utils/Helpers';

import { StatusDocumentoDigital } from '~/constant';

const InfoSignatario = ({
  signatario,
  disabled,
  status,
  displayCpf,
  signatarios,
}) => {
  const FORMA_ENVIO_CORREIO = 1;
  const FORMA_ENVIO_EMAIL = 2;

  const isStatusAssinatura =
    status !== StatusDocumentoDigital.Cancelado &&
    status !== StatusDocumentoDigital.Descartado;

  const isEmailRepetido =
    signatarios.filter(s => s.email === signatario.email).length > 1;
  return (
    <div className="d-flex flex-column flex-sm-row justify-content-between flex-grow-1 mr-2">
      <div>
        <strong className={`d-block text-${disabled ? 'muted' : 'dark'}`}>
          {signatario.nome}{' '}
          <Badge variant="info">{signatario.descricao_tipo}</Badge>
        </strong>
        {displayCpf &&
          (signatario.cpf ? (
            <span className="d-block">
              <FaRegIdCard className="mr-1" />
              {format.cpf(signatario.cpf)}
            </span>
          ) : (
            <Badge
              variant="danger"
              className="d-inline-flex justify-content-center align-content-baseline px-2"
            >
              <MdWarning />
              CPF não cadastrado
            </Badge>
          ))}
        {signatario.email ? (
          <span className="d-block">
            <MdEmail className="mr-1" />
            {signatario.email}
            {isEmailRepetido && (
              <Badge
                variant="warning"
                className="ml-1 d-inline-flex justify-content-center align-content-baseline px-2"
              >
                <MdWarning className="mr-1" />
                E-mail repetido
              </Badge>
            )}
          </span>
        ) : (
          <Badge
            variant="danger"
            className="d-inline-flex justify-content-center align-content-baseline px-2"
          >
            <MdWarning className="mr-1" />
            E-mail não cadastrado
          </Badge>
        )}
      </div>
      <div className="d-flex align-items-start">
        {!signatario.ativo && (
          <small className="text-muted d-block text-right">Inativo</small>
        )}
        {!signatario.signatario && (
          <small className="text-right">
            Não incluído na solicitação de assinatura digital.
          </small>
        )}

        {signatario.signatario &&
          !signatario.assinado &&
          !signatario.codigo_signatario_substituto &&
          signatario.data_ultima_notificacao &&
          isStatusAssinatura && (
            <div>
              <small className="d-block text-right text-danger">
                Assinatura pendente
              </small>
              <small className="d-block text-muted text-right">
                Notificado em:{' '}
                {format.datetime(signatario.data_ultima_notificacao)}
              </small>
            </div>
          )}

        {signatario.codigo_signatario_substituto && (
          <small className="d-block text-right text-muted">
            Assinatura substituída por outro membro do grupo
          </small>
        )}

        {signatario.assinado &&
          isStatusAssinatura &&
          !signatario.codigo_signatario_substituto && (
            <div>
              <small className="d-block text-right text-success">
                Assinado
              </small>
              <small className="d-block text-muted text-right">
                {format.datetime(signatario.data_assinatura)}
              </small>
              {signatario.forma_envio === FORMA_ENVIO_CORREIO && (
                <small>Optou por envio por correios</small>
              )}
              {signatario.forma_envio === FORMA_ENVIO_EMAIL &&
                (signatario.data_envio_contrato ? (
                  <small>
                    Contrato enviado em{' '}
                    {format.datetime(signatario.data_envio_contrato)}
                  </small>
                ) : (
                  <small>Optou por envio por e-mail</small>
                ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default InfoSignatario;
